import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { listLeads } from "../../../graphql/queries";
import type { RootState } from "./../../../store";

// Define a type for the slice state
interface PaginationState {
  prevToken: any;
  nextToken: any;
  currentToken: any;
  status: string | null; // TODO: Replace any
}

// Define the initial state using that type
const initialState: PaginationState = {
  prevToken: null,
  nextToken: null,
  currentToken: null,
  status: null,
};

export const fetchLead = createAsyncThunk(
  "leads/listLeads",
  async (data: any) => {
    let token = data.paginationData.nextToken;

    if (data.page == "next") {
      paginationSlice.actions.nextPage();
    } else {
      paginationSlice.actions.prevPage();
    }
    const response = await API.graphql(graphqlOperation(listLeads, { token }));

    return response;
  }
);

export const paginationSlice = createSlice({
  name: "pagination",
  // `userSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // TODO: action handles
    nextPage: (state) => {
      state.prevToken = state.currentToken;
      state.currentToken = state.nextToken;
    },
    prevPage: (state) => {
      state.nextToken = state.currentToken;
      state.currentToken = state.prevToken;
    },
  },
});

export const pagination = (state: RootState) => state.pagination;
export const { nextPage, prevPage } = paginationSlice.actions;

export default paginationSlice.reducer;
