import { Outlet } from "react-router-dom";
import Sidebar from "../views/Sidebar";
import "./../styles/app.scss";
import { useEffect } from "react";

import { fetchTenant } from "../app/features/Tenant/tenantSlice";
import { fetchBodyParts } from "../app/features/Workout/workoutSlice";
import { useDispatch } from "react-redux";

const AppLayout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTenant(null));
    dispatch(fetchBodyParts(null));
  }, []);
  return (
    <div>
      <Sidebar />
      <div className="container">
        <Outlet />
      </div>
    </div>
  );
};
export default AppLayout;
