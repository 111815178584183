import { useLocation, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";

export function RequireAuth({ children }) {
  const location = useLocation();
  const [authState, setAuthState] = useState("loading");
  useEffect(() => {
    checkAuthState();
  }, []);

  async function checkAuthState() {
    try {
      await Auth.currentAuthenticatedUser();
      setAuthState("authenticated");
    } catch (error) {
      setAuthState("unauthenticated");
    }
  }

  if (authState === "loading") {
    return null;
  } else if (authState === "authenticated") {
    return children;
  } else {
    sessionStorage.setItem("intendedRoute", location.pathname);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
}
