import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { createLeadAction } from "../../app/features/leads";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ReactComponent as Ques } from "../../images/Ques.svg";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100%",
      maxHeight: "100%",
      width: "100%",
      background:
        "linear-gradient(240.68deg, rgba(204, 220, 124, 0.6) 4.24%, rgba(204, 220, 124, 0) 58.33%)",
      height: "100vh",
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    container: {
      margin: 30,
      width: "600px",
      background:
        "linear-gradient(240.68deg, rgba(204, 220, 124, 0.1) 4.24%, rgba(204, 220, 124, 0) 58.33%)",
    },
  })
);

function getSteps() {
  return [
    <b style={{ color: "purple" }}>'How can I help you?'</b>,
    <b style={{ color: "purple" }}>'I am'</b>,
    <b style={{ color: "purple" }}>'My age is'</b>,
    <b style={{ color: "purple" }}>
      'What would you like to achieve by working with me?'
    </b>,
    <b style={{ color: "purple" }}>'What is your motivation to start now?'</b>,
    <b style={{ color: "purple" }}>'Contact information '</b>,
  ];
}

export default function Questionnaire() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const listTenant: any = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;
  const [questionnaire, setQuestionnaire] = useState<any>({
    name: null,
    email: null,
    phone: null,
    ageRange: null,
    country: null,
    location: null,
    status: {
      leadStatus: "NEW_LEAD",
    },
    activeStatus: "NEW_LEAD",
    state: null,
    gender: null,
    goal: null,
    achievement: null,
    motivation: null,
  });
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const handleChange = (event) => {
    let field = {};
    field[event.target.name] = event.target.value;
    setQuestionnaire({ ...questionnaire, ...field });
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <form className="form-group">
            <div style={{ paddingLeft: 10, paddingTop: 10 }}>
              <div onChange={handleChange}>
                <input
                  id="1"
                  type="radio"
                  name="goal"
                  value={"Personalized Meal Planning"}
                  checked={questionnaire.goal === "Personalized Meal Planning"}
                  style={{ marginLeft: 0 }}
                />{" "}
                <label htmlFor="1" style={{ cursor: "pointer" }}>
                  {" "}
                  Personalized Meal Planning
                </label>
              </div>
              <input
                id="2"
                type="radio"
                value="Gain Lean Muscle"
                name="goal"
                checked={questionnaire.goal === "Gain Lean Muscle"}
                onChange={handleChange}
                style={{ marginLeft: 0 }}
              />{" "}
              <label htmlFor="2" style={{ cursor: "pointer" }}>
                Gain Lean Muscle
              </label>
              <br />
              <input
                id="3"
                type="radio"
                value="Healthy lifestyle"
                checked={questionnaire.goal === "Healthy lifestyle"}
                name="goal"
                onChange={handleChange}
                style={{ marginLeft: 0 }}
              />{" "}
              <label htmlFor="3" style={{ cursor: "pointer" }}>
                Healthy lifestyle
              </label>
            </div>
          </form>
        );
      case 1:
        return (
          <>
            <form className="form-group">
              <div>
                <input
                  id="4"
                  type="radio"
                  value="Male"
                  name="gender"
                  checked={questionnaire.gender === "Male"}
                  onChange={handleChange}
                />{" "}
                <label htmlFor="4" style={{ cursor: "pointer" }}>
                  Male
                </label>
                <input
                  id="5"
                  type="radio"
                  value="Female"
                  name="gender"
                  checked={questionnaire.gender === "Female"}
                  onChange={handleChange}
                  style={{ marginLeft: 20 }}
                />{" "}
                <label htmlFor="5" style={{ cursor: "pointer" }}>
                  Female
                </label>
              </div>
            </form>
          </>
        );
      case 2:
        return (
          <form className="form-group">
            <div>
              <input
                id="6"
                type="radio"
                value="15 to 17"
                name="ageRange"
                checked={questionnaire.ageRange === "15 to 17"}
                onChange={handleChange}
              />{" "}
              <label htmlFor="6" style={{ cursor: "pointer" }}>
                15 to 17
              </label>
              <br />
              <input
                id="7"
                type="radio"
                value="18 to 30"
                name="ageRange"
                checked={questionnaire.ageRange === "18 to 30"}
                onChange={handleChange}
              />{" "}
              <label htmlFor="7" style={{ cursor: "pointer" }}>
                18 to 30
              </label>
              <br />
              <input
                id="8"
                type="radio"
                value="Over 30"
                name="ageRange"
                checked={questionnaire.ageRange === "Over 30"}
                onChange={handleChange}
              />{" "}
              <label htmlFor="8" style={{ cursor: "pointer" }}>
                Over 30
              </label>
            </div>
          </form>
        );
      case 3:
        return (
          <form className="form-group">
            <div>
              <textarea
                style={{
                  maxHeight: "75px",
                  minHeight: "38px",
                  resize: "none",
                  padding: "9px",
                  boxSizing: "border-box",
                  fontSize: "15px",
                }}
                name="achievement"
                placeholder="I would like to gain weight"
                value={questionnaire.achievement}
                onChange={handleChange}
              />
            </div>
          </form>
        );
      case 4:
        return (
          <form className="form-group">
            <div>
              <textarea
                style={{
                  maxHeight: "75px",
                  minHeight: "38px",
                  resize: "none",
                  padding: "9px",
                  boxSizing: "border-box",
                  fontSize: "15px",
                }}
                name="motivation"
                placeholder="Upcoming marriage"
                onChange={handleChange}
                value={questionnaire.motivation}
              />
            </div>
          </form>
        );
      case 5:
        return (
          <form className="form-group">
            <div style={{ paddingLeft: 20 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                  paddingLeft: 0,
                  paddingTop: 10,
                }}
              >
                <label>Name:</label>
                <div style={{ paddingLeft: 10 }}></div>
                <input type="text" name="name" onChange={handleChange}></input>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                  paddingLeft: 0,
                  paddingTop: 10,
                }}
              >
                <label>Email:</label>
                <div style={{ paddingLeft: 12 }}></div>
                <input
                  type="text"
                  placeholder=""
                  name="email"
                  onChange={handleChange}
                ></input>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                  paddingLeft: 0,
                  paddingTop: 10,
                }}
              >
                <label>Phone:</label>
                <div style={{ paddingLeft: 7 }}></div>
                <input
                  type="text"
                  placeholder=""
                  name="phone"
                  onChange={handleChange}
                ></input>
              </div>
            </div>
          </form>
        );
      default:
        return "Unknown step";
    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    dispatch(createLeadAction({ ...questionnaire, tenant: tenant }));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function handleClick() {
    navigate(`/leads`);
  }
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          flexGrow: "1",
          justifyContent: "flex-end",
          margin: 10,
        }}
      >
        <Ques
          style={{ marginTop: 59, position: "absolute", marginLeft: -200 }}
        />

        <img
          alt="Jean-img"
          style={{ position: "relative", marginTop: 135 }}
          src="https://jpf-content-library02018-tech.s3.us-west-2.amazonaws.com/static-images/Jean.svg"
        ></img>
      </div>

      <div className={classes.container}>
        <h1 style={{ fontWeight: "bold", color: "#2d3748", paddingLeft: 30 }}>
          Online Fitness Coaching
        </h1>

        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          style={{
            background:
              "linear-gradient(240.68deg, rgba(204, 220, 124, 0.1) 4.24%, rgba(204, 220, 124, 0) 58.33%)",
          }}
        >
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      // style={{color:'black',background:'black'}}
                      color="primary"
                      onClick={
                        activeStep === steps.length - 1
                          ? handleSubmit
                          : handleNext
                      }
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper
            square
            elevation={0}
            className={classes.resetContainer}
            style={{
              background:
                "linear-gradient(8.76deg, rgba(2, 15, 25, 0.0001) 3.23%, #ffffff 99.78%)",
            }}
          >
            <Typography
              style={{ color: "#7F9037", fontWeight: "bold", paddingLeft: 5 }}
            >
              Request Submitted!
            </Typography>
            <Button
              onClick={handleReset}
              className={classes.button}
              style={{
                color: "purple",
                fontWeight: "bold",
                width: 20,
                height: 20,
                background:
                  "linear-gradient(0.76deg, rgba(23, 158, 255, 0.0001) 3.23%, #CCDC7C 1.78%)",
              }}
            >
              Reset
            </Button>
            <Button
              onClick={handleClick}
              className={classes.button}
              style={{
                color: "purple",
                fontWeight: "bold",
                display: "flex",
                height: 20,
                background:
                  "linear-gradient(0.76deg, rgba(23, 158, 255, 0.0001) 3.23%, #CCDC7C 1.78%)",
              }}
            >
              Back to Leads
            </Button>
          </Paper>
        )}
      </div>
    </div>
  );
}
