import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Auth } from "aws-amplify";

const httpLink = createHttpLink({
  uri: "https://oxojxktfw5apvecp6wdcko3vmy.appsync-api.us-west-2.amazonaws.com/graphql",
});

const authLink = setContext(async (_, { headers }) => {
  // Get the authentication token from Amplify Auth
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
