import { useEffect, useState } from "react";
import { ReactComponent as Knife } from "../../../images/knife.svg";
import { ReactComponent as Carbon } from "../../../images/carbonEye.svg";
import { ReactComponent as Goal } from "../../../images/Goals.svg";
import { ReactComponent as Edit } from "../../../images/edit.svg";
import { ReactComponent as PlusCircle } from "../../../images/GreenStar.svg";
import { ReactComponent as MinusCircle } from "../../../images/MinusCircle.svg";
import { ReactComponent as X } from "../../../images/X20.svg";
import EditModal from "../EditModal/EditModal";
import "./MealPlanner.scss";
import ProgressBar from "../ProgressBar";
import Nutrients from "../Nutrients/Nutrients";
// import "../EditLabel/EditLabel.scss";
import { updateUser } from "../../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { useParams } from "react-router-dom";
import { sumOfUnits } from "../../../Utils";
const ProgressItem = [
  {
    name: "Protein",

    bgcolor: " #E0AF00",
  },
  {
    name: "Carbs",

    bgcolor: " #06AB8D",
  },
  {
    name: "Fat",
    bgcolor: "  #AC3EC8",
  },
];
const NutrientsItem = [
  {
    name: "protien",
    percent: "40%",
    gram: "32.3g/23.6g",
  },
  { name: "carbs", percent: "40%", gram: "32.3g/23.6g" },
  { name: "fats", percent: "40%", gram: "32.3g/23.6g" },
];

const MealPlanner = (props) => {
  const [show, setShow] = useState(false);
  const [mealPlanner, setMealPlanner] = useState<any>();
  const [nutrients, setNutrients] = useState(false);
  const [calorie, setCalorie] = useState<any>("");
  const [percent, setPercent] = useState<any>({
    protein: "0",
    carbs: "0",
    fat: "0",
  });

  const [mealPlannerData, setMealPlannerData] = useState<any>();
  const [inputExcludeFood, setInputExcludeFood] = useState<any>("");
  const [showInput, toggleShowInput] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    async function getMealPlanner() {
      setCalorie(props.data["data"]["getUser"]["dailyKcal"]);
      setPercent({
        protein: props.data["data"]["getUser"]["proteinInPercentage"],
        carbs: props.data["data"]["getUser"]["carbsInPercentage"],
        fat: props.data["data"]["getUser"]["fatInPercentage"],
      });
      if (props.nutrientsData) {
        const unitResult = sumOfUnits(props.nutrientsData);

        setMealPlannerData(unitResult);
      }

      setMealPlanner(props.data);
    }
    if (props.data) {
      getMealPlanner();
    }
  }, [props.data, props.nutrientsData]);

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };
  const showNutrients = () => {
    if (props?.nutrientsData?.length > 0) {
      setNutrients(true);
    }
  };

  const hideNutrients = () => {
    setNutrients(false);
  };

  const handleChange = async (values, calorie) => {
    await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: id,
          _version: mealPlanner?.data.getUser?._version, //mealPlanner?.data.getUser?._version,
          proteinInPercentage: values.protein,
          fatInPercentage: values.fat,
          carbsInPercentage: values.carbs,
          dailyKcal: calorie || mealPlanner?.data.getUser?.dailyKcal,
        },
      })
    );
    props.fetchData();
  };

  async function updateExcludeFood(items) {
    await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: id,
          _version: mealPlanner?.data.getUser?._version, //mealPlanner?.data.getUser?._version,
          excludeFood: items,
        },
      })
    );
    props.fetchData();
  }

  const saveExcludedFood = () => {
    const items = mealPlanner?.data.getUser?.excludeFood;
    if (inputExcludeFood !== "" && items !== null) {
      items.push(inputExcludeFood);
      updateExcludeFood(items);
      setInputExcludeFood("");
    } else if (inputExcludeFood !== "" && items === null) {
      updateExcludeFood([inputExcludeFood]);
      setInputExcludeFood("");
    }
  };

  async function deleteUpdateExcludeFood(list) {
    await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: id,
          _version: mealPlanner?.data.getUser?._version, //mealPlanner?.data.getUser?._version,
          excludeFood: list,
        },
      })
    );
    props.fetchData();
  }
  const deleteExcludeFood = (i) => {
    const list = mealPlanner?.data.getUser?.excludeFood;

    if (i > -1) {
      list.splice(i, 1);
    }
    deleteUpdateExcludeFood(list);
  };

  const onChangeExclude = (e) => {
    setInputExcludeFood(e.target.value);
  };

  return (
    <>
      <div className="MealPlannerContainer">
        <div className="MealPlannerContainer__header">
          <div className="MealPlannerContainer__header__left">
            <div>
              <Knife />
            </div>

            <div className="MealPlannerContainer__header__text">
              Meal Planner
            </div>
          </div>
        </div>

        <div className="MealPlannerContainer__content">
          <div className="MealPlannerContainer__content__left">
            <div className="MealPlannerContainer__content__left__header">
              <div className="MealPlannerContainer__content__left__header__leftcontent">
                <div className="MealPlannerContainer__content__left__header__image">
                  <Goal />
                </div>

                <div className="MealPlannerContainer__content__left__header__text">
                  <div className="MealPlannerContainer__content__left__header__utext">
                    Daily Intake
                  </div>
                  <div className="MealPlannerContainer__content__left__header__dtext">
                    {calorie ?? 0} Kcal
                  </div>
                </div>
              </div>

              <div className="MealPlannerContainer__content__left__header__right">
                <EditModal
                  show={show}
                  handleClose={hideModal}
                  handleSubmit={handleChange}
                  percent={percent}
                />
                <Edit onClick={showModal} />
              </div>
            </div>

            <div className="Progress">
              <div className="Progress__item">
                {ProgressItem?.map((item, index) => (
                  <div key={index} className={"Progress__item__left"}>
                    <div className={"Progress__item__left__utext"}>
                      {" "}
                      {item.name}
                      <div className={"Progress__item__left__dtext"}>
                        {percent[item.name.toLowerCase()] ?? 0}%
                      </div>
                    </div>

                    <ProgressBar
                      bgcolor={item.bgcolor}
                      completed={percent[item.name.toLowerCase()] + "%"}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="MealPlannerContainer__content__middle">
            <div className="MealPlannerContainer__content__middle__header">
              <div className="MealPlannerContainer__content__middle__header__textcontainer">
                {" "}
                <div className="MealPlannerContainer__content__middle__header__utext">
                  Nutrients
                </div>
                <div className="MealPlannerContainer__content__middle__header__btext">
                  OBs:All micronutrient data might not be available.
                </div>
              </div>
            </div>
            <div className="MealPlannerContainer__content__middle__content">
              {NutrientsItem?.map((item, index) => (
                <div
                  key={index}
                  className="MealPlannerContainer__content__middle__content__data"
                >
                  <div
                    className={
                      "MealPlannerContainer__content__middle__content__ldata"
                    }
                  >
                    {item.name === "protien" ? "Protein" : item.name}
                  </div>

                  <div
                    className={
                      "MealPlannerContainer__content__middle__content__rdata"
                    }
                  >
                    {mealPlannerData?.[item.name]
                      ? mealPlannerData?.[item.name].toFixed(2) + "g"
                      : "NA"}
                  </div>
                </div>
              ))}
              <div className="MealPlannerContainer__content__middle__content__footer">
                <Carbon />
                <div
                  className="MealPlannerContainer__content__middle__content__footer__text"
                  onClick={showNutrients}
                >
                  Show All Nutrients
                </div>

                {props?.nutrientsData?.length > 0 && (
                  <Nutrients
                    show={nutrients}
                    handleClose={hideNutrients}
                    data={props.nutrientsData}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="MealPlannerContainer__content__right">
            <div className="MealPlannerContainer__content__right__header">
              <div>Exclude</div>
              <div>
                {!showInput ? (
                  <PlusCircle
                    onClick={toggleShowInput.bind(this, !showInput)}
                  />
                ) : (
                  <MinusCircle
                    onClick={toggleShowInput.bind(this, !showInput)}
                  />
                )}
              </div>
            </div>
            <div className="MealPlannerContainer__content__right__body">
              {mealPlanner?.data.getUser?.excludeFood?.map((item, index) => (
                <div key={index}>
                  <div className={"MealPlannerContainer__content__right__item"}>
                    {item}
                  </div>
                  <div className="MealPlannerContainer__content__right__item__hide">
                    <X onClick={deleteExcludeFood.bind(this, index)} />
                  </div>
                </div>
              ))}
              {showInput && (
                <input
                  type="text"
                  name="inputText"
                  placeholder="Edit"
                  value={inputExcludeFood}
                  onChange={(e) => onChangeExclude(e)}
                  onBlur={() => saveExcludedFood()}
                  className="MealPlannerContainer__content__right__item"
                  style={{
                    minWidth: "30px",
                    height: "25px",
                    padding: "5px",
                    margin: "15px",
                    display: "flex",
                    outline: "none",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MealPlanner;
