import "./LibraryPage.scss";
import { useNavigate } from "react-router-dom";

const LibraryPage = () => {
  const navigate = useNavigate();

  const goToPage = (path) => {
    navigate(`/library/${path}`);
  };

  return (
    <>
      <div className="clients">
        <div className="clients__header">
          <h1>Library</h1>
        </div>
      </div>
      <div className="Library">
        <div className="Library__body" onClick={() => goToPage("Foodplan")}>
          <button className="Library__body__text">Go To Foods</button>
        </div>
        <div className="Library__body" onClick={() => goToPage("dietplan")}>
          <button className="Library__body__text">Go To Diet Plan</button>
        </div>
        <div
          className="Library__body"
          onClick={() => goToPage("layoutmanager")}
        >
          <button className="Library__body__text">Go to Meal Layout</button>
        </div>
      </div>
      <div className="Library">
        <div className="Library__body" onClick={() => goToPage("exercise")}>
          <button className="Library__body__text">Go to Exercises</button>
        </div>
        <div className="Library__body" onClick={() => goToPage("workoutplan")}>
          <button className="Library__body__text">Go to Workout Plan</button>
        </div>
      </div>
    </>
  );
};

export default LibraryPage;
