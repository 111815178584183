import "./WorkoutUnits.scss";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import SelectComponent from "../SelectComponent/SelectComponent";

const WorkoutUnits = (props) => {
  const { handleChange, readOnly, value, selectedUnit } = props;

  const handleUnitChange = (e) => {
    handleChange({ ...value, ...{ [e.target.name]: e.target.value } });
  };

  return (
    <Box
      className="workoutUnit"
      component="form"
      sx={{
        "& .MuiTextField-root": { marginTop: 1, width: "10ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {value &&
          Object.keys(value).map((key, id) => {
            if (key === "options" && Array.isArray(selectedUnit?.options)) {
              return (
                <Grid key={"units-select" + id} item xs={6}>
                  <SelectComponent
                    data={selectedUnit?.options}
                    value={
                      Array.isArray(value[key]) ? value[key][0] : value[key]
                    }
                    name={key}
                    label={key}
                    handleChange={handleUnitChange}
                    style={{ marginTop: "8px" }}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid key={"units" + id} item xs={6}>
                  <TextField
                    label={key}
                    value={value[key]}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name={key}
                    onChange={handleUnitChange}
                    disabled={readOnly}
                    style={{ width: "80px", height: "28px" }}
                  />
                </Grid>
              );
            }
          })}
      </Grid>
    </Box>
  );
};
export default WorkoutUnits;
