import Image from "mui-image";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

import DeleteComponent from "../../../../../component/Delete/Delete";
import { useState } from "react";
import WorkoutUnits from "../../../../../component/WorkoutUnits/WorkoutUnits";
import MinHeightTextarea from "../../../../../component/MUITextAreaAutoResize/TextAreaAuto";
import { useAppSelector } from "../../../../../hooks";
interface IProps {
  name: string;
  imageData: string;
  data: any;
  onDelete: any;
  onChange: (any) => void;
  type: string;
}
const WorkoutItem = (props: IProps) => {
  const listTenant = useAppSelector((state) => state.tenant);
  const tenants = listTenant?.tenant?.currentTenant;
  const tenant = props.data.tenant;
  const { name, imageData, data, onDelete, onChange, type } = {
    ...props,
  };
  const [isDeletes, setIsDeletes] = useState<any>(false);
  const [value, setValue] = useState(
    data.defaultValue
      ? JSON.parse(data?.defaultValue)
      : JSON.parse(data?.exercise?.defaultValue)
  );
  return (
    <Paper
      sx={{
        p: 2,
        margin: "15px 0",
        padding: "10px !important",
        maxWidth: 500,

        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
      className="mealItem"
    >
      <Grid container spacing={2}>
        <Grid item xs={14} sm container>
          <Grid item spacing={1}>
            <ButtonBase
              sx={{
                width: 70,
                height: 70,
                borderRadius: 5,
              }}
              // onMouseDown={(e) => e.preventDefault()}
            >
              <Image
                alt={name}
                fit="contain"
                style={{
                  width: "100%",
                  borderRadius: "5px",
                }}
                src={imageData}
              />
            </ButtonBase>
          </Grid>
          <Grid
            item
            xs={8}
            container
            direction="column"
            style={{
              padding: "0 5px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Typography
              gutterBottom
              variant="subtitle1"
              component="div"
              noWrap
              style={{
                textOverflow: "ellipsis",
                fontSize: "0.9rem",
              }}
            >
              {name}
            </Typography>
            {/* Uncomment this and pass selectedType, defaultValue */}
            <WorkoutUnits
              name="unitValue"
              handleChange={(val) => {
                setValue(val); // To show the change in text box
                onChange({
                  id: data.id,
                  _version: data._version,
                  defaultValue: JSON.stringify(val),
                });
              }}
              value={value}
              readOnly={
                type === "calendar"
                  ? false
                  : tenant === null ||
                    tenants === tenant[0] ||
                    (tenants === "ADMIN" && tenant?.includes("ADMIN"))
                  ? false
                  : true
              }
            />
            <div>
              <MinHeightTextarea
                content={
                  data.notes !== "" ? data.notes : data.exercise.additionalNotes
                }
                onChange={(e) => {
                  onChange({
                    id: data.id,
                    _version: data._version,
                    notes: e.target.value,
                  });
                }}
                readOnly={
                  type === "calendar"
                    ? false
                    : tenant === null ||
                      tenants === tenant[0] ||
                      (tenants === "ADMIN" && tenant?.includes("ADMIN"))
                    ? false
                    : true
                }
              />
            </div>
          </Grid>

          <Grid
            item
            xs={1}
            direction={"column"}
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              {type === "calendar" ? (
                <DeleteComponent
                  deletes={isDeletes}
                  setDeletes={setIsDeletes}
                  onDeletes={onDelete}
                />
              ) : tenant === null ||
                tenants === tenant[0] ||
                (tenants === "ADMIN" && tenant?.includes("ADMIN")) ? (
                <DeleteComponent
                  deletes={isDeletes}
                  setDeletes={setIsDeletes}
                  onDeletes={onDelete}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default WorkoutItem;
