import { useEffect, useState } from "react";
import "./Exercise.scss";
import { Storage } from "aws-amplify";
import { ReactComponent as Upload } from "../../../../images/upload_file.svg";
import xcircle from "../../../../images/x-circle.svg";
import EditIcon from "@mui/icons-material/Edit";
import { v4 as uuid } from "uuid";
import Grid from "@mui/material/Grid";

import { Tooltip } from "@material-ui/core";
import LoaderButton from "../../../../component/LoaderButton";
import VideoPlayerTag from "../../../../component/VideoPlayer/VideoPlayer";
import { useAppSelector } from "../../../../hooks";
import SelectComponent from "../../../../component/SelectComponent/SelectComponent";
import WorkoutUnits from "../../../../component/WorkoutUnits/WorkoutUnits";
import _ from "lodash";

const unitTypesAndValues = {
  "Sets and Reps": { sets: "", reps: "" },
  Time: { value: "", options: ["secs", "mins", "hours"] },
  Distance: { value: "", options: ["meter", "km", "yard"] },
  Custom: { text: "" },
};
const initialState = {
  name: {
    value: "",
    error: false,
    errorMessage: "Please enter exercise name",
  },
  notes: {
    value: "",
    error: false,
    errorMessage: "Please enter the additional notes",
    ignoreValidation: true,
  },
  bodyPart: {
    value: "",
    error: false,
    errorMessage: "Please select the body part",
  },
  unitType: {
    value: Object.keys(unitTypesAndValues)[0],
    error: false,
    errorMessage: "",
  },

  unitValue: {
    value: "",
    error: false,
    errorMessage: "Please enter the values",
  },
  tenant: { value: null, error: false, ignoreValidation: true },
};

const ExcerciseModal = ({
  handleClose,
  show,
  handleSubmit,
  propsItem,
  handleEdit,
  tenant,
}) => {
  const [bucketPath, setBucketPath]: any = useState();
  const [uploadPercentage, setUploadPercentage]: any = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [formValues, setFormValues] = useState<any>({
    ..._.cloneDeep(initialState),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value,
      },
    });
  };

  const handleChangeUnit = (e) => {
    const { value } = e.target;
    setFormValues({
      ...formValues,
      unitType: {
        ...formValues["unitType"],
        value,
      },
      unitValue: {
        ...formValues["unitValue"],
        value: "",
      },
    });
  };

  const handleChangeUnits = (val) => {
    setFormValues({
      ...formValues,
      ...{
        unitValue: {
          ...formValues["unitValue"],
          value: val,
        },
      },
    });
  };

  const bodyParts = useAppSelector((state) => state.workout.bodyParts);
  console.log("bodyParts", bodyParts);
  useEffect(() => {
    (async () => {
      const url = await Storage.get("");
      return setBucketPath(url.split("?")[0]);
    })();
  }, []);

  const showHideClassName = show
    ? "Addmodal display-block"
    : "Addmodal display-none";
  const [videoFile, setVideoFile] = useState<string>();
  const [imgFile, setImgFile] = useState<string>();

  const [formState, setFormState] = useState<{
    name: string;
    price: string;
    duration: string;
    description: string;
    productType: string;
    features: string[];
    img: Record<string, any>;
    video: Record<string, any>; // Type definition for video as an object
  }>({
    name: "",
    price: "",
    duration: "",
    description: "",
    img: {
      any: undefined,
    },
    video: {}, // Provide an empty object as the initial value
    features: ["Meal plans", "Workouts"],
    productType: "Subscription",
  });

  useEffect(() => {
    propsItem?.videoUrl && setVideoFile(bucketPath + propsItem?.videoUrl);
    propsItem?.imageUrl && setImgFile(bucketPath + propsItem?.imageUrl);
  }, [bucketPath, propsItem]);
  useEffect(() => {
    if (propsItem != null) {
      let newFormValues = { ...formValues };
      newFormValues.name.value = propsItem.name;
      newFormValues.notes.value = propsItem.additionalNotes;

      newFormValues.unitType.value = propsItem.selectedUnit;
      newFormValues.unitValue.value = propsItem.defaultValue
        ? JSON.parse(propsItem.defaultValue)
        : formValues.unitValue.value;
      newFormValues.tenant.value = propsItem.tenant;

      const bodyPartName = propsItem?.bodyPartId
        ? bodyParts.filter((items) => items.id === propsItem.bodyPartId)[0]
            ?.name
        : null;
      newFormValues.bodyPart.value = bodyPartName;

      setFormValues(newFormValues);
    }
  }, [propsItem]);

  const Submit = async (e) => {
    e.preventDefault();
    let valid = true;
    const formFields = Object.keys(formValues);
    let newFormValues = { ...formValues };

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formValues[currentField].value;
      const ignoreValidation = formValues[currentField]?.ignoreValidation;

      if (!ignoreValidation && (!currentValue || currentValue === "")) {
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: true,
          },
        };
      } else {
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: false,
          },
        };
      }
    }

    for (const key in newFormValues) {
      if (newFormValues[key].error) {
        valid = false;
        break;
      }
    }

    setFormValues(newFormValues);
    if (valid) {
      e.preventDefault();

      if (propsItem === null) {
        try {
          const uploadPromises: any = []; // Store promises for image and video uploads

          if (formState?.video?.name) {
            setLoading(true);
            const videoUpload = Storage.put(
              formState.video.name,
              formState.video.file,
              {
                contentType: "video/*",
                completeCallback: (event) => {
                  console.log(`Successfully uploaded ${event.key}`);
                },
                progressCallback: (progress) => {
                  console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                  const completionPercentage =
                    (progress.loaded / progress.total) * 100;
                  setUploadPercentage(completionPercentage);
                },
                errorCallback: (err) => {
                  console.error("Unexpected error while uploading", err);
                },
              }
            );
            uploadPromises.push(videoUpload);
          }

          if (formState?.img?.name) {
            const imgUpload = Storage.put(
              formState.img.name,
              formState.img.file,
              {
                contentType: "image/svg+xml/jpeg/png",
                completeCallback: (event) => {
                  console.log(`Successfully uploaded ${event.key}`);
                },
                progressCallback: (progress) => {
                  console.log(
                    `Uploaded Image: ${progress.loaded}/${progress.total}`
                  );
                },
                errorCallback: (err) => {
                  console.error("Unexpected error while uploading", err);
                },
              }
            );
            uploadPromises.push(imgUpload);
          }

          // Wait for all uploads to complete before calling handleSubmit
          await Promise.all(uploadPromises);
        } catch (e) {
          console.log("S3 error", e);
        }

        // Now that both image and video uploads are complete, call handleSubmit
        handleSubmit({
          additionalNotes: formValues.notes.value,
          bodyPartId: getBodyPartId(formValues.bodyPart.value),
          defaultValue: JSON.stringify(formValues.unitValue.value),
          imageUrl: formState.img.name,
          name: formValues.name.value,
          selectedUnit: formValues.unitType.value,
          videoUrl: formState.video.name,
        });
      } else {
        const uploadPromises: any = []; // Store promises for image and video uploads

        let formData = {
          additionalNotes: formValues.notes.value,
          bodyPartId: getBodyPartId(formValues.bodyPart.value),
          defaultValue: JSON.stringify(formValues.unitValue.value),
          imageUrl: formState.img.name,
          name: formValues.name.value,
          selectedUnit: formValues.unitType.value,
          videoUrl: formState.video.name,
        };
        try {
          if (formState?.video?.name) {
            setLoading(true);
            const video = Storage.put(
              formState.video.name,
              formState.video.file,
              {
                contentType: "video/*", // contentType is optional
                completeCallback: (event) => {
                  console.log(`Successfully uploaded ${event.key}`);
                },
                progressCallback: (progress) => {
                  const completionPercentage =
                    (progress.loaded / progress.total) * 100;
                  setUploadPercentage(completionPercentage);
                },
                errorCallback: (err) => {
                  console.error("Unexpected error while uploading", err);
                },
              }
            );
            uploadPromises.push(video);
          }
          if (formState?.img?.name) {
            const img = Storage.put(formState.img.name, formState.img.file, {
              contentType: "image/svg+xml/jpeg/png", // contentType is optional
              completeCallback: (event) => {
                console.log(`Successfully uploaded ${event.key}`);
              },
              progressCallback: (progress) => {
                console.log(
                  `Uploaded Image: ${progress.loaded}/${progress.total}`
                );
              },
              errorCallback: (err) => {
                console.error("Unexpected error while uploading", err);
              },
            });
            uploadPromises.push(img);
          }
          await Promise.all(uploadPromises);
        } catch (e) {
          console.log("S3 error", e);
        }

        handleEdit({
          ...formData,
          _version: propsItem._version,
          id: propsItem.id,
        });
      }
    }
  };

  async function handleChangeVideo(e: any) {
    const file = e.target.files[0];
    try {
      e.persist();
      const videoName = `Exercise_${uuid()}`;
      if (!e.target.files[0]) return;
      const video = {
        file: file,
        name: videoName,
      };
      setFormState((currentState) => ({
        ...currentState,
        //file: URL.createObjectURL(e.target.files[0]),
        video,
      }));
      setVideoFile(URL.createObjectURL(e.target.files[0]));
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }
  async function handleChangeImage(e: any) {
    const file = e.target.files[0];
    try {
      e.persist();
      const imgName = `Exercise_${uuid()}`;
      if (!e.target.files[0]) return;
      const img = {
        file: file,
        name: imgName,
      };
      setFormState((currentState) => ({
        ...currentState,
        //file: URL.createObjectURL(e.target.files[0]),
        img,
      }));
      setImgFile(URL.createObjectURL(e.target.files[0]));
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  const getBodyPartId = (bodyPartName) => {
    const selectedPart = bodyParts.filter(
      (item) => !item._deleted && item.name === bodyPartName
    )[0];
    return selectedPart.id;
  };

  return (
    <>
      <div className={`${showHideClassName} exercise`}>
        <section className="AddPackage-main">
          <div className="heading">
            {propsItem === null ? "Add Exercise" : "Edit Exercise"}
            <img
              className="heading__image"
              onClick={() => {
                handleClose();
                setFormValues({ ..._.cloneDeep(initialState) });
              }}
              alt="close"
              src={xcircle}
            />
          </div>
          <form className="formstyle" onSubmit={Submit}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <div>
                  <div className="videoPackage">
                    <div>
                      <input
                        type="file"
                        hidden
                        id="exercise-img"
                        accept="image/svg+xml/jpeg/png"
                        onChange={handleChangeImage}
                        name="Exercise image"
                        disabled={
                          formValues.tenant.value === null ||
                          tenant === formValues.tenant.value[0] ||
                          (tenant === "ADMIN" &&
                            formValues.tenant.value.includes("ADMIN"))
                            ? false
                            : true
                        }
                      />
                    </div>
                    {formState?.img?.name || imgFile ? (
                      <div className="videoPreview">
                        <div className="videoChange">
                          <Tooltip title="Change video">
                            <label
                              htmlFor="exercise-img"
                              className="package-upload"
                            >
                              <EditIcon />
                            </label>
                          </Tooltip>
                        </div>
                        <img
                          src={imgFile}
                          style={{ width: "100%", height: "100%" }}
                          alt="workout_image"
                        />
                      </div>
                    ) : (
                      <label className="noVideo" htmlFor="exercise-img">
                        <Upload />
                        <span> Click here to upload workout image</span>
                      </label>
                    )}
                  </div>
                </div>
                <div>
                  <div className="videoPackage">
                    <div>
                      <input
                        type="file"
                        hidden
                        id="exercise-video"
                        accept="video/*"
                        onChange={handleChangeVideo}
                        name="Package video"
                        disabled={
                          formValues.tenant.value === null ||
                          tenant === formValues.tenant.value[0] ||
                          (tenant === "ADMIN" &&
                            formValues.tenant.value.includes("ADMIN"))
                            ? false
                            : true
                        }
                      />
                    </div>
                    {formState?.video?.name || videoFile ? (
                      <div className="videoPreview">
                        <div className="videoChange">
                          <Tooltip title="Change video">
                            <label
                              htmlFor="exercise-video"
                              className="package-upload"
                            >
                              <EditIcon />
                            </label>
                          </Tooltip>
                        </div>
                        <VideoPlayerTag src={videoFile} />
                      </div>
                    ) : (
                      <label className="noVideo" htmlFor="exercise-video">
                        <Upload />
                        <span> Click here to upload the package video</span>
                      </label>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item sm={6} container>
                <div className="packagecontent">
                  <div className="inputContent">
                    <span className="inputType">Name</span>
                    <div>
                      <input
                        type="text"
                        className="contentinputBox"
                        name="name"
                        value={formValues.name.value}
                        onChange={handleChange}
                        onBlur={handleChange}
                        disabled={
                          formValues.tenant.value === null ||
                          tenant === formValues.tenant.value[0] ||
                          (tenant === "ADMIN" &&
                            formValues.tenant.value.includes("ADMIN"))
                            ? false
                            : true
                        }
                        // disabled={propsItem?.name ? true : false}
                      />
                      {formValues.name.error && (
                        <div className="errorMessage">
                          {formValues.name.errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="inputContent">
                    <span className="inputType"> Body part</span>
                    <div>
                      {/* <input
                        type="text"
                        className="contentinputBox"
                        name="body-part"
                        value={formState.price}
                        onChange={handleChangePrice}
                        onBlur={handleBlur}
                        disabled={propsItem?.price ? true : false}
                      /> */}
                      {bodyParts && (
                        <SelectComponent
                          name="bodyPart"
                          data={bodyParts
                            .filter((parts) => !parts._deleted)
                            ?.map((item) => item.name)}
                          value={formValues.bodyPart.value}
                          handleChange={handleChange}
                          disabled={
                            formValues.tenant.value === null ||
                            tenant === formValues.tenant.value[0] ||
                            (tenant === "ADMIN" &&
                              formValues.tenant.value.includes("ADMIN"))
                              ? false
                              : true
                          }
                        />
                      )}
                      {formValues.bodyPart.error && (
                        <div className="errorMessage">
                          {formValues.bodyPart.errorMessage}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="description inputContent">
                    <span className="inputType">Notes</span>
                    <div>
                      <textarea
                        className="contentinputBox"
                        name="notes"
                        value={formValues.notes.value}
                        onChange={handleChange}
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                          border: "1px solid #c2cfe0 !important",
                        }}
                        disabled={
                          formValues.tenant.value === null ||
                          tenant === formValues.tenant.value[0] ||
                          (tenant === "ADMIN" &&
                            formValues.tenant.value.includes("ADMIN"))
                            ? false
                            : true
                        }
                        // disabled={propsItem?.name ? true : false}
                      />

                      {/* <div>
                        {formValues.notes.error && (
                          <div className="errorMessage">
                            {formValues.notes.errorMessage}
                          </div>
                        )}
                      </div> */}
                    </div>
                  </div>
                  <div className="inputContent">
                    <span className="inputType" style={{ paddingTop: "5px" }}>
                      {" "}
                      Units
                    </span>
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <SelectComponent
                          data={Object.keys(unitTypesAndValues)}
                          value={formValues.unitType.value}
                          name="unitType"
                          handleChange={handleChangeUnit}
                          disabled={
                            formValues.tenant.value === null ||
                            tenant === formValues.tenant.value[0] ||
                            (tenant === "ADMIN" &&
                              formValues.tenant.value.includes("ADMIN"))
                              ? false
                              : true
                          }
                        />
                      </div>

                      {formValues.unitType.error && (
                        <div className="errorMessage">
                          {formValues.unitType.errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className=" inputContent">
                    <span className="inputType" style={{ paddingTop: "5px" }}>
                      {" "}
                    </span>
                    <div>
                      {formValues && formValues.unitValue.value ? (
                        <WorkoutUnits
                          //type={formValues.unitType.value}
                          name="unitValue"
                          handleChange={handleChangeUnits}
                          value={formValues.unitValue.value}
                          selectedUnit={
                            unitTypesAndValues[formValues.unitType.value]
                          }
                          readOnly={
                            formValues.tenant.value === null ||
                            tenant === formValues.tenant.value[0] ||
                            (tenant === "ADMIN" &&
                              formValues.tenant.value.includes("ADMIN"))
                              ? false
                              : true
                          }
                        />
                      ) : (
                        <WorkoutUnits
                          //type={formValues.unitType.value}
                          name="unitValue"
                          handleChange={handleChangeUnits}
                          value={unitTypesAndValues[formValues.unitType.value]}
                          selectedUnit={
                            unitTypesAndValues[formValues.unitType.value]
                          }
                          readOnly={
                            formValues.tenant.value === null ||
                            tenant === formValues.tenant.value[0] ||
                            (tenant === "ADMIN" &&
                              formValues.tenant.value.includes("ADMIN"))
                              ? false
                              : true
                          }
                        />
                      )}
                      <div>
                        {formValues.unitValue.error && (
                          <div
                            className="errorMessage"
                            style={{ paddingLeft: "10px" }}
                          >
                            {formValues.unitValue.errorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div>
              {/* <button type="submit" className="savepackage">
                Save
              </button> */}
              {formValues.tenant.value === null ||
              tenant === formValues.tenant.value[0] ||
              (tenant === "ADMIN" &&
                formValues.tenant.value.includes("ADMIN")) ? (
                <LoaderButton
                  className="savepackage"
                  progress={uploadPercentage}
                  loading={loading}
                />
              ) : (
                false
              )}
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default ExcerciseModal;
