interface Props {
  border;
  color;
  children;
  height;
  onClick;
  radius;
  width?;
  margin?;
  className;
  icon?;
  disabled?;
}

const FtButton: React.FC<Props> = ({
  border,
  color,
  children,
  height,
  onClick,
  radius,
  width,
  margin,
  className,
  icon,
  disabled
}) => {
  return (
    <button
      onClick={onClick}
      className={className}
      disabled={disabled}
      style={{
        backgroundColor: color,
        border,
        borderRadius: radius,
        height,
        width,
        margin,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 15px",
        cursor: "pointer"
        // marginLeft: "5px"
      }}
    >
      {icon && (
        <span
          style={{ marginRight: "15px", display: "flex", alignItems: "end" }}
        >
          {icon}
        </span>
      )}
      <span
        style={{
          fontSize: "13px",
          fontWeight: 500,
          fontFamily: "Poppins-medium"
        }}
      >
        {" "}
        {children}
      </span>
    </button>
  );
};

export default FtButton;
