import { useState } from "react";
import "./EditModal.scss";

import xcircle from "../../../images/x-circle.svg";
import MultiRangeSlider from "../silder/slider";
const EditModal = ({ handleClose, show, handleSubmit, percent }) => {
  const showHideClassName = show
    ? "Editmodal display-block"
    : "Editmodal display-none";

  const closeModal = () => {
    handleClose();
  };
  const Submit = (e) => {
    e.preventDefault();
    handleSubmit(values, calorie);
  };

  const [values, setValues] = useState<any>({
    protein: "",
    carbs: "",
    fat: "",
  });
  const [calorie, setCalorie] = useState("");

  const onChange = ({ min, max }: { min: number; max: number }) => {
    let mid = max - min;

    setValues({ protein: min, carbs: mid, fat: 100 - max });
  };

  return (
    <>
      <div className={showHideClassName}>
        <section className="Editmodal-main">
          <div className="heading">
            Daily Intake
            <img
              className="heading__image"
              onClick={handleClose}
              src={xcircle}
            />
          </div>
          <div className="HeaderText">Percentage should be added to 100%</div>
          <form onSubmit={Submit}>
            <div className="calories">
              <span className="inputType">Daily Intakes</span>
              <input
                type="number"
                className="contentboxone"
                name="calorie"
                onChange={(e) => {
                  setCalorie(e.target.value);
                }}
              />
              <span>kcal</span>
            </div>
            <MultiRangeSlider min={0} max={100} onChange={onChange} />
            <div className="modalcontent">
              <div className="contentalign">
                <span className="inputType">Protein</span>
                <input
                  readOnly
                  type="text"
                  className="contentbox"
                  name="protein"
                  value={values["protein"] + "%"}
                />
              </div>
              <div className="contentalign">
                <span className="inputType">Carbs</span>
                <input
                  readOnly
                  className="contentbox"
                  type="text"
                  name="carbs"
                  value={values["carbs"] + "%"}
                />
              </div>
              <div className="contentalign">
                <span className="inputType">Fat</span>
                <input
                  readOnly
                  className="contentbox"
                  type="text"
                  name="fat"
                  value={values["fat"] + "%"}
                />
              </div>
            </div>

            <div>
              <button type="submit" className="addpercent" onClick={closeModal}>
                Save
              </button>
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default EditModal;
