import { gql } from "@apollo/client";

export const getUserCalendarMealCustom = `query GetUserCalendarMeal($idDate: String!) {
  getUserCalendarMeal(idDate: $idDate) {
      id
      idDate
      tenant
      date
      mealLayoutID
      mealLayout {
        id
        layout
        tenant
        layoutName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      UserCalendarMealItems {
        items {
          id
          calendarMealId
          tenant
          recipeId
          foodId
          userID
          meal
          servingSize
          servingDescription
          recipe {
            id
            title
            description
            imageUrl
            servingSize
            servingDescription
            category
            isMainDish
            isBreakfast
            ingredients {
              items {
                id
                recipeId
                servingSize
                servingDescription
                foodId
                food {
                  id
                  name
                  description
                  calories
                  moreFields
                  additionalUnits
                  carbs
                  fats
                  protien
                  servingSize
                  servingDescription
                  gramsPerServe
                  imageUrl
                  foodGroup
                  createdAt
                  updatedAt
                }
                _version
                _deleted
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
          }
          food {
            id
            name
            description
            calories
            moreFields
            additionalUnits
            carbs
            fats
            protien
            servingSize
            servingDescription
            gramsPerServe
            imageUrl
            foodGroup
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const getUserCalendarWorkoutCustom = `query GetUserCalendarWorkout($idDate: String!) {
  getUserCalendarWorkout(idDate: $idDate) {
    id
    idDate
    userID
    tenant
    dayNotes
    sessionOrder
    UserCalendarWorkoutItems {
      items{
        id
        calendarWorkoutId
        userID
        tenant
        workoutPlanDetailId
        exerciseId
        exercise {
          id
          name
          additionalNotes
          weight
          tenant
          bodyPartId
          videoUrl
          imageUrl
          selectedUnit
          defaultValue
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        session
        notes
        defaultValue
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
`;
export const listCalendarsCustom = /* GraphQL */ `
  query ListCalendars(
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        days
        userID
        meal
        tenant
        mealPlannerId
        servingSize
        servingDescription
        recipeId
        foodId
        _deleted
        _version

        recipe {
          id
          title
          description
          imageUrl
          servingSize
          servingDescription
          category
          isMainDish
          isBreakfast
          ingredients {
            items {
              id
              recipeId
              servingSize
              servingDescription
              foodId
              food {
                id
                name
                description
                calories
                moreFields
                additionalUnits
                carbs
                fats
                protien
                servingSize
                servingDescription
                gramsPerServe
                imageUrl
                foodGroup
                createdAt
                updatedAt
              }
              _version
              _deleted
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
        }
        food {
          id
          name
          description
          calories
          moreFields
          additionalUnits
          carbs
          fats
          protien
          servingSize
          servingDescription
          gramsPerServe
          imageUrl
          foodGroup
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const listCalenderWorkoutCustom = /* GraphQL */ `
  query ListCalendars(
    $filter: ModelWorkoutCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkoutCalendars(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        days
        session
        sessionTitle
        userID
        sets
        reps
        repType
        rest
        workoutId
        createdAt
        updatedAt

        _deleted
        workout {
          id
          name
          description
          videoUrl
          thumbnailUrl
          workoutGroups
          equipments
          favourite
          videoLanguage
          createdAt
          updatedAt

          _deleted
        }
        user {
          id
          email
          name
          imageUrl
          gender
          activeStatus
          ageRange
          age
          height
          weightStart
          weightGoal
          motivation
          goal
          goalType
          achievement
          workoutCountPerWeek
          weightChangePerWeek
          workoutLocation
          workoutExperience
          workoutInfo
          mealCountPerDay
          phone
          location
          country
          timezone
          lastLogin
          emailValidated
          injuries
          dietPreference
          allergies
          excludeFood
          dailyKcal
          proteinInPercentage
          carbsInPercentage
          fatInPercentage
          createdAt
          updatedAt

          _deleted
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listRemindersCustom = /* GraphQL */ `
  query ListReminders(
    $filter: ModelReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReminders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        message
        finishBy

        isDone
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listEnumValues = `query ListEnumValues($enum: String!) {
    enum: __type(name: $enum) {
      enumValues {
        name
      }
    }
  }`;

export const getMealPlanner = /* GraphQL */ `
  query GetMealPlanner($id: ID!, $nextToken: String, $day: Int) {
    getMealPlanner(id: $id) {
      id
      planTitle
      mealLayoutID
      numOfdays
      mealLayout {
        layout
        id
        _deleted
        _lastChangedAt
        _version
        createdAt
        layoutName
        updatedAt
      }
      mealPlannerItemsMap(
        filter: { day: { eq: $day } }
        nextToken: $nextToken
      ) {
        items {
          id
          mealPlannerId
          day
          meal
          recipeId
          foodId
          food {
            _deleted
            _lastChangedAt
            _version
            calories
            carbs
            createdAt
            description
            fats
            foodGroup
            additionalUnits
            id
            gramsPerServe
            imageUrl
            moreFields
            name
            protien
            servingDescription
            servingSize
            updatedAt
          }
          recipe {
            _deleted
            _lastChangedAt
            _version
            category
            createdAt
            id
            description
            imageUrl
            servingSize
            servingDescription
            title
            ingredients {
              items {
                _deleted
                _lastChangedAt
                _version
                createdAt
                food {
                  _deleted
                  _lastChangedAt
                  _version
                  calories
                  carbs
                  createdAt
                  description
                  fats
                  foodGroup
                  additionalUnits
                  id
                  gramsPerServe
                  imageUrl
                  moreFields
                  name
                  protien
                  servingDescription
                  servingSize
                  updatedAt
                }
                foodId
                id
                servingDescription
                recipeId
                servingSize
              }
            }
          }

          servingSize
          servingDescription
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getDietMealPlanner = /* GraphQL */ `
  query GetMealPlanner($id: ID!, $nextToken: String) {
    getMealPlanner(id: $id) {
      id
      planTitle
      mealLayoutID
      numOfdays
      tenant
      mealLayout {
        layout
        id
        _deleted
        _lastChangedAt
        _version
        createdAt
        layoutName
        updatedAt
      }
      mealPlannerDay {
        items {
          id
          _deleted
          _lastChangedAt
          _version
          createdAt
          dayNum
          tenant
          dayOff
          mealDayOrder
          mealPlannerId
          mealPlannerItemsMap(nextToken: $nextToken) {
            items {
              _deleted
              _version
              _lastChangedAt
              createdAt
              day
              food {
                _deleted
                _lastChangedAt
                _version
                additionalUnits
                calories
                carbs
                createdAt
                description
                fats
                foodGroup
                gramsPerServe
                id
                imageUrl
                moreFields
                name
                protien
                updatedAt
                servingSize
                servingDescription
              }
              foodId
              id
              meal
              mealPlannerDayId

              recipe {
                _deleted
                _lastChangedAt
                _version
                category
                createdAt
                description
                id
                imageUrl
                isBreakfast
                isMainDish
                servingSize
                servingDescription
                steps
                updatedAt
                title
                ingredients {
                  items {
                    _deleted
                    _version
                    createdAt
                    _lastChangedAt
                    foodId
                    id
                    servingDescription
                    recipeId
                    servingSize
                    updatedAt
                    food {
                      _deleted
                      _lastChangedAt
                      _version
                      additionalUnits
                      carbs
                      calories
                      createdAt
                      fats
                      description
                      foodGroup
                      gramsPerServe
                      id
                      moreFields
                      imageUrl
                      name
                      protien
                      servingDescription
                      servingSize
                      updatedAt
                    }
                  }
                }
              }
              recipeId
              servingDescription
              servingSize
              updatedAt
              tenant
            }
          }
        }
      }

      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listCustomRecipes = /* GraphQL */ `
  query ListRecipes(
    $filter: ModelRecipeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecipes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _lastChangedAt
        _version
        category
        createdAt
        id
        description
        tenant
        favorites {
          items {
            id
            foodId
            food {
              name
            }
            _version
            _deleted
          }

          nextToken
          startedAt
          __typename
        }
        imageUrl
        servingSize
        servingDescription
        title
        ingredients {
          items {
            _deleted
            _lastChangedAt
            _version
            createdAt
            food {
              _deleted
              _lastChangedAt
              _version
              calories
              carbs
              createdAt
              description
              fats
              foodGroup
              additionalUnits
              id
              gramsPerServe
              imageUrl
              moreFields
              name
              protien
              servingDescription
              servingSize
              updatedAt
            }
            foodId
            id
            servingDescription
            recipeId
            servingSize
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomRecipe = /* GraphQL */ `
  query GetRecipe($id: ID!) {
    getRecipe(id: $id) {
      id
      title
      steps
      description
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      tenant
      ingredients {
        items {
          _deleted
          _lastChangedAt
          _version
          createdAt
          food {
            _deleted
            _lastChangedAt
            _version
            calories
            carbs
            additionalUnits
            createdAt
            description
            fats
            foodGroup
            gramsPerServe
            id
            imageUrl
            moreFields
            name
            protien
            servingDescription
            servingSize
            updatedAt
          }
          foodId
          id
          recipeId
          servingDescription
          servingSize
          updatedAt
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listCalendarsPlanCustom = /* GraphQL */ `
  query ListCalendars(
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextCalendarToken: String
  ) {
    listCalendars(
      filter: $filter
      limit: $limit
      nextToken: $nextCalendarToken
    ) {
      items {
        id
        startDate
        endDate
        days
        userID
        meal
        mealPlannerId
        servingSize
        servingDescription
        recipeId
        foodId
        _deleted
        _version
        mealPlanner {
          id
          planTitle
          mealLayoutID
          numOfdays
          mealLayout {
            layout
            id
            _deleted
            _lastChangedAt
            _version
            createdAt
            layoutName
            updatedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        alteredMealItems {
          items {
            _deleted
            _lastChangedAt
            _version
            calendarId
            createdAt
            day
            foodId
            id
            meal
            mealPlannerId
            mealPlannerItemsMapId
            recipeId
            servingDescription
            servingSize
            updatedAt
            userID
            recipe {
              id
              title
              description
              imageUrl
              servingSize
              servingDescription
              category
              isMainDish
              isBreakfast
              ingredients {
                items {
                  id
                  recipeId
                  servingSize
                  servingDescription
                  foodId
                  food {
                    id
                    name
                    description
                    calories
                    moreFields
                    additionalUnits
                    carbs
                    fats
                    protien
                    servingSize
                    servingDescription
                    gramsPerServe
                    imageUrl
                    foodGroup
                    createdAt
                    updatedAt
                  }
                  _version
                  _deleted
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
            }
            food {
              id
              name
              description
              calories
              moreFields
              additionalUnits
              carbs
              fats
              protien
              servingSize
              servingDescription
              gramsPerServe
              imageUrl
              foodGroup
              createdAt
              updatedAt
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const listCustomExercises = /* GraphQL */ `
  query ListExercises(
    $filter: ModelExerciseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExercises(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        tenant
        additionalNotes
        weight
        bodyPartId
        videoUrl
        bodyPart {
          id
          name
        }
        defaultValue
        selectedUnit
        imageUrl
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getCustomWorkoutPlan = `
query GetWorkoutPlan($id: ID!) {
  getWorkoutPlan(id: $id) {
    workoutPlanDay {
      items {
        workoutPlanId
        updatedAt
        id
        sessionNotes
        sessionOrder
        dayOff
        dayNum
        tenant
        dayNotes
        createdAt
        _version
        _deleted
        _lastChangedAt
        workoutPlannerItemsMap {
          items {
            workoutPlanId
            workoutPlanDayId
            updatedAt
            session
            notes
            defaultValue
            id
            exerciseId
            tenant
            _version
            createdAt
            _lastChangedAt
            exercise {
              id
      name
      additionalNotes
      weight
      bodyPartId
      bodyPart {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
            }
            _deleted


            
          }
        }
      }
    }
    id
    name
    _deleted
    _lastChangedAt
    _version
    createdAt
    notes
    numOfdays
    updatedAt
    tenant
  }
}`;
export const listWorkoutCustomCalendars = /* GraphQL */ `
  query ListWorkoutCalendars(
    $filter: ModelWorkoutCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkoutCalendars(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        userID
        workoutPlanId
        workoutPlanDayId
        workoutPlanDetailId
        tenant
        workoutPlanDay {
          sessionOrder
          dayNum
          dayNotes
        }
        exerciseId
        exercise {
          id
          name
          additionalNotes
          weight
          bodyPartId
          bodyPart {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          videoUrl
          imageUrl
          selectedUnit
          defaultValue
          createdAt
          updatedAt
          _version
          _deleted
        }
        session
        notes
        defaultValue
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const listCustomFoods = /* GraphQL */ `
  query ListFoods(
    $filter: ModelFoodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFoods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        calories
        tenant
        carbs
        fats
        protien
        servingSize
        servingDescription
        gramsPerServe
        imageUrl
        foodGroup
        moreFields
        favorites {
          items {
            id
            foodId
            food {
              name
            }
            _version
            _deleted
          }

          nextToken
          startedAt
          __typename
        }
        additionalUnits
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listCustomFavorites = /* GraphQL */ `
  query ListFavorites(
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavorites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        trainerID
        recipeId
        foodId
        recipe {
          id
          title
          description
          servingSize
          servingDescription
          imageUrl
          category
          isMainDish
          isBreakfast
          steps
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        food {
          id
          name
          description
          calories
          carbs
          fats
          protien
          servingSize
          servingDescription
          gramsPerServe
          imageUrl
          foodGroup
          moreFields
          additionalUnits
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const updateCustomFood = /* GraphQL */ `
  mutation UpdateFood(
    $input: UpdateFoodInput!
    $condition: ModelFoodConditionInput
  ) {
    updateFood(input: $input, condition: $condition) {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      favorites {
        items {
          id
          foodId
          food {
            name
          }
          _version
          _deleted
        }

        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        imageUrl
        gender
        activeStatus
        ageRange
        age
        height
        weightStart
        weightGoal
        motivation
        goal
        goalType
        achievement
        workoutCountPerWeek
        weightChangePerWeek
        workoutLocation
        workoutExperience
        workoutInfo
        mealCountPerDay
        phone
        location
        country
        timezone
        lastLogin
        emailValidated
        injuries
        dietPreference
        allergies
        excludeFood
        recentActivity {
          _deleted
          _lastChangedAt
          _version
          createdAt
          id
          mealsCalendar
          updatedAt
          workoutCalendar
          workoutPlannerData {
            id
            name
            numOfdays
            notes
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          mealPlannerData {
            id
            planTitle
            mealLayoutID
            numOfdays
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
        }
        dailyKcal
        proteinInPercentage
        carbsInPercentage
        fatInPercentage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRecentActivityId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMealsCalendarDatesCustom = /* GraphQL */ `
  query ListMealsCalendarDates(
    $filter: ModelMealsCalendarDateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMealsCalendarDates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        date
        mealLayoutID
        tenant
        mealLayout {
          id
          layout
          layoutName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDietMealPlannerDay = /* GraphQL */ `
  query GetMealPlanner($id: ID!) {
    getMealPlanner(id: $id) {
      id
      planTitle
      mealLayoutID
      numOfdays
      tenant
      mealLayout {
        layout
        id
        _deleted
        _lastChangedAt
        _version
        createdAt
        layoutName
        updatedAt
      }
      mealPlannerDay {
        items {
          mealDayOrder
        }
      }

      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const GET_NOTES = gql`
  query GetNotes($userID: ID!) {
    listNotes(filter: { userID: { eq: $userID }, _deleted: { ne: true } }) {
      items {
        id
        note
        createdAt
        _version
      }
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      id
      note
      createdAt
      _version
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      id
      note
      _version
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation DeleteNote($input: DeleteNoteInput!) {
    deleteNote(input: $input) {
      id
    }
  }
`;

export const GET_REMINDERS = gql`
  query GetReminders($userID: ID!, $limit: Int) {
    listReminders(
      filter: { userID: { eq: $userID }, _deleted: { ne: true } }
      limit: $limit
    ) {
      items {
        id
        message
        finishBy
        isDone
        createdAt
        _version
      }
    }
  }
`;

export const CREATE_REMINDER = gql`
  mutation CreateReminder($input: CreateReminderInput!) {
    createReminder(input: $input) {
      id
      message
      finishBy
      isDone
      createdAt
      _version
    }
  }
`;

export const UPDATE_REMINDER = gql`
  mutation UpdateReminder($input: UpdateReminderInput!) {
    updateReminder(input: $input) {
      id
      message
      isDone
      _version
    }
  }
`;

export const DELETE_REMINDER = gql`
  mutation DeleteReminder($input: DeleteReminderInput!) {
    deleteReminder(input: $input) {
      id
    }
  }
`;

export const GET_ALL_CLIENT_REMINDERS = gql`
  query GetReminders($tenant: String!, $limit: Int, $nextToken: String) {
    listReminders(
      filter: { tenant: { eq: $tenant }, _deleted: { ne: true } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        finishBy
        isDone
        createdAt
        updatedAt
        userID
        tenant
        _version
        _lastChangedAt
      }
      nextToken
    }
  }
`;
