import { useState, useEffect } from "react";
import "./style.scss";
import { forwardRef } from "react";

import Grid from "@material-ui/core/Grid";

import _ from "lodash";
import { listMealTypes } from "../../graphql/queries";
import {
  createMealType,
  deleteMealType,
  updateMealType,
} from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { Alert } from "@material-ui/lab";
import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  ChevronRight,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronLeft,
  ArrowDownward,
  Remove,
  ViewColumn,
  Search,
} from "@material-ui/icons";
import MaterialTable from "material-table";
import { useAppSelector } from "../../hooks";

//TODO: Refactor any with proper types
const tableIcons: any = {
  Add: forwardRef((props: any, ref: any) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props: any, ref: any) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props: any, ref: any) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props: any, ref: any) => (
    <DeleteOutline {...props} ref={ref} />
  )),

  DetailPanel: forwardRef((props: any, ref: any) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props: any, ref: any) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props: any, ref: any) => (
    <SaveAlt {...props} ref={ref} />
  )),
  Filter: forwardRef((props: any, ref: any) => (
    <FilterList {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props: any, ref: any) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef((props: any, ref: any) => (
    <LastPage {...props} ref={ref} />
  )),
  NextPage: forwardRef((props: any, ref: any) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props: any, ref: any) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props: any, ref: any) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef((props: any, ref: any) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props: any, ref: any) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props: any, ref: any) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props: any, ref: any) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

function MealType() {
  var columns = [
    { title: "id", field: "id", hidden: true },
    {
      title: "Meal type",
      field: "name",
    },
    { title: "Description", field: "description" },
  ];

  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState<any>([]);
  const [mealTypes, setMealTypes] = useState<any>([]);
  const [datas, setDatas] = useState<any>([]);
  const [load, setLoad] = useState(false);
  const listTenant: any = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;
  useEffect(() => {
    async function getAllMealTypes() {
      const mealTypes = await API.graphql(graphqlOperation(listMealTypes));

      setDatas(mealTypes);
    }

    getAllMealTypes();
  }, [load]);

  useEffect(() => {
    const meal = datas?.data?.listMealTypes?.items.filter((data) => {
      if (!data._deleted) {
        return data;
      }
    });

    const meal1 = _.sortBy(meal, ["createdAt"]);

    setMealTypes(meal1);
  }, [datas]);

  const handleRowUpdate = async (newData, oldData, resolve) => {
    //validation
    let errorList: string[] = [];
    if (newData.name === undefined) {
      errorList.push("Please enter mealtype");
    }

    if (errorList.length < 1) {
      try {
        await API.graphql(
          graphqlOperation(updateMealType, {
            input: {
              id: newData.id,
              name: newData.name,
              description: newData.description,
              _version: newData._version,
            },
          })
        );
        setLoad(!load);
        setErrorMessages([]);
        setIserror(false);
        resolve();
      } catch (error) {
        setErrorMessages(["Update failed! Server error"]);
        setIserror(true);
        resolve();
      }
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowAdd = async (newData, resolve) => {
    let errorList: string[] = [];
    if (newData.name === undefined) {
      errorList.push("Please enter mealtype");
    }

    if (errorList.length < 1) {
      try {
        await API.graphql(
          graphqlOperation(createMealType, {
            input: {
              name: newData.name,
              description: newData.description,
              tenant:
                tenant === "ADMIN" ? ["ADMIN", "Trainers", "Clients"] : tenant,
            },
          })
        );
        setLoad(!load);
        setErrorMessages([]);
        setIserror(false);
        resolve();
      } catch (error) {
        setErrorMessages(["Create failed! Server error"]);
        setIserror(true);
        resolve();
      }
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowDelete = async (oldData, resolve) => {
    try {
      await API.graphql(
        graphqlOperation(deleteMealType, {
          input: {
            id: oldData.id,
            _version: oldData._version,
          },
        })
      );
      setLoad(!load);
      setErrorMessages([]);
      setIserror(false);
      resolve();
    } catch (error) {
      setErrorMessages(["Delete failed! Server error"]);
      setIserror(true);
      resolve();
    }
  };

  return (
    <div className="App">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div>
            {iserror && (
              <Alert severity="error">
                {errorMessages.map((msg, i) => {
                  return <div key={i}>{msg}</div>;
                })}
              </Alert>
            )}
          </div>
          {/* <MaterialTable
            columns={[
              { title: "Adı", field: "name" },
              { title: "Soyadı", field: "surname" },
              { title: "Doğum Yılı", field: "birthYear", type: "numeric" },
              {
                title: "Doğum Yeri",
                field: "birthCity",
                lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
              },
            ]}
            data={[
              {
                name: "Mehmet",
                surname: "Baran",
                birthYear: 1987,
                birthCity: 63,
              },
            ]}
            title="Demo Title"
          /> */}
          {/* <DataGrid
            rows={data}
            columns={columns}
            pageSize={5}
            checkboxSelection
            disableSelectionOnClick
          /> */}
          <MaterialTable
            title="Meal type settings"
            columns={columns}
            data={mealTypes}
            icons={tableIcons}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  handleRowUpdate(newData, oldData, resolve);
                }),
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  handleRowAdd(newData, resolve);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  if (oldData.tenant === null || tenant === oldData.tenant[0]) {
                    handleRowDelete(oldData, resolve);
                  }
                }),
              isDeletable: (oldData) =>
                oldData.tenant === null || tenant === oldData.tenant[0],
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
export default MealType;
