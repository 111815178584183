import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { removeToast } from "../../app/features/notifications";

import "./Toast.scss";

interface Props {
  toastList: [
    {
      id: number;
      title: string;
      description: string;
      backgroundColor: string;
      icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    }
  ];
  position: string;
  autoDelete: boolean;
  dismissTime: number;
}
const Toast = (props: Props) => {
  const dispatch = useDispatch();
  const { toastList, position, autoDelete, dismissTime } = props;
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList([...toastList]);

    // eslint-disable-next-line
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line
  }, [toastList, autoDelete, dismissTime, list]);

  const deleteToast = id => {
    dispatch(removeToast(id));
  };

  return (
    <>
      <div className={`notification-container ${position}`}>
        {list.map((toast, i) => (
          <div
            key={i}
            className={`notification toast ${position}`}
            style={{ backgroundColor: toast.backgroundColor }}
          >
            <button onClick={() => deleteToast(toast.id)}>X</button>
            {toast?.icon && (
              <div className="notification-image">
                <toast.icon />
              </div>
            )}
            <div>
              <p className="notification-title">{toast.title}</p>
              <p className="notification-message">{toast.description}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Toast;
