import { useEffect, useState } from "react";

import { ReactComponent as CloseIcon } from "../../../../images/CloseOutlineIcon.svg";
import "./SearchWorkoutExercise.scss";
import Search from "../../../../component/Search/Search";
import FourItemsContainer from "../../../Clients/FourItemsContainer/FourItemsContainer";

import "react-datepicker/dist/react-datepicker.css";

import ModalComponent from "../../../../component/ModalComponent/ModalComponent";

import { filterSearchData } from "../../../../Utils";
import { useAppSelector } from "../../../../hooks";
import { useDispatch } from "react-redux";
import { fetchExercises } from "../../../../app/features/Workout/workoutSlice";

interface IProps {
  openWorkout: boolean;
  setOpenWorkoutExercise: (boolean) => void;
  sessionName: String;
  day: Number;
  sessionOrder: String;
  _version: Number;
  type: String;
  onSelectExercise?: any;
  onSelectedItem: (
    id: string,
    sessionName: String,
    day: Number,
    sessionOrder: String,
    _version: Number
  ) => void;
}

const SeachWorkoutExercise = (props: IProps) => {
  const exercises = useAppSelector((state) => state.exercises);

  const dispatch = useDispatch();
  const exercise: any = exercises.exercises?.items.filter(
    (item) => !item._deleted
  );
  const [searchText, setSearchText] = useState("");
  const filteredExercise = filterSearchData(exercise, searchText, [
    "name",
    (item) => item.bodyPart?.name,
  ]);
  async function getAllExcercise() {
    dispatch(fetchExercises(null));
  }
  useEffect(() => {
    if (exercises.exercises?.status !== "succeeded") {
      getAllExcercise();
    }
  }, []);
  useEffect(() => {
    if (exercises.exercises?.nextToken) {
      dispatch(fetchExercises(exercises.exercises?.nextToken));
    }
  }, [exercises.exercises?.nextToken]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const closeSearch = () => {
    props.setOpenWorkoutExercise(false);
  };

  const handleOpen = async (item, type) => {
    if (props?.type === "workoutCalendar") {
      props?.onSelectExercise(item);
    } else {
      props?.onSelectedItem(
        item.id,
        props.sessionName,
        props.day,
        props.sessionOrder,
        props._version
      );
    }
    // props.onSelectedItem(item);
  };
  return (
    <>
      <ModalComponent
        handleClose={() => closeSearch()}
        onClose={() => closeSearch()}
        title={"Add Exercises"}
        open={true}
        style={{ height: "100% !important", width: "70% !important" }}
      >
        {props.openWorkout ? (
          <div className="searchFoodContainers">
            <div className="searchFoodHeader">
              <h2>Search Exercises</h2>
              <CloseIcon onClick={closeSearch} />
            </div>
            <div className="searchBar">
              <Search onChange={onChange} placeHolder="Search Exercise" />
            </div>
            <div className="searchFoodListTabContainer">
              {/* @ts-ignore */}
              <div label="All categories">
                <div className="horizontalScrollableDiv">
                  {filteredExercise ? (
                    <>
                      <label>All Exercises</label>
                      <FourItemsContainer
                        data={filteredExercise}
                        type={"exercise"}
                        onChange={handleOpen}
                      />
                    </>
                  ) : null}
                </div>
              </div>
              {/* @ts-ignore */}
            </div>
          </div>
        ) : (
          ""
        )}
      </ModalComponent>
    </>
  );
};

export default SeachWorkoutExercise;
