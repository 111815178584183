import React from "react";
import CalenderIcon from "../../images/calenderIcon.svg";

const DatePickerComponent = React.forwardRef((props, ref) => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <img
        style={{ height: "25px", width: "25px", marginLeft: "10px" }}
        onClick={props.onClick}
        src={CalenderIcon}
        ref={ref}
        alt=""
      />
    </div>
  );
});

export default DatePickerComponent;
