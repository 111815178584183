import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { ReactComponent as Clock } from "../../images/calander_clock.svg";
import {
  GET_ALL_CLIENT_REMINDERS,
  UPDATE_REMINDER,
} from "../../graphql/customQueries";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../component/Common/Loader";

const ReminderList = ({ tenant }) => {
  const [reminders, setReminders] = useState<any[]>([]);
  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery(GET_ALL_CLIENT_REMINDERS, {
    variables: {
      tenant: tenant,
      limit: 1000,
    },
    fetchPolicy: "network-only",
  });
  const [updateReminder] = useMutation(UPDATE_REMINDER);

  useEffect(() => {
    if (data && data.listReminders) {
      const currentDate = moment().startOf("day");
      const filteredReminders = data.listReminders.items.filter((item) =>
        moment(item.finishBy).isSameOrAfter(currentDate)
      );
      setReminders(filteredReminders);
    }
  }, [data]);

  const handleToggleReminder = async (id, isDone, version) => {
    try {
      await updateReminder({
        variables: {
          input: {
            id,
            isDone: !isDone,
            _version: version,
          },
        },
      });
      refetch();
    } catch (error) {
      console.error("Error updating reminder:", error);
    }
  };

  if (loading) return <Loader />;
  if (error) return <p>Error loading reminders: {error.message}</p>;

  return (
    <div className="reminderList">
      <div className="remainder__header">
        <Clock />
        <div className="remainder__header__text">Reminder</div>
      </div>
      <div className="remainder__body" style={{ height: "100%" }}>
        {reminders.map((item: any, index) => (
          <div
            key={item.id}
            className={`remainder__body__item ${
              index % 2 === 0 ? "even" : "odd"
            } ${item.isDone ? "completed" : ""}`}
          >
            <div className="remainder__body__items__checkbox">
              <input
                type="checkbox"
                checked={item.isDone}
                onChange={() =>
                  handleToggleReminder(item.id, item.isDone, item._version)
                }
              />
            </div>
            <div
              className={`remainder__body__items__utext ${
                item.isDone ? "completed" : ""
              }`}
              onClick={() => navigate(`/clients/${item.userID}/overview`)}
              style={{ width: "90%", cursor: "pointer" }}
            >
              <label>{item.message}</label>
              <div className="remainder__body__items__dtext">
                {moment(item.finishBy).format("MMM DD, yyyy")}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReminderList;
