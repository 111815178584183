import { useEffect, useState, useCallback, useRef } from "react";
import "./Recipe.scss";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { updateIngredient, deleteIngredient } from "../../../graphql/mutations";
import debounce from "lodash.debounce";
import "../MealItem/MealItem.scss";
import ButtonComponent from "../../../component/ButtonComponent/ButtonComponent";
import { sendNotification } from "../../../app/features/notifications";
import { ReactComponent as Check } from "../../../images/check.svg";
import { useDispatch } from "react-redux";
import ImageComponent from "../ImageComponent";
import { trackPromise } from "react-promise-tracker";
import { ReactComponent as BigPlus } from "../../../images/bigcircle.svg";
import { ReactComponent as Delete } from "../../../images/delete.svg";
import { ReactComponent as Drag } from "../../../images/drag.svg";
import { ReactComponent as IngredientLogo } from "../../../images/ingredientLogo.svg";
import SearchFoodRecipe from "../../ClientDetail/SearchFoodRecipe/SearchFoodRecipe";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomRecipe } from "../../../graphql/customQueries";
import ScaleIcon from "@mui/icons-material/Scale";
import Servings from "../../Library/Workout/Serving/Serving";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Image from "mui-image";
import DeleteComponent from "../../../component/Delete/Delete";
import { useAppSelector } from "../../../hooks";
import {
  getAdditionUnits,
  getCalculateFoodServings,
  sumOfUnits,
} from "../../../Utils";
import QuickInfo from "../../../component/QuickInfo/QuickInfo";
import {
  createRecipeFn,
  updateRecipeFn,
} from "../../../app/features/Recipe/recipeSlice";
const Recipe = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ingridientsData, setIngredientsData] = useState<any>([]);
  const [recipeId, setRecipeId] = useState<any>();
  const [openFoodRecipe, setOpenFoodRecipe] = useState(false);

  const [imagePath, setImagePath]: any = useState();
  const [isDeletes, setIsDeletes] = useState<any>(false);
  const [dietLoad, setDietLoad] = useState<any>(false);
  const [sum, setSum] = useState<any>();
  const { recipes }: any = useAppSelector((state) => state.recipes);
  const listTenant: any = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;
  const initialStateRecipe = {
    servingDescription: null,
    servingSize: null,
    title: null,
    description: null,
    imageUrl: null,
    tenant: tenant,
  };
  const [foodRecipe, setFoodRecipe] = useState<any>(initialStateRecipe);
  useEffect(() => {
    if (recipes.id) {
      setRecipeId(recipes.id);
    }
  }, [recipes]);
  useEffect(() => {
    (async () => {
      const url = await Storage.get("");

      return setImagePath(url.split("?")[0]);
    })();
  }, []);
  useEffect(() => {
    async function getRecipeDetail() {
      const recipe_id = recipeId || id;
      const data: any = await API.graphql(
        graphqlOperation(getCustomRecipe, {
          id: recipe_id,
        })
      );

      let stepsData: any = [];
      if (
        data?.data?.getRecipe.steps &&
        typeof data?.data?.getRecipe.steps === "string" &&
        JSON.parse(data?.data?.getRecipe.steps).data
      ) {
        stepsData = JSON.parse(data?.data?.getRecipe.steps).data;
      }

      setFoodRecipe({
        ...data.data?.getRecipe,
        steps: { data: stepsData },
      });

      const filteredItems = data?.data?.getRecipe?.ingredients?.items
        .filter((item) => !item?._deleted)
        .map((item) => {
          if (item && item.food) {
            const result = getCalculateFoodServings(
              item.food,
              item.servingSize,
              item.servingDescription
            );
            item = { ...item, ...result };
          }

          return item;
        });
      console.log("filteredItems", filteredItems);
      setIngredientsData(filteredItems);
      const result = sumOfUnits(filteredItems);
      if (result === 0) {
        setSum(0);
      } else {
        setSum(result);
      }
      if (id) {
        setRecipeId(id);
      }
    }

    if (id || recipeId) {
      trackPromise(getRecipeDetail());
    }
  }, [id, dietLoad, openFoodRecipe]);

  const onRecipePropertyInfoChange = (name, e) => {
    const data = {};
    data[name] = e.target.value;
    setFoodRecipe({
      ...foodRecipe,
      ...data,
    });
  };

  const addRecipeForm = async function updateServingSize() {
    foodRecipe.steps.data =
      foodRecipe.steps.data.length > 0
        ? foodRecipe.steps.data.filter((item) => item !== "")
        : null;

    await dispatch(
      updateRecipeFn({
        id: foodRecipe.id,
        _version: foodRecipe._version,
        servingSize: foodRecipe.servingSize,
        servingDescription: foodRecipe.servingDescription,
        title: foodRecipe.title,
        description: foodRecipe.description,
        steps: JSON.stringify(foodRecipe.steps),
        imageUrl: foodRecipe.imageUrl,
      })
    );

    dispatch(
      sendNotification({
        title: "Success",
        description: "Updated Successfuly",
        backgroundColor: "#5cb85c",
        icon: Check,
      })
    );
  };

  const addRecipe = async function createRecipeFood() {
    await dispatch(
      createRecipeFn({
        servingSize: foodRecipe.servingSize,
        servingDescription: foodRecipe.servingDescription,
        title: foodRecipe.title,
        description: foodRecipe.description,
        imageUrl: foodRecipe.imageUrl,
        tenant: tenant === "ADMIN" ? ["ADMIN", "Trainers", "Clients"] : tenant,
      })
    );
    dispatch(
      sendNotification({
        title: "Success",
        description: "Now Add your Ingredients",
        backgroundColor: "#5cb85c",
        icon: Check,
      })
    );
  };
  const addDietForm = async function updates(
    id: any,
    servingSize: any,
    servingDescription: any,
    _version
  ) {
    await API.graphql(
      graphqlOperation(updateIngredient, {
        input: {
          id: id,
          _version: _version,
          recipeId: recipeId,
          servingSize: servingSize,
          servingDescription: servingDescription,
        },
      })
    );
    dispatch(
      sendNotification({
        title: "Success",
        description: "Servings updated successfully!",
        backgroundColor: "#5cb85c",
        icon: Check,
      })
    );
    setDietLoad(!dietLoad);
  };
  const handleServingsChange = (amount, unit, index, _version) => {
    if (amount && amount !== "") addDietForm(index, amount, unit, _version);
  };
  const debouncedChangeHandler = useCallback(
    debounce(handleServingsChange, 500),
    [ingridientsData]
  );

  const onDelete = async function dietForm(id: any, version: any) {
    await API.graphql(
      graphqlOperation(deleteIngredient, {
        input: {
          id: id,
          _version: version,
        },
      })
    );
    dispatch(
      sendNotification({
        title: "Success",
        description: "Deleted successfully!",
        backgroundColor: "#5cb85c",
        icon: Check,
      })
    );
    setDietLoad(!dietLoad);
  };

  async function onChangeImage(e: any) {
    const file = e.target.files[0];
    try {
      await trackPromise(
        Storage.put(file.name, file, {
          contentType: "image/svg+xml/jpeg/png", // contentType is optional
        }).then(async (result) => {
          setFoodRecipe({
            ...foodRecipe,
            ...{
              imageUrl: result.key,
            },
          });
        })
      );
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  const onDeleteInputBox = (indexToRemove) => {
    setFoodRecipe({
      ...foodRecipe,
      steps: {
        data: foodRecipe.steps.data.filter(
          (_, index) => index !== indexToRemove
        ),
      },
    });
  };
  const handleInputChange = (index: number, value: string) => {
    const newInputValues = [...foodRecipe.steps.data];
    newInputValues[index] = value;

    setFoodRecipe({
      ...foodRecipe,
      steps: { data: newInputValues },
    });
  };

  const handleAddItem = () => {
    setFoodRecipe({
      ...foodRecipe,
      steps: { data: [...foodRecipe?.steps.data, ""] },
    });
  };
  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();
  const dragStart = (e: any, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e: any, position) => {
    e.preventDefault();
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...foodRecipe?.steps.data];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    setFoodRecipe({
      ...foodRecipe,

      steps: { data: copyListItems },
    });
  };

  const backToFoods = () => {
    navigate(`/library/Foodplan`);
  };
  return (
    <>
      <div className="topBar">
        <div className="topBar__back">
          {" "}
          <button onClick={backToFoods} className="topBar__button">
            &#60; Back
          </button>
        </div>
        <div className="topBar__search"> </div>
      </div>
      <div className="leads">
        <div className="leads__header">
          <div className="leads__header__text">
            <h1>Recipe Details</h1>
          </div>
          {recipeId && foodRecipe?.tenant?.includes(tenant) ? (
            <div className="leads__header__buttons">
              <ButtonComponent
                onClick={() => {
                  trackPromise(addRecipeForm());
                }}
                disabled={
                  !foodRecipe.servingSize || !foodRecipe.servingDescription
                }
              >
                Save Changes
              </ButtonComponent>
            </div>
          ) : null}
        </div>

        <div className="RecipieIngridient__container">
          <section className="RecipieIngridient">
            {openFoodRecipe ? (
              <SearchFoodRecipe
                setOpenFoodRecipe={setOpenFoodRecipe}
                openFood={openFoodRecipe}
                recipeId={recipeId}
                type={"addIngredients"}
              />
            ) : null}

            <div>
              <div className="RecipieIngridient__preparation">
                <div className="RecipieIngridient__preparation__image">
                  {ImageComponent(imagePath, foodRecipe.imageUrl)}
                  <input
                    type="file"
                    accept="image/svg+xml, image/jpeg, image/png"
                    onChange={onChangeImage}
                    name="Image"
                    style={{ padding: "10px 0" }}
                    disabled={
                      foodRecipe?.tenant?.includes(tenant) ? false : true
                    }
                  />
                </div>

                <div className="RecipieIngridient__preparation__counts">
                  <label className="meal_modal_heading">
                    Enter the details of Recipe item
                  </label>
                  <div className="form-group foodrows">
                    <label className="col-form-label " htmlFor="title">
                      Recipe Title
                    </label>
                    <div className="add-inputbox">
                      <input
                        id="title"
                        value={foodRecipe.title}
                        onChange={onRecipePropertyInfoChange.bind(
                          this,
                          "title"
                        )}
                        className="form-control"
                        name="title"
                        type="text"
                        disabled={
                          foodRecipe?.tenant?.includes(tenant) ? false : true
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group foodrows">
                    <label className="col-form-label " htmlFor="description">
                      Recipe Description
                    </label>
                    <div className="add-inputbox">
                      <input
                        id="description"
                        value={foodRecipe.description}
                        onChange={onRecipePropertyInfoChange.bind(
                          this,
                          "description"
                        )}
                        className="form-control"
                        name="description"
                        type="text"
                        disabled={
                          foodRecipe?.tenant?.includes(tenant) ? false : true
                        }
                      />
                    </div>
                  </div>
                  <label className="meal_modal_heading">
                    Serving Size Info
                  </label>
                  <div className="form-group foodrows">
                    <label className="col-form-label " htmlFor="servingSize">
                      Serving Size
                    </label>
                    <div className="add-inputbox">
                      <input
                        id="servingSize"
                        value={foodRecipe.servingSize}
                        onChange={onRecipePropertyInfoChange.bind(
                          this,
                          "servingSize"
                        )}
                        className="form-control"
                        name="servingSize"
                        type="text"
                        disabled={
                          foodRecipe?.tenant?.includes(tenant) ? false : true
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group foodrows">
                    <label
                      className="col-form-label "
                      htmlFor="servingDescription"
                    >
                      Serving Description
                    </label>
                    <div className="add-inputbox">
                      <input
                        id="servingDescription"
                        value={foodRecipe.servingDescription}
                        onChange={onRecipePropertyInfoChange.bind(
                          this,
                          "servingDescription"
                        )}
                        className="form-control"
                        name="servingDescription"
                        type="text"
                        disabled={
                          foodRecipe?.tenant?.includes(tenant) ? false : true
                        }
                      />
                    </div>
                  </div>

                  {!recipeId && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingLeft: 45,
                      }}
                    >
                      <ButtonComponent
                        onClick={() => {
                          addRecipe();
                        }}
                        disabled={
                          !foodRecipe.servingSize ||
                          !foodRecipe.servingDescription
                        }
                      >
                        create Recipe
                      </ButtonComponent>
                    </div>
                  )}
                </div>
              </div>
              <br></br>

              {recipeId && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingLeft: 30,
                  }}
                >
                  <div className="Ingredients">
                    <div className="ingredients__text">
                      <IngredientLogo />
                      <div className="ingredients__text__cal">
                        <h2>Ingredients</h2>
                        <QuickInfo
                          name={""}
                          calories={sum?.calories}
                          carbs={sum?.carbs}
                          protein={sum?.protien}
                          fats={sum?.fats}
                          description={""}
                        >
                          <label className="dietinfohover">
                            {sum?.calories} kcal
                          </label>
                        </QuickInfo>
                      </div>
                      {foodRecipe?.tenant?.includes(tenant) ? (
                        <div className="ingredients__text__bigplus">
                          <BigPlus
                            onClick={() => {
                              setOpenFoodRecipe(true);
                            }}
                          ></BigPlus>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="recipeInfo">
                    <div className="recipesName">
                      {ingridientsData &&
                        ingridientsData?.map((item: any) => {
                          return (
                            <QuickInfo
                              name={item?.food.name}
                              description={item?.description}
                              serving={item?.servingDescription}
                              amount={item?.servingSize}
                              calories={item?.calories}
                              carbs={item?.carbs}
                              protein={item?.protien}
                              fats={item?.fats}
                            >
                              <Paper
                                sx={{
                                  p: 2,
                                  margin: "15px 0",
                                  maxWidth: 500,
                                  flexGrow: 1,
                                  backgroundColor: (theme) =>
                                    theme.palette.mode === "dark"
                                      ? "#1A2027"
                                      : "#fff",
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm container>
                                    <Grid item spacing={1}>
                                      <ButtonBase
                                        sx={{
                                          width: 100,
                                          height: 100,
                                        }}
                                      >
                                        <Image
                                          alt={item.food.imageUrl}
                                          style={{
                                            width: "100%",
                                            borderRadius: "5px",
                                          }}
                                          fit="contain"
                                          src={imagePath + item.food.imageUrl}
                                        />
                                      </ButtonBase>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={7}
                                      container
                                      direction="column"
                                      style={{ padding: "0 5px" }}
                                    >
                                      <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                        component="div"
                                        noWrap
                                        style={{
                                          textOverflow: "ellipsis",
                                          width: "150px",
                                        }}
                                      >
                                        {item.food.name}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        gutterBottom
                                        noWrap
                                      >
                                        {item.food.category ||
                                          item.food.description}
                                      </Typography>
                                      <Grid>
                                        {" "}
                                        <Servings
                                          allUnits={getAdditionUnits(item)}
                                          onServingChange={
                                            debouncedChangeHandler
                                          }
                                          disabled={
                                            foodRecipe?.tenant?.includes(tenant)
                                              ? true
                                              : false
                                          }
                                          selectedServingSize={item.servingSize}
                                          selectedServingDescription={
                                            item.servingDescription
                                          }
                                          id={item.id}
                                          _version={item._version}
                                        />
                                      </Grid>
                                      {item.calculatedGrams ? (
                                        <Grid>
                                          <Typography
                                            gutterBottom
                                            variant="body1"
                                            component="div"
                                            noWrap
                                            style={{
                                              textOverflow: "ellipsis",
                                              display: "flex",
                                              alignItems: "center",
                                              fontFamily: "Poppins",
                                              fontStyle: "normal",
                                              fontWeight: 500,
                                              fontSize: "13.6052px",
                                              lineHeight: "20px",

                                              color: "#34465e",
                                            }}
                                          >
                                            <ScaleIcon
                                              sx={{
                                                fontSize: "16px !important",
                                              }}
                                            />
                                            <span
                                              style={{ paddingLeft: "5px" }}
                                            >
                                              {Math.round(item.calculatedGrams)}{" "}
                                              grams
                                            </span>
                                          </Typography>
                                        </Grid>
                                      ) : null}
                                    </Grid>

                                    <Grid
                                      item
                                      xs={1}
                                      direction={"column"}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Grid item>
                                        {foodRecipe?.tenant?.includes(
                                          tenant
                                        ) ? (
                                          <DeleteComponent
                                            deletes={isDeletes}
                                            setDeletes={setIsDeletes}
                                            onDeletes={() =>
                                              onDelete(item.id, item._version)
                                            }
                                          />
                                        ) : null}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </QuickInfo>
                          );
                        })}
                    </div>
                  </div>
                  <div className="recipeSteps__heading">
                    <div className="recipeSteps__heading__box">
                      <label className="recipeSteps__heading__box__text">
                        Directions
                      </label>

                      {foodRecipe?.tenant?.includes(tenant) ? (
                        <div className="ingredients__text__bigplus">
                          <BigPlus onClick={handleAddItem}></BigPlus>
                        </div>
                      ) : null}
                    </div>

                    {foodRecipe &&
                      foodRecipe?.steps?.data?.map((item, index) => (
                        <div
                          className="recipeSteps"
                          onDragStart={(e) => dragStart(e, index)}
                          onDragEnter={(e) => dragEnter(e, index)}
                          onDragEnd={drop}
                          key={index}
                          draggable
                        >
                          <div className="recipeSteps__delete">
                            <Delete onClick={() => onDeleteInputBox(index)} />
                          </div>
                          <div className="recipeSteps__inputBox">
                            <textarea
                              className="recipeInputBox"
                              key={index}
                              value={item}
                              onChange={(e) =>
                                handleInputChange(index, e.target.value)
                              }
                            />
                          </div>

                          <div className="recipeSteps__drag">
                            <Drag />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Recipe;
