import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { getProduct } from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { Storage } from "aws-amplify";

const PackageVideo = () => {
  const [videoFile, setVideoFile] = useState<string>("");

  const { id } = useParams();

  useEffect(() => {
    const fetchVideoFile = async () => {
      const url = await Storage.get("");
      const validUrl = url.split("?")[0];

      const data: any = await API.graphql(
        graphqlOperation(getProduct, { id: id })
      );

      setVideoFile(validUrl + data?.data.getProduct?.video);
    };

    fetchVideoFile();
  }, [id]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {videoFile !== "" && (
        <video controls autoPlay style={{ width: "100%", height: "100vh" }}>
          <source src={videoFile} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

export default PackageVideo;
