import { ReactComponent as More } from "../../../../images/MoreButton.svg";

const workoutInfo = (props) => {
  return (
    <div className="mealInfo">
      <div className="mealInfoHeading">
        <div className="mealTypeWithNutients">
          <div className="mealType">
            <div className="dietinfohover">
              <h2>{props.label}</h2>
            </div>
          </div>
          <More />
        </div>
      </div>
    </div>
  );
};
export default workoutInfo;
