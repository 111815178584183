import FtButton from "../../../Ft-Button/Ft-Button";
import { useEffect, useState } from "react";
import { Storage } from "aws-amplify";

import Search from "../../../../component/Search/Search";
import { filterSearchData } from "../../../../Utils";
import { useAppSelector } from "../../../../hooks";
import Nodata from "../../../../images/noData.png";
import { ReactComponent as Check } from "../../../../images/check.svg";
import { sendNotification } from "../../../../app/features/notifications";
import "./FoodPlan.scss";
import React from "react";
import Food from "../../../Clients/Food/Food";
import FourItemsContainer from "../../../Clients/FourItemsContainer/FourItemsContainer";
import {
  fetchFoods,
  deleteFoodsFn,
} from "../../../../app/features/Food/foodSlice";
import {
  fetchRecipes,
  deleteRecipeFn,
} from "../../../../app/features/Recipe/recipeSlice";
import { fetchTenant } from "../../../../app/features/Tenant/tenantSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const FoodPlan = () => {
  const [showFood, setShowFood] = useState<boolean>(false);
  const [selectedFoodItem, setSelectedFoodItem] = useState<any>();
  const [imagePath, setImagePath]: any = useState();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const listfood: any = useAppSelector((state) => state.foods);
  const listfoods: any = listfood.foods?.items.filter((item) => !item._deleted);
  const listrecipe: any = useAppSelector((state) => state.recipes);
  const listrecipes: any = listrecipe.recipes?.items.filter(
    (item) => !item._deleted
  );
  const filteredFoods = filterSearchData(listfoods, searchText, [
    "name",
    "description",
  ]);
  const filteredRecipes = filterSearchData(listrecipes, searchText, [
    "title",
    "description",
  ]);
  async function getAllFoods() {
    dispatch(fetchFoods(null));
    dispatch(fetchRecipes(null));
    dispatch(fetchTenant(null));
  }
  useEffect(() => {
    if (listfood.foods?.status !== "succeeded") {
      getAllFoods();
    }
  }, []);

  useEffect(() => {
    (async () => {
      const url = await Storage.get("");

      return setImagePath(url.split("?")[0]);
    })();
  }, []);
  useEffect(() => {
    if (listfood.foods?.nextToken) {
      dispatch(fetchFoods(listfood.foods?.nextToken));
    }
    if (listrecipe.recipes?.nextToken) {
      dispatch(fetchRecipes(listrecipe.recipes?.nextToken));
    }
  }, [listfood.foods?.nextToken, listrecipe.recipes?.nextToken, dispatch]);
  const handleSelectedItem = (item, type) => {
    if (type === "foodLibrary") {
      setShowFood(true);
      setSelectedFoodItem(item);
    } else {
      if (item.id) {
        navigate(`/library/foodplan/recipedetails/${item?.id}`);
      } else {
        navigate(`/library/foodplan/recipedetails`);
      }
    }
  };
  const handleOpen = async (item, type) => {
    if (type === "foodLibrary") {
      dispatch(deleteFoodsFn(item));
      dispatch(
        sendNotification({
          title: "Success",
          description: "Deleted Successfully",
          backgroundColor: "#5cb85c",
          icon: Check,
        })
      );
    }
    if (type === "recipeLibrary") {
      dispatch(deleteRecipeFn(item));
      dispatch(
        sendNotification({
          title: "Success",
          description: "Deleted Successfully",
          backgroundColor: "#5cb85c",
          icon: Check,
        })
      );
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const handleClose = async () => {
    setShowFood(false);
    setSelectedFoodItem(null);
  };

  return (
    <>
      <div className="foodlibrary">
        {showFood && (
          <Food
            showFood={showFood}
            imagePath={imagePath}
            handleClose={handleClose}
          />
        )}

        {selectedFoodItem && (
          <Food
            showFood={showFood}
            imagePath={imagePath}
            handleClose={handleClose}
            item={selectedFoodItem}
          />
        )}

        <div className="foodlibrary__header">
          <div className="foodlibrary__header__text">
            <h1>Foods</h1>
          </div>
          <div className="foodlibrary__header__button">
            <div className="foodlibrary__header__button">
              <FtButton
                className="createnewbtn"
                border="1px solid #109CF1"
                color="#109CF1"
                height="40px"
                onClick={() => {
                  setShowFood(true);
                }}
                radius="5px"
                width="100%"
                children="Create New food"
                icon={""}
              />
            </div>
            <div className="foodlibrary__header__button">
              {" "}
              <FtButton
                className="createnewbtn"
                border="1px solid #109CF1"
                color="#109CF1"
                height="40px"
                onClick={() => {
                  navigate(`/library/foodplan/recipedetails`);
                }}
                radius="5px"
                width="100%"
                children="Create New Recipe"
                icon={""}
              />
            </div>
          </div>
        </div>
        <div className="searchBar">
          <Search
            onChange={(e) => {
              onChange(e);
            }}
            placeHolder="Search food"
          />
        </div>
      </div>
      <div className="Food__body">
        <>
          <div style={{ width: "100%" }}>
            <label className="footitle__Text">All Foods</label>
            {filteredFoods?.length ? (
              <div className="scrollableContainer">
                <FourItemsContainer
                  data={filteredFoods}
                  type="foodLibrary"
                  onSelectedItem={handleSelectedItem}
                  onChange={handleOpen}
                />
              </div>
            ) : (
              <div className="Food__body" style={{ alignItems: "center" }}>
                <img src={Nodata} alt="No data found" />
              </div>
            )}
            <label className="footitle__Text">All Recipes</label>
            {filteredRecipes.length ? (
              <div className="scrollableContainer">
                <FourItemsContainer
                  data={filteredRecipes}
                  type="recipeLibrary"
                  onSelectedItem={handleSelectedItem}
                  onChange={handleOpen}
                />
              </div>
            ) : (
              <div className="Food__body" style={{ alignItems: "center" }}>
                <img src={Nodata} alt="No data found" />
              </div>
            )}
          </div>
        </>
      </div>
    </>
  );
};
export default FoodPlan;
