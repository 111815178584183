import { ReactComponent as Bowl } from "../../../images/bowl.svg";
import { ReactComponent as Run } from "../../../images/Run.svg";
import { ReactComponent as MinusCircle } from "../../../images/MinusCircle.svg";
import { ReactComponent as PlusCircle } from "../../../images/PlusCircle.svg";
import { ReactComponent as Info } from "../../../images/info.svg";
import { ReactComponent as X } from "../../../images/X20.svg";
import "./ClientDetailMeal.scss";
import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { updateUser } from "../../../graphql/mutations";
import { useParams } from "react-router-dom";
import { bmr } from "../../../Utils";
import _ from "lodash";

const rangeValues = [1.4, 1.6, 1.65, 1.7, "2.0", 2.4, "2.4<<"];
const Message = [
  "Inactive (Hospital patient with limited physical mobility Lifestyle)",
  "Sedentary (Little (if any) physical activity at work or in leisure time, e.g. typical UK or USA office worker - male or female)",
  "Moderately Active (Moderate physical activity at work, e.g. in construction, or some jobs in agriculture or the leisure industry. ) ",
  "Moderately Active (Moderate physical activity at work or leisure - female)",
  "Moderately Active (Moderate physical activity at work or leisure - male)",
  "Very Active (Considerable physical activity at work, e.g. some military or outdoor occupations or energetic jobs in the leisure industry such as fitness trainers who run alongside clients)",
  "Extremely Active (Professional athlete or sports person e.g. football player)",
];

function Meal(props) {
  const [currentRange, setCurrentRange] = useState<any>();
  const [clientDetailMeal, setClientDetailMeal] = useState<any>();
  const [inputWeightChangePerWeek, setInputWeightChangePerWeek] =
    useState<any>();
  const [inputAllergies, setInputAllergies] = useState<any>("");
  const [show, toggleShow] = useState(false);
  const [inputPreferences, setInputPreferences] = useState<any>("");
  const [showInput, toggleShowInput] = useState(false);
  const [counterInput, setCounterInput] = useState<any>();

  const { id } = useParams();

  useEffect(() => {
    async function getClientDetailMeal() {
      setClientDetailMeal(props.data);
      setCurrentRange((props.data as any)?.data.getUser?.workoutCountPerWeek);
      setCounterInput((props.data as any)?.data.getUser?.weightGoal);
      setInputWeightChangePerWeek(
        (props.data as any)?.data.getUser?.weightChangePerWeek
      );
    }
    if (props.data) {
      getClientDetailMeal();
    }
  }, [props.data]);

  function getValues() {
    return { a: rangeValues[currentRange], b: Message[currentRange] };
  }
  const { a, b } = getValues();
  const bodyMassRange = () => {
    let val = bmr(
      clientDetailMeal?.data.getUser?.gender,
      clientDetailMeal?.data.getUser?.weightStart,
      clientDetailMeal?.data.getUser?.height,
      clientDetailMeal?.data.getUser?.age
    );

    return val;
  };

  const tdee = () => {
    return 1.55 * bodyMassRange();
  };
  // Daily Activity Level
  async function updateDailyRange(updatedDailyRange) {
    const updateRange = await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: id,
          _version: clientDetailMeal?.data.getUser?._version,
          workoutCountPerWeek: updatedDailyRange,
        },
      })
    );
    setCurrentRange(updateRange);
    props.fetchdata();
  }

  const handleSaveDailyRange = () => {
    const updatedRange = updateDailyRange(currentRange);
  };

  // weightGoal
  async function updateWeightGoal(updatedWeightGoal) {
    const updateGoal = await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: id,
          _version: clientDetailMeal?.data.getUser?._version,
          weightGoal: updatedWeightGoal,
        },
      })
    );

    props.fetchdata();
  }

  const handleSaveWeightGoal = () => {
    const updatedGoal = updateWeightGoal(counterInput);
  };

  async function updateWeightChangePerWeek(updatedWeightChangePerWeek) {
    const updateLbs = await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: id,
          _version: clientDetailMeal?.data.getUser?._version,
          weightChangePerWeek: updatedWeightChangePerWeek,
        },
      })
    );

    props.fetchdata();
  }

  const saveWeightChangePerWeek = () => {
    const updatedGoal = updateWeightChangePerWeek(inputWeightChangePerWeek);
  };

  //Allergies
  async function updateAllergies(items) {
    const updates: any = await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: id,
          _version: clientDetailMeal?.data.getUser?._version,
          allergies: items,
        },
      })
    );
    props.fetchdata();
  }

  const saveAllergies = () => {
    const items = clientDetailMeal?.data.getUser?.allergies;

    if (inputAllergies !== "" && items !== null) {
      items.push(inputAllergies);
      const updatedResponse = updateAllergies(items);
      setInputAllergies("");
    } else if (inputAllergies !== "" && items === null) {
      const updatedResponse = updateAllergies([inputAllergies]);
    }
  };

  async function deleteUpdateAllergies(list) {
    const updateDelete: any = await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: id,
          _version: clientDetailMeal?.data.getUser?._version,
          allergies: list,
        },
      })
    );

    props.fetchdata();
  }
  const deleteAllergies = (i) => {
    const list = clientDetailMeal?.data.getUser?.allergies;
    if (i > -1) {
      // only splice array when item is found
      list.splice(i, 1); // 2nd parameter means remove one item only
    }

    const deletedResponse = deleteUpdateAllergies(list);
  };

  // preference
  async function updatePreferences(items) {
    const updates: any = await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: id,
          _version: clientDetailMeal?.data.getUser?._version,
          dietPreference: items,
        },
      })
    );

    props.fetchdata();
  }

  const savePrefernces = () => {
    const items = clientDetailMeal?.data.getUser?.dietPreference;
    // When Items is null and  inputPreferences are not empty
    if (inputPreferences !== "" && items !== null) {
      items.push(inputPreferences);
      const updatedResponse = updatePreferences(items);
      setInputPreferences("");
    }
    // When Items is null and  inputPreferences are not empty
    else if (inputPreferences !== "" && items === null) {
      const updatedResponse = updatePreferences([inputPreferences]);
    }
  };
  async function deleteUpdatePreferences(list) {
    const updateDelete: any = await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: id,
          _version: clientDetailMeal?.data.getUser?._version,
          dietPreference: list,
        },
      })
    );

    props.fetchdata();
  }
  const deletePreferences = (i) => {
    const list = clientDetailMeal?.data.getUser?.dietPreference;
    if (i > -1) {
      // only splice array when item is found
      list.splice(i, 1); // 2nd parameter means remove one item only
    }
    const deletedResponse = deleteUpdatePreferences(list);
  };

  return (
    <>
      <div className="Meals"></div>
      <div className="Meals__MealContainer">
        <div className="Meals__MealContainer__header">
          <div>
            <Bowl />
          </div>
          <div className="Meals__MealContainer__header__text">
            Nutrition Information
          </div>
        </div>
        <div>
          <hr />
        </div>
        <div className="Meals__MealContainer__content">
          <div className="Meals__MealContainer__content__left">
            <div className="Meals__MealContainer__content__left__header">
              <div className="Meals__MealContainer__content__left__header__text1">
                BMR / TDEE
                <div className="Meals__MealContainer__content__left__header__text1__info1">
                  <Info />
                </div>
                <div className="Meals__MealContainer__content__left__header__text1__count1">
                  {bodyMassRange().toFixed(2)} / {tdee().toFixed(2)} kcal
                </div>
              </div>
              <div className="Meals__MealContainer__content__left__header__text2">
                WEIGHT GOAL
                <div className="Meals__MealContainer__content__left__header__text2__info2">
                  <Info />
                </div>
                <div className="Meals__MealContainer__content__left__header__text2__count2">
                  <div className="Meals__MealContainer__content__left__header__text2__count2__goal">
                    <input
                      type="text"
                      value={counterInput}
                      onChange={(e) => {
                        setCounterInput(e.target.value);
                      }}
                      onBlur={handleSaveWeightGoal}
                    />
                    <p>lbs</p>
                  </div>
                </div>
              </div>
              <div className="Meals__MealContainer__content__left__header__text3">
                LBS. PER WEEK
                <div className="Meals__MealContainer__content__left__header__text3__info3">
                  <Info />
                </div>
                <div className="Meals__MealContainer__content__left__header__text3__count3">
                  <div className="Meals__MealContainer__content__left__header__text3__count3__lbs">
                    <input
                      type="text"
                      value={inputWeightChangePerWeek}
                      onChange={(e) => {
                        setInputWeightChangePerWeek(e.target.value);
                      }}
                      onBlur={saveWeightChangePerWeek}
                    />
                    <p>lbs</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="Meals__MealContainer__content__left__body">
              <div className="Meals__MealContainer__content__left__body__pal">
                <div className="Meals__MealContainer__content__left__body__pal__run">
                  <Run />
                </div>
                <div className="Meals__MealContainer__content__left__body__pal__text">
                  PAL
                  <div className="Meals__MealContainer__content__left__body__pal__text__info4">
                    <Info />
                  </div>
                  <div className="Meals__MealContainer__content__left__body__pal__text__count">
                    {a}
                  </div>
                </div>
              </div>
              <div className="Meals__MealContainer__content__left__body__activity">
                <div className="Meals__MealContainer__content__left__body__activity__work">
                  ACTIVITY LEVEL AT WORK
                  <div className="Meals__MealContainer__content__left__body__activity__work__slider">
                    <div className="Meals__MealContainer__content__left__body__activity__work__slider__container">
                      <input type="range" id="sliderRange" />
                    </div>
                  </div>
                </div>
                <div className="Meals__MealContainer__content__left__body__activity__daily">
                  DAILY ACTIVITY LEVEL
                  <div className="Meals__MealContainer__content__left__body__activity__work__slider">
                    <div className="Meals__MealContainer__content__left__body__activity__work__slider__container">
                      <input
                        type="range"
                        min="0"
                        max="6"
                        value={currentRange}
                        onChange={(event) => {
                          setCurrentRange(+event.target.value);
                        }}
                        id="sliderRange"
                        onClick={handleSaveDailyRange}
                      />
                    </div>
                  </div>
                  <p>{b}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="Meals__MealContainer__content__right">
            <div className="Meals__MealContainer__content__right__allergies">
              <div className="Meals__MealContainer__content__right__allergies__header">
                Allergies
                <div className="Meals__MealContainer__content__right__allergies__header__plus3">
                  {!show ? (
                    <PlusCircle onClick={() => toggleShow(!show)} />
                  ) : (
                    <MinusCircle onClick={() => toggleShow(!show)} />
                  )}
                </div>
              </div>
              <div className="Meals__MealContainer__content__right__allergies__body">
                {clientDetailMeal?.data.getUser?.allergies?.map((item, i) => (
                  <div>
                    <div className="Meals__MealContainer__content__right__allergies__body__items">
                      {item}
                    </div>
                    <div className="Meals__MealContainer__content__right__allergies__body__items__hide">
                      <X onClick={() => deleteAllergies(i)} />
                    </div>
                  </div>
                ))}
                {show && (
                  <input
                    type="text"
                    name="inputText"
                    placeholder="Add"
                    value={inputAllergies}
                    onChange={(e) => setInputAllergies(e.target.value)}
                    onBlur={saveAllergies}
                    className="Meals__MealContainer__content__right__allergies__body__items"
                    style={{
                      minWidth: "30px",
                      height: "25px",
                      padding: "5px",
                      margin: "15px",
                      display: "flex",
                      cursor: "auto",
                      outline: "none",
                    }}
                  />
                )}
              </div>
            </div>

            <div className="Meals__MealContainer__content__right__preferences">
              <div className="Meals__MealContainer__content__right__preferences__header">
                Preferences
                <div className="Meals__MealContainer__content__right__preferences__header__plus4">
                  {!showInput ? (
                    <PlusCircle onClick={() => toggleShowInput(!showInput)} />
                  ) : (
                    <MinusCircle onClick={() => toggleShowInput(!showInput)} />
                  )}
                </div>
              </div>
              <div className="Meals__MealContainer__content__right__preferences__body">
                {clientDetailMeal?.data.getUser.dietPreference?.map(
                  (item, i) => (
                    <div>
                      <div className="Meals__MealContainer__content__right__preferences__body__items">
                        {item}
                      </div>
                      <div className="Meals__MealContainer__content__right__preferences__body__items__hide">
                        <X onClick={() => deletePreferences(i)} />
                      </div>
                    </div>
                  )
                )}
                {showInput && (
                  <input
                    type="text"
                    name="inputText"
                    placeholder="Add"
                    value={inputPreferences}
                    onChange={(e) => setInputPreferences(e.target.value)}
                    onBlur={() => savePrefernces()}
                    className="Meals__MealContainer__content__right__preferences__body__items"
                    style={{
                      minWidth: "30px",
                      height: "25px",
                      padding: "5px",
                      margin: "15px",
                      display: "flex",
                      outline: "none",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Meal;
