import { createExercise, updateExercise } from "./../../../graphql/mutations";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { listCustomExercises } from "../../../graphql/customQueries";
import { listBodyParts } from "../../../graphql/queries";
import _ from "lodash";
import { deleteExercise } from "../../../graphql/mutations";
// import type { RootState } from "../../app/store";

// Define a type for the slice state
interface WorkoutState {
  value: number;
  exercises: { items: any[]; nextToken: string | null; status: string | null };
  workoutPlans: [];
  status: string | null;
  bodyParts: any[];
}

// Define the initial state using that type
export const initialState: WorkoutState = {
  value: 0,
  exercises: {
    items: [],
    nextToken: null,
    status: null,
  },
  workoutPlans: [],
  status: null,
  bodyParts: [],
};

export const fetchExercises = createAsyncThunk(
  "workout/listExercise",
  async (nextToken: string | null) => {
    const response = await API.graphql(
      graphqlOperation(listCustomExercises, { nextToken })
    );
    return response;
  }
);
export const deleteExerciseFn = createAsyncThunk(
  "workout/deleteExercise",
  async (item: any) => {
    const response = await API.graphql(
      graphqlOperation(deleteExercise, {
        input: {
          id: item.id,
          _version: item._version,
        },
      })
    );

    return response;
  }
);
export const fetchBodyParts = createAsyncThunk(
  "workout/listBodyParts",
  async (nextToken: string | null) => {
    const response = await API.graphql(
      graphqlOperation(listBodyParts, { nextToken })
    );
    console.log("bodyParts slice", response);
    return response;
  }
);
export const updateExerciseFn = createAsyncThunk(
  "exercise/update",
  async (input: any) => {
    const response = await API.graphql(
      graphqlOperation(updateExercise, { input: input })
    );
    return response;
  }
);
export const createExerciseFn = createAsyncThunk(
  "exercise/create",
  async (input: any) => {
    const response = await API.graphql(
      graphqlOperation(createExercise, { input: input })
    );
    return response;
  }
);
export const workoutSlice = createSlice({
  name: "workout",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },

    logout: (state) => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchExercises.pending, (state, action) => {
        state.exercises.status = "loading";
      })
      .addCase(fetchExercises.fulfilled, (state, action) => {
        const sortedItems = action.payload["data"].listExercises.items
          .slice()
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
          });

        state.exercises = {
          items: state.exercises?.items.concat(sortedItems),
          nextToken: action.payload["data"].listExercises.nextToken,
          status: "succeeded",
        };
      })
      .addCase(fetchExercises.rejected, (state) => {
        state.exercises.status = "failed";
      })
      .addCase(deleteExerciseFn.fulfilled, (state, action) => {
        // Add any fetched posts to the array
        const deletedItem: any = action.payload;
        state.exercises = {
          ...state.exercises,
          ...{
            items: state.exercises?.items.map((data) => {
              if (data.id === deletedItem.data?.deleteExercise.id) {
                return deletedItem?.data?.deleteExercise;
              }
              return data;
            }),
            status: "succeeded",
          },
        };
      })
      .addCase(updateExerciseFn.fulfilled, (state, action) => {
        const updatedExercise = action.payload["data"].updateExercise;

        const exerciseIndex = state.exercises.items.findIndex(
          (exercise) => exercise.id === updatedExercise.id
        );

        if (exerciseIndex !== -1) {
          state.exercises.items[exerciseIndex] = updatedExercise;
        }

        state.exercises.status = "succeeded";
      })
      .addCase(createExerciseFn.fulfilled, (state, action) => {
        const createdExercise = action.payload["data"].createExercise; // Adjust this to match your API response structure

        // Add the created exercise to the items array
        state.exercises.items.push(createdExercise);

        // Set the status to "succeeded"
        state.exercises.status = "succeeded";
      })
      .addCase(fetchBodyParts.fulfilled, (state, action) => {
        // Add any fetched posts to the array
        state.bodyParts = action.payload["data"].listBodyParts.items.filter(
          (item) => !item?._deleted
        );
      });
  },
});

// export const { increment, decrement, incrementByAmount } = workoutSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const exercises = (state: RootState) => state.exercises;

export default workoutSlice.reducer;
export const { logout } = workoutSlice.actions;
