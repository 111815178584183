import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { listUsers, listLeads } from "../../../graphql/queries";
import type { RootState } from "./../../../store";

// Define a type for the slice state
interface UserState {
  status: string | null;
  users: any; // TODO: Replace any
}

// Define the initial state using that type
const initialState: UserState = {
  status: null,
  users: [],
};

export const fetchUsers = createAsyncThunk("users/listUsers", async () => {
  const response = await API.graphql(graphqlOperation(listUsers));

  return response;
});

export const fetchLeads = createAsyncThunk("leads/listLeads", async () => {
  const response = await API.graphql(graphqlOperation(listLeads));

  return response;
});

export const userSlice = createSlice({
  name: "users",
  // `userSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    logout: (state) => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.users = state.users.concat(action.payload);
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// export const { } = userSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const users = (state: RootState) => state.users;
export const { logout } = userSlice.actions;
export default userSlice.reducer;
