import { useEffect, useState } from "react";
import moment from "moment";
import "./CalenderStrip.scss";
import { API, graphqlOperation, Storage } from "aws-amplify";
import leftArrow from "../../../images/chevron-left.svg";
import rightArrow from "../../../images/chevron-right.svg";
import calenderIcon from "../../../images/calenderIcon.svg";
import loadTemplateIcon from "../../../images/loadTemplateIcon.svg";
import DatePickerComponent from "../../Clients/datepicker.js";
import DatePicker from "react-datepicker";
import EditModalLoadWorkout from "../EditModalLoadWorkout/EditModalLoadWorkout";
import "react-datepicker/dist/react-datepicker.css";
import EditModalLoadTemplate from "../EditModalLoadTemplate/EditModalLoadTemplate";
import Notes from "../../../component/Notes/Notes";
import { updateWorkoutsCalendarDate } from "../../../graphql/mutations";

const weekDay = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thrusday",
  5: "Friday",
  6: "Saturday",
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const CalenderStrip = ({
  CalenderStripDate,
  topItems,
  userId,
  load,
  setLoad,
}) => {
  const [dat, setDat] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedOnlyDate, setSelectedOnlyDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [startDay, setStartDay] = useState(new Date());

  const [todayDate, setTodayDate] = useState(
    moment(new Date(), "DD-MM-YYYY").format("DD-MM-YYYY")
  );
  const [uncheckDefaultDate, setUncheckDefaultDate] = useState(false);
  const [show, setShow] = useState(false);
  const [showLoad, setShowLoad] = useState(false);
  const [showLayout, setShowLayout] = useState(false);
  const [save, setSave] = useState(0);
  const [currDate, setCurrDate] = useState("");
  const hideModal = () => {
    setShow(false);
    setLoad(!load);
  };
  const showModal = () => {
    setShow(true);
  };
  const hideModalLoad = () => {
    setShowLoad(false);
    setLoad(!load);
  };
  const showModalLoad = () => {
    setShowLoad(true);
  };
  const hideCreateLayout = () => {
    setShowLayout(false);
  };
  const showCreateLayout = () => {
    setShowLayout(true);
  };

  useEffect(() => {
    let current_picked_date = moment(new Date(), "MM-DD-YYYY");
    let current_picked_date_format = current_picked_date.format("MM-DD-YYYY");
    setStartDay(current_picked_date_format);
    let new_datee = moment(current_picked_date_format, "MM-DD-YYYY");
    let strtDay = new_datee.format("YYYY-MM-DD");
    let date_for_day = new Date(current_picked_date);
    let initial =
      current_picked_date_format && current_picked_date_format != ""
        ? current_picked_date_format.split("-")
        : strtDay.split("-");

    setSelectedOnlyDate(initial[1]);
    setSelectedMonth(months[parseInt(initial[0]) - 1]);
    setSelectedYear(initial[2]);
    setSelectedDay(weekDay[date_for_day.getDay()]);
  }, [userId]);

  useEffect(() => {
    data();
  }, [startDay]);

  useEffect(() => {
    if (selectedDate && selectedDate != "") {
      setCurrDate(new Date(moment(selectedDate, "DD-MM-YYYY")));
      let initial = selectedDate.split("-");

      const dateObject = moment(selectedDate, "DD-MM-YYYY");

      const dayOfWeek = dateObject.format("dddd");
      setSelectedDay(dayOfWeek);

      setSelectedOnlyDate(initial[0]);
      setSelectedMonth(months[parseInt(initial[1]) - 1]);
      setSelectedYear(initial[2]);
      CalenderStripDate(
        moment(selectedDate, "DD-MM-YYYY").format("YYYY-MM-DD")
      );
    } else {
      const today = new Date(startDay);

      setCurrDate(today);
      setSelectedOnlyDate(today.getDate().toString().padStart(2, "0"));
      setSelectedMonth(months[today.getMonth()]);
      setSelectedYear(today.getFullYear());
    }
  }, [selectedDate]);

  const data = () => {
    const today = new Date(startDay);
    let arr = [];
    for (let i = 0; i < 10; i++) {
      let objDate = new Date(today);
      let obj = {
        day: "",
        date: "",
      };
      objDate.setDate(today.getDate() + i);
      obj.date =
        objDate.getDate().toString().padStart(2, "0") +
        "-" +
        (objDate.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        objDate.getFullYear().toString().padStart(2, "0");
      obj.day = weekDay[objDate.getDay()];
      arr.push(obj);
    }

    setSelectedDate(moment(today).format("DD-MM-YYYY"));
    setDat(arr);
  };
  function getPreviousData() {
    let previousDate = new Date(startDay);
    previousDate.setDate(previousDate.getDate() - 10);
    setStartDay(previousDate);
  }

  function getNextData() {
    let nextDate = new Date(startDay);
    nextDate.setDate(nextDate.getDate() + 10);
    setStartDay(nextDate);
  }

  const selectedDateUser = (e, value) => {
    setSelectedDate(value.date);
    setUncheckDefaultDate(true);
  };

  return (
    <>
      <div className="calenderStripHeader">
        <div className="topDate">
          <span className="calDate">{selectedOnlyDate}</span>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            <span className="calDay">{selectedDay}</span>
            <span className="calMonthYear">
              {selectedMonth + "   " + selectedYear}
            </span>
          </div>
        </div>
        {topItems ? (
          <div className="calenderStripButtons">
            <img src={loadTemplateIcon} alt="" />
            <button className="calenderStripButton" onClick={showModalLoad}>
              Load Diet Plan
            </button>
            {showLoad && (
              <EditModalLoadTemplate
                show={showLoad}
                handleClose={hideModalLoad}
                userId={userId}
                key={save}
                currentDate={currDate}
              />
            )}
          </div>
        ) : (
          <div className="calenderStripButtons">
            <img src={loadTemplateIcon} alt="" />
            <button className="calenderStripButton" onClick={showModal}>
              Load Workout Plan
            </button>
            {show && (
              <EditModalLoadWorkout
                show={show}
                handleClose={hideModal}
                userId={userId}
                key={save}
                currentDate={currDate}
              />
            )}
          </div>
        )}
      </div>
      <div className="calStrr">
        <div
          style={{
            display: "flex",
            flex: "2 1 auto",
            alignItems: "center",
            justifyContent: "space-between",
            overflowX: "hidden",
          }}
        >
          {dat.map((item) =>
            item.date === todayDate && !uncheckDefaultDate ? (
              <div style={{ padding: "5px" }}>
                <button
                  onClick={(e, value) => selectedDateUser(e, item)}
                  key={item.date}
                  className="buttonCalenderClickToday"
                >
                  <div className="boxday">{item?.day?.substr(0, 1)}</div>
                  <div className="boxdate">{item?.date?.substr(0, 2)}</div>
                </button>
              </div>
            ) : (
              <div style={{ padding: "5px" }}>
                <button
                  onClick={(e, value) => selectedDateUser(e, item)}
                  key={item.date}
                  className={`buttonCalenderClick  ${
                    item.date === selectedDate ? "selectedDate" : ""
                  }`}
                >
                  <div className="boxday">{item?.day?.substr(0, 1)}</div>
                  <div className="boxdate">{item?.date?.substr(0, 2)}</div>
                </button>
              </div>
            )
          )}
        </div>
        <div className="calenderStripItems">
          <div className="calenderArrow">
            <img
              className="leftArrowCalender"
              onClick={getPreviousData}
              src={leftArrow}
              alt=""
            />
            <img
              onClick={getNextData}
              style={{
                width: "30px",
                height: "30px",
                cursor: "pointer",
                background: "#F2F9FF",
                border: "transparent",
                marginLeft: "7px",
              }}
              src={rightArrow}
              alt=""
            />
          </div>

          <div style={{ marginTop: "30px" }}>
            <button
              style={{
                outline: "none",
                backgroundColor: "transparent",
                border: "transparent ",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img className="calenderIcon" src={calenderIcon} alt="" />
                <div className="calenderDropdown">
                  <DatePicker
                    color={"white"}
                    selected={currDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => {
                      setCurrDate(date);
                      setStartDay(date);

                      CalenderStripDate(
                        moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")
                      );
                    }}
                    customInput={<DatePickerComponent />}
                  />
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalenderStrip;
