import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000000de",
    color: "white",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    opacity: 0.5,
  },
}));
interface IProps {
  name: string;
  description: string;
  calories?: number;
  serving?: number;
  amount?: number;
  carbs?: number;
  protein?: number;
  fats?: number;
  children: React.ReactChild;
  key?: string;
}
const QuickInfo = (props: IProps) => {
  const {
    name,
    description,
    serving,
    amount,
    calories,
    carbs,
    protein,
    fats,
    children,
    key,
  } = {
    ...props,
  };
  return (
    <HtmlTooltip
      id={key}
      arrow
      placement="right"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 0 }}
      title={
        <>
          <Typography color="inherit">{name}</Typography>
          <em>{description}</em>
          <Divider />

          <div>
            <Typography component="div" color="white" padding={"10px 0px"}>
              Calories {Math.round(calories ?? 0)} kcal
            </Typography>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "fit-content",
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: 1,

                color: "white",
                textAlign: "center",
              }}
            >
              <div>
                <Typography sx={{ mb: 1.5, mr: 1, ml: 1 }} color="orange">
                  Protein <em>{Math.round(protein ?? 0)}g</em>
                </Typography>
              </div>
              <Divider
                orientation="vertical"
                color="grey"
                variant="middle"
                flexItem
              />
              <div>
                <Typography sx={{ mb: 1.5, mr: 1, ml: 1 }} color="aqua">
                  Carbs <em>{Math.round(carbs ?? 0)}g</em>
                </Typography>
              </div>
              <Divider
                orientation="vertical"
                color="grey"
                variant="middle"
                flexItem
              />
              <div>
                <Typography sx={{ mb: 1.5, mr: 1, ml: 1 }} color="#976fe8">
                  Fats <em>{Math.round(fats ?? 0)}g</em>
                </Typography>
              </div>
            </Box>
          </div>
        </>
      }
    >
      <div>{children}</div>
    </HtmlTooltip>
  );
};
export default QuickInfo;
