import Reminders from "./Reminders";
import "../ClientDetail/CalendarStripe/CalenderStrip.js";
import leftArrow from "../../images/chevron-left.svg";
import rightArrow from "../../images/chevron-right.svg";
import calenderIcon from "../../images/calenderIcon.svg";
import DatePicker from "react-datepicker";
import DatePickerComponent from "../Clients/datepicker.js";
import dashboardTotalClient from "../../images/dashboardTotalClient.svg";
import dashboardActiveClient from "../../images/dashboardActiveClient.svg";
import dasboardNewLead from "../../images/dasboardNewLead.svg";
import notificationTitleIcon from "../../images/notificationTitleIcon.svg";
import { useEffect, useState } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { listUsers, listLeads } from "../../graphql/queries";
import ReminderList from "./ReminderList";

const weekDay = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thrusday",
  5: "Friday",
  6: "Saturday",
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Dashboard = () => {
  const [openReminder, setOpenReminder] = useState(false);
  const [todayDate, setTodayDate] = useState(new Date());
  const [date, setDate] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [pickedDate, setPickedDate] = useState(todayDate);
  const [clientCount, setClientCount] = useState(0);
  const [activeClientCount, setActiveClientCount] = useState(0);
  const [leadCount, setLeadCount] = useState(0);
  const [trainerId, setTrainerId] = useState("");

  useEffect(() => {
    const fetchUserInfo = async () => {
      const user = await Auth.currentUserInfo();
      const { attributes } = user;
      setTrainerId(attributes.sub);
    };

    fetchUserInfo();
  }, []);

  function getPreviousDate() {
    let previousDate = new Date(todayDate);
    previousDate.setDate(previousDate.getDate() - 1);
    setTodayDate(previousDate);
  }

  function getNextDate() {
    let nextDate = new Date(todayDate);
    nextDate.setDate(nextDate.getDate() + 1);
    setTodayDate(nextDate);
  }

  const listLeadsData = async () => {
    let nextToken = null;
    let nextLeadItems = [];

    do {
      const queryVariables = { nextToken };
      const result = await API.graphql(
        graphqlOperation(listLeads, queryVariables)
      );

      const leadData = result?.data?.listLeads;
      nextToken = leadData?.nextToken;
      const items = leadData?.items;

      if (items) {
        nextLeadItems = nextLeadItems.concat(items);
      }
    } while (nextToken);
    const filteredLeadItems = nextLeadItems.filter((item) => !item._deleted);
    setLeadCount(filteredLeadItems.length);
  };

  const listClients = async () => {
    let nextToken = null;
    let nextUserItems = [];
    do {
      const queryVariables = { nextToken };
      const result = await API.graphql(
        graphqlOperation(listUsers, queryVariables)
      );

      const userData = result?.data?.listUsers;
      nextToken = userData?.nextToken;
      const items = userData?.items;

      if (items) {
        nextUserItems = nextUserItems.concat(items);
      }
    } while (nextToken);

    const filteredLeadItems = nextUserItems.filter((item) => !item._deleted);
    setClientCount(filteredLeadItems.length);
    setActiveClientCount(
      nextUserItems.filter((item) => item.activeStatus == "ACTIVE").length
    );
  };

  useEffect(() => {
    listClients();
    listLeadsData();
  }, []);

  useEffect(() => {
    setDate(todayDate.getDate());
    setDay(weekDay[todayDate.getDay()]);
    setMonth(months[todayDate.getMonth()]);
    setYear(todayDate.getFullYear());
    var tempoMonth = todayDate.getMonth() + 1;
    var tempoDate = todayDate.getDate();
    if (tempoMonth < 10) tempoMonth = "0" + tempoMonth;
    if (tempoDate < 10) tempoDate = "0" + tempoDate;
  }, [todayDate]);

  useEffect(() => {
    setTodayDate(pickedDate);
  }, [pickedDate]);

  useEffect(() => {
    setPickedDate(todayDate);
  }, [todayDate]);
  return (
    <>
      {!openReminder ? (
        <>
          <div className="topDashboard">
            <div>
              <span className="dashboardTitle">Dashboard</span>
            </div>
            <div style={{ padding: "10px" }}>
              <div className="calenderIcons">
                <span className="dashboardTitleDate">
                  {date + " " + day},{" " + month + " " + year}
                </span>
                <div className="dateArrows">
                  <img
                    className="leftArrowCalender"
                    onClick={getPreviousDate}
                    src={leftArrow}
                  />
                  <img
                    onClick={getNextDate}
                    style={{
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      background: "#F2F9FF",
                      border: "transparent",
                      marginLeft: "7px",
                    }}
                    src={rightArrow}
                  />
                </div>

                <div>
                  <button
                    style={{
                      outline: "none",
                      backgroundColor: "transparent",
                      border: "transparent ",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img className="calenderIcon" src={calenderIcon} />
                      <div className="calenderDropdown">
                        <DatePicker
                          color={"#f3f6fa"}
                          selected={pickedDate}
                          dateFormat="dd-MM-yyyy"
                          onChange={(date) => {
                            setPickedDate(date);
                          }}
                          customInput={<DatePickerComponent />}
                        />
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="workoutInfoBoxItems">
            <div className="workoutInfoBoxItem__Dashboard">
              <div className="boxDetail">
                <img style={{ width: "49px" }} src={dashboardTotalClient} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                    marginTop: "-4px",
                  }}
                >
                  <span className="boxDetailHeader__Dashboard">
                    Total Clients
                  </span>
                  <span className="boxDetailHeaderInfo__Dashboard">
                    {clientCount}
                  </span>
                </div>
              </div>
            </div>
            <div className="workoutInfoBoxItem__Dashboard">
              <div className="boxDetail">
                <img style={{ width: "49px" }} src={dashboardActiveClient} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                  }}
                >
                  <span className="boxDetailHeader__Dashboard">
                    Active Clients
                  </span>
                  <span className="boxDetailHeaderInfo__Dashboard">
                    {activeClientCount}
                  </span>
                </div>
              </div>
            </div>
            <div className="workoutInfoBoxItem__Dashboard">
              <div className="boxDetail">
                <img style={{ width: "49px" }} src={dasboardNewLead} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                  }}
                >
                  <span className="boxDetailHeader__Dashboard">
                    Total Leads
                  </span>
                  <span className="boxDetailHeaderInfo__Dashboard">
                    {leadCount}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="lowerPartDashboard">
            <div className="notificationList">
              <div>
                <div className="notificationListTitleIcon">
                  <img src={notificationTitleIcon} />
                  <span className="notificationListTitleIcon__title">
                    Notification List
                  </span>
                </div>
              </div>
            </div>

            <ReminderList tenant={trainerId} />
          </div>
        </>
      ) : (
        <div>
          <Reminders backToDashboard={setOpenReminder} />{" "}
        </div>
      )}
    </>
  );
};

export default Dashboard;
