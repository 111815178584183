import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listMealPlanners } from "../../../graphql/queries";
import moment from "moment";
import Pagination from "../../../component/Pagination/pagination";
import { useNavigate } from "react-router-dom";
import { deleteMealPlanner } from "../../../graphql/mutations";
import DeleteComponent from "../../../component/Delete/Delete";
import TopNav from "../../TopNav/TopNav";
import { filterSearchData } from "../../../Utils";
import FtButton from "../../Ft-Button/Ft-Button";
import Search from "../../../component/Search/Search";
import EditModalMeal from "../../ClientDetail/EditModalMeal/EditModalMeal";
import { useAppSelector } from "../../../hooks";
let PageSize = 10;
const DietPlan = () => {
  const [mealPlan, setMealPlan] = useState<any>();
  const [filteredUser, setFilteredUser] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    async function getDietPlan() {
      const MealPlannerData: any = await API.graphql(
        graphqlOperation(listMealPlanners, {
          limit: 1000,
        })
      );

      setMealPlan(MealPlannerData);
    }
    getDietPlan();
  }, [load]);
  const compareBy = (key) => {
    return function (a, b) {
      if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) return -1;
      if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) return 1;
      return 0;
    };
  };
  useEffect(() => {
    let temp = mealPlan?.data?.listMealPlanners?.items;

    if (temp) {
      temp.sort(compareBy("planTitle"));

      setFilteredUser(temp);
    }
  }, [mealPlan]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredUser(
      filterSearchData(
        mealPlan && mealPlan?.data?.listMealPlanners?.items,
        event.target.value,
        ["planTitle"]
      )
    );
  };
  const backToLibrary = () => {
    navigate(`/library`);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="topBar">
        <div className="topBar__back">
          {" "}
          <button onClick={backToLibrary} className="topBar__button">
            &#60; Back
          </button>
        </div>
        <div className="topBar__search">
          {" "}
          <TopNav>
            <Search
              onChange={onChange}
              placeHolder="Search Diet Plan by Meal Template"
            />
          </TopNav>
        </div>
      </div>
      <div className="leads">
        <div className="leads__header">
          <div className="leads__header__text">
            <h1>Diet Plans</h1>
          </div>
          <div className="newbt">
            <FtButton
              border="1px solid #109CF1"
              color="#109CF1"
              height="40px"
              className="createnewbtn"
              radius="5px"
              onClick={setShowModal}
            >
              Create New Diet Plan
            </FtButton>

            <EditModalMeal
              isDietPlan={true}
              // durations={setDuration}
              // categories={setLayoutCategory}
              show={showModal}
              handleClose={hideModal}
              // addCallback={setShowTemplate}
              // setTemplateLayoutData={setTemplateLayoutData}
              // setCreatedMealPlannerData={setCreatedMealPlannerData}
            />
          </div>
        </div>
        <div className="leads__container">
          {filteredUser && <Table data={filteredUser} setData={setLoad} />}
        </div>
      </div>
    </>
  );
};

const Table = (props) => {
  const sortTypes = {
    up: {
      class: "sort-up",
    },
    down: {
      class: "sort-down",
    },
    default: {
      class: "sort",
    },
  };
  const Columns = [
    { name: 0 },
    { meal: 0 },
    { email: 0 },
    { payment_type: 0 },
    { membership: 0 },
  ];

  const [tableState, setTableState] = useState<any>({
    currentSort: "default",
    activeColumnSort: "",
    columns: Columns,
    currentPage: 1,
    data: [],
  });
  const [paginatedData, setPaginatedData] = useState<any>([]);

  const compareBy = (key) => {
    return function (a, b) {
      if (a[key].toLowerCase() > b[key].toLowerCase()) return -1;
      if (a[key].toLowerCase() < b[key].toLowerCase()) return 1;
      return 0;
    };
  };
  let order = 1;
  const sortBy = (key) => {
    let nextSort;

    if (tableState.currentSort === "down") nextSort = "up";
    else if (tableState.currentSort === "up") nextSort = "default";
    else if (tableState.currentSort === "default") nextSort = "down";

    let arrayCopy1 = [...props.data];

    let arrayCopy2 = [...tableState.columns];

    arrayCopy1.sort(compareBy(key));
    if (tableState.currentSort === "down") {
      arrayCopy1.sort(function (a, b) {
        if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) return -1;
        if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) return 1;
        return 0;
      });
    } else if (tableState.currentSort === "up") {
      arrayCopy1.sort(function (a, b) {
        if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) return -1;
        if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) return 1;
        return 0;
      });
    }
    arrayCopy2[key] = order;
    setTableState({
      ...tableState,
      ...{
        data: arrayCopy1,
        currentPage: 1,
        currentSort: nextSort,
        activeColumnSort: key,
      },
    });
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;
    setPaginatedData(arrayCopy1?.slice(firstPage, lastPage));
  };

  const onPageChange = (page) => {
    setTableState({
      ...tableState,
      ...{ currentPage: page },
    });
  };

  useEffect(() => {
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;

    setTableState({
      ...tableState,
      ...{
        firstPageIndex: firstPage,
        lastPageIndex: lastPage,
        data: props.data,
      },
    });
    setPaginatedData(props.data?.slice(firstPage, lastPage));
  }, [props.data]);

  useEffect(() => {
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;
    setPaginatedData(tableState.data?.slice(firstPage, lastPage));
  }, [tableState.currentPage, tableState.data]);

  const [deletedId, setDeletedId] = useState<any>();
  const listTenant = useAppSelector((state) => state.tenant);
  const tenants = listTenant?.tenant?.currentTenant;
  const onDelete = async function dietForm(id: any, version: any) {
    await API.graphql(
      graphqlOperation(deleteMealPlanner, {
        input: {
          id: id,
          _version: version,
        },
      })
    );
    setDeletedId(id);
  };

  useEffect(() => {
    const datas = props.data.filter((item) => {
      if (item.id !== deletedId) {
        return item;
      }
    });
    props.setData(datas);
  }, [deletedId]);

  const [isDeletes, setIsDeletes] = useState<any>(false);
  const Row = (
    { planTitle, numOfdays, createdAt, id, _version, tenant },
    index
  ) => (
    <>
      <tr
        key={index}
        className="tableRow"
        onClick={(e) => goToDietPlanDetail(id, e)}
      >
        <td>{planTitle ?? "NA"}</td>
        <td>{numOfdays ?? "NA"}</td>
        <td>{moment(createdAt).fromNow()}</td>

        <td
          className={
            tenants === "ADMIN" && tenant.includes("ADMIN") ? "actions" : ""
          }
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {tenant === null ||
          tenants === tenant[0] ||
          (tenants === "ADMIN" && tenant.includes("ADMIN")) ? (
            <DeleteComponent
              deletes={isDeletes}
              setDeletes={setIsDeletes}
              onDeletes={(e) => {
                onDelete(id, _version);
              }}
            />
          ) : null}
        </td>
      </tr>
    </>
  );

  const rows = paginatedData?.map((rowData) => {
    if (!rowData._deleted) {
      return <Row {...rowData} />;
    }
  });
  const navigate = useNavigate();
  const goToDietPlanDetail = (id, e) => {
    e.preventDefault();
    navigate(`/library/dietplan/dietplandetail/${id}`);
  };

  return (
    <div className="responsivetable">
      <table className="text-left">
        <thead>
          <tr>
            <th>
              Meal Template
              <button onClick={() => sortBy("planTitle")}>
                {tableState.activeColumnSort !== "planTitle" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort === "planTitle" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>
            </th>

            <th>
              No Of Days
              <button>
                {tableState.activeColumnSort !== "numOfdays" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort === "numOfdays" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>{" "}
            </th>
            <th>
              Created At
              <button onClick={() => sortBy("createdAt")}>
                {tableState.activeColumnSort !== "createdAt" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort === "createdAt" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>
            </th>
            <th style={{ paddingLeft: 45 }}>Actions</th>
          </tr>
        </thead>
        {rows?.length === 0 ? (
          <tbody>
            <tr>
              <td className="nodata" colSpan={5}>
                No data available
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>{rows}</tbody>
        )}
      </table>
      <Pagination
        className="pagination-bar"
        currentPage={tableState.currentPage}
        totalCount={tableState.data?.length}
        pageSize={PageSize}
        onPageChange={onPageChange}
        rowsPerPage={paginatedData.length}
        label="plans"
      />
    </div>
  );
};
export default DietPlan;
