import { useEffect, useState } from "react";
import Tabs from "../../../component/Tabs/Tabs";
import { ReactComponent as CloseIcon } from "../../../images/CloseOutlineIcon.svg";
import "./SearchFoodRecipe.scss";
import Search from "../../../component/Search/Search";
import FourItemsContainer from "../../Clients/FourItemsContainer/FourItemsContainer";
import { API, graphqlOperation } from "aws-amplify";
import "react-datepicker/dist/react-datepicker.css";
import {
  createCalendar,
  createIngredient,
  createUserCalendarMealItems,
} from "../../../graphql/mutations";
import { useAppSelector } from "../../../hooks";
import ModalComponent from "../../../component/ModalComponent/ModalComponent";
import { filterSearchData } from "../../../Utils";
import { useDispatch } from "react-redux";
import { fetchRecipes } from "../../../app/features/Recipe/recipeSlice";
import { fetchFoods } from "../../../app/features/Food/foodSlice";
import { trackPromise } from "react-promise-tracker";
import { fetchFavorites } from "../../../app/features/Favorites/favoriteSlice";

interface IProps {
  setShowAdd?: (boolean) => void;
  choosedRecipe?: any[];
  setChoosedRecipe?: React.Dispatch<React.SetStateAction<any>>;
  openFood: boolean;
  setOpenFoodRecipe: (boolean) => void;
  activeTab?: any;
  choosedCategory?: any;
  createdMealPlanner?: any;
  recipeId?: any;
  setDietPlanData?: any;
  mealDayOrder?: any;
  typeName?: any;
  onSelectedItem?: (
    id: string,
    typeName: any,
    day: Number,
    mealDayOrder: any,
    _version: any,
    type: String
  ) => void;
  _version?: any;
  day?: any;
  type?: string;
}

const SearchFoodRecipe = (props: IProps) => {
  const listfood: any = useAppSelector((state) => state.foods);
  const listfoods: any = listfood.foods?.items.filter((item) => !item._deleted);
  const listrecipe: any = useAppSelector((state) => state.recipes);
  const listrecipes: any = listrecipe.recipes?.items.filter(
    (item) => !item._deleted
  );
  const listfavorite: any = useAppSelector((state) => state.favorites);
  const listfavorites: any = listfavorite.favorites.items
    .filter((item) => !item._deleted)
    .map((item) => item.food);

  const [searchText, setSearchText] = useState("");
  const listTenant: any = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;
  const filteredFoods = filterSearchData(listfoods, searchText, [
    "name",
    "description",
  ]);
  const filteredRecipes = filterSearchData(listrecipes, searchText, [
    "title",
    "description",
  ]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (listfood.foods?.nextToken) {
      dispatch(fetchFoods(listfood.foods?.nextToken));
    }
    if (listrecipe.recipes?.nextToken) {
      dispatch(fetchRecipes(listrecipe.recipes?.nextToken));
    }
  }, [listfood.foods?.nextToken, listrecipe.recipes?.nextToken]);

  useEffect(() => {
    if (listfood.foods?.status !== "succeeded") {
      dispatch(fetchFoods(null));
    }
    if (listrecipe.recipes?.status !== "succeeded") {
      dispatch(fetchRecipes(null));
    }
    if (listfavorite.favorites.status !== "succeeded") {
      dispatch(fetchFavorites(null));
    }
  }, []);

  async function createNewFoodItems(item, type) {
    if (props.type === "addDietPlan" && props?.onSelectedItem) {
      props.onSelectedItem(
        item,
        props.typeName,
        props.day,
        props.mealDayOrder,
        props._version,
        type
      );
    }
  }
  async function createIngredients(item, type) {
    await API.graphql(
      graphqlOperation(createIngredient, {
        input: {
          recipeId: props.recipeId,
          foodId: item.id,
          servingDescription: item.servingDescription,
          servingSize: item.servingSize,
          tenant:
            tenant === "ADMIN" ? ["ADMIN", "Trainers", "Clients"] : tenant,
        },
      })
    );
  }
  async function createCalendarMutation(item, type) {
    console.log("i", item, props.choosedCategory);
    await API.graphql(
      graphqlOperation(createUserCalendarMealItems, {
        input: {
          recipeId: type === "recipe" ? item.id : null,
          foodId: type === "food" ? item.id : null,
          meal: props.choosedCategory.type,
          userID: props.createdMealPlanner,
          servingSize: item?.servingSize,
          servingDescription: item?.servingDescription,
          tenant: tenant,
          calendarMealId: props.choosedCategory.userCalendarId,
        },
      })
    );

    props.setDietPlanData(true);
    props.setOpenFoodRecipe(false);
  }

  const handleOpen = async (item, type) => {
    if (props.type === "addCalendar") {
      await trackPromise(createCalendarMutation(item, type));
    } else if (props.type === "addDietPlan") {
      await trackPromise(createNewFoodItems(item, type));

      if (props.choosedRecipe && props.choosedRecipe.length > 0) {
        props.setChoosedRecipe &&
          props.setChoosedRecipe([...props.choosedRecipe, item]);
      } else {
        props.setChoosedRecipe && props.setChoosedRecipe([item]);
      }
    } else {
      await trackPromise(createIngredients(item, type));
      props.setOpenFoodRecipe(false);
    }
  };

  const closeSearch = () => {
    props.setOpenFoodRecipe(false);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <>
      <ModalComponent
        handleClose={() => closeSearch()}
        onClose={() => closeSearch()}
        title={"Add Food"}
        open={true}
        style={{ height: "100% !important", width: "70% !important" }}
      >
        {props.openFood ? (
          <div className="searchFoodContainers">
            <div className="searchFoodHeader">
              <h2>Search Foods</h2>
              <CloseIcon onClick={closeSearch} />
            </div>
            <div className="searchBar">
              <Search onChange={onChange} placeHolder="Search food" />
            </div>
            <div className="searchFoodListTabContainer">
              <Tabs>
                {/* @ts-ignore */}
                <div label="All categories">
                  <div className="horizontalScrollableDiv">
                    {filteredRecipes && !props.recipeId ? (
                      <>
                        <label>All Recipes</label>
                        <FourItemsContainer
                          data={filteredRecipes}
                          type={"recipe"}
                          onChange={handleOpen}
                        />
                      </>
                    ) : null}
                    {filteredFoods && (
                      <>
                        <label>All Foods</label>
                        <FourItemsContainer
                          data={filteredFoods}
                          type={"food"}
                          onChange={handleOpen}
                        />
                      </>
                    )}
                  </div>
                </div>
                {/* @ts-ignore */}

                <div label="Favorites">
                  <div className="horizontalScrollableDiv">
                    {listfavorites && (
                      <>
                        <label>Favorite Foods</label>
                        <FourItemsContainer
                          data={listfavorites}
                          type={"food"}
                          onChange={handleOpen}
                        />
                      </>
                    )}
                  </div>
                </div>
                {/* @ts-ignore */}
              </Tabs>
            </div>
          </div>
        ) : (
          ""
        )}
      </ModalComponent>
    </>
  );
};

export default SearchFoodRecipe;
