import { useEffect } from "react";
import CalenderStrip from "../ClientDetail/CalendarStripe/CalenderStrip";
import { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listRemindersCustom } from "../../graphql/customQueries";
import ReusableTable from "../Clients/ReusableTable/ResuableTable";
import TopNav from "../TopNav/TopNav";
import "./Reminders.scss";
import "./Dashboard.scss";

const Reminders = ({ backToDashboard }) => {
  const [dateFromCalenderStrip, setDateFromCalenderStrip] = useState("");
  const [datas, setData] = useState([]);
  const [dataForSelectedDat, setDataForSelectedDay] = useState([]);

  const listReminder = async () => {
    const test = await API.graphql(graphqlOperation(listRemindersCustom));

    setData(test.data.listReminders.items);
  };

  useEffect(() => {
    setDataForSelectedDay(
      datas.filter((item) => item.finishBy == dateFromCalenderStrip)
    );
  }, [dateFromCalenderStrip, datas]);

  useEffect(() => {
    listReminder();
  }, []);
  return (
    <>
      <div className="reminderCalenderTop">
        <a
          style={{ width: "26%", padding: "12px" }}
          onClick={() => backToDashboard(false)}
          className="backtodashboard"
        >
          &#60; Back To Dashboard
        </a>
        <TopNav />
      </div>
      <div className="reminderTitle">Your Reminders</div>

      <div className="dashboard">
        <div className="mealsPlannerContainer">
          <div className="mealsCalendar">
            {
              <CalenderStrip
                topItems={false}
                CalenderStripDate={setDateFromCalenderStrip}
              />
            }
          </div>
          <div className="mealsSchedule">
            <div className="mealsTab">
              <div className="dashboard_container">
                {dataForSelectedDat.length > 0 ? (
                  <ReusableTable data={dataForSelectedDat} />
                ) : (
                  <div>No data available</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reminders;
