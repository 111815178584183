import { useEffect, useState } from "react";

import "./package.scss";
import DeleteComponent from "../../component/Delete/Delete";
import FtButton from "../Ft-Button/Ft-Button";
import { listProducts } from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";

import {
  createProduct,
  deleteProduct,
  updateProduct,
} from "../../graphql/mutations";
import AddPackage from "../Clients/AddPackage/AddPackage";
import Switch from "../switch/switch";
import { useAppSelector } from "../../hooks";
import { trackPromise } from "react-promise-tracker";

const Package = () => {
  const [packageList, setPackageList] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [packageItem, setPackageItem] = useState<any>(null);
  const [filteredPackage, setFilteredPackage] = useState<any>([]);

  const listPackage = async () => {
    const data: any = await API.graphql(graphqlOperation(listProducts));
    setPackageList(data?.data.listProducts?.items);
  };

  const listTenant = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;

  const compareBy = (key) => {
    return function (a, b) {
      if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) return -1;
      if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) return 1;
      return 0;
    };
  };

  useEffect(() => {
    let temp = packageList;
    if (temp) {
      // Filter out deleted packages and those without a valid stripePriceId
      temp = temp.filter((item) => !item._deleted && item.stripePriceId);
      temp.sort(compareBy("createdAt"));
      setFilteredPackage(temp);
    }
  }, [packageList]);

  useEffect(() => {
    trackPromise(listPackage());
  }, [load]);

  useEffect(() => {
    if (show === false && packageItem !== null) {
      setPackageItem(null);
    }
  }, [show, packageItem]);

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  const handleSubmit = async (e) => {
    await API.graphql(
      graphqlOperation(createProduct, {
        input: {
          name: e.name,
          price: e.price,
          durationInMonths: e.duration,
          description: e.description,
          features: e.features,
          productType: e.productType,
          thumbnailUrl: e.thumbnail,
          video: e.video,
          status: false,
          tenant: tenant,
        },
      })
    );
    setLoad(!load);
  };

  const handleEdit = async (e) => {
    await API.graphql(
      graphqlOperation(updateProduct, {
        input: {
          id: e.id,
          name: e.name,
          price: e.price,
          durationInMonths: e.duration,
          description: e.description,
          thumbnailUrl: e.thumbnail,
          video: e.video,
          features: e.features,
          productType: e.productType,
          _version: e._version,
        },
      })
    );
    setLoad(!load);
  };

  const handlePackageItemClick = (packageItem) => {
    setPackageItem(packageItem);
  };

  return (
    <>
      <div className="packagelist">
        <div className="packagelist__header">
          <div className="packagelist__header__text">
            <h1>Packages</h1>
          </div>
          {show && (
            <AddPackage
              show={show}
              handleClose={hideModal}
              handleSubmit={handleSubmit}
              packageItem={packageItem}
              handleEdit={handleEdit}
            />
          )}

          <div className="packagelist__header__content">
            <FtButton
              className="createpackagebutton"
              border="1px solid #109CF1"
              color="#109CF1"
              height="40px"
              onClick={showModal}
              radius="5px"
              children="Create New"
            />
          </div>
        </div>
        <div className="packagelist__container">
          {filteredPackage && (
            <Table
              data={filteredPackage}
              serFilteredPackage={setFilteredPackage}
              setPackageItems={handlePackageItemClick}
              setShow={setShow}
            />
          )}
        </div>
      </div>
    </>
  );
};

const Table = (props) => {
  const { data, serFilteredPackage, setPackageItems, setShow } = props;
  const Row = (
    {
      id,
      name,
      price,
      durationInMonths,
      description,
      status,
      _version,
      video,
      thumbnailUrl,
      features,
      productType,
    },
    index
  ) => {
    const [isToggled, setIsToggled] = useState(status);
    const showPackageItem = () => {
      setPackageItems({
        id: id,
        name: name,
        price: price,
        duration: durationInMonths,
        description: description,
        video: video,
        thumbnailUrl: thumbnailUrl,
        features: features,
        productType: productType,
        _version: _version,
      });
      setShow(true);
    };

    const update = async (e, id) => {
      const response: any = await API.graphql(
        graphqlOperation(updateProduct, {
          input: {
            id: id,
            status: e,
            _version: _version,
          },
        })
      );
      const updatedData = data.map((item) => {
        if (item.id === response.data.updateProduct.id) {
          return response.data.updateProduct;
        }
        return item;
      });

      serFilteredPackage(updatedData);
    };

    const onDelete = async function dietForm(id: any, version: any) {
      await API.graphql(
        graphqlOperation(deleteProduct, {
          input: {
            id: id,
            _version: version,
          },
        })
      );
      const datas = props.data.filter((item) => {
        if (item.id !== id) {
          return item;
        }
      });
      serFilteredPackage(datas);
    };

    const [isDeletes, setIsDeletes] = useState<any>(false);
    return (
      <tr key={index} className="tableRow">
        <td className="tdHover" onClick={showPackageItem}>
          {name}
        </td>
        <td className="tdHover" onClick={showPackageItem}>
          <span> {price}</span>
        </td>
        <td className="tdHover" onClick={showPackageItem}>
          <span> {durationInMonths}</span>
        </td>
        <td className="active-column">
          <Switch
            rounded={true}
            isToggle={!isToggled}
            onToggle={() => {
              setIsToggled(!isToggled);
              update(!isToggled, id);
            }}
          />{" "}
        </td>
        <td
          className={"actions"}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {true ? (
            <DeleteComponent
              deletes={isDeletes}
              setDeletes={setIsDeletes}
              onDeletes={(e) => {
                onDelete(id, _version);
              }}
            />
          ) : null}
        </td>
      </tr>
    );
  };

  const rows = data.map((rowData) => <Row {...rowData} />);

  return (
    <div className="responsivetable">
      <table className="text-left">
        <thead>
          <tr>
            <th>Name</th>
            <th>Price (in $)</th>
            <th>Duration (in months)</th>
            <th>Active</th>
            <th style={{ paddingLeft: 45 }}>Actions</th>
          </tr>
        </thead>
        {rows?.length === 0 ? (
          <tbody>
            <tr>
              <td className="nodata" colSpan={5}>
                No data available
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>{rows}</tbody>
        )}
      </table>
    </div>
  );
};

export default Package;
