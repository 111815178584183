/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getNote = /* GraphQL */ `query GetNote($id: ID!) {
  getNote(id: $id) {
    id
    userID
    tenant
    note
    created_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetNoteQueryVariables, APITypes.GetNoteQuery>;
export const listNotes = /* GraphQL */ `query ListNotes(
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      tenant
      note
      created_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListNotesQueryVariables, APITypes.ListNotesQuery>;
export const syncNotes = /* GraphQL */ `query SyncNotes(
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncNotes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userID
      tenant
      note
      created_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncNotesQueryVariables, APITypes.SyncNotesQuery>;
export const getReminder = /* GraphQL */ `query GetReminder($id: ID!) {
  getReminder(id: $id) {
    id
    userID
    tenant
    message
    finishBy
    isDone
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReminderQueryVariables,
  APITypes.GetReminderQuery
>;
export const listReminders = /* GraphQL */ `query ListReminders(
  $filter: ModelReminderFilterInput
  $limit: Int
  $nextToken: String
) {
  listReminders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      tenant
      message
      finishBy
      isDone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRemindersQueryVariables,
  APITypes.ListRemindersQuery
>;
export const syncReminders = /* GraphQL */ `query SyncReminders(
  $filter: ModelReminderFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncReminders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userID
      tenant
      message
      finishBy
      isDone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRemindersQueryVariables,
  APITypes.SyncRemindersQuery
>;
export const getMembership = /* GraphQL */ `query GetMembership($id: ID!) {
  getMembership(id: $id) {
    id
    type
    status
    startTime
    endTime
    tenant
    deliveryAddress
    userID
    order {
      id
      userID
      tenant
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderProductId
      orderPaymentId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    membershipOrderId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMembershipQueryVariables,
  APITypes.GetMembershipQuery
>;
export const listMemberships = /* GraphQL */ `query ListMemberships(
  $filter: ModelMembershipFilterInput
  $limit: Int
  $nextToken: String
) {
  listMemberships(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      status
      startTime
      endTime
      tenant
      deliveryAddress
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      membershipOrderId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMembershipsQueryVariables,
  APITypes.ListMembershipsQuery
>;
export const syncMemberships = /* GraphQL */ `query SyncMemberships(
  $filter: ModelMembershipFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMemberships(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      type
      status
      startTime
      endTime
      tenant
      deliveryAddress
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      membershipOrderId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMembershipsQueryVariables,
  APITypes.SyncMembershipsQuery
>;
export const getPayment = /* GraphQL */ `query GetPayment($id: ID!) {
  getPayment(id: $id) {
    id
    paymentMode
    tenant
    totalAmount
    discountAmount
    shippingAmount
    taxAmount
    transactionId
    status
    paymentType
    stripeCurrency
    stripePaymentIntent
    stripePaymentStatus
    stripeStatus
    userId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentQueryVariables,
  APITypes.GetPaymentQuery
>;
export const listPayments = /* GraphQL */ `query ListPayments(
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      paymentMode
      tenant
      totalAmount
      discountAmount
      shippingAmount
      taxAmount
      transactionId
      status
      paymentType
      stripeCurrency
      stripePaymentIntent
      stripePaymentStatus
      stripeStatus
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentsQueryVariables,
  APITypes.ListPaymentsQuery
>;
export const syncPayments = /* GraphQL */ `query SyncPayments(
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPayments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      paymentMode
      tenant
      totalAmount
      discountAmount
      shippingAmount
      taxAmount
      transactionId
      status
      paymentType
      stripeCurrency
      stripePaymentIntent
      stripePaymentStatus
      stripeStatus
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPaymentsQueryVariables,
  APITypes.SyncPaymentsQuery
>;
export const getProduct = /* GraphQL */ `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    name
    imageUrl
    tenant
    description
    type
    price
    status
    stripePriceId
    durationInMonths
    includes
    video
    thumbnailUrl
    features
    productType
    trainerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductQueryVariables,
  APITypes.GetProductQuery
>;
export const listProducts = /* GraphQL */ `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      imageUrl
      tenant
      description
      type
      price
      status
      stripePriceId
      durationInMonths
      includes
      video
      thumbnailUrl
      features
      productType
      trainerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsQueryVariables,
  APITypes.ListProductsQuery
>;
export const syncProducts = /* GraphQL */ `query SyncProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncProducts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      imageUrl
      tenant
      description
      type
      price
      status
      stripePriceId
      durationInMonths
      includes
      video
      thumbnailUrl
      features
      productType
      trainerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncProductsQueryVariables,
  APITypes.SyncProductsQuery
>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    userID
    tenant
    product {
      id
      name
      imageUrl
      tenant
      description
      type
      price
      status
      stripePriceId
      durationInMonths
      includes
      video
      thumbnailUrl
      features
      productType
      trainerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    payment {
      id
      paymentMode
      tenant
      totalAmount
      discountAmount
      shippingAmount
      taxAmount
      transactionId
      status
      paymentType
      stripeCurrency
      stripePaymentIntent
      stripePaymentStatus
      stripeStatus
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderProductId
    orderPaymentId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      tenant
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderProductId
      orderPaymentId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const syncOrders = /* GraphQL */ `query SyncOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userID
      tenant
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderProductId
      orderPaymentId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOrdersQueryVariables,
  APITypes.SyncOrdersQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    name
    tenant
    imageUrl
    gender
    status {
      userStatus
      updatedAt
      __typename
    }
    activeStatus
    ageRange
    age
    height
    weightStart
    weightGoal
    motivation
    goal
    goalType
    achievement
    workoutCountPerWeek
    weightChangePerWeek
    workoutLocation
    workoutExperience
    workoutInfo
    mealCountPerDay
    phone
    location
    country
    timezone
    notes {
      nextToken
      startedAt
      __typename
    }
    Orders {
      nextToken
      startedAt
      __typename
    }
    membership {
      nextToken
      startedAt
      __typename
    }
    reminders {
      nextToken
      startedAt
      __typename
    }
    lastLogin
    emailValidated
    dailyProgress {
      date
      weight
      imageUrls
      __typename
    }
    imageProgress {
      date
      imageUrls
      __typename
    }
    injuries
    dietPreference
    allergies
    excludeFood
    dailyKcal
    proteinInPercentage
    carbsInPercentage
    fatInPercentage
    recentActivity {
      id
      mealsCalendar
      tenant
      workoutCalendar
      mealPlannerID
      workoutPlannerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userRecentActivityId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      name
      tenant
      imageUrl
      gender
      activeStatus
      ageRange
      age
      height
      weightStart
      weightGoal
      motivation
      goal
      goalType
      achievement
      workoutCountPerWeek
      weightChangePerWeek
      workoutLocation
      workoutExperience
      workoutInfo
      mealCountPerDay
      phone
      location
      country
      timezone
      lastLogin
      emailValidated
      injuries
      dietPreference
      allergies
      excludeFood
      dailyKcal
      proteinInPercentage
      carbsInPercentage
      fatInPercentage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRecentActivityId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      email
      name
      tenant
      imageUrl
      gender
      activeStatus
      ageRange
      age
      height
      weightStart
      weightGoal
      motivation
      goal
      goalType
      achievement
      workoutCountPerWeek
      weightChangePerWeek
      workoutLocation
      workoutExperience
      workoutInfo
      mealCountPerDay
      phone
      location
      country
      timezone
      lastLogin
      emailValidated
      injuries
      dietPreference
      allergies
      excludeFood
      dailyKcal
      proteinInPercentage
      carbsInPercentage
      fatInPercentage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRecentActivityId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const getRecentActivity = /* GraphQL */ `query GetRecentActivity($id: ID!) {
  getRecentActivity(id: $id) {
    id
    mealsCalendar
    tenant
    workoutCalendar
    mealPlannerID
    workoutPlannerID
    workoutPlannerData {
      id
      name
      numOfdays
      tenant
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerData {
      id
      planTitle
      mealLayoutID
      tenant
      numOfdays
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRecentActivityQueryVariables,
  APITypes.GetRecentActivityQuery
>;
export const listRecentActivities = /* GraphQL */ `query ListRecentActivities(
  $filter: ModelRecentActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listRecentActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      mealsCalendar
      tenant
      workoutCalendar
      mealPlannerID
      workoutPlannerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRecentActivitiesQueryVariables,
  APITypes.ListRecentActivitiesQuery
>;
export const syncRecentActivities = /* GraphQL */ `query SyncRecentActivities(
  $filter: ModelRecentActivityFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRecentActivities(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      mealsCalendar
      tenant
      workoutCalendar
      mealPlannerID
      workoutPlannerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRecentActivitiesQueryVariables,
  APITypes.SyncRecentActivitiesQuery
>;
export const getTrainer = /* GraphQL */ `query GetTrainer($id: ID!) {
  getTrainer(id: $id) {
    id
    transactionId
    totalAmount
    currency
    customerId
    name
    tenant
    email
    status
    phone
    stripeEndpointSecret
    stripeSecretKey
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTrainerQueryVariables,
  APITypes.GetTrainerQuery
>;
export const listTrainers = /* GraphQL */ `query ListTrainers(
  $filter: ModelTrainerFilterInput
  $limit: Int
  $nextToken: String
) {
  listTrainers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      transactionId
      totalAmount
      currency
      customerId
      name
      tenant
      email
      status
      phone
      stripeEndpointSecret
      stripeSecretKey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTrainersQueryVariables,
  APITypes.ListTrainersQuery
>;
export const syncTrainers = /* GraphQL */ `query SyncTrainers(
  $filter: ModelTrainerFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTrainers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      transactionId
      totalAmount
      currency
      customerId
      name
      tenant
      email
      status
      phone
      stripeEndpointSecret
      stripeSecretKey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTrainersQueryVariables,
  APITypes.SyncTrainersQuery
>;
export const getTrainerTransaction = /* GraphQL */ `query GetTrainerTransaction($id: ID!) {
  getTrainerTransaction(id: $id) {
    id
    transactionId
    totalAmount
    currency
    name
    tenant
    email
    status
    phone
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTrainerTransactionQueryVariables,
  APITypes.GetTrainerTransactionQuery
>;
export const listTrainerTransactions = /* GraphQL */ `query ListTrainerTransactions(
  $filter: ModelTrainerTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listTrainerTransactions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      transactionId
      totalAmount
      currency
      name
      tenant
      email
      status
      phone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTrainerTransactionsQueryVariables,
  APITypes.ListTrainerTransactionsQuery
>;
export const syncTrainerTransactions = /* GraphQL */ `query SyncTrainerTransactions(
  $filter: ModelTrainerTransactionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTrainerTransactions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      transactionId
      totalAmount
      currency
      name
      tenant
      email
      status
      phone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTrainerTransactionsQueryVariables,
  APITypes.SyncTrainerTransactionsQuery
>;
export const getLead = /* GraphQL */ `query GetLead($id: ID!) {
  getLead(id: $id) {
    id
    name
    tenant
    email
    phone
    ageRange
    country
    location
    status {
      leadStatus
      updatedAt
      __typename
    }
    state
    gender
    goal
    achievement
    motivation
    notes
    activeStatus
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetLeadQueryVariables, APITypes.GetLeadQuery>;
export const listLeads = /* GraphQL */ `query ListLeads(
  $filter: ModelLeadFilterInput
  $limit: Int
  $nextToken: String
) {
  listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      tenant
      email
      phone
      ageRange
      country
      location
      state
      gender
      goal
      achievement
      motivation
      notes
      activeStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListLeadsQueryVariables, APITypes.ListLeadsQuery>;
export const syncLeads = /* GraphQL */ `query SyncLeads(
  $filter: ModelLeadFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLeads(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      tenant
      email
      phone
      ageRange
      country
      location
      state
      gender
      goal
      achievement
      motivation
      notes
      activeStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncLeadsQueryVariables, APITypes.SyncLeadsQuery>;
export const getAdministrator = /* GraphQL */ `query GetAdministrator($id: ID!) {
  getAdministrator(id: $id) {
    id
    email
    tenant
    name
    emailValidated
    imageUrl
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdministratorQueryVariables,
  APITypes.GetAdministratorQuery
>;
export const listAdministrators = /* GraphQL */ `query ListAdministrators(
  $filter: ModelAdministratorFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdministrators(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      tenant
      name
      emailValidated
      imageUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdministratorsQueryVariables,
  APITypes.ListAdministratorsQuery
>;
export const syncAdministrators = /* GraphQL */ `query SyncAdministrators(
  $filter: ModelAdministratorFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAdministrators(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      email
      tenant
      name
      emailValidated
      imageUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAdministratorsQueryVariables,
  APITypes.SyncAdministratorsQuery
>;
export const getFood = /* GraphQL */ `query GetFood($id: ID!) {
  getFood(id: $id) {
    id
    name
    description
    tenant
    calories
    carbs
    fats
    protien
    servingSize
    servingDescription
    gramsPerServe
    imageUrl
    foodGroup
    moreFields
    additionalUnits
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFoodQueryVariables, APITypes.GetFoodQuery>;
export const listFoods = /* GraphQL */ `query ListFoods(
  $filter: ModelFoodFilterInput
  $limit: Int
  $nextToken: String
) {
  listFoods(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFoodsQueryVariables, APITypes.ListFoodsQuery>;
export const syncFoods = /* GraphQL */ `query SyncFoods(
  $filter: ModelFoodFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFoods(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncFoodsQueryVariables, APITypes.SyncFoodsQuery>;
export const getRecipe = /* GraphQL */ `query GetRecipe($id: ID!) {
  getRecipe(id: $id) {
    id
    title
    description
    tenant
    servingSize
    servingDescription
    imageUrl
    category
    isMainDish
    isBreakfast
    steps
    ingredients {
      nextToken
      startedAt
      __typename
    }
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRecipeQueryVariables, APITypes.GetRecipeQuery>;
export const listRecipes = /* GraphQL */ `query ListRecipes(
  $filter: ModelRecipeFilterInput
  $limit: Int
  $nextToken: String
) {
  listRecipes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRecipesQueryVariables,
  APITypes.ListRecipesQuery
>;
export const syncRecipes = /* GraphQL */ `query SyncRecipes(
  $filter: ModelRecipeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRecipes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRecipesQueryVariables,
  APITypes.SyncRecipesQuery
>;
export const getIngredient = /* GraphQL */ `query GetIngredient($id: ID!) {
  getIngredient(id: $id) {
    id
    recipeId
    tenant
    servingSize
    servingDescription
    foodId
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIngredientQueryVariables,
  APITypes.GetIngredientQuery
>;
export const listIngredients = /* GraphQL */ `query ListIngredients(
  $filter: ModelIngredientFilterInput
  $limit: Int
  $nextToken: String
) {
  listIngredients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      recipeId
      tenant
      servingSize
      servingDescription
      foodId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIngredientsQueryVariables,
  APITypes.ListIngredientsQuery
>;
export const syncIngredients = /* GraphQL */ `query SyncIngredients(
  $filter: ModelIngredientFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncIngredients(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      recipeId
      tenant
      servingSize
      servingDescription
      foodId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncIngredientsQueryVariables,
  APITypes.SyncIngredientsQuery
>;
export const getFavorite = /* GraphQL */ `query GetFavorite($id: ID!) {
  getFavorite(id: $id) {
    id
    trainerID
    tenant
    recipeId
    foodId
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFavoriteQueryVariables,
  APITypes.GetFavoriteQuery
>;
export const listFavorites = /* GraphQL */ `query ListFavorites(
  $filter: ModelFavoriteFilterInput
  $limit: Int
  $nextToken: String
) {
  listFavorites(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      trainerID
      tenant
      recipeId
      foodId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFavoritesQueryVariables,
  APITypes.ListFavoritesQuery
>;
export const syncFavorites = /* GraphQL */ `query SyncFavorites(
  $filter: ModelFavoriteFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFavorites(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      trainerID
      tenant
      recipeId
      foodId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFavoritesQueryVariables,
  APITypes.SyncFavoritesQuery
>;
export const getMealsCalendarDate = /* GraphQL */ `query GetMealsCalendarDate($id: ID!) {
  getMealsCalendarDate(id: $id) {
    id
    userID
    tenant
    date
    mealLayoutID
    mealLayout {
      id
      layout
      tenant
      layoutName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMealsCalendarDateQueryVariables,
  APITypes.GetMealsCalendarDateQuery
>;
export const listMealsCalendarDates = /* GraphQL */ `query ListMealsCalendarDates(
  $filter: ModelMealsCalendarDateFilterInput
  $limit: Int
  $nextToken: String
) {
  listMealsCalendarDates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      tenant
      date
      mealLayoutID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMealsCalendarDatesQueryVariables,
  APITypes.ListMealsCalendarDatesQuery
>;
export const syncMealsCalendarDates = /* GraphQL */ `query SyncMealsCalendarDates(
  $filter: ModelMealsCalendarDateFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMealsCalendarDates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userID
      tenant
      date
      mealLayoutID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMealsCalendarDatesQueryVariables,
  APITypes.SyncMealsCalendarDatesQuery
>;
export const getUserCalendarMeal = /* GraphQL */ `query GetUserCalendarMeal($idDate: String!) {
  getUserCalendarMeal(idDate: $idDate) {
    id
    idDate
    tenant
    date
    mealLayoutID
    mealLayout {
      id
      layout
      tenant
      layoutName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    UserCalendarMealItems {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserCalendarMealQueryVariables,
  APITypes.GetUserCalendarMealQuery
>;
export const listUserCalendarMeals = /* GraphQL */ `query ListUserCalendarMeals(
  $idDate: String
  $filter: ModelUserCalendarMealFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserCalendarMeals(
    idDate: $idDate
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      idDate
      tenant
      date
      mealLayoutID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserCalendarMealsQueryVariables,
  APITypes.ListUserCalendarMealsQuery
>;
export const syncUserCalendarMeals = /* GraphQL */ `query SyncUserCalendarMeals(
  $filter: ModelUserCalendarMealFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserCalendarMeals(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      idDate
      tenant
      date
      mealLayoutID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserCalendarMealsQueryVariables,
  APITypes.SyncUserCalendarMealsQuery
>;
export const getUserCalendarMealItems = /* GraphQL */ `query GetUserCalendarMealItems($id: ID!) {
  getUserCalendarMealItems(id: $id) {
    id
    calendarMealId
    tenant
    recipeId
    foodId
    userID
    meal
    servingSize
    servingDescription
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserCalendarMealItemsQueryVariables,
  APITypes.GetUserCalendarMealItemsQuery
>;
export const listUserCalendarMealItems = /* GraphQL */ `query ListUserCalendarMealItems(
  $filter: ModelUserCalendarMealItemsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserCalendarMealItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      calendarMealId
      tenant
      recipeId
      foodId
      userID
      meal
      servingSize
      servingDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserCalendarMealItemsQueryVariables,
  APITypes.ListUserCalendarMealItemsQuery
>;
export const syncUserCalendarMealItems = /* GraphQL */ `query SyncUserCalendarMealItems(
  $filter: ModelUserCalendarMealItemsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserCalendarMealItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      calendarMealId
      tenant
      recipeId
      foodId
      userID
      meal
      servingSize
      servingDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserCalendarMealItemsQueryVariables,
  APITypes.SyncUserCalendarMealItemsQuery
>;
export const getCalendar = /* GraphQL */ `query GetCalendar($id: ID!) {
  getCalendar(id: $id) {
    id
    date
    days
    tenant
    userID
    recipeId
    foodId
    meal
    user {
      id
      email
      name
      tenant
      imageUrl
      gender
      activeStatus
      ageRange
      age
      height
      weightStart
      weightGoal
      motivation
      goal
      goalType
      achievement
      workoutCountPerWeek
      weightChangePerWeek
      workoutLocation
      workoutExperience
      workoutInfo
      mealCountPerDay
      phone
      location
      country
      timezone
      lastLogin
      emailValidated
      injuries
      dietPreference
      allergies
      excludeFood
      dailyKcal
      proteinInPercentage
      carbsInPercentage
      fatInPercentage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRecentActivityId
      __typename
    }
    servingSize
    servingDescription
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerId
    mealPlannerDayId
    mealPlannerDay {
      id
      dayNum
      tenant
      dayOff
      mealDayOrder
      mealPlannerId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerDetail
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCalendarQueryVariables,
  APITypes.GetCalendarQuery
>;
export const listCalendars = /* GraphQL */ `query ListCalendars(
  $filter: ModelCalendarFilterInput
  $limit: Int
  $nextToken: String
) {
  listCalendars(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      date
      days
      tenant
      userID
      recipeId
      foodId
      meal
      servingSize
      servingDescription
      mealPlannerId
      mealPlannerDayId
      mealPlannerDetail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCalendarsQueryVariables,
  APITypes.ListCalendarsQuery
>;
export const syncCalendars = /* GraphQL */ `query SyncCalendars(
  $filter: ModelCalendarFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCalendars(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      date
      days
      tenant
      userID
      recipeId
      foodId
      meal
      servingSize
      servingDescription
      mealPlannerId
      mealPlannerDayId
      mealPlannerDetail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCalendarsQueryVariables,
  APITypes.SyncCalendarsQuery
>;
export const getWorkoutsCalendarDate = /* GraphQL */ `query GetWorkoutsCalendarDate($id: ID!) {
  getWorkoutsCalendarDate(id: $id) {
    id
    userID
    tenant
    date
    dayNotes
    sessionOrder
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWorkoutsCalendarDateQueryVariables,
  APITypes.GetWorkoutsCalendarDateQuery
>;
export const listWorkoutsCalendarDates = /* GraphQL */ `query ListWorkoutsCalendarDates(
  $filter: ModelWorkoutsCalendarDateFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkoutsCalendarDates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      tenant
      date
      dayNotes
      sessionOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWorkoutsCalendarDatesQueryVariables,
  APITypes.ListWorkoutsCalendarDatesQuery
>;
export const syncWorkoutsCalendarDates = /* GraphQL */ `query SyncWorkoutsCalendarDates(
  $filter: ModelWorkoutsCalendarDateFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncWorkoutsCalendarDates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userID
      tenant
      date
      dayNotes
      sessionOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncWorkoutsCalendarDatesQueryVariables,
  APITypes.SyncWorkoutsCalendarDatesQuery
>;
export const getUserCalendarWorkout = /* GraphQL */ `query GetUserCalendarWorkout($idDate: String!) {
  getUserCalendarWorkout(idDate: $idDate) {
    id
    idDate
    userID
    tenant
    dayNotes
    sessionOrder
    UserCalendarWorkoutItems {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserCalendarWorkoutQueryVariables,
  APITypes.GetUserCalendarWorkoutQuery
>;
export const listUserCalendarWorkouts = /* GraphQL */ `query ListUserCalendarWorkouts(
  $idDate: String
  $filter: ModelUserCalendarWorkoutFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserCalendarWorkouts(
    idDate: $idDate
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      idDate
      userID
      tenant
      dayNotes
      sessionOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserCalendarWorkoutsQueryVariables,
  APITypes.ListUserCalendarWorkoutsQuery
>;
export const syncUserCalendarWorkouts = /* GraphQL */ `query SyncUserCalendarWorkouts(
  $filter: ModelUserCalendarWorkoutFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserCalendarWorkouts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      idDate
      userID
      tenant
      dayNotes
      sessionOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserCalendarWorkoutsQueryVariables,
  APITypes.SyncUserCalendarWorkoutsQuery
>;
export const getUserCalendarWorkoutItems = /* GraphQL */ `query GetUserCalendarWorkoutItems($id: ID!) {
  getUserCalendarWorkoutItems(id: $id) {
    id
    calendarWorkoutId
    userID
    tenant
    workoutPlanDetailId
    exerciseId
    exercise {
      id
      name
      additionalNotes
      weight
      tenant
      bodyPartId
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    session
    notes
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserCalendarWorkoutItemsQueryVariables,
  APITypes.GetUserCalendarWorkoutItemsQuery
>;
export const listUserCalendarWorkoutItems = /* GraphQL */ `query ListUserCalendarWorkoutItems(
  $filter: ModelUserCalendarWorkoutItemsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserCalendarWorkoutItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      calendarWorkoutId
      userID
      tenant
      workoutPlanDetailId
      exerciseId
      session
      notes
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserCalendarWorkoutItemsQueryVariables,
  APITypes.ListUserCalendarWorkoutItemsQuery
>;
export const syncUserCalendarWorkoutItems = /* GraphQL */ `query SyncUserCalendarWorkoutItems(
  $filter: ModelUserCalendarWorkoutItemsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserCalendarWorkoutItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      calendarWorkoutId
      userID
      tenant
      workoutPlanDetailId
      exerciseId
      session
      notes
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserCalendarWorkoutItemsQueryVariables,
  APITypes.SyncUserCalendarWorkoutItemsQuery
>;
export const getWorkoutCalendar = /* GraphQL */ `query GetWorkoutCalendar($id: ID!) {
  getWorkoutCalendar(id: $id) {
    id
    date
    userID
    workoutPlanId
    tenant
    workoutPlanDayId
    workoutPlanDay {
      id
      dayNum
      workoutPlanId
      dayOff
      tenant
      dayNotes
      sessionNotes
      sessionOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    workoutPlanDetailId
    exerciseId
    exercise {
      id
      name
      additionalNotes
      weight
      tenant
      bodyPartId
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    session
    notes
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWorkoutCalendarQueryVariables,
  APITypes.GetWorkoutCalendarQuery
>;
export const listWorkoutCalendars = /* GraphQL */ `query ListWorkoutCalendars(
  $filter: ModelWorkoutCalendarFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkoutCalendars(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      date
      userID
      workoutPlanId
      tenant
      workoutPlanDayId
      workoutPlanDetailId
      exerciseId
      session
      notes
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWorkoutCalendarsQueryVariables,
  APITypes.ListWorkoutCalendarsQuery
>;
export const syncWorkoutCalendars = /* GraphQL */ `query SyncWorkoutCalendars(
  $filter: ModelWorkoutCalendarFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncWorkoutCalendars(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      date
      userID
      workoutPlanId
      tenant
      workoutPlanDayId
      workoutPlanDetailId
      exerciseId
      session
      notes
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncWorkoutCalendarsQueryVariables,
  APITypes.SyncWorkoutCalendarsQuery
>;
export const getMealLayoutManager = /* GraphQL */ `query GetMealLayoutManager($id: ID!) {
  getMealLayoutManager(id: $id) {
    id
    layout
    tenant
    layoutName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMealLayoutManagerQueryVariables,
  APITypes.GetMealLayoutManagerQuery
>;
export const listMealLayoutManagers = /* GraphQL */ `query ListMealLayoutManagers(
  $filter: ModelMealLayoutManagerFilterInput
  $limit: Int
  $nextToken: String
) {
  listMealLayoutManagers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      layout
      tenant
      layoutName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMealLayoutManagersQueryVariables,
  APITypes.ListMealLayoutManagersQuery
>;
export const syncMealLayoutManagers = /* GraphQL */ `query SyncMealLayoutManagers(
  $filter: ModelMealLayoutManagerFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMealLayoutManagers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      layout
      tenant
      layoutName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMealLayoutManagersQueryVariables,
  APITypes.SyncMealLayoutManagersQuery
>;
export const getMealPlanner = /* GraphQL */ `query GetMealPlanner($id: ID!) {
  getMealPlanner(id: $id) {
    id
    planTitle
    mealLayoutID
    tenant
    mealLayout {
      id
      layout
      tenant
      layoutName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    numOfdays
    mealPlannerDay {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMealPlannerQueryVariables,
  APITypes.GetMealPlannerQuery
>;
export const listMealPlanners = /* GraphQL */ `query ListMealPlanners(
  $filter: ModelMealPlannerFilterInput
  $limit: Int
  $nextToken: String
) {
  listMealPlanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      planTitle
      mealLayoutID
      tenant
      numOfdays
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMealPlannersQueryVariables,
  APITypes.ListMealPlannersQuery
>;
export const syncMealPlanners = /* GraphQL */ `query SyncMealPlanners(
  $filter: ModelMealPlannerFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMealPlanners(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      planTitle
      mealLayoutID
      tenant
      numOfdays
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMealPlannersQueryVariables,
  APITypes.SyncMealPlannersQuery
>;
export const getMealPlannerDay = /* GraphQL */ `query GetMealPlannerDay($id: ID!) {
  getMealPlannerDay(id: $id) {
    id
    dayNum
    tenant
    dayOff
    mealDayOrder
    mealPlannerId
    mealPlannerItemsMap {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMealPlannerDayQueryVariables,
  APITypes.GetMealPlannerDayQuery
>;
export const listMealPlannerDays = /* GraphQL */ `query ListMealPlannerDays(
  $filter: ModelMealPlannerDayFilterInput
  $limit: Int
  $nextToken: String
) {
  listMealPlannerDays(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      dayNum
      tenant
      dayOff
      mealDayOrder
      mealPlannerId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMealPlannerDaysQueryVariables,
  APITypes.ListMealPlannerDaysQuery
>;
export const syncMealPlannerDays = /* GraphQL */ `query SyncMealPlannerDays(
  $filter: ModelMealPlannerDayFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMealPlannerDays(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      dayNum
      tenant
      dayOff
      mealDayOrder
      mealPlannerId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMealPlannerDaysQueryVariables,
  APITypes.SyncMealPlannerDaysQuery
>;
export const getMealPlannerItemsMap = /* GraphQL */ `query GetMealPlannerItemsMap($id: ID!) {
  getMealPlannerItemsMap(id: $id) {
    id
    mealPlannerDayId
    mealPlannerId
    day
    meal
    tenant
    recipeId
    foodId
    servingSize
    servingDescription
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMealPlannerItemsMapQueryVariables,
  APITypes.GetMealPlannerItemsMapQuery
>;
export const listMealPlannerItemsMaps = /* GraphQL */ `query ListMealPlannerItemsMaps(
  $filter: ModelMealPlannerItemsMapFilterInput
  $limit: Int
  $nextToken: String
) {
  listMealPlannerItemsMaps(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      mealPlannerDayId
      mealPlannerId
      day
      meal
      tenant
      recipeId
      foodId
      servingSize
      servingDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMealPlannerItemsMapsQueryVariables,
  APITypes.ListMealPlannerItemsMapsQuery
>;
export const syncMealPlannerItemsMaps = /* GraphQL */ `query SyncMealPlannerItemsMaps(
  $filter: ModelMealPlannerItemsMapFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMealPlannerItemsMaps(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      mealPlannerDayId
      mealPlannerId
      day
      meal
      tenant
      recipeId
      foodId
      servingSize
      servingDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMealPlannerItemsMapsQueryVariables,
  APITypes.SyncMealPlannerItemsMapsQuery
>;
export const getMealType = /* GraphQL */ `query GetMealType($id: ID!) {
  getMealType(id: $id) {
    name
    description
    tenant
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMealTypeQueryVariables,
  APITypes.GetMealTypeQuery
>;
export const listMealTypes = /* GraphQL */ `query ListMealTypes(
  $filter: ModelMealTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listMealTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      name
      description
      tenant
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMealTypesQueryVariables,
  APITypes.ListMealTypesQuery
>;
export const syncMealTypes = /* GraphQL */ `query SyncMealTypes(
  $filter: ModelMealTypeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMealTypes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      name
      description
      tenant
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMealTypesQueryVariables,
  APITypes.SyncMealTypesQuery
>;
export const getMailStore = /* GraphQL */ `query GetMailStore($id: ID!) {
  getMailStore(id: $id) {
    id
    from
    to
    tenant
    userID
    administratorID
    subject
    message
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMailStoreQueryVariables,
  APITypes.GetMailStoreQuery
>;
export const listMailStores = /* GraphQL */ `query ListMailStores(
  $filter: ModelMailStoreFilterInput
  $limit: Int
  $nextToken: String
) {
  listMailStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      from
      to
      tenant
      userID
      administratorID
      subject
      message
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMailStoresQueryVariables,
  APITypes.ListMailStoresQuery
>;
export const syncMailStores = /* GraphQL */ `query SyncMailStores(
  $filter: ModelMailStoreFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMailStores(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      from
      to
      tenant
      userID
      administratorID
      subject
      message
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMailStoresQueryVariables,
  APITypes.SyncMailStoresQuery
>;
export const getExercise = /* GraphQL */ `query GetExercise($id: ID!) {
  getExercise(id: $id) {
    id
    name
    additionalNotes
    weight
    tenant
    bodyPartId
    bodyPart {
      id
      name
      tenant
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    videoUrl
    imageUrl
    selectedUnit
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExerciseQueryVariables,
  APITypes.GetExerciseQuery
>;
export const listExercises = /* GraphQL */ `query ListExercises(
  $filter: ModelExerciseFilterInput
  $limit: Int
  $nextToken: String
) {
  listExercises(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      additionalNotes
      weight
      tenant
      bodyPartId
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListExercisesQueryVariables,
  APITypes.ListExercisesQuery
>;
export const syncExercises = /* GraphQL */ `query SyncExercises(
  $filter: ModelExerciseFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncExercises(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      additionalNotes
      weight
      tenant
      bodyPartId
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncExercisesQueryVariables,
  APITypes.SyncExercisesQuery
>;
export const getBodyPart = /* GraphQL */ `query GetBodyPart($id: ID!) {
  getBodyPart(id: $id) {
    id
    name
    tenant
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBodyPartQueryVariables,
  APITypes.GetBodyPartQuery
>;
export const listBodyParts = /* GraphQL */ `query ListBodyParts(
  $filter: ModelBodyPartFilterInput
  $limit: Int
  $nextToken: String
) {
  listBodyParts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      tenant
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBodyPartsQueryVariables,
  APITypes.ListBodyPartsQuery
>;
export const syncBodyParts = /* GraphQL */ `query SyncBodyParts(
  $filter: ModelBodyPartFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBodyParts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      tenant
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBodyPartsQueryVariables,
  APITypes.SyncBodyPartsQuery
>;
export const getWorkoutPlan = /* GraphQL */ `query GetWorkoutPlan($id: ID!) {
  getWorkoutPlan(id: $id) {
    id
    name
    numOfdays
    tenant
    workoutPlanDay {
      nextToken
      startedAt
      __typename
    }
    notes
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWorkoutPlanQueryVariables,
  APITypes.GetWorkoutPlanQuery
>;
export const listWorkoutPlans = /* GraphQL */ `query ListWorkoutPlans(
  $filter: ModelWorkoutPlanFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkoutPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      numOfdays
      tenant
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWorkoutPlansQueryVariables,
  APITypes.ListWorkoutPlansQuery
>;
export const syncWorkoutPlans = /* GraphQL */ `query SyncWorkoutPlans(
  $filter: ModelWorkoutPlanFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncWorkoutPlans(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      numOfdays
      tenant
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncWorkoutPlansQueryVariables,
  APITypes.SyncWorkoutPlansQuery
>;
export const getWorkoutPlanDay = /* GraphQL */ `query GetWorkoutPlanDay($id: ID!) {
  getWorkoutPlanDay(id: $id) {
    id
    dayNum
    workoutPlanId
    dayOff
    tenant
    dayNotes
    sessionNotes
    sessionOrder
    workoutPlannerItemsMap {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWorkoutPlanDayQueryVariables,
  APITypes.GetWorkoutPlanDayQuery
>;
export const listWorkoutPlanDays = /* GraphQL */ `query ListWorkoutPlanDays(
  $filter: ModelWorkoutPlanDayFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkoutPlanDays(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      dayNum
      workoutPlanId
      dayOff
      tenant
      dayNotes
      sessionNotes
      sessionOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWorkoutPlanDaysQueryVariables,
  APITypes.ListWorkoutPlanDaysQuery
>;
export const syncWorkoutPlanDays = /* GraphQL */ `query SyncWorkoutPlanDays(
  $filter: ModelWorkoutPlanDayFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncWorkoutPlanDays(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      dayNum
      workoutPlanId
      dayOff
      tenant
      dayNotes
      sessionNotes
      sessionOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncWorkoutPlanDaysQueryVariables,
  APITypes.SyncWorkoutPlanDaysQuery
>;
export const getWorkoutPlannerItemsMap = /* GraphQL */ `query GetWorkoutPlannerItemsMap($id: ID!) {
  getWorkoutPlannerItemsMap(id: $id) {
    id
    workoutPlanId
    workoutPlanDayId
    exerciseId
    tenant
    exercise {
      id
      name
      additionalNotes
      weight
      tenant
      bodyPartId
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    session
    notes
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWorkoutPlannerItemsMapQueryVariables,
  APITypes.GetWorkoutPlannerItemsMapQuery
>;
export const listWorkoutPlannerItemsMaps = /* GraphQL */ `query ListWorkoutPlannerItemsMaps(
  $filter: ModelWorkoutPlannerItemsMapFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkoutPlannerItemsMaps(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      workoutPlanId
      workoutPlanDayId
      exerciseId
      tenant
      session
      notes
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWorkoutPlannerItemsMapsQueryVariables,
  APITypes.ListWorkoutPlannerItemsMapsQuery
>;
export const syncWorkoutPlannerItemsMaps = /* GraphQL */ `query SyncWorkoutPlannerItemsMaps(
  $filter: ModelWorkoutPlannerItemsMapFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncWorkoutPlannerItemsMaps(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      workoutPlanId
      workoutPlanDayId
      exerciseId
      tenant
      session
      notes
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncWorkoutPlannerItemsMapsQueryVariables,
  APITypes.SyncWorkoutPlannerItemsMapsQuery
>;
