import { useEffect, useState } from "react";
import "./EditModalLoadTemplate.scss";
import xcircle from "../../../images/x-circle.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputContainer from "../../../component/InputContainer/InputContainer";
import { listMealPlanners } from "../../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { LoadTemplateToCalendar } from "../../../graphql/mutations";
import { getDietMealPlannerDay } from "../../../graphql/customQueries";
import { trackPromise } from "react-promise-tracker";
import AutocompleteComp from "../Autocomplete";
const moment = require("moment");
const EditModal = ({ handleClose, show, userId, currentDate }) => {
  const [loadNames, setLoadNames] = useState<any>([]);
  const [pickedDate, setPickedDate] = useState(new Date());
  const [layoutData, setLayoutData] = useState<any>([]);
  const [days, setDays] = useState<any>();
  const [layoutId, setLayoutId] = useState<any>();

  useEffect(() => {
    async function getListMealPlannerData() {
      const data: any = await API.graphql(
        graphqlOperation(listMealPlanners, { limit: 1000 })
      );
      setLayoutData(data?.data?.listMealPlanners?.items);

      setLoadNames(
        data?.data?.listMealPlanners?.items
          .filter((meal) => !meal._deleted)
          .map((item) => {
            return item.planTitle;
          })
      );
      setPickedDate(currentDate);
    }
    getListMealPlannerData();
  }, [currentDate]);

  const showHideClassName = show
    ? "Editmodal display-block"
    : "Editmodal display-none";

  const Submit = (e) => {
    e.preventDefault();
    trackPromise(addMealForm());
  };

  const addMealForm = async () => {
    const date = moment(pickedDate, "DD-MM-YYYY").format("YYYY-MM-DD");

    try {
      await API.graphql(
        graphqlOperation(LoadTemplateToCalendar, {
          mealPlannerId: layoutId.mealLayoutID,
          date: date,
          userID: userId,
          days: days,
          mealLayoutID: layoutId.layoutId,
        })
      );
    } catch (error) {
      // for now function is getting timed out
      console.error("Mutation failed:", error);
      handleClose();
    }
    setPickedDate(new Date());
    setDays(0);
    handleClose();
  };
  const onInputChangeLayoutName = (name) => {
    let data =
      layoutData && layoutData.length > 0
        ? layoutData.map((item) => {
            if (item.planTitle === name) {
              setDays(item.numOfdays);
              setLayoutId({
                mealLayoutID: item.id,
                layoutId: item.mealLayoutID,
              });
            }
          })
        : null;
  };
  return (
    <>
      <div className={showHideClassName}>
        <section className="Editmodal-meal">
          <div className="loadheading">
            Load Meal Template
            <img
              className="heading__image"
              onClick={handleClose}
              src={xcircle}
              alt=""
            />
          </div>

          <form onSubmit={Submit}>
            <div className="MealPlan">
              <div>
                <span className="inputMealPlan">Meal Plan: </span>
              </div>
              <div style={{ paddingLeft: 15, width: "70%" }}>
                <AutocompleteComp
                  handleChange={onInputChangeLayoutName}
                  loadNames={loadNames.sort()}
                />
              </div>
            </div>
            <div style={{ marginLeft: "38px" }} className="MealPlan">
              <div style={{ paddingTop: 15 }}>
                <span className="inputMealPlan">Date: </span>
              </div>
              <div style={{ width: "77%", paddingLeft: "5px" }}>
                <InputContainer>
                  <DatePicker
                    style={{ paddingLeft: 20 }}
                    color={"white"}
                    selected={pickedDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => {
                      setPickedDate(date);
                    }}
                  />
                </InputContainer>
              </div>
            </div>
            <div className="durationtext">
              Meal Plan applies for {days} days
            </div>

            <div style={{ paddingTop: "45px" }} className="save">
              <button type="submit" className="Load">
                Load
              </button>
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default EditModal;
