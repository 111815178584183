import CalenderStrip from "../CalendarStripe/CalenderStrip";
import Tabs from "../../../component/Tabs/Tabs";
import TabContent from "../../../component/Tabs/TabContent";
import { useState } from "react";
import WorkoutInfo from "../../Clients/WorkoutInfo";
import { API, graphqlOperation } from "aws-amplify";
import "./WorkoutSession.scss";
import moment from "moment";
import debounce from "lodash.debounce";
import { v4 as uuidv4 } from "uuid";
import { getUserCalendarWorkoutCustom } from "../../../graphql/customQueries";
import { trackPromise } from "react-promise-tracker";
import {
  createUserCalendarWorkoutItems,
  deleteUserCalendarWorkoutItems,
  updateUserCalendarWorkoutItems,
  updateUserCalendarWorkout,
} from "../../../graphql/mutations";
import Notes from "../../../component/Notes/Notes";
import { useEffect } from "react";
import { Storage } from "aws-amplify";
import { useAppSelector } from "../../../hooks";
import SearchWorkoutExercise from "../../Library/Workout/WorkoutPlanDetail/SearchWorkoutExercise";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import WorkoutList from "./WorkoutList";

const WorkoutSession = (props) => {
  const [dateFromCalenderStrip, setDateFromCalenderStrip] = useState(
    moment(new Date(), "DD-MM-YYYY").format("YYYY-MM-DD")
  );
  const [openSearchWorkout, setOpenSearchWorkout] = useState(false);
  const [workoutLayout, setWorkoutLayout] = useState();
  const [layout, setLayout] = useState([]);
  const [workouts, setWorkouts] = useState([]);
  const [favouriteWorkouts, setFavouriteWorkouts] = useState([]);
  const [workoutOrder, setWorkoutOrder] = useState({});
  const [imagePath, setImagePath] = useState();
  const [load, setLoad] = useState(false);
  const [workoutPlanDates, seWorkoutPlanDates] = useState();
  const [workoutsOrder, setWorkoutsOrder] = useState({});
  const listTenant = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;
  async function getAllWorkouts() {
    const idDate = props.id + dateFromCalenderStrip;
    const getUserCalendarWorkoutResponse = await API.graphql(
      graphqlOperation(getUserCalendarWorkoutCustom, {
        idDate: idDate,
      })
    );

    console.log(
      "getUserCalendarWorkoutResponse",
      getUserCalendarWorkoutResponse
    );
    seWorkoutPlanDates(
      getUserCalendarWorkoutResponse?.data?.getUserCalendarWorkout
    );

    let dailyWorkouts =
      getUserCalendarWorkoutResponse?.data?.getUserCalendarWorkout
        ?.UserCalendarWorkoutItems?.items || [];
    console.log("dailyWorkouts", dailyWorkouts);
    if (
      dailyWorkouts.length > 0 &&
      dailyWorkouts.filter((item) => !item._deleted).length > 0
    ) {
      const keysInDay = Object.keys(
        JSON.parse(
          getUserCalendarWorkoutResponse?.data?.getUserCalendarWorkout
            ?.sessionOrder
        )
      );

      setLayout(keysInDay);
    } else {
      setLayout([]);
    }

    setWorkouts(dailyWorkouts);
    setFavouriteWorkouts(dailyWorkouts);
  }

  useEffect(() => {
    if (
      workouts?.length > 0 &&
      workouts?.filter((item) => !item._deleted).length > 0
    ) {
      const sessionOrder = JSON.parse(workoutPlanDates.sessionOrder);
      setWorkoutsOrder(sessionOrder);
      const itemsBySession = {};

      for (const item of workouts) {
        const session = item.session;

        if (!itemsBySession[session]) {
          itemsBySession[session] = [];
        }

        itemsBySession[session].push(item);
      }

      const filteredData = {};

      for (const session in itemsBySession) {
        if (itemsBySession.hasOwnProperty(session)) {
          const data = sessionOrder[session];
          const filteredSessionData = data?.map((itemId) =>
            itemsBySession[session].find(
              (item) => item.workoutPlanDetailId === itemId
            )
          );
          filteredData[session] = filteredSessionData;
        }
      }

      setWorkoutOrder(filteredData);
    } else {
      setWorkoutOrder({});
    }
  }, [favouriteWorkouts]);

  useEffect(() => {
    if (props.id) getAllWorkouts();
  }, [props.id, dateFromCalenderStrip, load]);

  useEffect(() => {
    (async () => {
      const url = await Storage.get("");

      return setImagePath(url.split("?")[0]);
    })();
  }, []);

  const onDelete = async (id, version) => {
    await API.graphql(
      graphqlOperation(deleteUserCalendarWorkoutItems, {
        input: {
          id: id,
          _version: version,
        },
      })
    );
    trackPromise(getAllWorkouts());
  };
  const onChangeWorkoutPlannerItemsMap = async (data) => {
    await API.graphql(
      graphqlOperation(updateUserCalendarWorkoutItems, {
        input: {
          ...data,
        },
      })
    );
    trackPromise(getAllWorkouts());
  };
  const workoutUnitChange = debounce((info) => {
    trackPromise(onChangeWorkoutPlannerItemsMap(info));
  }, 2000);
  const openWorkoutModal = (date, session) => {
    setWorkoutLayout({ date, session });
    setOpenSearchWorkout(true);
  };
  const handleSelectedItem = async (item) => {
    const newuuID = uuidv4();
    const res = await API.graphql(
      graphqlOperation(createUserCalendarWorkoutItems, {
        input: {
          exerciseId: item.id,
          calendarWorkoutId: workoutPlanDates.id,
          session: workoutLayout.session,
          defaultValue: item.defaultValue,
          userID: props.id,
          tenant: tenant,
          workoutPlanDetailId: newuuID,
          notes: item?.additionalNotes,
        },
      })
    );
    console.log("res", res);
    // Assuming workoutPlanDates is an object with a property 'sessionOrder'
    let sessionOrder = JSON.parse(workoutPlanDates.sessionOrder);

    // Assuming workoutLayout is an object with a property 'session'
    sessionOrder[workoutLayout?.session] = [
      newuuID,
      ...sessionOrder[workoutLayout?.session],
    ];

    await API.graphql(
      graphqlOperation(updateUserCalendarWorkout, {
        input: {
          idDate: workoutPlanDates.idDate,
          _version: workoutPlanDates._version,
          sessionOrder: JSON.stringify(sessionOrder),
        },
      })
    );
    setOpenSearchWorkout(false);
    trackPromise(getAllWorkouts());
  };

  return (
    <>
      <div className="workoutPlanner">
        <div className="workoutPlannerContainer">
          <div className="workoutCalendar">
            {
              <CalenderStrip
                CalenderStripDate={setDateFromCalenderStrip}
                userId={props.id}
                load={load}
                setLoad={setLoad}
              />
            }
          </div>
          {openSearchWorkout ? (
            <SearchWorkoutExercise
              openWorkout={openSearchWorkout}
              setOpenWorkoutExercise={setOpenSearchWorkout}
              onSelectExercise={handleSelectedItem}
              type="workoutCalendar"
            />
          ) : null}
          <div className="workoutSchedule">
            <div className="workoutTab">
              <div className="workoutGradient">
                {workoutPlanDates && (
                  <div style={{ position: "relative", left: "180px" }}>
                    <Notes
                      readOnly={false}
                      text={workoutPlanDates?.dayNotes}
                      onLeave={async (e) => {
                        try {
                          await API.graphql(
                            graphqlOperation(updateUserCalendarWorkout, {
                              input: {
                                idDate: workoutPlanDates.idDate,
                                dayNotes: e.target.innerText,
                                _version: workoutPlanDates._version,
                              },
                            })
                          );

                          setLoad(!load);
                        } catch (error) {
                          console.error(
                            "Error updating user calendar workout:",
                            error
                          );
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              <Tabs>
                {/* @ts-ignore */}
                {layout
                  ? layout?.map((mealByType, index) => {
                      return (
                        <TabContent label={mealByType} key={index}>
                          <div style={{ display: "flex" }}>
                            <WorkoutInfo
                              setOpenNewWorkouts={() => {
                                openWorkoutModal(
                                  dateFromCalenderStrip,
                                  mealByType
                                );
                              }}
                              data={mealByType}
                              label={mealByType}
                            />
                          </div>

                          <div className="all_meals_array">
                            <DndProvider backend={HTML5Backend}>
                              <WorkoutList
                                workoutsInASessions={
                                  workoutOrder ? workoutOrder[mealByType] : []
                                }
                                imagePath={imagePath}
                                onDelete={onDelete}
                                workoutUnitChange={workoutUnitChange}
                                session={mealByType}
                                sessionOrder={workoutsOrder}
                                workoutPlanDates={workoutPlanDates}
                                getAllWorkouts={getAllWorkouts}
                              />
                            </DndProvider>
                          </div>
                        </TabContent>
                      );
                    })
                  : null}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkoutSession;
