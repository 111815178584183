import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { listLeads } from "../../../graphql/queries";
import { createLead } from "../../../graphql/mutations";
import { RootState } from "./../../../store";

// Define a type for the slice state
interface LeadsState {
  status: string | null;
  leads: any; // TODO: Replace any
  nextToken: string | null;
  prevToken: any[];
  currentToken: string | null;
}

interface Params {
  limit?: Number;
  nextToken?: string | null;
  filter?: {
    or: { [key: string]: { [key: string]: any } }[];
  } | null;
}

// Define the initial state using that type
const initialState: LeadsState = {
  status: null,
  leads: [],
  nextToken: null,
  prevToken: [],
  currentToken: null,
};

export const fetchLeads = createAsyncThunk(
  "leads/listLeads",
  async (params: Params) => {
    const response = await API.graphql(graphqlOperation(listLeads, params));
    return response;
  }
);

export const createLeadAction = createAsyncThunk(
  "leads/create",
  async (input: any) => {
    const response = await API.graphql({
      ...graphqlOperation(createLead, { input }),
      authMode: "API_KEY",
    });

    return response;
  }
);

export const leadsSlice = createSlice({
  name: "leads",
  // `userSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    popPrevToken(state) {
      if (state.currentToken && state.prevToken.length > 0) {
        state.prevToken.splice(-1);
      }
    },
    setCurrentToken(state, action) {
      state.currentToken = action.payload;
    },
    pushPrevToken(state) {
      state.currentToken &&
        state.prevToken &&
        state.prevToken.push &&
        state.prevToken.push(state.currentToken);

      if (state.nextToken) {
        state.currentToken = state.nextToken;
      }
    },

    newData: (state, action) => {
      state.leads = action.payload.items;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLeads.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchLeads.fulfilled, (state, action: any) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        const leadsPayload = action.payload;

        state.leads = leadsPayload?.data?.listLeads.items;
        if (leadsPayload?.data?.listLeads.nextToken) {
          state.nextToken = leadsPayload?.data?.listLeads.nextToken;
        } else {
          state.nextToken = null;
        }
      })
      .addCase(fetchLeads.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// export const { } = userSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const leads = (state: RootState) => state.leads;
export const { popPrevToken, pushPrevToken, setCurrentToken, newData } =
  leadsSlice.actions;

export default leadsSlice.reducer;
