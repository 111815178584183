import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDietMealPlanner } from "../../../graphql/customQueries";
import { API, graphqlOperation, Storage } from "aws-amplify";
import debounce from "lodash.debounce";
import { ReactComponent as PlusCircle } from "../../../images/PlusCircle.svg";
import { ReactComponent as Plus } from "../../../images/DietPlus.svg";
import QuickInfo from "../../../component/QuickInfo/QuickInfo";
import { trackPromise } from "react-promise-tracker";
import { sendNotification } from "../../../app/features/notifications";
import { ReactComponent as Check } from "../../../images/check.svg";
import { useDispatch } from "react-redux";
import FtButton from "../../Ft-Button/Ft-Button";
import "./DietPlanDetail.scss";
import InputComponent from "../../../component/InputComponent/InputComponent";
import InputContainer from "../../../component/InputContainer/InputContainer";
import {
  updateMealPlanner,
  updateMealPlannerItemsMap,
  deleteMealPlannerItemsMap,
  updateMealPlannerDay,
  createMealPlannerItemsMap,
  createMealPlannerDay,
  deleteMealTypeFoods,
  deleteDayFoods,
} from "../../../graphql/mutations";
import { IconButton, Tooltip } from "@material-ui/core";
import { CopyAll } from "@mui/icons-material";
import SearchFoodRecipe from "../../ClientDetail/SearchFoodRecipe/SearchFoodRecipe";
import DietInfo from "../../Clients/DietInfo/DietInfo";

import {
  getCalculateFoodServings,
  getCalculateRecipeServings,
  sumOfUnits,
  getGramsPerServings,
  permaterConfiguredServing,
} from "../../../Utils";
import MealItem from "../../Clients/MealItem/MealItem";
import CopyMealsModal from "../../../component/CopyMealsModal/CopyMealsModal";

import DeleteComponent from "../../../component/Delete/Delete";
import { useAppSelector } from "../../../hooks";
const DietPlanDetail = () => {
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [isDeletes, setIsDeletes] = useState<any>(false);
  const [mealInfo, setMealInfo] = useState<any>({ name: "" });
  const listTenant: any = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;
  const onTypeDelete = async (mealItems, data, typeName) => {
    const filteredItems = mealItems.map((item) => item.id);

    await API.graphql(
      graphqlOperation(deleteMealTypeFoods, {
        dietplanDay: JSON.stringify(data),
        deleteItemsId: JSON.stringify(filteredItems),
        typeName: typeName,
      })
    );
    trackPromise(getDietDetail());
  };
  const onDayDelete = async (day, data) => {
    await API.graphql(
      graphqlOperation(deleteDayFoods, {
        day: day,
        dietplanDay: JSON.stringify(data),
      })
    );
    trackPromise(getDietDetail());
  };
  const [data, setData] = useState<any>();
  const navigate = useNavigate();

  const [dietLoad, setDietLoad] = useState<any>();

  const [mealLayout, setMealLayout] = useState<any>([]);
  const [mealPlanId, setMeaPlanId] = useState<any>({
    mealPlanDayId: "",
    mealPlannerId: "",
    typeName: "",
  });
  const [daySummaries, setDaySummaries] = useState({});
  const [dragItem, setDragItem] = useState<any>({
    index: null,
    day: null,
    typeName: "",
  });
  const [dragOverItem, setDragOverItem] = useState<any>({
    index: null,
    day: null,
    typeName: "",
  });

  const [imagePath, setImagePath]: any = useState();
  const [fetchedData, setFetchedData] = useState<any>([]);
  const [openFoodRecipe, setOpenFoodRecipe] = useState<any>({
    open: false,
    typeName: "",
    day: 0,
    mealDayOrder: "",
    _version: 0,
  });
  const dispatch = useDispatch();

  const { id } = useParams();
  const goToDietplan = () => {
    navigate(`/library/dietplan`);
  };
  useEffect(() => {
    (async () => {
      const url = await Storage.get("");

      return setImagePath(url.split("?")[0]);
    })();
  }, []);

  const getDietDetail = useCallback(async () => {
    const { data }: any = await API.graphql(
      graphqlOperation(getDietMealPlanner, {
        id,
        limit: 1000,
      })
    );

    setFetchedData(data?.getMealPlanner);
    setMealInfo({ name: data?.getMealPlanner.planTitle });
    const sortedMealData = data.getMealPlanner.mealPlannerDay.items.sort(
      (a, b) => a.dayNum - b.dayNum
    );

    const mealLayouts = sortedMealData.map((dayData) => ({
      mealByTypes: data?.getMealPlanner?.mealLayout?.layout,
      day: dayData.dayNum,
      data: dayData,
    }));

    const transformedMealLayout: any = mealLayouts.map((dayData) => {
      const { day, data, mealByTypes } = dayData;
      const sessionData = {};

      const Order = JSON.parse(data.mealDayOrder);

      const d = day.toString();

      if (data.mealPlannerItemsMap && data.mealPlannerItemsMap.items) {
        data.mealPlannerItemsMap.items.forEach((item) => {
          if (item?.food && !item?._deleted) {
            const result = getCalculateFoodServings(
              item.food,
              item.servingSize,
              item.servingDescription
            );
            item = { ...item, ...result };
          } else if (item?.recipe && !item?._deleted) {
            const result = getCalculateRecipeServings(item.recipe); // Calcualte sum of ingredients
            const sumOfUnitsObj = sumOfUnits(result); //item = { ...item, ingredients?.items :result };
            item["recipe"]["ingredients"]["items"] = result; // Replace the items array with updated items with calculatedGrams and calories of individual ingredient
            item.recipe = { ...item.recipe, ...sumOfUnitsObj }; // calculate the sum of grams and calories and store in recipe
            const requiredGramsPerServe = getGramsPerServings(
              item?.recipe,
              item?.servingDescription,
              item?.servingSize
            );

            const requiredCalories = permaterConfiguredServing(
              item?.recipe?.calculatedGrams || 0,
              requiredGramsPerServe,
              item?.recipe?.calories
            );
            const requiredProtien = permaterConfiguredServing(
              item?.recipe?.calculatedGrams || 0,
              requiredGramsPerServe,
              item?.recipe?.protien
            );
            const requiredCarbs = permaterConfiguredServing(
              item?.recipe?.calculatedGrams || 0,
              requiredGramsPerServe,
              item?.recipe?.carbs
            );
            const requiredFats = permaterConfiguredServing(
              item?.recipe?.calculatedGrams || 0,
              requiredGramsPerServe,
              item?.recipe?.fats
            );

            const param = {
              calculatedGrams: requiredGramsPerServe,
              calories: requiredCalories,
              protien: requiredProtien,
              carbs: requiredCarbs,
              fats: requiredFats,
            };
            item = { ...item, ...param };
          }

          const { meal, _deleted } = item;
          if (!_deleted) {
            if (!sessionData[meal]) {
              sessionData[meal] = [];
            }

            const existingItem = sessionData[meal].find(
              (existing) => existing.id === item.id
            );
            if (!existingItem) {
              sessionData[meal].push(item);
            }
          }
        });
      }

      const filteredData = {};

      for (const session in sessionData) {
        if (sessionData.hasOwnProperty(session)) {
          if (Order) {
            const sessionOrder = Order[d][session];
            if (sessionOrder) {
              const filteredSessionData = sessionOrder.map((itemId) => {
                const item = sessionData[session].find(
                  (item) => item.id === itemId
                );

                return item;
              });

              filteredData[session] = filteredSessionData;
            }
          }
        }
      }

      return {
        day,
        data: {
          ...data,
          mealPlannerItemsMap: undefined,
        },
        mealData: filteredData,
        mealByTypes: mealByTypes,
      };
    });

    console.log("transformedMealLayout", transformedMealLayout);

    setMealLayout([...transformedMealLayout, { data: "empty" }]);
  }, [id]);
  useEffect(() => {
    trackPromise(getDietDetail());
  }, [dietLoad, id, getDietDetail]);

  const creatMealPlanDayButton = async () => {
    await API.graphql(
      graphqlOperation(updateMealPlanner, {
        input: {
          id: id,
          numOfdays: fetchedData.numOfdays + 1,
          _version: fetchedData._version,
        },
      })
    );
    await API.graphql(
      graphqlOperation(createMealPlannerDay, {
        input: {
          dayNum: fetchedData.numOfdays + 1,
          mealPlannerId: id,
          tenant:
            tenant === "ADMIN" ? ["ADMIN", "Trainers", "Clients"] : tenant,
        },
      })
    );
    setDietLoad(!dietLoad);
  };

  const addDietForm = async (
    id: any,
    servingSize: any,
    servingDescription: any,
    _version
  ) => {
    await API.graphql(
      graphqlOperation(updateMealPlannerItemsMap, {
        input: {
          id: id,
          _version: _version,
          servingSize: servingSize,
          servingDescription: servingDescription,
        },
      })
    );
    dispatch(
      sendNotification({
        title: "Success",
        description: "Servings updated successfully!",
        backgroundColor: "#5cb85c",
        icon: Check,
      })
    );
    setDietLoad(!dietLoad);
  };

  const onDelete = async (day, typeName, idr, _version) => {
    const index = mealLayout.findIndex((item) => item.day === day);

    if (index !== -1) {
      // Create a shallow copy of the workoutlayout array
      const updatedLayoutState = [...mealLayout];

      const newOrder = JSON.parse(updatedLayoutState[index].data.mealDayOrder);
      if (newOrder && newOrder[day][typeName]) {
        // Filter the array to remove the specified ID
        newOrder[day][typeName] = newOrder[day][typeName].filter(
          (id) => id !== idr
        );

        // Check if the array is empty and remove the sessionName key if it is
        if (newOrder[day][typeName].length === 0) {
          delete newOrder[day][typeName];
        }
      }

      await API.graphql(
        graphqlOperation(deleteMealPlannerItemsMap, {
          input: {
            id: idr,
            _version: _version,
          },
        })
      );
      await API.graphql(
        graphqlOperation(updateMealPlannerDay, {
          input: {
            id: updatedLayoutState[index].data.id,
            mealDayOrder: JSON.stringify(newOrder),
            _version: updatedLayoutState[index].data._version,
          },
        })
      );
    }
    dispatch(
      sendNotification({
        title: "Success",
        description: "Deleted successfully!",
        backgroundColor: "#5cb85c",
        icon: Check,
      })
    );
    trackPromise(getDietDetail());
  };

  const sumOfCaloriesForTheDay = useCallback(
    (day) => {
      // Initialize the result object
      const result = {
        calculatedGrams: 0,
        calories: 0,
        protein: 0,
        carbs: 0,
        fats: 0,
      };

      // Loop through the mealLayout data

      mealLayout.forEach((mealData) => {
        if (mealData.day === day && mealData.mealData) {
          Object.values(mealData?.mealData).forEach((meal: any) => {
            meal?.forEach((item, index) => {
              if (item && !item._deleted) {
                result.calculatedGrams += item.calculatedGrams || 0;
                result.calories += item.calories || 0;
                result.protein += item.protien || 0; // Note the typo in your data (it should be protein, not protien)
                result.carbs += item.carbs || 0;
                result.fats += item.fats || 0;
              }
            });
          });
        }
      });

      // Round the values to the nearest integer
      const roundedResult = {
        calculatedGrams: Math.round(result.calculatedGrams),
        calories: Math.round(result.calories),
        protein: Math.round(result.protein),
        carbs: Math.round(result.carbs),
        fats: Math.round(result.fats),
      };

      // Update the daySummary for the specific day
      setDaySummaries((prevSummaries) => ({
        ...prevSummaries,
        [day]: roundedResult,
      }));
    },
    [mealLayout, setDaySummaries]
  );

  useEffect(() => {
    mealLayout.forEach((data) => {
      sumOfCaloriesForTheDay(data.day);
    });
  }, [mealLayout, sumOfCaloriesForTheDay]);

  const handleServingsChange = (amount, unit, index, _version) => {
    if (amount && amount !== "") addDietForm(index, amount, unit, _version);
  };

  const debouncedChangeHandler = useCallback(
    debounce(handleServingsChange, 500),
    [fetchedData]
  );

  const openModal = (mealData, date) => {
    const allItems: any = [];

    for (const mealKey in mealData) {
      if (
        mealData.hasOwnProperty(mealKey) &&
        Array.isArray(mealData[mealKey])
      ) {
        allItems.push(...mealData[mealKey]);
      }
    }

    if (mealData)
      setData({
        userId: id,
        calendar: allItems,
        selectedDate: date,
      });
    setShowCopyModal(true);
  };
  const onCloseCopyMealDay = () => {
    setShowCopyModal(false);
    // trackPromise(getDietDetail());
  };
  const onCopyRefresh = (refresh) => {
    if (refresh) {
      trackPromise(getDietDetail());
    }
  };

  const dragStart = (index: number, day: number, typeName: any) => {
    setDragItem({
      index: index,
      day: day,
      typeName: typeName,
    });
  };
  const onChangeMealPlanDayData = async (data) => {
    await API.graphql(
      graphqlOperation(updateMealPlannerDay, {
        input: {
          id: data.id,
          mealDayOrder: JSON.stringify(data.mealDayOrder),
          _version: data._version,
        },
      })
    );
    trackPromise(getDietDetail());
  };

  const updateDndData = debounce((info) => {
    trackPromise(onChangeMealPlanDayData(info));
  }, 1000);

  const drop = (e, day, mealData, typeName) => {
    e.preventDefault();
    if (dragItem.index !== dragOverItem.index) {
      const copyListItems = [...mealData[typeName]];
      const dragItemContent = copyListItems[dragItem.index!];
      copyListItems.splice(dragItem.index!, 1);
      copyListItems.splice(dragOverItem.index!, 0, dragItemContent);

      const index = mealLayout.findIndex((item) => item.day === day);
      if (index !== -1) {
        // Create a shallow copy of the workoutlayout array
        const updatedLayoutState = [...mealLayout];
        updatedLayoutState[index].mealData[typeName] = copyListItems;

        // Update the state with the new layout
        setMealLayout(updatedLayoutState);
        const structuredData = {
          [day]: {},
        };

        const data = updatedLayoutState[index].mealData;

        for (const mealType in data) {
          if (data.hasOwnProperty(mealType)) {
            const sessionData = data[mealType];
            structuredData[day][mealType] = sessionData.map((item) => item.id);
          }
        }

        // Update the API data with the new order
        updateDndData({
          id: updatedLayoutState[index].data.id,
          mealDayOrder: structuredData,
          _version: updatedLayoutState[index].data._version,
        });
      }
    }

    setDragItem({
      index: null,
      day: null,
      typeName: "",
    });
    setDragOverItem({
      index: null,
      day: null,
      typeName: "",
    });
  };

  const handleSelectedItem = async (
    item,
    typeName,
    day,
    mealDayOrder,
    version,
    type
  ) => {
    const newOrder = JSON.parse(mealDayOrder);

    const res: any = await API.graphql(
      graphqlOperation(createMealPlannerItemsMap, {
        input: {
          recipeId: type === "recipe" ? item.id : null,
          foodId: type === "food" ? item.id : null,
          meal: typeName,
          day: day,
          mealPlannerDayId: mealPlanId.mealPlanDayId,
          mealPlannerId: id,
          servingDescription: item.servingDescription,
          servingSize: item.servingSize,
          tenant:
            tenant === "ADMIN" ? ["ADMIN", "Trainers", "Clients"] : tenant,
        },
      })
    );
    const item_id = res.data.createMealPlannerItemsMap.id;
    const structuredData = {
      [day]: {},
    };

    if (
      mealDayOrder === null ||
      mealDayOrder === "false" ||
      (typeof mealDayOrder === "object" &&
        Object.keys(mealDayOrder[day]).length > 0)
    ) {
      structuredData[day][typeName] = [item_id];
    } else {
      if (!newOrder[day][typeName]) {
        newOrder[day][typeName] = [item_id];
      } else {
        newOrder[day][typeName].push(item_id);
      }
    }

    const mealOrderData =
      mealDayOrder === null || mealDayOrder === "false"
        ? structuredData
        : newOrder;
    const mealOrderString = JSON.stringify(mealOrderData);

    await API.graphql(
      graphqlOperation(updateMealPlannerDay, {
        input: {
          id: mealPlanId.mealPlanDayId,
          mealDayOrder: mealOrderString,
          _version: version,
        },
      })
    );

    setOpenFoodRecipe({
      open: !openFoodRecipe.open,
      typeName: "",
    });
    trackPromise(getDietDetail());
  };
  const saveMealPlanName = async () => {
    await API.graphql(
      graphqlOperation(updateMealPlanner, {
        input: {
          id: fetchedData.id,
          planTitle: mealInfo.name,
          _version: fetchedData._version,
        },
      })
    );
    trackPromise(getDietDetail());
  };
  const onInputChange = (event) => {
    const { name, value } = event.target;
    setMealInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  return (
    <>
      <button className="backtodietplan" onClick={goToDietplan}>
        <p> &#60; Back to Diet Plan</p>
      </button>
      {openFoodRecipe.open ? (
        <SearchFoodRecipe
          openFood={openFoodRecipe.open}
          setOpenFoodRecipe={setOpenFoodRecipe}
          activeTab={data?.getMealPlanner?.numOfdays}
          onSelectedItem={handleSelectedItem}
          typeName={openFoodRecipe.typeName}
          type={"addDietPlan"}
          day={openFoodRecipe.day}
          mealDayOrder={openFoodRecipe.mealDayOrder}
          _version={openFoodRecipe._version}
        />
      ) : null}
      <div style={{ display: "flex", padding: "35px" }}>
        <div>
          <div>
            <InputContainer
              label="Enter the name of the plan"
              style={{ width: "fit-content", display: "flex", gap: "10px" }}
              labelStyle={{
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "12px",
                letterSpacing: "0.025em",
                textAlign: "left",
                color: "#34465e",
              }}
            >
              <InputComponent
                value={mealInfo.name}
                name="name"
                className="workoutName"
                onChange={onInputChange}
                disabled={
                  fetchedData?.tenant && fetchedData?.tenant?.includes(tenant)
                    ? false
                    : true
                }
              />
            </InputContainer>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          {fetchedData &&
            fetchedData?.tenant?.includes(tenant) &&
            fetchedData?.planTitle && (
              <FtButton
                className="createpackagebutton"
                border="1px solid #109CF1"
                color="#109CF1"
                height="40px"
                onClick={saveMealPlanName}
                radius="5px"
                children="Save"
              />
            )}
        </div>
      </div>
      <section className="DietPlanDetail">
        <div
          className={`DietPlanDetail__container  
        ${dragItem.day ? "drag" : ""}
        
        `}
        >
          <div style={{ zIndex: 1 }}>
            {" "}
            {showCopyModal && data ? (
              <CopyMealsModal
                show={showCopyModal}
                onClose={onCloseCopyMealDay}
                data={data}
                endDate={fetchedData.numOfdays}
                mealLayout={mealLayout}
                title={"Copy Day"}
                type="dietPlanDay"
                onCopyRefresh={onCopyRefresh}
              />
            ) : null}
          </div>
          {mealLayout?.map(({ day, data, mealData, mealByTypes }, index) => {
            if (data !== "empty") {
              return (
                <div
                  key={index}
                  className={`DietPlanDetail__container__card ${
                    dragItem.day === day ? "" : ""
                  } `}
                  style={{ position: "relative" }}
                >
                  <div className="DietPlanDetail__container__card__dietday">
                    <div className="DietPlanDetail__container__card__dietday__day">
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          DAY
                          <div className="DietPlanDetail__container__card__dietday__duration">
                            {day}
                          </div>
                        </div>
                        <div>
                          {daySummaries[day] &&
                            daySummaries[day].calories !== 0 && (
                              <QuickInfo
                                name={""}
                                calories={daySummaries[day].calories}
                                carbs={daySummaries[day].carbs}
                                protein={daySummaries[day].protein}
                                fats={daySummaries[day].fats}
                                description={""}
                              >
                                <label className="dietinfohover">
                                  Total calories {daySummaries[day].calories}{" "}
                                  kcal
                                </label>
                              </QuickInfo>
                            )}
                        </div>
                      </div>
                      {Object.keys(mealData).length > 0 && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {fetchedData.tenant.includes(tenant) ? (
                            <Tooltip title="Click here to copy this day to other days!">
                              <IconButton
                                onClick={() => {
                                  openModal(mealData, day);
                                  setShowCopyModal(true);
                                }}
                              >
                                <CopyAll fontSize="large" />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          {fetchedData.tenant.includes(tenant) ? (
                            <div>
                              <DeleteComponent
                                deletes={isDeletes}
                                setDeletes={setIsDeletes}
                                onDeletes={() => {
                                  onDayDelete(day, data);
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>

                  {mealByTypes?.map((typeName, index) => {
                    const mealItems = mealData[typeName] || [];
                    return (
                      <div
                        key={index}
                        className={`DietPlanDetail__container__card__dietbox ${
                          fetchedData.tenant.includes(tenant) ? "cursor" : ""
                        }`}
                        style={{ position: "relative" }}
                      >
                        {data.dayOff ? (
                          <div className="backdrop-filter">deleted meals</div>
                        ) : null}
                        <div
                          className={`DietPlanDetail__container__card__dietbox__dietcard  
                        
                         ${
                           day === dragItem.day &&
                           typeName === dragItem.typeName
                             ? "blurcard"
                             : ""
                         }
                         `}
                        >
                          <div className="hide-on-drag"></div>
                          <div className="DietPlanDetail__container__card__dietbox__dietcard__mealtype">
                            <DietInfo
                              key={index}
                              label={typeName}
                              data={mealData[typeName]}
                              openModal={openModal}
                            />

                            {fetchedData.tenant.includes(tenant) ? (
                              <button
                                className="addSymbol"
                                onClick={() => {
                                  setOpenFoodRecipe({
                                    open: true,
                                    typeName: typeName,
                                    day: day,
                                    mealDayOrder: data.mealDayOrder,
                                    _version: data._version,
                                  });
                                  setMeaPlanId({
                                    mealPlanDayId: data.id,
                                    mealPlannerId: data.mealPlannerId,
                                    typeName: typeName,
                                  });
                                }}
                              >
                                <PlusCircle />
                              </button>
                            ) : null}
                            {mealItems &&
                              mealItems.length > 0 &&
                              fetchedData.tenant.includes(tenant) && (
                                <DeleteComponent
                                  deletes={isDeletes}
                                  setDeletes={setIsDeletes}
                                  onDeletes={() => {
                                    onTypeDelete(mealItems, data, typeName);
                                  }}
                                />
                              )}
                          </div>
                          <div>
                            {mealItems.length > 0 &&
                              mealItems
                                .filter((item) => !item?._deleted)
                                ?.map((items, index) => {
                                  if (
                                    (items?.recipe || items?.food) &&
                                    !items?._deleted
                                  ) {
                                    return (
                                      <div
                                        className="childColumn"
                                        key={"dietplan_item" + index}
                                        onDragStart={(e) => {
                                          dragStart(index, day, typeName);
                                        }}
                                        // onDragEnter={(e) => {
                                        //   dragEnter(e, index, day, typeName);
                                        // }}
                                        onDragEnd={(e) =>
                                          drop(e, day, mealData, typeName)
                                        }
                                        draggable={fetchedData.tenant.includes(
                                          tenant
                                        )}
                                      >
                                        <QuickInfo
                                          key={
                                            "dietplan_item_quickInfo" + index
                                          }
                                          name={
                                            items?.recipe
                                              ? items.recipe.title
                                              : items.food.name
                                          }
                                          serving={items.servingDescription}
                                          amount={items.servingSize}
                                          calories={items.calories}
                                          carbs={items.carbs}
                                          protein={items.protien}
                                          fats={items.fats}
                                          description={""}
                                        >
                                          <MealItem
                                            data={items}
                                            grams={items.calculatedGrams}
                                            debouncedChangeHandler={
                                              debouncedChangeHandler
                                            }
                                            servingSize={items.servingSize}
                                            servingDescription={
                                              items?.servingDescription
                                            }
                                            id={items?.id}
                                            key={"mealItem_" + items.id}
                                            _version={items?._version}
                                            name={
                                              items?.recipe
                                                ? items.recipe.title
                                                : items.food.name
                                            }
                                            mealLayout={mealLayout}
                                            imageData={
                                              imagePath +
                                              (items?.recipe
                                                ? items.recipe.imageUrl
                                                : items.food.imageUrl)
                                            }
                                            onCopyRefresh={onCopyRefresh}
                                            endDate={fetchedData?.numOfdays}
                                            type={"dietPlanFood"}
                                            onDelete={() => {
                                              onDelete(
                                                day,
                                                typeName,
                                                items.id,
                                                items._version
                                              );
                                            }}
                                            userId={id}
                                            date={day}
                                          />
                                        </QuickInfo>
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            } else {
              return (
                <div>
                  {fetchedData.tenant.includes(tenant) ? (
                    <div
                      key={index}
                      className="DietPlanDetail__container__card"
                    >
                      <div className="workoutEmptyBox">
                        <button
                          className="DietPlanDetail__container__empty__image"
                          onClick={creatMealPlanDayButton}
                        >
                          <Plus />
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            }
          })}
        </div>
      </section>
    </>
  );
};
export default DietPlanDetail;
