import "./InputContainer.scss";
const InputContainer = (props) => {
  return (
    <div className="inputContainer" style={props.style}>
      <label style={props.labelStyle}>{props.label}</label>
      {props.children}
    </div>
  );
};
export default InputContainer;
