import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getMealLayoutManager } from "../../../../graphql/queries";
import { useParams, useNavigate } from "react-router-dom";
import DeleteComponent from "../../../../component/Delete/Delete";
import "./LayoutList.scss";
import xcircle from "../../../../images/x-circle.svg";
import { updateMealLayoutManager } from "../../../../graphql/mutations";

import { listMealTypes } from "../../../../graphql/queries";
import { useDispatch } from "react-redux";
import { ReactComponent as Check } from "../../../../images/check.svg";
import { sendNotification } from "../../../../app/features/notifications";
import AutocompleteComp from "../../../ClientDetail/Autocomplete";
import { useAppSelector } from "../../../../hooks";

const LayoutList = () => {
  const { id } = useParams();
  const [layout, setLayout] = useState<any>([]);
  const [isDeletes, setIsDeletes] = useState<any>(false);
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [layoutNames, setLayoutNames] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const listTenant: any = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;
  const [layoutName, setLayoutName] = useState<string>(
    layout?.layoutName || ""
  );

  const [dragItem, setDragItem] = useState<number | null>(null);
  const [dragOverItem, setDragOverItem] = useState<number | null>(null);
  const [updateLayout, setUpdateLayout] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    async function getLayout() {
      const LayoutData: any = await API.graphql(
        graphqlOperation(getMealLayoutManager, {
          id,
        })
      );
      setLayout(LayoutData?.data?.getMealLayoutManager);
    }
    getLayout();
  }, [load]);

  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  async function getDelete(list) {
    await API.graphql(
      graphqlOperation(updateMealLayoutManager, {
        input: { id, layout: list, _version: layout._version },
      })
    );

    setLoad(!load);
  }

  async function onDelete(item) {
    const list = layout.layout;

    if (item > -1) {
      list.splice(item, 1);
    }
    getDelete(list);
  }

  const handleSubmit = async (data) => {
    await API.graphql(
      graphqlOperation(updateMealLayoutManager, {
        input: {
          id,
          layout: [...layout.layout, data],
          _version: layout._version,
        },
      })
    );

    setLoad(!load);
  };

  useEffect(() => {
    async function getAllMealTypes() {
      const mealTypes = await API.graphql(graphqlOperation(listMealTypes));

      setData(mealTypes);
    }
    getAllMealTypes();
  }, []);

  useEffect(() => {
    setLayoutNames(
      data?.data?.listMealTypes?.items
        ?.filter((meal) => !meal._deleted)
        ?.map((item) => item.name)
    );
  }, [data]);

  const AddMeal = ({ handleClose, show, handleSubmit }) => {
    const [inputValue, setInputValue] = useState("");
    const showHideClassName = show
      ? "Editmodal display-block"
      : "Editmodal display-none";

    const onInputChangeLayoutName = (e, value) => {
      setInputValue(e);
    };

    const onHandleSave = () => {
      const newLayout = {
        ...layout,
        layout: [...layout.layout, inputValue],
      };

      setLayout(newLayout);

      hideModal();
    };

    return (
      <div className={showHideClassName}>
        <section className="Editmodal-layoutlist">
          <div className="heading">
            Add Meals to Weekly planner
            <img
              className="heading__image"
              onClick={handleClose}
              src={xcircle}
              alt="Close"
            />
          </div>
          <div className="LayoutLibrary__body">
            <div className="LayoutLibrary__body__text">Which meal Type</div>
            <div className="LayoutLibrary__body__text__input">
              <AutocompleteComp
                handleChange={onInputChangeLayoutName}
                loadNames={layoutNames}
              />
            </div>
          </div>
          <div className="layoutsavebtn__container">
            <button className="layoutsavebtn" onClick={onHandleSave}>
              Save
            </button>
          </div>
        </section>
      </div>
    );
  };

  const goToLayoutPlan = () => {
    navigate(`/library/layoutmanager`);
  };

  const dragStart = (e: any, index: number) => {
    e.dataTransfer.setData("text/plain", index.toString());
    setDragItem(index);
  };

  const dragEnter = (e: any, index: number) => {
    e.preventDefault();
    setDragOverItem(index);
  };

  const drop = (e: any) => {
    e.preventDefault();
    const copyListItems = [...layout?.layout];
    const dragItemContent = copyListItems[dragItem!];
    copyListItems.splice(dragItem!, 1);
    copyListItems.splice(dragOverItem!, 0, dragItemContent);
    let newLayout = layout;
    newLayout.layout = [...copyListItems];
    setUpdateLayout(newLayout);
    setDragItem(null);
    setDragOverItem(null);
  };

  useEffect(() => {
    setLayout(updateLayout);
  }, [updateLayout]);

  const CreateMealLayoutManager = async () => {
    await API.graphql(
      graphqlOperation(updateMealLayoutManager, {
        input: {
          id,
          layout: [...layout?.layout],
          layoutName: layoutName,
          _version: layout._version,
        },
      })
    );
    dispatch(
      sendNotification({
        title: "Success",
        description: "Layout updated successfully!",
        backgroundColor: "#5cb85c",
        icon: Check,
      })
    );

    setLoad(!load);
  };

  const Submit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setLayoutName(layout?.layoutName);
  }, [layout]);
  return (
    <>
      <button className="backtodietplan" onClick={goToLayoutPlan}>
        <p> &#60; Back to Layout Plan</p>
      </button>
      <form onSubmit={Submit}>
        <div className="layouts-container">
          <div className="list">
            <span className="inputlayout">Meal Layout Name: </span>
            <input
              style={{ height: 30 }}
              className="inputLayoutText"
              type="text"
              value={layoutName}
              disabled={layout?.tenant?.includes(tenant) ? false : true}
              onChange={(e) => setLayoutName(e.target.value)}
            />
          </div>
          <div className="checkbox-container">
            <div style={{ paddingLeft: 0 }}>
              <span className="inputlayout">Meal Types: </span>
            </div>
          </div>
          <div className="leads">
            <div className="leads__header">
              <AddMeal
                show={show}
                handleClose={hideModal}
                handleSubmit={handleSubmit}
              />
              <div className="leads__header__buttons"></div>
            </div>

            <div className="layout__list">
              {layout &&
                layout?.layout?.map((item, index) => {
                  return (
                    <div
                      className="layout__list__names"
                      onDragStart={(e) => dragStart(e, index)}
                      onDragEnter={(e) => dragEnter(e, index)}
                      onDragEnd={drop}
                      key={index}
                      draggable={layout?.tenant?.includes(tenant)}
                    >
                      {layout?.tenant?.includes(tenant) ? (
                        <div className="layout__list__names__delete">
                          <DeleteComponent
                            deletes={isDeletes}
                            setDeletes={setIsDeletes}
                            onDeletes={() => onDelete(index)}
                          />
                        </div>
                      ) : null}
                      <div className="layout__list__names__title"> {item}</div>
                    </div>
                  );
                })}
              {layout?.tenant?.includes(tenant) ? (
                <button className="AddMeals__button" onClick={showModal}>
                  Add Meals
                </button>
              ) : null}
            </div>
            {layout?.tenant?.includes(tenant) ? (
              <div className="saveEditLayout">
                <button
                  type="submit"
                  className="editButton"
                  onClick={() => {
                    CreateMealLayoutManager();
                  }}
                  disabled={layoutName === "" || layout?.length === 0}
                >
                  Save
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </form>
    </>
  );
};
export default LayoutList;
