import { useEffect, useState } from "react";
import "./EditModalLoadWorkout.scss";
import xcircle from "../../../images/x-circle.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputContainer from "../../../component/InputContainer/InputContainer";
import { listWorkoutPlans } from "../../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { LoadWorkoutToCalendar } from "../../../graphql/mutations";
import { trackPromise } from "react-promise-tracker";
import AutocompleteComp from "./../Autocomplete";
const moment = require("moment");
const EditModalLoadWorkout = ({ handleClose, show, userId, currentDate }) => {
  const [loadNames, setLoadNames] = useState<any>([]);
  const [pickedDate, setPickedDate] = useState(new Date());
  const [layoutData, setLayoutData] = useState<any>([]);
  const [days, setDays] = useState<any>();
  const [layoutId, setLayoutId] = useState<any>();

  useEffect(() => {
    async function getListMealPlannerData() {
      const data: any = await API.graphql(
        graphqlOperation(listWorkoutPlans, { limit: 1000 })
      );

      setLayoutData(data?.data?.listWorkoutPlans?.items);

      setLoadNames(
        data?.data?.listWorkoutPlans?.items
          .filter((workout) => !workout._deleted)
          .map((item) => {
            return item.name;
          })
      );
      setPickedDate(currentDate);
    }
    getListMealPlannerData();
  }, [currentDate]);

  const showHideClassName = show
    ? "Editmodal display-block"
    : "Editmodal display-none";

  const Submit = (e) => {
    e.preventDefault();
    trackPromise(addMealForm());
  };

  const addMealForm = async () => {
    const date = moment(pickedDate, "DD-MM-YYYY").format("YYYY-MM-DD");

    await API.graphql(
      graphqlOperation(LoadWorkoutToCalendar, {
        workoutId: layoutId,
        date: date,
        userID: userId,
        days: days,
      })
    );
    setPickedDate(new Date());
    setDays(0);
    handleClose();
  };
  const onInputChangeLayoutName = (name) => {
    let data =
      layoutData && layoutData.length > 0
        ? layoutData.map((item) => {
            if (item.name === name) {
              setDays(item.numOfdays);
              setLayoutId(item.id);
            }
          })
        : null;
  };
  return (
    <>
      <div className={showHideClassName}>
        <section className="Editmodal-meal">
          <div className="loadheading">
            Load Workout Plan
            <img
              className="heading__image"
              onClick={handleClose}
              src={xcircle}
            />
          </div>

          <form onSubmit={Submit}>
            <div className="MealPlan">
              <div>
                <span className="inputMealPlan">Workout Plan: </span>
              </div>
              <div style={{ paddingLeft: 15, width: "70%" }}>
                <AutocompleteComp
                  handleChange={onInputChangeLayoutName}
                  loadNames={loadNames.sort()}
                />
              </div>
            </div>
            <div style={{ marginLeft: "60px" }} className="MealPlan">
              <div style={{ paddingTop: 15 }}>
                <span className="inputMealPlan">Date: </span>
              </div>
              <div style={{ width: "77%", paddingLeft: "5px" }}>
                <InputContainer>
                  <DatePicker
                    style={{ paddingLeft: 20 }}
                    color={"white"}
                    selected={pickedDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => {
                      setPickedDate(date);
                    }}
                  />
                </InputContainer>
              </div>
            </div>
            <div className="durationtext">
              Workout Plan applies for {days} days
            </div>

            <div style={{ paddingTop: "45px" }} className="save">
              <button type="submit" className="Load">
                Load
              </button>
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default EditModalLoadWorkout;
