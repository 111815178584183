import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listMealLayoutManagers } from "../../../../graphql/queries";
import moment from "moment";
import Pagination from "../../../../component/Pagination/pagination";
import { useNavigate } from "react-router-dom";
import { deleteMealLayoutManager } from "../../../../graphql/mutations";
import DeleteComponent from "../../../../component/Delete/Delete";
import FtButton from "../../../Ft-Button/Ft-Button";
import "./LayoutManager.scss";
import TopNav from "../../../TopNav/TopNav";
import { filterSearchData } from "../../../../Utils";
import Search from "../../../../component/Search/Search";
import { useAppSelector } from "../../../../hooks";
let PageSize = 10;
const LayoutManager = () => {
  const [layout, setLayout] = useState<any>();
  const [filteredUser, setFilteredUser] = useState<any>(null);
  useEffect(() => {
    async function getLayout() {
      const LayoutData: any = await API.graphql(
        graphqlOperation(listMealLayoutManagers, {
          limit: 1000,
        })
      );

      setLayout(LayoutData);
      setFilteredUser(LayoutData?.data?.listMealLayoutManagers?.items);
    }
    getLayout();
  }, []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredUser(
      filterSearchData(
        layout?.data?.listMealLayoutManagers?.items,
        event.target.value,
        ["layoutName"]
      )
    );
  };
  const navigate = useNavigate();
  const goToDietPlanDetail1 = (e: any) => {
    e.preventDefault();
    navigate(`/library/layoutmanager/createlayoutdetails`);
  };
  const backToLibrary = () => {
    navigate(`/library`);
  };
  return (
    <>
      <div className="topBar">
        <div className="topBar__back">
          {" "}
          <button onClick={backToLibrary} className="topBar__button">
            <label> &#60; Back</label>
          </button>
        </div>
        <div className="topBar__search">
          {" "}
          <TopNav>
            <Search
              onChange={onChange}
              placeHolder="Search  Layout by Layout name"
            />
          </TopNav>
        </div>
      </div>
      <div className="leads">
        <div className="leads__header">
          <div className="leads__header__text">
            <h1>Layout Plans</h1>
          </div>
          <div className="newbt">
            <FtButton
              border="1px solid #109CF1"
              color="#109CF1"
              height="40px"
              className="createnewbtn"
              radius="5px"
              onClick={(e: any) => goToDietPlanDetail1(e)}
            >
              Create New Meal Layout
            </FtButton>
          </div>
        </div>
        <div className="leads__container">
          {filteredUser && (
            <Table data={filteredUser} setData={setFilteredUser} />
          )}
        </div>
      </div>
    </>
  );
};

const Table = (props) => {
  const sortTypes = {
    up: {
      class: "sort-up",
    },
    down: {
      class: "sort-down",
    },
    default: {
      class: "sort",
    },
  };
  const Columns = [
    { name: 0 },
    { meal: 0 },
    { email: 0 },
    { payment_type: 0 },
    { membership: 0 },
  ];

  const [tableState, setTableState] = useState<any>({
    currentSort: "default",
    activeColumnSort: "",
    columns: Columns,
    currentPage: 1,
    data: [],
  });
  const [paginatedData, setPaginatedData] = useState<any>([]);

  const compareBy = (key) => {
    return function (a, b) {
      if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
      if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
      return 0;
    };
  };

  const sortBy = (key) => {
    let nextSort;

    if (tableState.currentSort === "down") nextSort = "up";
    else if (tableState.currentSort === "up") nextSort = "default";
    else if (tableState.currentSort === "default") nextSort = "down";

    let arrayCopy1 = props.data.filter((data) => {
      if (!data._deleted) {
        return data;
      }
    });

    let arrayCopy2 = [...tableState.columns];

    let order = 1;
    arrayCopy1.sort(compareBy(key));
    arrayCopy1.sort(compareBy(key));
    if (tableState.columns[key] !== 2) {
      order = 2;
      arrayCopy1.reverse();
    }
    arrayCopy2[key] = order;
    setTableState({
      ...tableState,
      ...{
        data: arrayCopy1,

        currentPage: 1,
        currentSort: nextSort,
        activeColumnSort: key,
      },
    });
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;
    setPaginatedData(arrayCopy1?.slice(firstPage, lastPage));
  };

  const onPageChange = (page) => {
    setTableState({
      ...tableState,
      ...{ currentPage: page },
    });
  };

  useEffect(() => {
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;
    let arrayCopy1 = props.data.filter((data) => {
      if (!data._deleted) {
        return data;
      }
    });
    setTableState({
      ...tableState,
      ...{
        firstPageIndex: firstPage,
        lastPageIndex: lastPage,
        data: arrayCopy1,
      },
    });
    setPaginatedData(arrayCopy1?.slice(firstPage, lastPage));
  }, [props.data]);

  useEffect(() => {
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;
    setPaginatedData(tableState.data?.slice(firstPage, lastPage));
  }, [tableState.currentPage, tableState.data]);

  const [deletedId, setDeletedId] = useState<any>();

  const onDelete = async function dietForm(id: any, version: any) {
    const deleteddata: any = await API.graphql(
      graphqlOperation(deleteMealLayoutManager, {
        input: {
          id: id,
          _version: version,
        },
      })
    );
    setDeletedId(id);
  };

  useEffect(() => {
    const datas = props.data.filter((item) => {
      if (item.id !== deletedId) {
        return item;
      }
    });
    props.setData(datas);
  }, [deletedId]);
  const listTenant = useAppSelector((state) => state.tenant);
  const tenants = listTenant?.tenant?.currentTenant;
  const [isDeletes, setIsDeletes] = useState<any>(false);
  const Row = (
    { id, layout, layoutName, createdAt, _version, tenant },
    index
  ) => (
    <>
      <tr
        key={index}
        className="tableRow"
        onClick={(e) => goToDietPlanDetail(id, e)}
      >
        <td className="layoutname">{layoutName ?? "NA"}</td>
        <td className="layoutnameslist">
          {layout.map((item) => {
            return " " + item;
          }) + " " ?? "NA"}
        </td>
        <td>{moment(createdAt).fromNow()}</td>

        <td
          className="actions"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {tenant === null ||
          tenants === tenant[0] ||
          (tenants === "ADMIN" && tenant.includes("ADMIN")) ? (
            <DeleteComponent
              deletes={isDeletes}
              setDeletes={setIsDeletes}
              onDeletes={(e) => {
                onDelete(id, _version);
              }}
            />
          ) : null}
        </td>
      </tr>
    </>
  );

  const rows = paginatedData?.map((rowData) => {
    if (!rowData._deleted) {
      return <Row {...rowData} />;
    }
  });
  const navigate = useNavigate();
  const goToDietPlanDetail = (id, e) => {
    e.preventDefault();
    navigate(`/library/layoutmanager/layoutdetails/${id}`);
  };

  return (
    <div className="responsivetable">
      <table className="text-left">
        <thead>
          <tr>
            <th>
              Layout Name
              <button>
                {tableState.activeColumnSort != "layoutname" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort == "layoutname" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>
            </th>
            <th>
              Meal Types
              <button>
                {tableState.activeColumnSort != "layout" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort == "layout" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>{" "}
            </th>
            <th>
              Created At
              <button onClick={() => sortBy("createdAt")}>
                {tableState.activeColumnSort != "createdAt" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort == "createdAt" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>
            </th>
            <th style={{ paddingLeft: "48px" }}>Actions</th>
          </tr>
        </thead>
        {rows?.length === 0 ? (
          <tbody>
            <tr>
              <td className="nodata" colSpan={5}>
                No data available
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>{rows}</tbody>
        )}
      </table>
      <Pagination
        className="pagination-bar"
        currentPage={tableState.currentPage}
        totalCount={tableState.data?.length}
        pageSize={PageSize}
        onPageChange={onPageChange}
        rowsPerPage={paginatedData.length}
        label="plans"
      />
    </div>
  );
};
export default LayoutManager;
