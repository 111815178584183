import { useState, useEffect } from "react";

import FtButton from "../../../Ft-Button/Ft-Button";

import Search from "../../../../component/Search/Search";
import { ReactComponent as Check } from "../../../../images/check.svg";
import { filterSearchData } from "../../../../Utils";
import FourItemsContainer from "../../../Clients/FourItemsContainer/FourItemsContainer";

import { useDispatch } from "react-redux";
import { fetchTenant } from "../../../../app/features/Tenant/tenantSlice";
import {
  createExerciseFn,
  deleteExerciseFn,
  fetchExercises,
  updateExerciseFn,
} from "../../../../app/features/Workout/workoutSlice";
import { useAppSelector } from "../../../../hooks";
import ExerciseModal from "./ExerciseModal";
import { sendNotification } from "../../../../app/features/notifications";
const Exercise = () => {
  const [open, setOpen] = useState(false);
  const exercises = useAppSelector((state) => state.exercises);
  const listTenant: any = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;

  const [selectedWorkoutItem, setSelectedWorkoutItem] = useState<any>(null);
  const dispatch = useDispatch();
  const exercise: any = exercises.exercises?.items.filter(
    (item) => !item._deleted
  );
  const [searchText, setSearchText] = useState("");
  const filteredExercise = filterSearchData(exercise, searchText, [
    "name",
    (item) => item.bodyPart?.name,
  ]);
  async function getAllExcercise() {
    dispatch(fetchExercises(null));
    dispatch(fetchTenant(null));
  }
  useEffect(() => {
    if (exercises.exercises?.status !== "succeeded") {
      getAllExcercise();
    }
  }, []);
  useEffect(() => {
    if (exercises.exercises?.nextToken) {
      dispatch(fetchExercises(exercises.exercises?.nextToken));
    }
  }, [exercises.exercises?.nextToken]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const handleSelectedItem = (item, type) => {
    setOpen(true);
    setSelectedWorkoutItem(item);
  };
  const handleOpen = async (item, type) => {
    dispatch(deleteExerciseFn(item));
    dispatch(
      sendNotification({
        title: "Success",
        description: "Deleted Successfully",
        backgroundColor: "#5cb85c",
        icon: Check,
      })
    );
  };
  const handleSubmit = async (data) => {
    dispatch(
      createExerciseFn({
        ...data,
        tenant: tenant.includes("ADMIN")
          ? ["ADMIN", "Trainers", "Clients"]
          : tenant,
      })
    );
    dispatch(
      sendNotification({
        title: "Success",
        description: "Created Successfully",
        backgroundColor: "#5cb85c",
        icon: Check,
      })
    );
    setOpen(false);
  };
  const handleEdit = async (data) => {
    dispatch(updateExerciseFn(data));
    dispatch(
      sendNotification({
        title: "Success",
        description: "Exercise updated Successfully",
        backgroundColor: "#5cb85c",
        icon: Check,
      })
    );
    setOpen(false);
  };
  return (
    <>
      <div className="foodlibrary__header">
        <div className="foodlibrary__header__text">
          <h1>Exercises</h1>
        </div>
        <div className="foodlibrary__header__button">
          <div className="foodlibrary__header__button">
            <FtButton
              className="createnewbtn"
              border="1px solid #109CF1"
              color="#109CF1"
              height="40px"
              onClick={() => {
                setOpen(true);
              }}
              radius="5px"
              width="100%"
              children="Create New Exercise"
              icon={""}
            />
          </div>
        </div>
      </div>
      <div>
        {open && (
          <ExerciseModal
            show={open}
            handleClose={() => {
              setOpen(false);
              setSelectedWorkoutItem(null);
            }}
            handleSubmit={handleSubmit}
            propsItem={selectedWorkoutItem}
            handleEdit={handleEdit}
            tenant={tenant}
          />
        )}

        <div className="searchBar">
          <Search
            onChange={(e) => {
              onChange(e);
            }}
            placeHolder="Search Exercise"
          />
        </div>

        <div style={{ padding: "17px" }}>
          <div className="scrollableContainer">
            {filteredExercise && (
              <FourItemsContainer
                data={filteredExercise}
                type="workoutLibrary"
                onSelectedItem={handleSelectedItem}
                onChange={handleOpen}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Exercise;
