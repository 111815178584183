import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { ReactComponent as Clipboard } from "../../../images/blackclipboard.svg";
import { ReactComponent as Plus } from "../../../images/blacksave.svg";
import { ReactComponent as Clock } from "../../../images/calander_clock.svg";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, TextField } from "@mui/material";
import DatePickerComponent from "../NotesDatePicker";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./NotesAndRemainder.scss";
import { useAppSelector } from "../../../hooks";
import X from "../../../images/X.svg";
import { Loader } from "../../../component/Common/Loader";
import {
  CREATE_NOTE,
  CREATE_REMINDER,
  DELETE_NOTE,
  DELETE_REMINDER,
  GET_NOTES,
  GET_REMINDERS,
  UPDATE_NOTE,
  UPDATE_REMINDER,
} from "../../../graphql/customQueries";

const NotesAndRemainder: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [noteValue, setNoteValue] = useState("");
  const [reminderValue, setReminderValue] = useState("");
  const [reminderDate, setReminderDate] = useState(new Date());
  const [editingReminderId, setEditingReminderId] = useState<string | null>(
    null
  );
  const [editedReminderMessage, setEditedReminderMessage] = useState("");
  const [isOperationLoading, setIsOperationLoading] = useState(false);
  const listTenant: any = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;

  const {
    loading: notesLoading,
    error: notesError,
    data: notesData,
  } = useQuery(GET_NOTES, {
    variables: { userID: id },
    fetchPolicy: "network-only",
  });

  const {
    loading: remindersLoading,
    error: remindersError,
    data: remindersData,
    refetch: refetchReminders,
  } = useQuery(GET_REMINDERS, {
    variables: { userID: id, limit: 1000 },
    fetchPolicy: "network-only",
  });

  const [createNote] = useMutation(CREATE_NOTE, {
    refetchQueries: [{ query: GET_NOTES, variables: { userID: id } }],
  });

  const [updateNote] = useMutation(UPDATE_NOTE, {
    refetchQueries: [{ query: GET_NOTES, variables: { userID: id } }],
  });

  const [deleteNote] = useMutation(DELETE_NOTE, {
    refetchQueries: [{ query: GET_NOTES, variables: { userID: id } }],
  });

  const [createReminder] = useMutation(CREATE_REMINDER, {
    update(cache, { data: { createReminder } }) {
      const existingReminders = cache.readQuery<any>({
        query: GET_REMINDERS,
        variables: { userID: id, limit: 1000 },
      });
      if (existingReminders && existingReminders.listReminders) {
        cache.writeQuery({
          query: GET_REMINDERS,
          variables: { userID: id, limit: 1000 },
          data: {
            listReminders: {
              ...existingReminders.listReminders,
              items: [...existingReminders.listReminders.items, createReminder],
            },
          },
        });
      }
    },
    onCompleted: () => {
      refetchReminders();
    },
  });

  const [updateReminder] = useMutation(UPDATE_REMINDER, {
    refetchQueries: [{ query: GET_REMINDERS, variables: { userID: id } }],
  });

  const [deleteReminder] = useMutation(DELETE_REMINDER, {
    update(cache, { data: { deleteReminder } }) {
      const existingReminders = cache.readQuery<any>({
        query: GET_REMINDERS,
        variables: { userID: id, limit: 1000 },
      });
      if (existingReminders && existingReminders.listReminders) {
        const updatedItems = existingReminders.listReminders.items.filter(
          (item: any) => item.id !== deleteReminder.id
        );
        cache.writeQuery({
          query: GET_REMINDERS,
          variables: { userID: id, limit: 1000 },
          data: {
            listReminders: {
              ...existingReminders.listReminders,
              items: updatedItems,
            },
          },
        });
      }
    },
    onCompleted: () => {
      refetchReminders();
    },
  });

  const handleCreateNote = async (e: React.FormEvent) => {
    e.preventDefault();
    if (noteValue.trim() !== "") {
      setIsOperationLoading(true);
      try {
        await createNote({
          variables: {
            input: {
              userID: id,
              note: noteValue.trim(),
              tenant: tenant,
            },
          },
        });
        setNoteValue("");
      } catch (error) {
        console.error("Error creating note:", error);
      } finally {
        setIsOperationLoading(false);
      }
    }
  };

  const handleUpdateNote = async (
    id: string,
    note: string,
    version: number
  ) => {
    setIsOperationLoading(true);
    try {
      await updateNote({
        variables: {
          input: {
            id,
            note,
            _version: version,
          },
        },
      });
    } catch (error) {
      console.error("Error updating note:", error);
    } finally {
      setIsOperationLoading(false);
    }
  };

  const handleDeleteNote = async (id: string, version: number) => {
    setIsOperationLoading(true);
    try {
      await deleteNote({
        variables: {
          input: {
            id,
            _version: version,
          },
        },
      });
    } catch (error) {
      console.error("Error deleting note:", error);
    } finally {
      setIsOperationLoading(false);
    }
  };

  const handleCreateReminder = async () => {
    if (reminderValue.trim() !== "") {
      setIsOperationLoading(true);
      try {
        await createReminder({
          variables: {
            input: {
              userID: id,
              message: reminderValue.trim(),
              finishBy: reminderDate.toISOString().split("T")[0],
              tenant: tenant,
              isDone: false,
            },
          },
        });
        setReminderValue("");
        setReminderDate(new Date());
      } catch (error) {
        console.error("Error creating reminder:", error);
      } finally {
        setIsOperationLoading(false);
      }
    }
  };

  const handleToggleReminder = async (
    id: string,
    isDone: boolean,
    version: number
  ) => {
    setIsOperationLoading(true);
    try {
      await updateReminder({
        variables: {
          input: {
            id,
            isDone: !isDone,
            _version: version,
          },
        },
      });
    } catch (error) {
      console.error("Error updating reminder:", error);
    } finally {
      setIsOperationLoading(false);
    }
  };

  const handleDeleteReminder = async (id: string, version: number) => {
    setIsOperationLoading(true);
    try {
      await deleteReminder({
        variables: {
          input: {
            id,
            _version: version,
          },
        },
      });
    } catch (error) {
      console.error("Error deleting reminder:", error);
    } finally {
      setIsOperationLoading(false);
    }
  };

  const handleEditReminder = async (
    id: string,
    message: string,
    version: number
  ) => {
    setIsOperationLoading(true);
    try {
      await updateReminder({
        variables: {
          input: {
            id,
            message,
            _version: version,
          },
        },
      });
      setEditingReminderId(null);
    } catch (error) {
      console.error("Error updating reminder:", error);
    } finally {
      setIsOperationLoading(false);
    }
  };

  if (notesLoading || remindersLoading) return <Loader />;
  if (notesError) return <p>Error loading notes: {notesError.message}</p>;
  if (remindersError)
    return <p>Error loading reminders: {remindersError.message}</p>;

  return (
    <div className="notesandremainder">
      <div className="notes">
        <div className="notes__header">
          <Clipboard />
          <div className="notes__header__text">Notes</div>
        </div>
        {isOperationLoading && <Loader />}
        <div className="notes__body">
          {notesData?.listNotes.items.map((item: any) => (
            <div key={item.id} className="notes__body__items">
              <div>
                <div className="notes__body__items__date">
                  <div className="notes__body__items__date__d">
                    {moment(item.createdAt).format("MMM DD,yyyy")}
                  </div>
                  <div
                    className="notes__body__items__date__close"
                    onClick={() => handleDeleteNote(item.id, item._version)}
                  >
                    <img src={X} alt="" />
                  </div>
                </div>
                <div>
                  <textarea
                    className="notes__body__items__text"
                    value={item.note}
                    onChange={(e) =>
                      handleUpdateNote(item.id, e.target.value, item._version)
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="notes__footer">
          <form className="notes__footer__box" onSubmit={handleCreateNote}>
            <div className="notesimage">
              <Clipboard />
            </div>
            <textarea
              className="notesTextare"
              name="textvalue"
              placeholder="Write a note..."
              value={noteValue}
              onChange={(e) => setNoteValue(e.target.value)}
              style={{
                paddingLeft: "10px",
              }}
            ></textarea>
            <div className="addnotesbutton">
              <button className="addnotesbutton__button" type="submit">
                <Plus />
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="remainder">
        <div className="remainder__header">
          <Clock />
          <div className="remainder__header__text">Reminder</div>
        </div>
        <div className="remainder__body">
          {remindersData &&
            remindersData.listReminders.items.map(
              (item: any, index: number) => (
                <div
                  key={item.id}
                  className={`remainder__body__item ${
                    index % 2 === 0 ? "even" : "odd"
                  } ${item.isDone ? "completed" : ""}`}
                >
                  <div className="remainder__body__item__content">
                    <div className="remainder__body__item__checkbox">
                      <input
                        type="checkbox"
                        checked={item.isDone}
                        onChange={() =>
                          handleToggleReminder(
                            item.id,
                            item.isDone,
                            item._version
                          )
                        }
                      />
                    </div>
                    <div className="remainder__body__item__text">
                      {editingReminderId === item.id ? (
                        <TextField
                          value={editedReminderMessage}
                          onChange={(e) =>
                            setEditedReminderMessage(e.target.value)
                          }
                          onBlur={() =>
                            handleEditReminder(
                              item.id,
                              editedReminderMessage,
                              item._version
                            )
                          }
                          autoFocus
                          fullWidth
                          variant="standard"
                        />
                      ) : (
                        <label>{item.message}</label>
                      )}
                      <div className="remainder__body__item__date">
                        {moment(item.finishBy).format("MMM DD, yyyy")}
                      </div>
                    </div>
                  </div>
                  <div className="remainder__body__item__actions">
                    <IconButton
                      onClick={() => {
                        setEditingReminderId(item.id);
                        setEditedReminderMessage(item.message);
                      }}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        handleDeleteReminder(item.id, item._version)
                      }
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              )
            )}
        </div>
        <div className="remainder__footer">
          <div className="remainder__footer__box">
            <div className="calenderbutton">
              <DatePicker
                selected={reminderDate}
                onChange={(date: Date) => setReminderDate(date)}
                dateFormat="dd-MM-yyyy"
                minDate={new Date()}
                customInput={<DatePickerComponent />}
              />
            </div>
            <textarea
              className="notesTextare"
              placeholder="Write a Reminder..."
              value={reminderValue}
              onChange={(e) => setReminderValue(e.target.value)}
              style={{
                paddingLeft: "10px",
              }}
            />
            <div className="addnotesbutton">
              <button
                className="addnotesbutton__button"
                type="button"
                onClick={handleCreateReminder}
              >
                <Plus />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesAndRemainder;
