import "./FourItemsContainer.scss";
import { ReactComponent as AddButtonIcon } from "../../../images/plus-circle.svg";
import ImageComponent from "../ImageComponent";
import { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import DeleteComponent from "../../../component/Delete/Delete";
import QuickInfo from "../../../component/QuickInfo/QuickInfo";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "../../../hooks";
interface IProps {
  data: any[];
  onChange?: (id: string, type: string) => void;
  onSelectedItem?: (id: string, type: string) => void;
  type: string;
}
const FourItemsContainer = (props: IProps) => {
  const limit = 4;
  const [imagePath, setImagePath]: any = useState();

  const listTenant = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;
  const [isDeletes, setIsDeletes] = useState<any>(false);
  useEffect(() => {
    (async () => {
      const url = await Storage.get("");

      return setImagePath(url.split("?")[0]);
    })();
  }, []);

  const verticalListObj = props.data
    .filter((item) => !item._deleted)
    .reduce((acc, item, index) => {
      {
        const group = Math.floor(index / limit);
        if (!acc[group]) {
          acc[group] = [item];
        } else {
          acc[group].push(item);
        }
      }
      return acc;
    }, {});

  const showSnackbar = () => {
    let x = document.getElementById("snackbar");
    x!.className = "show";
    setTimeout(function () {
      x!.className = x!.className.replace("show", "");
    }, 3000);
  };

  return (
    <div className="listByFour">
      {Object.keys(verticalListObj).map((verticalItem, index) => {
        return (
          <div className="listLimitGroup" key={index}>
            {verticalListObj[verticalItem].map((item, index) => {
              return props.type !== "workoutLibrary" ? (
                <div key={index}>
                  {props.type !== "exercise" ? (
                    <QuickInfo
                      name={item.title || item.name}
                      description={item.description}
                      serving={item.servingDescription}
                      amount={item.servingSize}
                      calories={item.calories}
                      carbs={item.carbs}
                      protein={item.protien}
                      fats={item.fats}
                    >
                      <div className="listItem">
                        <div
                          className={`mealContainer 
                     "hover" 
                    `}
                          onClick={props?.onSelectedItem?.bind(
                            this,
                            item,
                            props.type
                          )}
                        >
                          <div className="listImg">
                            {ImageComponent(imagePath, item.imageUrl)}
                          </div>
                          <div className="listContainer">
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                              component="div"
                              noWrap
                              style={{
                                textOverflow: "ellipsis",
                                width: "150px",
                              }}
                            >
                              {item.title || item.name}
                            </Typography>
                            <span>{item.category || item.description}</span>
                          </div>
                        </div>

                        {props.type === "recipe" ||
                        props.type === "food" ||
                        props.type === "exercise" ? (
                          <div onClick={showSnackbar}>
                            <AddButtonIcon
                              className="addButton"
                              onClick={props.onChange?.bind(
                                this,
                                item,
                                props.type
                              )}
                            />
                          </div>
                        ) : null}

                        {props.type === "recipeLibrary" ||
                        props.type === "foodLibrary" ? (
                          <div>
                            {/* <DeleteButton
                        className="addButton"
                        onClick={props.onChange?.bind(this, item, props.type)}
                      /> */}
                            {item.tenant === null ||
                            tenant === item.tenant[0] ? (
                              <DeleteComponent
                                deletes={isDeletes}
                                setDeletes={setIsDeletes}
                                onDeletes={props.onChange?.bind(
                                  this,
                                  item,
                                  props.type
                                )}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : null}
                      </div>
                    </QuickInfo>
                  ) : (
                    <div className="listItem">
                      <div
                        className={`mealContainer 
                     "hover" 
                    `}
                        onClick={props?.onSelectedItem?.bind(
                          this,
                          item,
                          props.type
                        )}
                      >
                        <div className="listImg">
                          {ImageComponent(imagePath, item.imageUrl)}
                        </div>
                        <div className="listContainer">
                          <label>{item.name}</label>
                          <span>{item?.bodyPart?.name || ""}</span>
                        </div>
                      </div>
                      <div onClick={showSnackbar}>
                        <AddButtonIcon
                          className="addButton"
                          onClick={props.onChange?.bind(this, item, props.type)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div key={index}>
                  {" "}
                  <div className="listItem">
                    <div
                      className={`mealContainer 
                     "hover" 
                    `}
                      onClick={props?.onSelectedItem?.bind(
                        this,
                        item,
                        props.type
                      )}
                    >
                      <div className="listImg">
                        {ImageComponent(imagePath, item.imageUrl)}
                      </div>
                      <div className="listContainer">
                        <label>{item.name}</label>
                        <span>{item?.bodyPart?.name}</span>
                      </div>
                    </div>

                    <div>
                      {item.tenant === null || tenant === item.tenant[0] ? (
                        <DeleteComponent
                          deletes={isDeletes}
                          setDeletes={setIsDeletes}
                          onDeletes={props.onChange?.bind(
                            this,
                            item,
                            props.type
                          )}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div id="snackbar">Item added</div>
          </div>
        );
      })}
    </div>
  );
};
export default FourItemsContainer;
