import { ReactChild } from "react";
import { ReactComponent as CloseIcon } from "../../images/CloseOutlineIcon.svg";
import "./ModalComponent.scss";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
interface IProps {
  onClose?: () => void;
  handleClose: () => void;
  title: string;
  children: ReactChild;
  style?: any;
  open: boolean;
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #ebeff2",
  boxShadow: 24,
  p: 4,
  borderRadius: 10,
};

const ModalComponent = (props: IProps) => {
  const useStyles = makeStyles((theme) => ({
    customModal: {
      "& .MuiBox-root": {
        height: "600px",
        width: "835px",
      },
    },
  }));
  const classes = useStyles();
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={
        props.title === "Add Workout" || props.title === "Edit Workout"
          ? classes.customModal
          : "customModal"
      }
    >
      <Box sx={{ ...style, ...props?.style }}>
        <div className="AddMealHeader">
          <h2>{props.title}</h2>
          <CloseIcon onClick={props.handleClose} />
        </div>
        {props.children}
      </Box>
    </Modal>
  );
};
export default ModalComponent;
