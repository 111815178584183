import React, { useEffect, useState } from "react";
import { CometChat } from "@cometchat-pro/chat";
import { CometChatUI } from "../../cometchat-pro-react-ui-kit/CometChatWorkspace/src/components/CometChatUI";

const Messaging = (props) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const authKey = process.env.REACT_APP_CHAT_AUTH_KEY;
    const uid = props.id;

    if (!initialized) {
      console.log("CometChat not initialized yet, waiting...");
      return;
    }

    CometChat.login(uid, authKey).then(
      (user) => {
        console.log("Login Successful:", { user });
      },
      (error) => {
        console.log("Login failed with exception:", { error });
      }
    );
  }, [initialized, props.id]);

  useEffect(() => {
    const appID = process.env.REACT_APP_CHAT_APP_ID;
    const region = process.env.REACT_APP_CHAT_REGION;
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(region)
      .build();

    CometChat.init(appID, appSetting).then(
      () => {
        console.log("Initialization completed successfully");
        setInitialized(true);
      },
      (error) => {
        console.log("Initialization failed with error:", error);
        // Handle initialization failure
      }
    );
  }, []);

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <CometChatUI />
    </div>
  );
};

export default Messaging;
