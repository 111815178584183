import React, { useEffect, useState } from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { sumOfUnits } from "../../Utils";
import ModalComponent from "../ModalComponent/ModalComponent";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";
import FtButton from "../../views/Ft-Button/Ft-Button";
import { API, graphqlOperation } from "aws-amplify";
import {
  copyCalendarDay,
  copyCalendarFood,
  copyMealPlannerDay,
  copyMealPlannerFood,
} from "../../graphql/mutations";
import { ReactComponent as Check } from "../../images/check.svg";
import { useDispatch } from "react-redux";
import { sendNotification } from "../../app/features/notifications";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import { trackPromise } from "react-promise-tracker";
const CopyMealsModal = (props) => {
  const data = props?.data;
  const dispatch = useDispatch();
  const today = props?.date;

  const [list, setLists] = useState<any>([]);
  useEffect(() => {
    if (props.type === "dietPlanDay" || props.type === "dietPlanFood") {
      const result: any = [];
      for (let i = 1; i <= props.endDate; i++) {
        result.push({
          label: `${"Day" + " " + i}`,
          value: i,
        });
      }
      setLists(result);
    } else {
      const result: any = [
        {
          label: "Tomorrow",
          value: moment(today).add(1, "day").format("yyyy-MM-DD"),
        },
      ];
      for (let i = 2; i <= 14; i++) {
        result.push({
          label: moment(today).add(i, "day").format("DD-MMM ddd"),
          value: moment(today).add(i, "day").format("yyyy-MM-DD"),
        });
      }
      setLists(result);
    }
  }, [props.type, props.endDate]);

  const [selected, setSelected]: any = useState([]);
  const copyMealPlans = async () => {
    const selectedDate = selected?.map((item) => item.value);

    if (selectedDate.length >= 0 && props?.data?.calendar) {
      if (props.type === "dietPlanDay") {
        const resultArray = selectedDate.map((dayObj) => {
          const matchingDay = props.mealLayout.find(
            (item) => item.data && item.data.dayNum === dayObj
          );
          return {
            value: dayObj,
            id: matchingDay ? matchingDay.data.id : null,
          };
        });

        await API.graphql(
          graphqlOperation(copyMealPlannerDay, {
            data: JSON.stringify(props.data.calendar),
            dates: JSON.stringify(resultArray),
            userId: props?.data?.userId,
          })
        );

        dispatch(
          sendNotification({
            title: "Success",
            description: "Meal plan applied to selected dates",
            backgroundColor: "#5cb85c",
            icon: Check,
          })
        );
      } else if (props.type === "dietPlanFood") {
        const resultArray = selectedDate.map((dayObj) => {
          const matchingDay = props.mealLayout.find(
            (item) => item.data && item.data.dayNum === dayObj
          );
          return {
            value: dayObj,
            id: matchingDay ? matchingDay.data.id : null,
          };
        });

        await API.graphql(
          graphqlOperation(copyMealPlannerFood, {
            data: JSON.stringify(props.data.calendar),
            dates: JSON.stringify(resultArray),
            userId: props?.data?.userId,
          })
        );

        dispatch(
          sendNotification({
            title: "Success",
            description: "Meal plan applied to selected dates",
            backgroundColor: "#5cb85c",
            icon: Check,
          })
        );
      } else if (props.type === "copyDay") {
        await API.graphql(
          graphqlOperation(copyCalendarDay, {
            data: JSON.stringify(props.data.calendar),
            dates: JSON.stringify(selectedDate),
            userId: props?.data?.userId,
            layoutId: props?.data?.layoutId,
          })
        );

        dispatch(
          sendNotification({
            title: "Success",
            description: "Meal plan applied to selected days",
            backgroundColor: "#5cb85c",
            icon: Check,
          })
        );
      } else {
        await API.graphql(
          graphqlOperation(copyCalendarFood, {
            data: JSON.stringify(props.data.calendar),
            dates: JSON.stringify(selectedDate),
            userId: props?.data?.userId,
          })
        );
        dispatch(
          sendNotification({
            title: "Success",
            description: "Meal plan applied to selected dates",
            backgroundColor: "#5cb85c",
            icon: Check,
          })
        );
      }
      if (props.type === "dietPlanFood" || props.type === "dietPlanDay") {
        props.onCopyRefresh(true);
      } else {
        props?.onClose();
      }
    } else {
      console.log("Copy meals to another invalid selection!");
    }
  };
  const foodDetails = data?.calendar ? sumOfUnits(data?.calendar) : null;

  return (
    <ModalComponent
      handleClose={props.onClose}
      title={props.title}
      open={props.show}
    >
      <div style={{ width: "500px" }}>
        <Typography variant="h6" gutterBottom>
          <i>
            Copy the plans from
            {props.type === "dietPlanDay" || props.type === "dietPlanFood"
              ? " Day " + moment(props.data.selectedDate)
              : " " + moment(props.data.selectedDate).format("DD-MMM, ddd")}
          </i>
        </Typography>

        <div
          style={{
            padding: "10px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <List
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "fit-content",
              border: (theme: { palette: { divider: any } }) =>
                `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
              bgcolor: "background.paper",
              color: "text.secondary",
              "& svg": {
                m: 1.5,
              },
              "& hr": {
                mx: 0.5,
              },
            }}
          >
            <ListItem>
              <ListItemText
                primary="Grams"
                secondary={foodDetails.calculatedGrams}
              />
            </ListItem>
            <Divider orientation="vertical" variant="middle" flexItem />
            <ListItem>
              <ListItemText
                primary="Calories"
                secondary={foodDetails.calories}
              />
            </ListItem>
            <Divider orientation="vertical" variant="middle" flexItem />
            <ListItem>
              <ListItemText primary="Carbs" secondary={foodDetails.carbs} />
            </ListItem>

            <Divider orientation="vertical" variant="middle" flexItem />

            <ListItem>
              <ListItemText primary="Fat" secondary={foodDetails.fats} />
            </ListItem>
            <Divider orientation="vertical" variant="middle" flexItem />
            <ListItem>
              <ListItemText primary="Protien" secondary={foodDetails.protien} />
            </ListItem>
          </List>
        </div>
        <Grid container>
          <Grid item xs={2} alignItems="center" style={{ display: "flex" }}>
            Apply to
          </Grid>
          <Grid item xs={10}>
            <MultiSelect
              options={list}
              value={selected}
              onChange={setSelected}
              labelledBy=""
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: "20px",
          }}
        >
          <ButtonComponent
            type="button"
            onClick={props.onClose}
            style={{
              backgroundColor: "grey",
              height: "40px",
              padding: "0 13px",
              margin: "5px",
            }}
          >
            Close
          </ButtonComponent>
          <FtButton
            className="createnewbtn"
            border="1px solid #109CF1"
            color="#109CF1"
            height="40px"
            onClick={() => {
              props?.onClose();
              trackPromise(copyMealPlans());
            }}
            radius="5px"
            margin="5px"
            width=""
            disabled={selected.length <= 0}
            children="Copy"
          />
        </div>
      </div>
    </ModalComponent>
  );
};
export default CopyMealsModal;
