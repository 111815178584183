import { ReactNode } from "react";
import "./TopNav.scss";

type ITopNavProps = {
  children?: ReactNode;
};
const TopNav = ({ children }: ITopNavProps) => {
  return (
    <div className="topnav">
      <div className="topnav__container">{children}</div>
    </div>
  );
};
export default TopNav;
