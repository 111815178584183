import Grid from "@mui/material/Grid";
import "./Servings.scss";
import { useEffect, useState } from "react";

import InputContainer from "../../../../component/InputContainer/InputContainer";
import InputComponent from "../../../../component/InputComponent/InputComponent";
import {
  convertDecimalToFraction,
  convertFractionToDecimal,
  validateServing,
} from "../../../../Utils";

const Servings = (props) => {
  const allUnits = props.allUnits;
  const [selectedGrams, setSelectedGrams] = useState(1);
  const [unit, setUnit] = useState(allUnits[0]?.unitName);

  // TODO: Check after the copy plan
  useEffect(() => {
    const selectedServingSize = props?.selectedServingSize;
    const selectedServingDescription = props?.selectedServingDescription;

    if (selectedServingSize) {
      const servingSizeAsFraction: any =
        convertDecimalToFraction(selectedServingSize);

      setSelectedGrams(servingSizeAsFraction);
    } else if (allUnits[0]?.amount) {
      const defaultServingSizeAsFraction = convertDecimalToFraction(
        allUnits[0]?.amount
      );
      const defaultServingSizeAsNumber = parseFloat(
        defaultServingSizeAsFraction
      );
      setSelectedGrams(defaultServingSizeAsNumber);
    }

    const selectedUnit = allUnits?.find(
      (item) => item.unitName === selectedServingDescription
    );
    if (selectedUnit) {
      setUnit(selectedUnit.unitName);
    } else {
      setUnit(allUnits[0]?.unitName);
    }
  }, [props.selectedServingSize, props.selectedServingDescription, allUnits]);

  const [error, setError] = useState(false);

  const handleUnitChange = (e) => {
    setUnit(e.target.value);
    if (e.target.value === "grams") {
      setSelectedGrams(allUnits[0]?.grams);

      unit &&
        props?.onServingChange(
          allUnits[0]?.grams,
          e.target.value,
          props?.id,
          props?._version,
          props?.mealPlannerItemId
        );
    } else {
      unit &&
        props?.onServingChange(
          1,
          e.target.value,
          props?.id,
          props?._version,
          props?.mealPlannerItemId
        );
      setSelectedGrams(1);
    }
  };
  const getServings = () => {
    return (
      <InputContainer
        label="Serving"
        key={"servings-component" + "-" + props.id}
      >
        <select
          className="unit"
          onChange={handleUnitChange}
          value={unit}
          disabled={!props.disabled}
          style={{
            display: "flex",
            maxWidth: 100,
            height: 30,
            border: "1px solid #c2cfe0",
            borderRadius: 5,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {allUnits?.map((item) => {
            return <option value={item.unitName}>{item.unitName}</option>;
          })}
        </select>
      </InputContainer>
    );
  };

  const saveQtyOnBlur = (e) => {
    if (validateServing(selectedGrams)) {
      const decimalValue = convertFractionToDecimal(selectedGrams);
      setError(false);
      unit &&
        decimalValue &&
        props?.onServingChange(decimalValue, unit, props?.id, props?._version);
    } else {
      setError(true);
    }
  };
  const handleChangeQty = (e) => {
    let val = e.target.value;
    if (validateServing(val)) {
      setError(false);
    } else {
      setError(true);
    }
    setSelectedGrams(val);
    // }
  };

  return (
    <Grid
      item
      xs={12}
      sm
      container
      className="servings"
      key={"servings-component-render" + "-" + props.id}
    >
      <Grid item xs={12} className="servings-container" spacing={2}>
        <Grid item xs={16} style={{ display: "flex" }}>
          <InputContainer label="Qty" style={{ width: "fit-content" }}>
            <InputComponent
              className={`qty ${error ? "error-msg" : ""}`}
              style={{ maxWidth: "70px", height: "30px" }}
              onChange={handleChangeQty}
              value={selectedGrams}
              onBlur={saveQtyOnBlur}
              error={error}
              disabled={!props.disabled}
            />
          </InputContainer>
          {getServings()}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Servings;
