import { CometChat } from "@cometchat-pro/chat";
import { Amplify, Auth } from "aws-amplify";

import { UIKitSettings } from "../../../util/UIKitSettings";

export class UserListManager {
  userRequest = null;
  userListenerId = "userlist_" + new Date().getTime();

  constructor(context, searchKey) {
    this.context = context;
    this.searchKey = searchKey;
  }

  initializeUsersRequest = async () => {
    const userListMode = this.context.UIKitSettings.userListMode;
    const userListModeOptions = UIKitSettings.userListFilterOptions;
    const currentUser = await CometChat.getLoggedinUser();
    const tag = (await CometChat.getUser(currentUser.uid)).getTags();

    return new Promise((resolve, reject) => {
      this.usersRequest = new CometChat.UsersRequestBuilder();

      if (userListMode === userListModeOptions["ALL"]) {
        if (this.searchKey) {
          if (currentUser.getRole() === "1") {
            // Admin can see this fellow admin, his all trainers and if he is trainer admin he can see his clients
            this.usersRequest = new CometChat.UsersRequestBuilder()
              .setLimit(30)
              .setRoles(["1", "2", "3"])
              .setTags(tag)
              .setSearchKeyword(this.searchKey)
              .build();
            //tag && this.usersRequest.setTags(tag);
          } else if (currentUser.getRole() === "2") {
            // Trainer cannot see Role 2 other tainers and he can see his clients
            this.usersRequest = new CometChat.UsersRequestBuilder()
              .setLimit(30)
              .setRoles(["1", "3"])
              .setTags(tag)
              .setSearchKeyword(this.searchKey)
              .build();
            // Handle trainer
          } else if (currentUser.getRole() === "3") {
            // Handle client
            // Clients can see only his trainers based on the tag
            // this.usersRequest.setRoles(["1", "2"]);
            // tag && this.usersRequest.setUIDs([tag]);
            this.usersRequest = new CometChat.UsersRequestBuilder()
              .setLimit(30)
              .setRoles(["1", "2"])
              .setTags(tag)
              .setSearchKeyword(this.searchKey)
              .build();
          }
        } else {
          if (currentUser.getRole() === "1") {
            // Admin can see this fellow admin, his all trainers and if he is trainer admin he can see his clients
            this.usersRequest = new CometChat.UsersRequestBuilder()
              .setLimit(30)
              .setRoles(["1", "2", "3"])
              .setTags(tag)
              .setSearchKeyword(this.searchKey)
              .build();
            //tag && this.usersRequest.setTags(tag);
          } else if (currentUser.getRole() === "2") {
            // Trainer cannot see Role 2 other tainers and he can see his clients
            this.usersRequest = new CometChat.UsersRequestBuilder()
              .setLimit(30)
              .setRoles(["1", "3"])
              .setTags(tag)
              .setSearchKeyword(this.searchKey)
              .build();
            console.log("this.usersRequest", this.usersRequest);
            // Handle trainer
          } else if (currentUser.getRole() === "3") {
            // Handle client
            // Clients can see only his trainers based on the tag
            // this.usersRequest.setRoles(["1", "2"]);
            // tag && this.usersRequest.setUIDs([tag]);
            this.usersRequest = new CometChat.UsersRequestBuilder()
              .setLimit(30)
              .setRoles(["1", "2"])
              .setTags(tag)
              .setSearchKeyword(this.searchKey)
              .build();
          }
        }

        return resolve(this.usersRequest);
      } else if (userListMode === userListModeOptions["FRIENDS"]) {
        if (this.searchKey) {
          this.usersRequest = new CometChat.UsersRequestBuilder()
            .setLimit(30)
            .friendsOnly(true)
            .setSearchKeyword(this.searchKey)
            .build();
        } else {
          this.usersRequest = new CometChat.UsersRequestBuilder()
            .setLimit(30)
            .friendsOnly(true)
            .build();
        }

        return resolve(this.usersRequest);
      } else {
        return reject({ message: "Invalid filter for userlist" });
      }
    });
  };

  fetchNextUsers() {
    return this.usersRequest.fetchNext();
  }

  attachListeners(callback) {
    CometChat.addUserListener(
      this.userListenerId,
      new CometChat.UserListener({
        onUserOnline: (onlineUser) => {
          /* when someuser/friend comes online, user will be received here */
          callback(onlineUser);
        },
        onUserOffline: (offlineUser) => {
          /* when someuser/friend went offline, user will be received here */
          callback(offlineUser);
        },
      })
    );
  }

  removeListeners() {
    CometChat.removeUserListener(this.userListenerId);
  }
}
