import { useEffect, useState } from "react";
import "./EditModalMeal.scss";
import xcircle from "../../../images/x-circle.svg";
import { API, graphqlOperation } from "aws-amplify";
import { listMealLayoutManagers } from "../../../graphql/queries";
import {
  createMealPlanner,
  createMealPlannerDay,
} from "../../../graphql/mutations";
import AutocompleteComp from "../Autocomplete";
import { useNavigate } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { useAppSelector } from "../../../hooks";
interface IProps {
  handleClose: () => void;
  show: any;
  addCallback?: any;
  durations?: any;
  categories?: any;
  setTemplateLayoutData?: any;
  setCreatedMealPlannerData?: any;
  isDietPlan: any;
}

const EditModal = (props: IProps) => {
  const {
    handleClose,
    show,
    addCallback,
    durations,
    categories,
    setTemplateLayoutData,
    setCreatedMealPlannerData,
    isDietPlan,
  } = {
    ...props,
  };
  const [mealPlan, setMealplan] = useState("");
  const [duration, setDuration] = useState<any>("");
  const [layoutData, setLayoutData] = useState<any>([]);
  const [layoutNames, setLayoutNames] = useState<any>([]);
  const [selectedLayoutName, setSelectedLayoutName] = useState<any>("");

  const [error, setError] = useState<any>(false);
  const listTenant: any = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;
  const navigate = useNavigate();

  useEffect(() => {
    async function getLayoutManager() {
      const data: any = await API.graphql(
        graphqlOperation(listMealLayoutManagers)
      );

      setTemplateLayoutData &&
        setTemplateLayoutData(data?.data?.listMealLayoutManagers?.items);

      setLayoutData(data?.data?.listMealLayoutManagers?.items);

      let layoutNames = data?.data?.listMealLayoutManagers?.items.filter(
        (item) => {
          return (
            item.layoutName != null && item.layoutName !== "" && !item._deleted
          );
        }
      );

      setLayoutNames(
        layoutNames?.map((item) => {
          return item.layoutName;
        })
      );
    }
    getLayoutManager();
  }, []);

  const createMealPlannerData = async () => {
    const layoutId =
      layoutData && layoutData.length > 0
        ? layoutData.filter((item) => item.layoutName === selectedLayoutName)
        : null;

    if (mealPlan && layoutId && duration) {
      setError(false);
      const data: any = await API.graphql(
        graphqlOperation(createMealPlanner, {
          input: {
            planTitle: mealPlan,
            mealLayoutID: layoutId[0].id,
            numOfdays: duration,
            tenant:
              tenant === "ADMIN" ? ["ADMIN", "Trainers", "Clients"] : tenant,
          },
        })
      );

      for (let i = 1; i <= duration; i++) {
        try {
          await API.graphql(
            graphqlOperation(createMealPlannerDay, {
              input: {
                dayNum: i,
                mealPlannerId: data?.data?.createMealPlanner?.id,
                tenant:
                  tenant === "ADMIN"
                    ? ["ADMIN", "Trainers", "Clients"]
                    : tenant,
              },
            })
          );
        } catch (err) {
          console.log("---err", err);
          return err;
        }
      }

      const id = data?.data?.createMealPlanner?.id;

      if (isDietPlan) {
        navigate(`/library/dietplan/dietplandetail/${id}`);
      }

      setCreatedMealPlannerData &&
        setCreatedMealPlannerData(data["data"]["createMealPlanner"]["id"]);
    } else {
      setError(true);
    }
  };

  const showHideClassName = show
    ? "Editmodal display-block"
    : "Editmodal display-none";

  const Submit = async (e) => {
    e.preventDefault();
    trackPromise(createMealPlannerData());
  };

  const onInputChangeLayoutName = (name) => {
    setSelectedLayoutName(name);
  };

  return (
    <>
      <div className={showHideClassName}>
        <section className="mealCreate">
          <div className="mealHeading">
            Create Meal Template
            <img
              className="mealHeading__image"
              onClick={handleClose}
              src={xcircle}
              alt="X"
            />
          </div>

          <form onSubmit={Submit}>
            <div className="createProperties">
              <div style={{ paddingLeft: 15 }}>
                <span className="inputCreate">Meal Plan: </span>
              </div>
              <input
                type="text"
                className="contentBoxOne"
                name="mealplan"
                placeholder="Set your meal plan"
                onChange={(e) => {
                  setMealplan(e.target.value);
                }}
              />
            </div>

            <div className="createProperties">
              <div style={{ paddingLeft: 5 }}>
                <span className="inputCreate">No. of Days: </span>
              </div>
              <input
                type="number"
                className="contentBoxTwo"
                name="duration"
                value={duration}
                onChange={(e) => {
                  const inputValue = parseInt(e.target.value, 10);
                  if (!isNaN(inputValue) && inputValue >= 0) {
                    setDuration(inputValue);
                  }
                }}
              />
            </div>
            <div className="createProperties">
              <span className="inputCreate">Meal Layout: </span>
              <div style={{ marginLeft: 10, width: "65%", marginTop: -4 }}>
                {layoutNames && (
                  <AutocompleteComp
                    handleChange={onInputChangeLayoutName}
                    loadNames={layoutNames.sort()}
                  />
                )}
              </div>
            </div>

            {error ? (
              <div style={{ marginLeft: "15px" }}>
                <span className="error">
                  All the above fields should be filled
                </span>
              </div>
            ) : null}
            <div className="save">
              <button type="submit" className="submit">
                Add
              </button>
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default EditModal;
