import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { ReactComponent as PlusCircle } from "../../../../images/PlusCircle.svg";
import { ReactComponent as Plus } from "../../../../images/DietPlus.svg";
import { trackPromise } from "react-promise-tracker";
import WorkoutInfo from "./WorkoutInfo";
import debounce from "lodash.debounce";
import InputContainer from "../../../../component/InputContainer/InputContainer";
import InputComponent from "../../../../component/InputComponent/InputComponent";
import FtButton from "../../../Ft-Button/Ft-Button";
import {
  createWorkoutPlan,
  createWorkoutPlanDay,
  createWorkoutPlannerItemsMap,
  deleteWorkoutPlannerItemsMap,
  updateWorkoutPlan,
  updateWorkoutPlanDay,
  updateWorkoutPlannerItemsMap,
} from "../../../../graphql/mutations";

import { getCustomWorkoutPlan } from "../../../../graphql/customQueries";
import SearchWorkoutExercise from "./SearchWorkoutExercise";
import WorkoutItem from "./TempWorkoutItem/WorkoutItem";
import Switch from "../../../switch/switch";
import { Tooltip } from "@material-ui/core";
import Notes from "../../../../component/Notes/Notes";
import { useAppSelector } from "../../../../hooks";
import { getExercise } from "../../../../graphql/queries";

const WorkoutPlanDetail = () => {
  const [workoutlayout, setWorkoutLayout] = useState<any>([]);
  const [workouPlanIds, setWorkouPlanIds] = useState<any>({
    workoutPlanDayId: "",
    workoutId: "",
    sessionName: "",
  });
  const listTenant: any = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;
  const [dragItem, setDragItem] = useState<any>({
    index: null,
    day: null,
    sesionName: "",
  });
  const [dragOverItem, setDragOverItem] = useState<any>({
    index: null,
    day: null,
    sesionName: "",
  });

  const [imagePath, setImagePath]: any = useState();

  const [workoutInfo, setWorkoutInfo] = useState<any>({
    name: "",
    noOfDays: "",
  });
  const [workoutData, setWorkoutData] = useState<any>(null);
  const [openWorkoutExercise, setOpenWorkoutExercise] = useState<any>({
    open: false,
    sessionName: "",
    day: 0,
    sessionOrder: "",
    _version: 0,
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const goToWorkoutplan = () => {
    navigate(`/library/workoutplan`);
  };
  useEffect(() => {
    (async () => {
      const url = await Storage.get("");

      return setImagePath(url.split("?")[0]);
    })();
  }, []);

  const getWorkoutData = useCallback(async () => {
    try {
      const { data }: any = await API.graphql(
        graphqlOperation(getCustomWorkoutPlan, { id, limit: 1000 })
      );
      setWorkoutData(data.getWorkoutPlan);
      setWorkoutInfo({
        name: data.getWorkoutPlan.name,
        noOfDays: data.getWorkoutPlan.numOfdays,
      });

      const sortedWorkoutData = data.getWorkoutPlan.workoutPlanDay.items.sort(
        (a, b) => a.dayNum - b.dayNum
      ); // Sort by dayNum

      // Create the workout layout for all days
      const workoutLayout = sortedWorkoutData.map((dayData) => ({
        session: `Session 1`,
        day: dayData.dayNum,
        data: dayData,
      }));

      const transformedWorkoutLayout: any = workoutLayout.map((dayData) => {
        const { day, data } = dayData;
        const sessionData = {};
        const uniqueSessions: any = [];
        const Order = JSON.parse(data.sessionOrder);
        const d = day.toString();

        if (data.workoutPlannerItemsMap && data.workoutPlannerItemsMap.items) {
          data.workoutPlannerItemsMap?.items.forEach((item) => {
            const { session, _deleted } = item;
            if (!_deleted) {
              if (!sessionData[session]) {
                sessionData[session] = [];
                uniqueSessions.push(session);
              }

              const existingItem = sessionData[session].find(
                (existing) => existing.id === item.id
              );

              if (!existingItem) {
                sessionData[session].push(item);
              }
            }
          });
        }

        const filteredData = {};

        for (const session in sessionData) {
          if (sessionData.hasOwnProperty(session)) {
            if (Order) {
              const sessionOrder = Order[d][session];
              if (sessionOrder) {
                const filteredSessionData = sessionOrder.map((itemId) => {
                  const item = sessionData[session].find(
                    (item) => item.id === itemId
                  );

                  return item;
                });

                filteredData[session] = filteredSessionData;
              }
            }
          }
        }

        uniqueSessions.sort((a, b) => {
          const sessionNumberA = parseInt(a.replace("Session", ""));
          const sessionNumberB = parseInt(b.replace("Session", ""));
          return sessionNumberA - sessionNumberB;
        });

        if (!uniqueSessions.includes("Session 1")) {
          uniqueSessions.push("Session 1");
        }

        return {
          day,
          data: {
            ...data,
            workoutPlannerItemsMap: undefined,
          },
          sessionData: filteredData,
          sessions: uniqueSessions,
        };
      });
      console.log(transformedWorkoutLayout);

      setWorkoutLayout([...transformedWorkoutLayout, { data: "empty" }]);
    } catch (error) {
      // Handle error here
      console.error("Error fetching workout data:", error);
    }
  }, [id]);

  useEffect(() => {
    if (id) trackPromise(getWorkoutData());
  }, [id, getWorkoutData]);

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setWorkoutInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };
  const createNew = async () => {
    if (workoutInfo.name !== "" && workoutInfo.noOfDays !== "") {
      const number = Number(workoutInfo.noOfDays);
      const response: any = await API.graphql(
        graphqlOperation(createWorkoutPlan, {
          input: {
            name: workoutInfo.name,
            numOfdays: number,
            tenant:
              tenant === "ADMIN" ? ["ADMIN", "Trainers", "Clients"] : tenant,
          },
        })
      );

      for (let i = 1; i <= number; i++) {
        try {
          await API.graphql(
            graphqlOperation(createWorkoutPlanDay, {
              input: {
                dayNum: i,
                workoutPlanId: response.data.createWorkoutPlan.id,
                tenant:
                  tenant === "ADMIN"
                    ? ["ADMIN", "Trainers", "Clients"]
                    : tenant,
              },
            })
          );
        } catch (err) {
          console.log("---err", err);
          return err;
        }
      }

      navigate(
        `/library/workoutplan/workoutplandetail/${response.data.createWorkoutPlan.id}`
      );
    }
  };

  const handleSelectedItem = async (
    item,
    sessionName,
    day,
    sessionOrder,
    version
  ) => {
    try {
      console.log("try is getting called ");
      // Verify the exerciseId exists before proceeding
      const exerciseId = item;
      const exerciseData: any = await API.graphql(
        graphqlOperation(getExercise, { id: exerciseId })
      );
      console.log("exerciseData", exerciseData);

      if (!exerciseData.data.getExercise) {
        console.error(`Exercise with id ${exerciseId} does not exist.`);
        return;
      }

      const newOrder = JSON.parse(sessionOrder);
      console.log("newOrder", newOrder);

      // Ensure defaultValue is properly handled
      const defaultValue =
        item?.defaultValue || exerciseData.data.getExercise.defaultValue || "";

      console.log(
        "Calling createWorkoutPlannerItemsMap mutation",
        item,
        workouPlanIds,
        defaultValue
      );

      const res: any = await API.graphql(
        graphqlOperation(createWorkoutPlannerItemsMap, {
          input: {
            exerciseId: item,
            session: workouPlanIds.session,
            workoutPlanDayId: workouPlanIds.workoutPlanDayId,
            workoutPlanId: workouPlanIds.workoutId,
            defaultValue: defaultValue,
            tenant:
              tenant === "ADMIN" ? ["ADMIN", "Trainers", "Clients"] : [tenant],
          },
        })
      );

      console.log("res", res);

      // Manually fetch the exercise data and set it in the response
      const createdItem = res.data.createWorkoutPlannerItemsMap;
      const exercise = exerciseData.data.getExercise;

      if (!exercise) {
        console.error("Failed to fetch exercise data.");
        return;
      }

      createdItem.exercise = exercise;

      console.log("Created item:", createdItem);

      const item_id = createdItem.id;
      const structuredData = {
        [day]: {},
      };

      if (sessionOrder === null) {
        structuredData[day][sessionName] = [item_id];
      } else {
        if (!newOrder[day][sessionName]) {
          newOrder[day][sessionName] = [item_id];
        } else {
          newOrder[day][sessionName].push(item_id);
        }
      }

      const sessionOrderData =
        sessionOrder === null ? structuredData : newOrder;
      const sessionOrderString = JSON.stringify(sessionOrderData);

      await API.graphql(
        graphqlOperation(updateWorkoutPlanDay, {
          input: {
            id: workouPlanIds.workoutPlanDayId,
            sessionOrder: sessionOrderString,
            _version: version,
          },
        })
      );

      setOpenWorkoutExercise({
        open: !openWorkoutExercise?.open,
        sessionName: "",
      });

      trackPromise(getWorkoutData());
    } catch (error) {
      console.error("Error handling selected item:", error);
    }
  };

  const saveChanges = async () => {
    const number = Number(workoutInfo.noOfDays);

    const response: any = await API.graphql(
      graphqlOperation(updateWorkoutPlan, {
        input: {
          id: id,
          name: workoutInfo.name,
          numOfdays: number,
          _version: workoutData._version,
        },
      })
    );

    setWorkoutData(response.data.updateWorkoutPlan);
  };
  const createWorkoutPanDayButton = async () => {
    const response: any = await API.graphql(
      graphqlOperation(updateWorkoutPlan, {
        input: {
          id: id,
          numOfdays: workoutData.numOfdays + 1,
          _version: workoutData._version,
        },
      })
    );

    await API.graphql(
      graphqlOperation(createWorkoutPlanDay, {
        input: {
          dayNum: response.data.updateWorkoutPlan.numOfdays,
          workoutPlanId: workoutData.id,
          tenant: tenant[0],
        },
      })
    );
    trackPromise(getWorkoutData());
  };
  const toggleSwitch = async (day, data) => {
    try {
      const updatedDayOffValue = !data.dayOff;

      await API.graphql(
        graphqlOperation(updateWorkoutPlanDay, {
          input: {
            id: data.id,
            dayOff: updatedDayOffValue,
            _version: data._version,
          },
        })
      );
      trackPromise(getWorkoutData());
    } catch (error) {
      console.error("Error while updating workout plan:", error);
    }
  };
  const handleButtonClick = (day, id, workoutPlanId) => {
    setWorkoutLayout((prevLayout) => {
      const updatedLayout = [...prevLayout];
      const currentDayIndex = updatedLayout.findIndex(
        (item) => item.day === day
      );

      if (currentDayIndex !== -1) {
        const sessionArray = updatedLayout[currentDayIndex].sessions;
        const sessionNumbers = sessionArray
          .filter((sessionName) => sessionName.startsWith("Session"))
          .map((sessionName) =>
            parseInt(sessionName.replace("Session", ""), 10)
          )
          .sort((a, b) => a - b);

        const newSessionName = `Session ${sessionNumbers.length + 1}`;
        updatedLayout[currentDayIndex].sessions.push(newSessionName);
      }

      return updatedLayout;
    });
  };
  const onDelete = async (day, sessionName, idr, _version) => {
    const index = workoutlayout.findIndex((item) => item.day === day);
    if (index !== -1) {
      // Create a shallow copy of the workoutlayout array
      const updatedLayoutState = [...workoutlayout];

      const newOrder = JSON.parse(updatedLayoutState[index].data.sessionOrder);
      if (newOrder && newOrder[day][sessionName]) {
        // Filter the array to remove the specified ID
        newOrder[day][sessionName] = newOrder[day][sessionName].filter(
          (id) => id !== idr
        );

        // Check if the array is empty and remove the sessionName key if it is
        if (newOrder[day][sessionName].length === 0) {
          delete newOrder[day][sessionName];
        }
      }

      await API.graphql(
        graphqlOperation(deleteWorkoutPlannerItemsMap, {
          input: {
            id: idr,
            _version: _version,
          },
        })
      );

      await API.graphql(
        graphqlOperation(updateWorkoutPlanDay, {
          input: {
            id: updatedLayoutState[index].data.id,
            sessionOrder: JSON.stringify(newOrder),
            _version: updatedLayoutState[index].data._version,
          },
        })
      );
      trackPromise(getWorkoutData());
    }
  };

  const onChangeWorkoutPlannerItemsMap = async (data) => {
    await API.graphql(
      graphqlOperation(updateWorkoutPlannerItemsMap, {
        input: {
          ...data,
        },
      })
    );
    trackPromise(getWorkoutData());
  };
  const workoutUnitChange = debounce((info) => {
    trackPromise(onChangeWorkoutPlannerItemsMap(info));
  }, 2000);
  const dragStart = (e: any, index: number, day: number, sessionName: any) => {
    e.dataTransfer.setData("text/plain", index.toString());

    setDragItem({
      index: index,
      day: day,
      sesionName: sessionName,
    });
  };
  // const dragEnter = (e: any, index: number, day: number, sessionName: any) => {
  //   e.preventDefault();

  //   const dragIndex = parseInt(e.dataTransfer.getData("text/plain"));
  //   setDragOverItem({
  //     index: index,
  //     day: day,
  //     sesionName: sessionName,
  //   });
  // };
  const onChangeworkoutPlanDayData = async (data) => {
    await API.graphql(
      graphqlOperation(updateWorkoutPlanDay, {
        input: {
          id: data.id,
          sessionOrder: JSON.stringify(data.sessionOrder),
          _version: data._version,
        },
      })
    );
    trackPromise(getWorkoutData());
  };
  const updateDndData = debounce((info) => {
    trackPromise(onChangeworkoutPlanDayData(info));
  }, 1000);

  const drop = (e, day, sessionData, sessionName) => {
    e.preventDefault();
    if (dragItem.index !== dragOverItem.index) {
      const copyListItems = [...sessionData[sessionName]];
      const dragItemContent = copyListItems[dragItem.index!];
      copyListItems.splice(dragItem.index!, 1);
      copyListItems.splice(dragOverItem.index!, 0, dragItemContent);

      const index = workoutlayout.findIndex((item) => item.day === day);
      if (index !== -1) {
        // Create a shallow copy of the workoutlayout array
        const updatedLayoutState = [...workoutlayout];
        updatedLayoutState[index].sessionData[sessionName] = copyListItems;

        // Update the state with the new layout
        setWorkoutLayout(updatedLayoutState);
        const structuredData = {
          [day]: {},
        };

        const data = updatedLayoutState[index].sessionData;

        for (const session in data) {
          if (data.hasOwnProperty(session)) {
            const sessionData = data[session];
            structuredData[day][session] = sessionData.map((item) => item.id);
          }
        }

        // Update the API data with the new order
        updateDndData({
          id: updatedLayoutState[index].data.id,
          sessionOrder: structuredData,
          _version: updatedLayoutState[index].data._version,
        });
      }
    }

    setDragItem({
      index: null,
      day: null,
      sesionName: "",
    });
    setDragOverItem({
      index: null,
      day: null,
      sesionName: "",
    });
  };

  return (
    <>
      <button className="backtodietplan" onClick={goToWorkoutplan}>
        <p> &#60; Back to Workout Plan</p>
      </button>
      {openWorkoutExercise.open ? (
        <SearchWorkoutExercise
          openWorkout={openWorkoutExercise.open}
          setOpenWorkoutExercise={setOpenWorkoutExercise}
          onSelectedItem={handleSelectedItem}
          sessionName={openWorkoutExercise.sessionName}
          day={openWorkoutExercise.day}
          sessionOrder={openWorkoutExercise.sessionOrder}
          _version={openWorkoutExercise._version}
          type="workoutPlanDetail"
        />
      ) : null}
      <div style={{ display: "flex", padding: "35px" }}>
        <div>
          <div>
            <InputContainer
              label="Enter the name of the plan"
              style={{ width: "fit-content", display: "flex", gap: "10px" }}
              labelStyle={{
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "12px",
                letterSpacing: "0.025em",
                textAlign: "left",
                color: "#34465e",
              }}
            >
              <InputComponent
                value={workoutInfo.name}
                name="name"
                className="workoutName"
                onChange={onInputChange}
                disabled={
                  workoutData == null || workoutData?.tenant.includes(tenant)
                    ? false
                    : true
                }
              />
            </InputContainer>
          </div>
        </div>
        {!workoutData?.numOfdays ? (
          <div>
            <InputContainer
              label="No Of Days"
              style={{ width: "fit-content", display: "flex", gap: "10px" }}
            >
              <InputComponent
                value={workoutInfo.noOfDays}
                name="noOfDays"
                className="workoutName"
                onChange={onInputChange}
              />
            </InputContainer>
          </div>
        ) : (
          ""
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          {!workoutData && (
            <FtButton
              className="createpackagebutton"
              border="1px solid #109CF1"
              color="#109CF1"
              height="40px"
              onClick={createNew}
              radius="5px"
              children="Create New"
            />
          )}
          {(workoutData && workoutData?.tenant === null) ||
          tenant === workoutData?.tenant[0] ? (
            <FtButton
              className="createpackagebutton"
              border="1px solid #109CF1"
              color="#109CF1"
              height="40px"
              onClick={saveChanges}
              radius="5px"
              children="Save"
            />
          ) : null}
        </div>
      </div>
      <section className="DietPlanDetail">
        <div
          className={`DietPlanDetail__container  
        ${dragItem.day ? "drag" : ""}
        
        `}
        >
          {workoutlayout?.map(({ day, data, sessionData, sessions }, index) => {
            if (data !== "empty") {
              return (
                <div
                  key={index}
                  className={`DietPlanDetail__container__card ${
                    dragItem.day === day ? "" : ""
                  } `}
                  style={{ position: "relative" }}
                >
                  <div
                    className="DietPlanDetail__container__card__dietday"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      position: "relative",
                    }}
                  >
                    <div style={{ display: "flex", flexBasis: "60%" }}>
                      <div>
                        <div className="DietPlanDetail__container__card__dietday__day">
                          DAY
                        </div>
                        <div className="DietPlanDetail__container__card__dietday__duration">
                          {day}
                        </div>
                      </div>
                    </div>

                    {workoutData.tenant === null ||
                    tenant === workoutData.tenant[0] ||
                    (tenant === "ADMIN" &&
                      workoutData.tenant?.includes("ADMIN")) ? (
                      <div style={{ display: "flex" }}>
                        <Tooltip title="Click here to toggle day off">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            Rest day
                            <Switch
                              rounded={true}
                              isToggle={data.dayOff}
                              onToggle={() => {
                                toggleSwitch(day, data);
                              }}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    ) : null}

                    <Notes
                      text={data?.dayNotes}
                      onLeave={async (e) => {
                        await API.graphql(
                          graphqlOperation(updateWorkoutPlanDay, {
                            input: {
                              id: data.id,
                              dayNotes: e.target.innerText,
                              _version: data._version,
                            },
                          })
                        );
                        trackPromise(getWorkoutData());
                      }}
                    />
                  </div>
                  {sessions.map((sessionName, index) => {
                    const sessionItems = sessionData[sessionName] || []; // Get the items for the current session

                    return (
                      <div
                        key={index}
                        className="DietPlanDetail__container__card__dietbox cursor"
                        style={{ position: "relative" }}
                      >
                        {data.dayOff ? (
                          <div className="backdrop-filter">
                            Muscles grow while you rest
                          </div>
                        ) : null}
                        <div
                          className={`DietPlanDetail__container__card__dietbox__dietcard  
                        
                         ${
                           day === dragItem.day &&
                           sessionName === dragItem.sesionName
                             ? "blurcard"
                             : ""
                         }
                         `}
                        >
                          <div className="hide-on-drag"></div>
                          <div
                            className={`DietPlanDetail__container__card__dietbox__dietcard__mealtype`}
                          >
                            <WorkoutInfo
                              key={sessionName}
                              label={sessionName}
                            />

                            {workoutData.tenant === null ||
                            tenant === workoutData.tenant[0] ||
                            (tenant === "ADMIN" &&
                              workoutData.tenant?.includes("ADMIN")) ? (
                              <button
                                className="addSymbol"
                                onClick={() => {
                                  setOpenWorkoutExercise({
                                    open: true,
                                    sessionName: sessionName,
                                    day: day,
                                    sessionOrder: data.sessionOrder,
                                    _version: data._version,
                                  });
                                  setWorkouPlanIds({
                                    workoutPlanDayId: data.id,
                                    workoutId: data.workoutPlanId,
                                    session: sessionName,
                                  });
                                }}
                              >
                                <PlusCircle />
                              </button>
                            ) : null}
                          </div>
                          <div>
                            {sessionItems.length > 0 &&
                              sessionItems
                                .filter((item) => !item?._deleted)
                                .map((item, index) => {
                                  return (
                                    <div
                                      key={item?.id}
                                      onDragStart={(e) =>
                                        dragStart(e, index, day, sessionName)
                                      }
                                      // onDragEnter={(e) =>
                                      //   dragEnter(e, index, day, sessionName)
                                      // }
                                      onDragEnd={(e) =>
                                        drop(e, day, sessionData, sessionName)
                                      }
                                      draggable
                                    >
                                      {item?.exercise && (
                                        <WorkoutItem
                                          key={item.id}
                                          name={item?.exercise?.name}
                                          imageData={
                                            imagePath + item?.exercise?.imageUrl
                                          }
                                          data={item}
                                          type={"workoutplan"}
                                          onDelete={() =>
                                            onDelete(
                                              day,
                                              sessionName,
                                              item.id,
                                              item._version
                                            )
                                          }
                                          onChange={workoutUnitChange}
                                        />
                                      )}
                                    </div>
                                  );
                                })}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {workoutData?.tenant === null ||
                  tenant === workoutData?.tenant[0] ? (
                    <div className="workoutEmptyBox">
                      <button
                        className="DietPlanDetail__container__empty__image"
                        onClick={() =>
                          handleButtonClick(day, data.id, data.workoutPlanId)
                        }
                      >
                        <Plus />
                      </button>
                    </div>
                  ) : null}
                </div>
              );
            } else {
              return (
                <div key={index}>
                  {workoutData.tenant.includes(tenant) ? (
                    <div
                      key={index}
                      className="DietPlanDetail__container__card"
                    >
                      <div className="workoutEmptyBox">
                        <button
                          className="DietPlanDetail__container__empty__image"
                          onClick={createWorkoutPanDayButton}
                        >
                          <Plus />
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            }
          })}
        </div>
      </section>
    </>
  );
};
export default WorkoutPlanDetail;
