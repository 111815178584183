import React, { useState, useRef } from "react";
import Image from "mui-image";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import DeleteComponent from "../../../../component/Delete/Delete";
import WorkoutUnits from "../../../../component/WorkoutUnits/WorkoutUnits";
import MinHeightTextarea from "../../../../component/MUITextAreaAutoResize/TextAreaAuto";
import { useAppSelector } from "../../../../hooks";
import { useDrag, useDrop } from "react-dnd";

const WorkoutItem = ({
  index,
  name,
  imageData,
  type,
  data,
  onDelete,
  onChange,
  moveWorkoutItem,
}) => {
  const ref = useRef(null);
  const listTenant = useAppSelector((state) => state.tenant);
  const tenants = listTenant?.tenant?.currentTenant;
  const tenant = data.tenant;
  const [isDeletes, setIsDeletes] = useState(false);
  const safeParseJSON = (value) => {
    try {
      return JSON.parse(value);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {};
    }
  };

  const [value, setValue] = useState(
    !!data?.defaultValue
      ? safeParseJSON(data?.defaultValue)
      : safeParseJSON(data?.exercise?.defaultValue)
  );
  const [{ isDragging }, dragRef] = useDrag({
    type: "workout",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: "workout",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (hoverIndex === undefined) {
        return;
      }

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      if (!hoverBoundingRect) {
        return;
      }

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      if (moveWorkoutItem) {
        moveWorkoutItem(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });

  const dragDropRef = (el) => {
    dragRef(el);
    dropRef(el);
    ref.current = el;
  };

  return (
    <div ref={dragDropRef} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Paper
        sx={{
          p: 2,
          margin: "15px 0",
          padding: "10px !important",
          maxWidth: 500,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
        className="mealItem"
      >
        <Grid container spacing={2}>
          <Grid item xs={14} sm container>
            <Grid item spacing={1}>
              <ButtonBase
                sx={{
                  width: 70,
                  height: 70,
                  borderRadius: 5,
                }}
              >
                <Image
                  alt={name}
                  fit="contain"
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                  }}
                  src={imageData}
                />
              </ButtonBase>
            </Grid>
            <Grid
              item
              xs={8}
              container
              direction="column"
              style={{
                padding: "0 5px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                noWrap
                style={{
                  textOverflow: "ellipsis",
                  fontSize: "0.9rem",
                }}
              >
                {name}
              </Typography>
              <WorkoutUnits
                name="unitValue"
                handleChange={(val) => {
                  setValue(val);
                  onChange({
                    id: data.id,
                    _version: data._version,
                    defaultValue: JSON.stringify(val),
                  });
                }}
                value={value}
                readOnly={
                  type === "calendar"
                    ? false
                    : tenant === null ||
                      tenants === tenant[0] ||
                      (tenants === "ADMIN" && tenant?.includes("ADMIN"))
                    ? false
                    : true
                }
              />
              <div>
                <MinHeightTextarea
                  content={
                    data.notes !== ""
                      ? data.notes
                      : data?.exercise?.additionalNotes
                  }
                  onChange={(e) => {
                    onChange({
                      id: data.id,
                      _version: data._version,
                      notes: e.target.value,
                    });
                  }}
                  readOnly={
                    type === "calendar"
                      ? false
                      : tenant === null ||
                        tenants === tenant[0] ||
                        (tenants === "ADMIN" && tenant?.includes("ADMIN"))
                      ? false
                      : true
                  }
                />
              </div>
            </Grid>

            <Grid
              item
              xs={1}
              direction={"column"}
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                {type === "calendar" ? (
                  <DeleteComponent
                    deletes={isDeletes}
                    setDeletes={setIsDeletes}
                    onDeletes={onDelete}
                  />
                ) : tenant === null ||
                  tenants === tenant[0] ||
                  (tenants === "ADMIN" && tenant?.includes("ADMIN")) ? (
                  <DeleteComponent
                    deletes={isDeletes}
                    setDeletes={setIsDeletes}
                    onDeletes={onDelete}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default WorkoutItem;

// 👇 bellow is the typtscript code avided for type error for ref on div

// import React, { useState, useRef, useCallback, useEffect } from "react";
// import Image from "mui-image";
// import Grid from "@mui/material/Grid";
// import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";
// import ButtonBase from "@mui/material/ButtonBase";
// import DeleteComponent from "../../../../component/Delete/Delete";
// import WorkoutUnits from "../../../../component/WorkoutUnits/WorkoutUnits";
// import MinHeightTextarea from "../../../../component/MUITextAreaAutoResize/TextAreaAuto";
// import { useAppSelector } from "../../../../hooks";
// import { useDrag, useDrop } from "react-dnd";

// type WorkoutItemProps = {
//   name: string;
//   imageData: string;
//   data: any;
//   onDelete: any;
//   onChange: (any) => void;
//   type: string;
//   index?: number;
//   moveWorkoutItem?: (dragIndex: any, hoverIndex: any) => void;
// };

// const WorkoutItem: React.FC<WorkoutItemProps> = ({
//   index,
//   name,
//   imageData,
//   type,
//   data,
//   onDelete,
//   onChange,
//   moveWorkoutItem,
// }) => {
//   const ref = useRef<HTMLDivElement>(null);
//   const listTenant = useAppSelector((state) => state.tenant);
//   const tenants = listTenant?.tenant?.currentTenant;
//   const tenant = data.tenant;
//   const [isDeletes, setIsDeletes] = useState<any>(false);
//   const [value, setValue] = useState(
//     data.defaultValue
//       ? JSON.parse(data?.defaultValue)
//       : JSON.parse(data?.exercise?.defaultValue)
//   );
//   const [{ isDragging }, dragRef] = useDrag({
//     type: "workout",
//     item: { index },
//     collect: (monitor) => ({
//       isDragging: monitor.isDragging(),
//     }),
//   });

//   const [, dropRef] = useDrop({
//     accept: "workout",
//     hover(item: any, monitor) {
//       if (!ref.current) {
//         return;
//       }

//       const dragIndex = item.index;
//       const hoverIndex = index;

//       if (hoverIndex === undefined) {
//         return;
//       }

//       if (dragIndex === hoverIndex) {
//         return;
//       }

//       const hoverBoundingRect = ref.current?.getBoundingClientRect();
//       if (!hoverBoundingRect) {
//         return;
//       }

//       const hoverMiddleY =
//         (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
//       const clientOffset = monitor.getClientOffset();
//       if (!clientOffset) {
//         return;
//       }

//       const hoverClientY = clientOffset.y - hoverBoundingRect.top;

//       if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
//         return;
//       }

//       if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
//         return;
//       }
//       if (moveWorkoutItem) {
//         moveWorkoutItem(dragIndex, hoverIndex);
//         item.index = hoverIndex;
//       }
//     },
//   });

//   const dragDropRef = dragRef(dropRef(ref));

//   console.log("dragDropRef", dragDropRef);
//   return (
//     <div ref={dragDropRef} style={{ opacity: isDragging ? 0.5 : 1 }}>
//       <Paper
//         sx={{
//           p: 2,
//           margin: "15px 0",
//           padding: "10px !important",
//           maxWidth: 500,

//           flexGrow: 1,
//           backgroundColor: (theme) =>
//             theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//         }}
//         className="mealItem"
//       >
//         <Grid container spacing={2}>
//           <Grid item xs={14} sm container>
//             <Grid item spacing={1}>
//               <ButtonBase
//                 sx={{
//                   width: 70,
//                   height: 70,
//                   borderRadius: 5,
//                 }}
//               >
//                 <Image
//                   alt={name}
//                   fit="contain"
//                   style={{
//                     width: "100%",
//                     borderRadius: "5px",
//                   }}
//                   src={imageData}
//                 />
//               </ButtonBase>
//             </Grid>
//             <Grid
//               item
//               xs={8}
//               container
//               direction="column"
//               style={{
//                 padding: "0 5px",
//                 display: "flex",
//                 alignItems: "flex-start",
//               }}
//             >
//               <Typography
//                 gutterBottom
//                 variant="subtitle1"
//                 component="div"
//                 noWrap
//                 style={{
//                   textOverflow: "ellipsis",
//                   fontSize: "0.9rem",
//                 }}
//               >
//                 {name}
//               </Typography>
//               {/* Uncomment this and pass selectedType, defaultValue */}
//               <WorkoutUnits
//                 name="unitValue"
//                 handleChange={(val) => {
//                   setValue(val); // To show the change in text box
//                   onChange({
//                     id: data.id,
//                     _version: data._version,
//                     defaultValue: JSON.stringify(val),
//                   });
//                 }}
//                 value={value}
//                 readOnly={
//                   type === "calendar"
//                     ? false
//                     : tenant === null ||
//                       tenants === tenant[0] ||
//                       (tenants === "ADMIN" && tenant?.includes("ADMIN"))
//                     ? false
//                     : true
//                 }
//               />
//               <div>
//                 <MinHeightTextarea
//                   content={
//                     data.notes !== ""
//                       ? data.notes
//                       : data.exercise.additionalNotes
//                   }
//                   onChange={(e) => {
//                     onChange({
//                       id: data.id,
//                       _version: data._version,
//                       notes: e.target.value,
//                     });
//                   }}
//                   readOnly={
//                     type === "calendar"
//                       ? false
//                       : tenant === null ||
//                         tenants === tenant[0] ||
//                         (tenants === "ADMIN" && tenant?.includes("ADMIN"))
//                       ? false
//                       : true
//                   }
//                 />
//               </div>
//             </Grid>

//             <Grid
//               item
//               xs={1}
//               direction={"column"}
//               container
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//               }}
//             >
//               <Grid item>
//                 {type === "calendar" ? (
//                   <DeleteComponent
//                     deletes={isDeletes}
//                     setDeletes={setIsDeletes}
//                     onDeletes={onDelete}
//                   />
//                 ) : tenant === null ||
//                   tenants === tenant[0] ||
//                   (tenants === "ADMIN" && tenant?.includes("ADMIN")) ? (
//                   <DeleteComponent
//                     deletes={isDeletes}
//                     setDeletes={setIsDeletes}
//                     onDeletes={onDelete}
//                   />
//                 ) : null}
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Paper>
//     </div>
//   );
// };
// export default WorkoutItem;
