interface ITab {
  label: String;
  onClick: (string) => void;
  activeTab?: string | null;
}
const Tab = (props: ITab) => {
  const onClick = () => {
    const { label, onClick } = props;
    onClick(label);
  };

  return (
    <li
      className={`tab-list-item ${
        props.activeTab === props.label ? " tab-list-active" : ""
      }`}
      onClick={onClick}
    >
      {props.label}
    </li>
  );
};
export default Tab;
