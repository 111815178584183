import { useCallback, useEffect, useState } from "react";
import { ReactComponent as NoMeals } from "../../../images/Meal-Plan.svg";
import TabContent from "../../../component/Tabs/TabContent";
import "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CalenderStrip from "../../ClientDetail/CalendarStripe/CalenderStrip";
import "./MealsSchedule.scss";
import DeleteComponent from "../../../component/Delete/Delete";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { getUserCalendarMealCustom } from "../../../graphql/customQueries";
import {
  deleteUserCalendarMealItems,
  updateUserCalendarMealItems,
} from "../../../graphql/mutations";
import { deleteCalendarMeals } from "../../../graphql/mutations";

import {
  getCalculateFoodServings,
  getCalculateRecipeServings,
  getGramsPerServings,
  permaterConfiguredServing,
  sumOfUnits,
  getMoreFieldsRecipeSum,
} from "../../../Utils";
import { debounce, IconButton, Tooltip } from "@material-ui/core";
import { trackPromise } from "react-promise-tracker";
import QuickInfo from "../../../component/QuickInfo/QuickInfo";
import MealInfo from "../MealInfo/MealInfo";
import MealItem from "../MealItem/MealItem";
import { CopyAll } from "@mui/icons-material";
import CopyMealsModal from "../../../component/CopyMealsModal/CopyMealsModal";
import SearchFoodRecipe from "../../ClientDetail/SearchFoodRecipe/SearchFoodRecipe";
import EditModalLoadTemplate from "../../ClientDetail/EditModalLoadTemplate/EditModalLoadTemplate";

const MealsScheduler = (props) => {
  const [calendar, setCalendar] = useState<any>();
  const [mealByTypes, setMealByTypes] = useState<any>({});
  const [isDeletes, setIsDeletes] = useState<any>(false);
  const [imagePath, setImagePath]: any = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [totalCaloriesAndId, setTotalCaloriesAndId] = useState<any>();
  const [openFoodRecipe, setOpenFoodRecipe] = useState(false);
  const [layoutOrder, setLayoutOrder] = useState<any>([]);
  const [load, setLoad] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [dateFromCalenderStrip, setDateFromCalenderStrip] = useState("");
  useEffect(() => {
    const currentDate = new Date();
    setStartDate(new Date(currentDate));
    setDateFromCalenderStrip(
      moment(currentDate, "DD-MM-YYYY").format("YYYY-MM-DD")
    );
  }, []);
  useEffect(() => {
    trackPromise(getCalendarData());
    setStartDate(new Date(dateFromCalenderStrip));
  }, [dateFromCalenderStrip, props.userId, load]);
  useEffect(() => {
    (async () => {
      const url = await Storage.get("");

      return setImagePath(url.split("?")[0]);
    })();
  }, []);

  async function getCalendarData() {
    const idDate = props.userId + dateFromCalenderStrip;
    try {
      const getUserCalendarMealResponse: any = await API.graphql(
        graphqlOperation(getUserCalendarMealCustom, {
          idDate: idDate,
        })
      );

      const dailyLayout =
        getUserCalendarMealResponse?.data?.getUserCalendarMeal || {};

      const dailyMeals =
        getUserCalendarMealResponse?.data?.getUserCalendarMeal
          ?.UserCalendarMealItems?.items || [];

      setLayoutOrder(dailyLayout || {});
      let combineData = [...dailyMeals];

      const filteredItems = combineData
        .filter((item) => !item?._deleted)
        .map((item) => {
          if (item?.food) {
            const result = getCalculateFoodServings(
              item.food,
              item.servingSize,
              item.servingDescription
            );
            item = { ...item, ...result };
          } else if (item?.recipe) {
            const result = getCalculateRecipeServings(item.recipe); // Calcualte sum of ingredients

            const sumOfUnitsObj = sumOfUnits(result); //item = { ...item, ingredients?.items :result };
            item["recipe"]["ingredients"]["items"] = result; // Replace the items array with updated items with calculatedGrams and calories of individual ingredient
            item.recipe = { ...item.recipe, ...sumOfUnitsObj }; // calculate the sum of grams and calories and store in recipe
            const requiredGramsPerServe = getGramsPerServings(
              item?.recipe,
              item?.servingDescription,
              item?.servingSize
            );

            const requiredCalories = permaterConfiguredServing(
              item?.recipe?.calculatedGrams || 0,
              requiredGramsPerServe,
              item?.recipe?.calories
            );
            const requiredProtien = permaterConfiguredServing(
              item?.recipe?.calculatedGrams || 0,
              requiredGramsPerServe,
              item?.recipe?.protien
            );
            const requiredCarbs = permaterConfiguredServing(
              item?.recipe?.calculatedGrams || 0,
              requiredGramsPerServe,
              item?.recipe?.carbs
            );
            const requiredFats = permaterConfiguredServing(
              item?.recipe?.calculatedGrams || 0,
              requiredGramsPerServe,
              item?.recipe?.fats
            );
            const moreFields = getMoreFieldsRecipeSum(
              item?.recipe.ingredients,
              item?.recipe?.calculatedGrams || 0,
              requiredGramsPerServe
            );

            const param = {
              calculatedGrams: requiredGramsPerServe,
              calories: requiredCalories,
              protien: requiredProtien,
              carbs: requiredCarbs,
              fats: requiredFats,
              moreFields: moreFields,
            };
            item = { ...item, ...param };
          }

          return item;
        });
      console.log("filteredItems", filteredItems);

      setCalendar(filteredItems);

      const totalCalories = sumOfUnits(filteredItems);
      setTotalCaloriesAndId({
        ...totalCalories,
        userCalendarId:
          getUserCalendarMealResponse?.data?.getUserCalendarMeal.id,
      });
      props.calendarData(filteredItems);
    } catch (e) {
      setCalendar([]);
      setLayoutOrder([]);
      props.calendarData([]);
    }
  }

  const onCalenderDelete = async (item) => {
    await API.graphql(
      graphqlOperation(deleteUserCalendarMealItems, {
        input: { id: item.id, _version: item._version },
      })
    );

    trackPromise(getCalendarData());
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("layoutOrder?.mealLayout?.layout", layoutOrder);
    const mealTypesOrder = layoutOrder?.mealLayout?.layout || [];

    const mealsByTypeData = calendar?.reduce((acc, item) => {
      const mealId = item.meal;

      if (!acc[mealId]) {
        acc[mealId] = [];
      }

      acc[mealId].push(item);
      return acc;
    }, {});

    const orderedMealsByTypeData: any = {};
    mealTypesOrder.forEach((mealType) => {
      if (mealsByTypeData[mealType]) {
        orderedMealsByTypeData[mealType] = mealsByTypeData[mealType];
      } else {
        orderedMealsByTypeData[mealType] = [];
      }
    });

    if (Object.keys(orderedMealsByTypeData).length > 0) {
      setMealByTypes(orderedMealsByTypeData);
    } else {
      setMealByTypes(mealsByTypeData);
    }
  }, [calendar]);

  const refreshFetchMeals = (value) => {
    setLoad(!load);
  };

  const updateCalanderData = async (item, amount, unit, index, _version) => {
    await API.graphql(
      graphqlOperation(updateUserCalendarMealItems, {
        input: {
          id: index,
          _version: _version,
          servingSize: amount,
          servingDescription: unit,
        },
      })
    );
    getCalendarData();
  };

  const debouncedChangeHandler = useCallback(
    debounce(updateCalanderData, 500),
    [calendar]
  );
  const [foodData, setFoodData] = useState<any>();
  const [showLoad, setShowLoad] = useState(false);
  const openFoodModal = async (day, layout, id) => {
    setFoodData({ day, layout });
    setOpenFoodRecipe(true);
  };
  const hideModalLoad = () => {
    setShowLoad(false);
    setLoad(!load);
  };

  const onDayMealsDelete = async (meals) => {
    const mealIds = meals.map((item) => item.id);

    await API.graphql(
      graphqlOperation(deleteCalendarMeals, {
        calendarIds: JSON.stringify(mealIds),
      })
    );
    trackPromise(getCalendarData());
  };
  const onTypeMealsDelete = async (meals) => {
    const mealIds = meals.map((item) => item.id);

    await API.graphql(
      graphqlOperation(deleteCalendarMeals, {
        calendarIds: JSON.stringify(mealIds),
      })
    );
    trackPromise(getCalendarData());
  };
  return (
    <div className="mealsPlanner">
      <div className="mealsPlannerContainer">
        <div className="mealsCalendar">
          {
            <CalenderStrip
              topItems={true}
              CalenderStripDate={setDateFromCalenderStrip}
              userId={props.userId}
              load={load}
              setLoad={setLoad}
            />
          }
        </div>
        {showCopyModal ? (
          <CopyMealsModal
            show={showCopyModal}
            onClose={() => {
              setShowCopyModal(false);
            }}
            data={{
              calendar,
              layoutId: layoutOrder.mealLayoutID,
              userId: props.userId,
              selectedDate: dateFromCalenderStrip,
            }}
            date={dateFromCalenderStrip}
            title={"Copy Day"}
            type="copyDay"
          />
        ) : null}
        {showLoad && (
          <EditModalLoadTemplate
            show={showLoad}
            handleClose={hideModalLoad}
            userId={props.userId}
            currentDate={startDate}
          />
        )}
        <div className="mealsSchedule">
          <div className="mealsTab">
            <>
              <div className="mealsGradient" style={{ padding: 10 }}></div>

              {mealByTypes && Object.keys(mealByTypes).length > 0 ? (
                <div className="mealTop">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <QuickInfo
                      name={""}
                      calories={totalCaloriesAndId.calories}
                      carbs={totalCaloriesAndId.carbs}
                      protein={totalCaloriesAndId.protien}
                      fats={totalCaloriesAndId.fats}
                      description={""}
                    >
                      <label
                        className="dietinfohover"
                        style={{ padding: 10 }}
                      >{`Total Calories : ${totalCaloriesAndId.calories.toFixed(
                        2
                      )} kcal`}</label>
                    </QuickInfo>

                    <Tooltip title="Click here to copy this day to other days!">
                      <IconButton
                        onClick={() => {
                          setShowCopyModal(true);
                        }}
                      >
                        <CopyAll fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Tooltip title="Click here to delete the meals of the day!">
                    <DeleteComponent
                      deletes={isDeletes}
                      setDeletes={setIsDeletes}
                      onDeletes={() => onDayMealsDelete(calendar)}
                    />
                  </Tooltip>
                  {/* <button className="searchFood" onClick={onSearchOrAdd}>
                      <Search1 />
                      Search / Add foods
                    </button> */}
                  {openFoodRecipe ? (
                    <SearchFoodRecipe
                      openFood={openFoodRecipe}
                      setOpenFoodRecipe={setOpenFoodRecipe}
                      day={dateFromCalenderStrip}
                      choosedCategory={{
                        type: foodData.layout,
                        userCalendarId: totalCaloriesAndId.userCalendarId,
                      }}
                      createdMealPlanner={props.userId}
                      type="addCalendar"
                      setDietPlanData={refreshFetchMeals}
                    />
                  ) : null}
                </div>
              ) : null}

              <div className="mealBox">
                {/* @ts-ignore */}
                {mealByTypes && Object?.keys(mealByTypes)?.length > 0 ? (
                  Object?.keys(mealByTypes)?.map((mealByType, id) => {
                    return (
                      <div
                        key={id}
                        className="mealBox1"
                        style={{ padding: 30, borderRadius: 5, margin: 30 }}
                      >
                        <TabContent key={id} label={mealByType}>
                          <div style={{ display: "flex" }}>
                            <MealInfo
                              setOpenFoodRecipe={() => {
                                openFoodModal(
                                  dateFromCalenderStrip,
                                  mealByType,
                                  mealByTypes
                                );
                              }}
                              label={mealByType}
                              data={mealByTypes}
                            />
                            <DeleteComponent
                              deletes={isDeletes}
                              setDeletes={setIsDeletes}
                              onDeletes={() =>
                                onTypeMealsDelete(mealByTypes[mealByType])
                              }
                            />
                          </div>

                          <div className="MealColumn">
                            {mealByTypes[mealByType] &&
                              mealByTypes[mealByType].length > 0 &&
                              mealByTypes[mealByType]?.map((items, id) => {
                                if (items?.recipe && !items?._deleted) {
                                  return (
                                    <div className="childColumn" key={id}>
                                      <QuickInfo
                                        name={items.recipe.title}
                                        serving={items.servingDescription}
                                        amount={items.servingSize}
                                        calories={items.calories}
                                        carbs={items.carbs}
                                        protein={items.protien}
                                        fats={items.fats}
                                        description={""}
                                      >
                                        <MealItem
                                          data={items}
                                          grams={items.calculatedGrams}
                                          debouncedChangeHandler={debouncedChangeHandler.bind(
                                            this,
                                            items
                                          )}
                                          servingSize={items.servingSize}
                                          servingDescription={
                                            items?.servingDescription
                                          }
                                          id={items?.id}
                                          _version={items?._version}
                                          name={items.recipe.title}
                                          imageData={
                                            imagePath + items?.recipe?.imageUrl
                                          }
                                          type="copyFood"
                                          onDelete={() => {
                                            onCalenderDelete(items);
                                          }}
                                          userId={items.userID}
                                          date={dateFromCalenderStrip}
                                        />
                                      </QuickInfo>
                                    </div>
                                  );
                                }
                                if (items?.food && !items?._deleted) {
                                  return (
                                    <QuickInfo
                                      name={items.food.name}
                                      serving={items.servingDescription}
                                      amount={items.servingSize}
                                      calories={items.calories}
                                      carbs={items.carbs}
                                      protein={items.protien}
                                      fats={items.fats}
                                      description={""}
                                    >
                                      <MealItem
                                        data={items}
                                        grams={items.calculatedGrams}
                                        servingSize={items.servingSize}
                                        servingDescription={
                                          items?.servingDescription
                                        }
                                        id={items?.id}
                                        _version={items?._version}
                                        debouncedChangeHandler={debouncedChangeHandler.bind(
                                          this,
                                          items
                                        )}
                                        type="copyFood"
                                        name={items.food.name}
                                        imageData={
                                          imagePath + items?.food?.imageUrl
                                        }
                                        onDelete={() => {
                                          onCalenderDelete(items);
                                        }}
                                        userId={items.userID}
                                        date={dateFromCalenderStrip}
                                      />
                                    </QuickInfo>
                                  );
                                }
                                return null;
                              })}
                          </div>
                        </TabContent>
                      </div>
                    );
                  })
                ) : (
                  <div className="noMeals">
                    <NoMeals />
                    {/* <button
                      className="searchMealButton"
                      onClick={showModalLoad}
                    >
                      Load Diet plan
                    </button> */}
                  </div>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MealsScheduler;
