import {
  listCustomFoods,
  updateCustomFood,
} from "../../../graphql/customQueries";
import {
  createFavorite,
  createFood,
  deleteFood,
} from "../../../graphql/mutations";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { API, Auth, graphqlOperation } from "aws-amplify";

import _ from "lodash";

// Define the state shape for your list of food items
interface ListFoodState {
  value: number;
  foods: { items: any[]; nextToken: string | null; status: string | null };
  status: string | null;
}

// Create the initial state
export const initialState: ListFoodState = {
  value: 0,
  foods: {
    items: [],
    nextToken: null,
    status: null,
  },
  status: null, // Initial status can be "idle" or any other initial status you prefer
};

export const fetchFoods = createAsyncThunk(
  "food/listFoods",
  async (nextToken: string | null) => {
    const response = await API.graphql(
      graphqlOperation(listCustomFoods, { nextToken })
    );

    return response;
  }
);
export const deleteFoodsFn = createAsyncThunk(
  "food/deleteFood",
  async (item: any) => {
    const response = await API.graphql(
      graphqlOperation(deleteFood, {
        input: {
          id: item.id,
          _version: item._version,
        },
      })
    );

    return response;
  }
);

export const updateFoodsFn = createAsyncThunk(
  "food/updateFood",
  async (input: any) => {
    const response = await API.graphql(
      graphqlOperation(updateCustomFood, { input: { ...input.input } })
    );

    return response;
  }
);
export const createFoodsFn = createAsyncThunk(
  "food/createFood",
  async (input: any) => {
    const tenant = input.input.tenant?.filter((item) => item !== "Trainers");

    try {
      const response: any = await API.graphql(
        graphqlOperation(createFood, { input: input.input })
      );

      const user = await Auth.currentAuthenticatedUser();
      // Set trainerID from user attributes
      const trainerID = user?.attributes?.sub;

      if (trainerID && input.favorite) {
        // If trainerID is successfully retrieved, create the favData object
        const favData = {
          trainerID: trainerID,
          foodId: response?.data?.createFood.id,
          tenant: tenant[0],
        };

        // Make the createFavorite API call
        await API.graphql(graphqlOperation(createFavorite, { input: favData }));
      }
      return response;
    } catch (e) {
      console.log("error", e);
    }
  }
);
export const foodSlice = createSlice({
  name: "food",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
    logout: (state) => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFoods.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchFoods.fulfilled, (state, action) => {
        const sortedItems = action.payload["data"].listFoods.items
          .filter((item) => !item?._deleted)
          .sort((a, b) => a.name.localeCompare(b.name));

        state.foods = {
          items: state.foods?.items.concat(sortedItems),
          nextToken: action.payload["data"]?.listFoods.nextToken,
          status: "succeeded",
        };
      })
      .addCase(fetchFoods.rejected, (state) => {
        state.foods.status = "failed";
      })
      .addCase(deleteFoodsFn.fulfilled, (state, action) => {
        // Add any fetched posts to the array
        //state.bodyParts = action.payload["data"].listBodyParts.items;

        const deletedItem: any = action.payload;
        state.foods = {
          ...state.foods,
          ...{
            items: state.foods?.items.map((data) => {
              if (data?.id === deletedItem.data?.deleteFood.id) {
                return deletedItem?.data?.deleteFood;
              }
              return data;
            }),
            status: "succeeded",
          },
        };
      })
      .addCase(updateFoodsFn.fulfilled, (state, action) => {
        const updatedFood = action.payload["data"]?.updateFood;

        const exerciseIndex = state.foods.items.findIndex(
          (exercise) => exercise.id === updatedFood.id
        );

        if (exerciseIndex !== -1) {
          state.foods.items[exerciseIndex] = updatedFood;
        }

        state.foods.status = "succeeded";
      })
      .addCase(createFoodsFn.fulfilled, (state, action) => {
        const createdData = action.payload["data"]?.createFood; // Adjust this to match your API response structure

        // Add the created exercise to the items array
        state.foods.items.push(createdData);

        // Set the status to "succeeded"
        state.foods.status = "succeeded";
      });
  },
});
export const { increment, decrement, incrementByAmount, logout } =
  foodSlice.actions;

export default foodSlice.reducer;
