import { ReactElement, useEffect, useState } from "react";
import Tab from "./Tab";
import "./Tabs.scss";
import AddButtonIcon from "../../images/plus-circle-green-color.svg";
interface ITabs {
  children?: ReactElement[];
  showAddMeals?: true;
  showAddButton?: false;
  refreshFetchWorkouts?: false;
}

const Tabs = (props: ITabs) => {
  const [activeTab, setActiveTab] = useState();

  useEffect(() => {
    setActiveTab(
      props.children && props.children.length
        ? props.children[0].props.label
        : null
    );
  }, [props.children]);
  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="tabs">
        <ol className="tab-list">
          {props?.children?.map((child) => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
          {props.showAddButton ? (
            <>
              <button
                style={{ display: "flex" }}
                onClick={() => {}}
                className="createNewWorkout"
              >
                <img alt="" className="addButton" src={AddButtonIcon} />
                <span style={{ cursor: "pointer" }}> Create New Workout </span>
              </button>
            </>
          ) : (
            ""
          )}
        </ol>
        <div className="tab-content">
          {props?.children?.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    </>
  );
};
export default Tabs;
