import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";
import "./style.scss";

export const Spinner = props => {
  const { promiseInProgress } = usePromiseTracker();

  return promiseInProgress ? (
    <div className="spinner">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#0e9df1"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        visible={true}
      />
    </div>
  ) : null;
};
