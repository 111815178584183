import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userSlice from "../app/features/users/index";
import leadsSlice from "../app/features/leads/index";
import paginationSlice from "../app/features/pagination";
import notificationSlice from "../app/features/notifications";
import workoutSlice from "../app/features/Workout/workoutSlice";
import foodSlice from "../app/features/Food/foodSlice";
import recipeSlice from "../app/features/Recipe/recipeSlice";
import favoriteSlice from "../app/features/Favorites/favoriteSlice";
import tenantSlice from "../app/features/Tenant/tenantSlice";
// import clearStoreReducer from "../app/features/Logout/logout";
const combineReducer = combineReducers({
  users: userSlice,
  leads: leadsSlice,
  pagination: paginationSlice,
  toasts: notificationSlice,
  workout: workoutSlice,
  exercises: workoutSlice,
  foods: foodSlice,
  recipes: recipeSlice,
  favorites: favoriteSlice,
  tenant: tenantSlice,
});
const rootReducer = (state, action) => {
  if (action.type === "food/logout") {
    state = undefined;
  }
  return combineReducer(state, action);
};
const store = configureStore({
  reducer: rootReducer,
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
