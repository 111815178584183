/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const convertClient = /* GraphQL */ `mutation ConvertClient($id: ID!) {
  convertClient(id: $id)
}
` as GeneratedMutation<
  APITypes.ConvertClientMutationVariables,
  APITypes.ConvertClientMutation
>;
export const dataCorrection = /* GraphQL */ `mutation DataCorrection(
  $tableName: String
  $id: String
  $shareStatus: Boolean
) {
  dataCorrection(tableName: $tableName, id: $id, shareStatus: $shareStatus)
}
` as GeneratedMutation<
  APITypes.DataCorrectionMutationVariables,
  APITypes.DataCorrectionMutation
>;
export const copyCalendarDay = /* GraphQL */ `mutation CopyCalendarDay(
  $data: AWSJSON
  $dates: AWSJSON
  $userId: String
  $layoutId: String
) {
  copyCalendarDay(
    data: $data
    dates: $dates
    userId: $userId
    layoutId: $layoutId
  )
}
` as GeneratedMutation<
  APITypes.CopyCalendarDayMutationVariables,
  APITypes.CopyCalendarDayMutation
>;
export const copyCalendarFood = /* GraphQL */ `mutation CopyCalendarFood($data: AWSJSON, $dates: AWSJSON, $userId: String) {
  copyCalendarFood(data: $data, dates: $dates, userId: $userId)
}
` as GeneratedMutation<
  APITypes.CopyCalendarFoodMutationVariables,
  APITypes.CopyCalendarFoodMutation
>;
export const copyMealPlannerDay = /* GraphQL */ `mutation CopyMealPlannerDay($data: AWSJSON, $dates: AWSJSON, $userId: String) {
  copyMealPlannerDay(data: $data, dates: $dates, userId: $userId)
}
` as GeneratedMutation<
  APITypes.CopyMealPlannerDayMutationVariables,
  APITypes.CopyMealPlannerDayMutation
>;
export const copyMealPlannerFood = /* GraphQL */ `mutation CopyMealPlannerFood($data: AWSJSON, $dates: AWSJSON, $userId: String) {
  copyMealPlannerFood(data: $data, dates: $dates, userId: $userId)
}
` as GeneratedMutation<
  APITypes.CopyMealPlannerFoodMutationVariables,
  APITypes.CopyMealPlannerFoodMutation
>;
export const LoadTemplateToCalendar = /* GraphQL */ `mutation LoadTemplateToCalendar(
  $mealPlannerId: String
  $date: String
  $userID: String
  $mealLayoutID: ID
  $days: Int
) {
  LoadTemplateToCalendar(
    mealPlannerId: $mealPlannerId
    date: $date
    userID: $userID
    mealLayoutID: $mealLayoutID
    days: $days
  )
}
` as GeneratedMutation<
  APITypes.LoadTemplateToCalendarMutationVariables,
  APITypes.LoadTemplateToCalendarMutation
>;
export const LoadWorkoutToCalendar = /* GraphQL */ `mutation LoadWorkoutToCalendar(
  $workoutId: String
  $date: String
  $userID: String
  $days: Int
) {
  LoadWorkoutToCalendar(
    workoutId: $workoutId
    date: $date
    userID: $userID
    days: $days
  )
}
` as GeneratedMutation<
  APITypes.LoadWorkoutToCalendarMutationVariables,
  APITypes.LoadWorkoutToCalendarMutation
>;
export const createWorkoutPlans = /* GraphQL */ `mutation CreateWorkoutPlans($name: String, $noOfDays: Int) {
  createWorkoutPlans(name: $name, noOfDays: $noOfDays)
}
` as GeneratedMutation<
  APITypes.CreateWorkoutPlansMutationVariables,
  APITypes.CreateWorkoutPlansMutation
>;
export const createChatUser = /* GraphQL */ `mutation CreateChatUser($data: AWSJSON) {
  createChatUser(data: $data)
}
` as GeneratedMutation<
  APITypes.CreateChatUserMutationVariables,
  APITypes.CreateChatUserMutation
>;
export const deleteMealTypeFoods = /* GraphQL */ `mutation DeleteMealTypeFoods(
  $dietplanDay: AWSJSON
  $deleteItemsId: AWSJSON
  $typeName: String
) {
  deleteMealTypeFoods(
    dietplanDay: $dietplanDay
    deleteItemsId: $deleteItemsId
    typeName: $typeName
  )
}
` as GeneratedMutation<
  APITypes.DeleteMealTypeFoodsMutationVariables,
  APITypes.DeleteMealTypeFoodsMutation
>;
export const deleteDayFoods = /* GraphQL */ `mutation DeleteDayFoods($day: Int, $dietplanDay: AWSJSON) {
  deleteDayFoods(day: $day, dietplanDay: $dietplanDay)
}
` as GeneratedMutation<
  APITypes.DeleteDayFoodsMutationVariables,
  APITypes.DeleteDayFoodsMutation
>;
export const deleteCalendarMeals = /* GraphQL */ `mutation DeleteCalendarMeals($calendarIds: AWSJSON) {
  deleteCalendarMeals(calendarIds: $calendarIds)
}
` as GeneratedMutation<
  APITypes.DeleteCalendarMealsMutationVariables,
  APITypes.DeleteCalendarMealsMutation
>;
export const createNote = /* GraphQL */ `mutation CreateNote(
  $input: CreateNoteInput!
  $condition: ModelNoteConditionInput
) {
  createNote(input: $input, condition: $condition) {
    id
    userID
    tenant
    note
    created_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNoteMutationVariables,
  APITypes.CreateNoteMutation
>;
export const updateNote = /* GraphQL */ `mutation UpdateNote(
  $input: UpdateNoteInput!
  $condition: ModelNoteConditionInput
) {
  updateNote(input: $input, condition: $condition) {
    id
    userID
    tenant
    note
    created_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNoteMutationVariables,
  APITypes.UpdateNoteMutation
>;
export const deleteNote = /* GraphQL */ `mutation DeleteNote(
  $input: DeleteNoteInput!
  $condition: ModelNoteConditionInput
) {
  deleteNote(input: $input, condition: $condition) {
    id
    userID
    tenant
    note
    created_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNoteMutationVariables,
  APITypes.DeleteNoteMutation
>;
export const createReminder = /* GraphQL */ `mutation CreateReminder(
  $input: CreateReminderInput!
  $condition: ModelReminderConditionInput
) {
  createReminder(input: $input, condition: $condition) {
    id
    userID
    tenant
    message
    finishBy
    isDone
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReminderMutationVariables,
  APITypes.CreateReminderMutation
>;
export const updateReminder = /* GraphQL */ `mutation UpdateReminder(
  $input: UpdateReminderInput!
  $condition: ModelReminderConditionInput
) {
  updateReminder(input: $input, condition: $condition) {
    id
    userID
    tenant
    message
    finishBy
    isDone
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReminderMutationVariables,
  APITypes.UpdateReminderMutation
>;
export const deleteReminder = /* GraphQL */ `mutation DeleteReminder(
  $input: DeleteReminderInput!
  $condition: ModelReminderConditionInput
) {
  deleteReminder(input: $input, condition: $condition) {
    id
    userID
    tenant
    message
    finishBy
    isDone
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReminderMutationVariables,
  APITypes.DeleteReminderMutation
>;
export const createMembership = /* GraphQL */ `mutation CreateMembership(
  $input: CreateMembershipInput!
  $condition: ModelMembershipConditionInput
) {
  createMembership(input: $input, condition: $condition) {
    id
    type
    status
    startTime
    endTime
    tenant
    deliveryAddress
    userID
    order {
      id
      userID
      tenant
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderProductId
      orderPaymentId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    membershipOrderId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMembershipMutationVariables,
  APITypes.CreateMembershipMutation
>;
export const updateMembership = /* GraphQL */ `mutation UpdateMembership(
  $input: UpdateMembershipInput!
  $condition: ModelMembershipConditionInput
) {
  updateMembership(input: $input, condition: $condition) {
    id
    type
    status
    startTime
    endTime
    tenant
    deliveryAddress
    userID
    order {
      id
      userID
      tenant
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderProductId
      orderPaymentId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    membershipOrderId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMembershipMutationVariables,
  APITypes.UpdateMembershipMutation
>;
export const deleteMembership = /* GraphQL */ `mutation DeleteMembership(
  $input: DeleteMembershipInput!
  $condition: ModelMembershipConditionInput
) {
  deleteMembership(input: $input, condition: $condition) {
    id
    type
    status
    startTime
    endTime
    tenant
    deliveryAddress
    userID
    order {
      id
      userID
      tenant
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderProductId
      orderPaymentId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    membershipOrderId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMembershipMutationVariables,
  APITypes.DeleteMembershipMutation
>;
export const createPayment = /* GraphQL */ `mutation CreatePayment(
  $input: CreatePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  createPayment(input: $input, condition: $condition) {
    id
    paymentMode
    tenant
    totalAmount
    discountAmount
    shippingAmount
    taxAmount
    transactionId
    status
    paymentType
    stripeCurrency
    stripePaymentIntent
    stripePaymentStatus
    stripeStatus
    userId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentMutationVariables,
  APITypes.CreatePaymentMutation
>;
export const updatePayment = /* GraphQL */ `mutation UpdatePayment(
  $input: UpdatePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  updatePayment(input: $input, condition: $condition) {
    id
    paymentMode
    tenant
    totalAmount
    discountAmount
    shippingAmount
    taxAmount
    transactionId
    status
    paymentType
    stripeCurrency
    stripePaymentIntent
    stripePaymentStatus
    stripeStatus
    userId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentMutationVariables,
  APITypes.UpdatePaymentMutation
>;
export const deletePayment = /* GraphQL */ `mutation DeletePayment(
  $input: DeletePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  deletePayment(input: $input, condition: $condition) {
    id
    paymentMode
    tenant
    totalAmount
    discountAmount
    shippingAmount
    taxAmount
    transactionId
    status
    paymentType
    stripeCurrency
    stripePaymentIntent
    stripePaymentStatus
    stripeStatus
    userId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentMutationVariables,
  APITypes.DeletePaymentMutation
>;
export const createProduct = /* GraphQL */ `mutation CreateProduct(
  $input: CreateProductInput!
  $condition: ModelProductConditionInput
) {
  createProduct(input: $input, condition: $condition) {
    id
    name
    imageUrl
    tenant
    description
    type
    price
    status
    stripePriceId
    durationInMonths
    includes
    video
    thumbnailUrl
    features
    productType
    trainerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductMutationVariables,
  APITypes.CreateProductMutation
>;
export const updateProduct = /* GraphQL */ `mutation UpdateProduct(
  $input: UpdateProductInput!
  $condition: ModelProductConditionInput
) {
  updateProduct(input: $input, condition: $condition) {
    id
    name
    imageUrl
    tenant
    description
    type
    price
    status
    stripePriceId
    durationInMonths
    includes
    video
    thumbnailUrl
    features
    productType
    trainerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductMutationVariables,
  APITypes.UpdateProductMutation
>;
export const deleteProduct = /* GraphQL */ `mutation DeleteProduct(
  $input: DeleteProductInput!
  $condition: ModelProductConditionInput
) {
  deleteProduct(input: $input, condition: $condition) {
    id
    name
    imageUrl
    tenant
    description
    type
    price
    status
    stripePriceId
    durationInMonths
    includes
    video
    thumbnailUrl
    features
    productType
    trainerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductMutationVariables,
  APITypes.DeleteProductMutation
>;
export const createOrder = /* GraphQL */ `mutation CreateOrder(
  $input: CreateOrderInput!
  $condition: ModelOrderConditionInput
) {
  createOrder(input: $input, condition: $condition) {
    id
    userID
    tenant
    product {
      id
      name
      imageUrl
      tenant
      description
      type
      price
      status
      stripePriceId
      durationInMonths
      includes
      video
      thumbnailUrl
      features
      productType
      trainerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    payment {
      id
      paymentMode
      tenant
      totalAmount
      discountAmount
      shippingAmount
      taxAmount
      transactionId
      status
      paymentType
      stripeCurrency
      stripePaymentIntent
      stripePaymentStatus
      stripeStatus
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderProductId
    orderPaymentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderMutationVariables,
  APITypes.CreateOrderMutation
>;
export const updateOrder = /* GraphQL */ `mutation UpdateOrder(
  $input: UpdateOrderInput!
  $condition: ModelOrderConditionInput
) {
  updateOrder(input: $input, condition: $condition) {
    id
    userID
    tenant
    product {
      id
      name
      imageUrl
      tenant
      description
      type
      price
      status
      stripePriceId
      durationInMonths
      includes
      video
      thumbnailUrl
      features
      productType
      trainerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    payment {
      id
      paymentMode
      tenant
      totalAmount
      discountAmount
      shippingAmount
      taxAmount
      transactionId
      status
      paymentType
      stripeCurrency
      stripePaymentIntent
      stripePaymentStatus
      stripeStatus
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderProductId
    orderPaymentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderMutationVariables,
  APITypes.UpdateOrderMutation
>;
export const deleteOrder = /* GraphQL */ `mutation DeleteOrder(
  $input: DeleteOrderInput!
  $condition: ModelOrderConditionInput
) {
  deleteOrder(input: $input, condition: $condition) {
    id
    userID
    tenant
    product {
      id
      name
      imageUrl
      tenant
      description
      type
      price
      status
      stripePriceId
      durationInMonths
      includes
      video
      thumbnailUrl
      features
      productType
      trainerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    payment {
      id
      paymentMode
      tenant
      totalAmount
      discountAmount
      shippingAmount
      taxAmount
      transactionId
      status
      paymentType
      stripeCurrency
      stripePaymentIntent
      stripePaymentStatus
      stripeStatus
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderProductId
    orderPaymentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderMutationVariables,
  APITypes.DeleteOrderMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    email
    name
    tenant
    imageUrl
    gender
    status {
      userStatus
      updatedAt
      __typename
    }
    activeStatus
    ageRange
    age
    height
    weightStart
    weightGoal
    motivation
    goal
    goalType
    achievement
    workoutCountPerWeek
    weightChangePerWeek
    workoutLocation
    workoutExperience
    workoutInfo
    mealCountPerDay
    phone
    location
    country
    timezone
    notes {
      nextToken
      startedAt
      __typename
    }
    Orders {
      nextToken
      startedAt
      __typename
    }
    membership {
      nextToken
      startedAt
      __typename
    }
    reminders {
      nextToken
      startedAt
      __typename
    }
    lastLogin
    emailValidated
    dailyProgress {
      date
      weight
      imageUrls
      __typename
    }
    imageProgress {
      date
      imageUrls
      __typename
    }
    injuries
    dietPreference
    allergies
    excludeFood
    dailyKcal
    proteinInPercentage
    carbsInPercentage
    fatInPercentage
    recentActivity {
      id
      mealsCalendar
      tenant
      workoutCalendar
      mealPlannerID
      workoutPlannerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userRecentActivityId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    email
    name
    tenant
    imageUrl
    gender
    status {
      userStatus
      updatedAt
      __typename
    }
    activeStatus
    ageRange
    age
    height
    weightStart
    weightGoal
    motivation
    goal
    goalType
    achievement
    workoutCountPerWeek
    weightChangePerWeek
    workoutLocation
    workoutExperience
    workoutInfo
    mealCountPerDay
    phone
    location
    country
    timezone
    notes {
      nextToken
      startedAt
      __typename
    }
    Orders {
      nextToken
      startedAt
      __typename
    }
    membership {
      nextToken
      startedAt
      __typename
    }
    reminders {
      nextToken
      startedAt
      __typename
    }
    lastLogin
    emailValidated
    dailyProgress {
      date
      weight
      imageUrls
      __typename
    }
    imageProgress {
      date
      imageUrls
      __typename
    }
    injuries
    dietPreference
    allergies
    excludeFood
    dailyKcal
    proteinInPercentage
    carbsInPercentage
    fatInPercentage
    recentActivity {
      id
      mealsCalendar
      tenant
      workoutCalendar
      mealPlannerID
      workoutPlannerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userRecentActivityId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    email
    name
    tenant
    imageUrl
    gender
    status {
      userStatus
      updatedAt
      __typename
    }
    activeStatus
    ageRange
    age
    height
    weightStart
    weightGoal
    motivation
    goal
    goalType
    achievement
    workoutCountPerWeek
    weightChangePerWeek
    workoutLocation
    workoutExperience
    workoutInfo
    mealCountPerDay
    phone
    location
    country
    timezone
    notes {
      nextToken
      startedAt
      __typename
    }
    Orders {
      nextToken
      startedAt
      __typename
    }
    membership {
      nextToken
      startedAt
      __typename
    }
    reminders {
      nextToken
      startedAt
      __typename
    }
    lastLogin
    emailValidated
    dailyProgress {
      date
      weight
      imageUrls
      __typename
    }
    imageProgress {
      date
      imageUrls
      __typename
    }
    injuries
    dietPreference
    allergies
    excludeFood
    dailyKcal
    proteinInPercentage
    carbsInPercentage
    fatInPercentage
    recentActivity {
      id
      mealsCalendar
      tenant
      workoutCalendar
      mealPlannerID
      workoutPlannerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userRecentActivityId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createRecentActivity = /* GraphQL */ `mutation CreateRecentActivity(
  $input: CreateRecentActivityInput!
  $condition: ModelRecentActivityConditionInput
) {
  createRecentActivity(input: $input, condition: $condition) {
    id
    mealsCalendar
    tenant
    workoutCalendar
    mealPlannerID
    workoutPlannerID
    workoutPlannerData {
      id
      name
      numOfdays
      tenant
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerData {
      id
      planTitle
      mealLayoutID
      tenant
      numOfdays
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRecentActivityMutationVariables,
  APITypes.CreateRecentActivityMutation
>;
export const updateRecentActivity = /* GraphQL */ `mutation UpdateRecentActivity(
  $input: UpdateRecentActivityInput!
  $condition: ModelRecentActivityConditionInput
) {
  updateRecentActivity(input: $input, condition: $condition) {
    id
    mealsCalendar
    tenant
    workoutCalendar
    mealPlannerID
    workoutPlannerID
    workoutPlannerData {
      id
      name
      numOfdays
      tenant
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerData {
      id
      planTitle
      mealLayoutID
      tenant
      numOfdays
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRecentActivityMutationVariables,
  APITypes.UpdateRecentActivityMutation
>;
export const deleteRecentActivity = /* GraphQL */ `mutation DeleteRecentActivity(
  $input: DeleteRecentActivityInput!
  $condition: ModelRecentActivityConditionInput
) {
  deleteRecentActivity(input: $input, condition: $condition) {
    id
    mealsCalendar
    tenant
    workoutCalendar
    mealPlannerID
    workoutPlannerID
    workoutPlannerData {
      id
      name
      numOfdays
      tenant
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerData {
      id
      planTitle
      mealLayoutID
      tenant
      numOfdays
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRecentActivityMutationVariables,
  APITypes.DeleteRecentActivityMutation
>;
export const createTrainer = /* GraphQL */ `mutation CreateTrainer(
  $input: CreateTrainerInput!
  $condition: ModelTrainerConditionInput
) {
  createTrainer(input: $input, condition: $condition) {
    id
    transactionId
    totalAmount
    currency
    customerId
    name
    tenant
    email
    status
    phone
    stripeEndpointSecret
    stripeSecretKey
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTrainerMutationVariables,
  APITypes.CreateTrainerMutation
>;
export const updateTrainer = /* GraphQL */ `mutation UpdateTrainer(
  $input: UpdateTrainerInput!
  $condition: ModelTrainerConditionInput
) {
  updateTrainer(input: $input, condition: $condition) {
    id
    transactionId
    totalAmount
    currency
    customerId
    name
    tenant
    email
    status
    phone
    stripeEndpointSecret
    stripeSecretKey
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTrainerMutationVariables,
  APITypes.UpdateTrainerMutation
>;
export const deleteTrainer = /* GraphQL */ `mutation DeleteTrainer(
  $input: DeleteTrainerInput!
  $condition: ModelTrainerConditionInput
) {
  deleteTrainer(input: $input, condition: $condition) {
    id
    transactionId
    totalAmount
    currency
    customerId
    name
    tenant
    email
    status
    phone
    stripeEndpointSecret
    stripeSecretKey
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTrainerMutationVariables,
  APITypes.DeleteTrainerMutation
>;
export const createTrainerTransaction = /* GraphQL */ `mutation CreateTrainerTransaction(
  $input: CreateTrainerTransactionInput!
  $condition: ModelTrainerTransactionConditionInput
) {
  createTrainerTransaction(input: $input, condition: $condition) {
    id
    transactionId
    totalAmount
    currency
    name
    tenant
    email
    status
    phone
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTrainerTransactionMutationVariables,
  APITypes.CreateTrainerTransactionMutation
>;
export const updateTrainerTransaction = /* GraphQL */ `mutation UpdateTrainerTransaction(
  $input: UpdateTrainerTransactionInput!
  $condition: ModelTrainerTransactionConditionInput
) {
  updateTrainerTransaction(input: $input, condition: $condition) {
    id
    transactionId
    totalAmount
    currency
    name
    tenant
    email
    status
    phone
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTrainerTransactionMutationVariables,
  APITypes.UpdateTrainerTransactionMutation
>;
export const deleteTrainerTransaction = /* GraphQL */ `mutation DeleteTrainerTransaction(
  $input: DeleteTrainerTransactionInput!
  $condition: ModelTrainerTransactionConditionInput
) {
  deleteTrainerTransaction(input: $input, condition: $condition) {
    id
    transactionId
    totalAmount
    currency
    name
    tenant
    email
    status
    phone
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTrainerTransactionMutationVariables,
  APITypes.DeleteTrainerTransactionMutation
>;
export const createLead = /* GraphQL */ `mutation CreateLead(
  $input: CreateLeadInput!
  $condition: ModelLeadConditionInput
) {
  createLead(input: $input, condition: $condition) {
    id
    name
    tenant
    email
    phone
    ageRange
    country
    location
    status {
      leadStatus
      updatedAt
      __typename
    }
    state
    gender
    goal
    achievement
    motivation
    notes
    activeStatus
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLeadMutationVariables,
  APITypes.CreateLeadMutation
>;
export const updateLead = /* GraphQL */ `mutation UpdateLead(
  $input: UpdateLeadInput!
  $condition: ModelLeadConditionInput
) {
  updateLead(input: $input, condition: $condition) {
    id
    name
    tenant
    email
    phone
    ageRange
    country
    location
    status {
      leadStatus
      updatedAt
      __typename
    }
    state
    gender
    goal
    achievement
    motivation
    notes
    activeStatus
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLeadMutationVariables,
  APITypes.UpdateLeadMutation
>;
export const deleteLead = /* GraphQL */ `mutation DeleteLead(
  $input: DeleteLeadInput!
  $condition: ModelLeadConditionInput
) {
  deleteLead(input: $input, condition: $condition) {
    id
    name
    tenant
    email
    phone
    ageRange
    country
    location
    status {
      leadStatus
      updatedAt
      __typename
    }
    state
    gender
    goal
    achievement
    motivation
    notes
    activeStatus
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLeadMutationVariables,
  APITypes.DeleteLeadMutation
>;
export const createAdministrator = /* GraphQL */ `mutation CreateAdministrator(
  $input: CreateAdministratorInput!
  $condition: ModelAdministratorConditionInput
) {
  createAdministrator(input: $input, condition: $condition) {
    id
    email
    tenant
    name
    emailValidated
    imageUrl
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdministratorMutationVariables,
  APITypes.CreateAdministratorMutation
>;
export const updateAdministrator = /* GraphQL */ `mutation UpdateAdministrator(
  $input: UpdateAdministratorInput!
  $condition: ModelAdministratorConditionInput
) {
  updateAdministrator(input: $input, condition: $condition) {
    id
    email
    tenant
    name
    emailValidated
    imageUrl
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdministratorMutationVariables,
  APITypes.UpdateAdministratorMutation
>;
export const deleteAdministrator = /* GraphQL */ `mutation DeleteAdministrator(
  $input: DeleteAdministratorInput!
  $condition: ModelAdministratorConditionInput
) {
  deleteAdministrator(input: $input, condition: $condition) {
    id
    email
    tenant
    name
    emailValidated
    imageUrl
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdministratorMutationVariables,
  APITypes.DeleteAdministratorMutation
>;
export const createFood = /* GraphQL */ `mutation CreateFood(
  $input: CreateFoodInput!
  $condition: ModelFoodConditionInput
) {
  createFood(input: $input, condition: $condition) {
    id
    name
    description
    tenant
    calories
    carbs
    fats
    protien
    servingSize
    servingDescription
    gramsPerServe
    imageUrl
    foodGroup
    moreFields
    additionalUnits
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFoodMutationVariables,
  APITypes.CreateFoodMutation
>;
export const updateFood = /* GraphQL */ `mutation UpdateFood(
  $input: UpdateFoodInput!
  $condition: ModelFoodConditionInput
) {
  updateFood(input: $input, condition: $condition) {
    id
    name
    description
    tenant
    calories
    carbs
    fats
    protien
    servingSize
    servingDescription
    gramsPerServe
    imageUrl
    foodGroup
    moreFields
    additionalUnits
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFoodMutationVariables,
  APITypes.UpdateFoodMutation
>;
export const deleteFood = /* GraphQL */ `mutation DeleteFood(
  $input: DeleteFoodInput!
  $condition: ModelFoodConditionInput
) {
  deleteFood(input: $input, condition: $condition) {
    id
    name
    description
    tenant
    calories
    carbs
    fats
    protien
    servingSize
    servingDescription
    gramsPerServe
    imageUrl
    foodGroup
    moreFields
    additionalUnits
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFoodMutationVariables,
  APITypes.DeleteFoodMutation
>;
export const createRecipe = /* GraphQL */ `mutation CreateRecipe(
  $input: CreateRecipeInput!
  $condition: ModelRecipeConditionInput
) {
  createRecipe(input: $input, condition: $condition) {
    id
    title
    description
    tenant
    servingSize
    servingDescription
    imageUrl
    category
    isMainDish
    isBreakfast
    steps
    ingredients {
      nextToken
      startedAt
      __typename
    }
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRecipeMutationVariables,
  APITypes.CreateRecipeMutation
>;
export const updateRecipe = /* GraphQL */ `mutation UpdateRecipe(
  $input: UpdateRecipeInput!
  $condition: ModelRecipeConditionInput
) {
  updateRecipe(input: $input, condition: $condition) {
    id
    title
    description
    tenant
    servingSize
    servingDescription
    imageUrl
    category
    isMainDish
    isBreakfast
    steps
    ingredients {
      nextToken
      startedAt
      __typename
    }
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRecipeMutationVariables,
  APITypes.UpdateRecipeMutation
>;
export const deleteRecipe = /* GraphQL */ `mutation DeleteRecipe(
  $input: DeleteRecipeInput!
  $condition: ModelRecipeConditionInput
) {
  deleteRecipe(input: $input, condition: $condition) {
    id
    title
    description
    tenant
    servingSize
    servingDescription
    imageUrl
    category
    isMainDish
    isBreakfast
    steps
    ingredients {
      nextToken
      startedAt
      __typename
    }
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRecipeMutationVariables,
  APITypes.DeleteRecipeMutation
>;
export const createIngredient = /* GraphQL */ `mutation CreateIngredient(
  $input: CreateIngredientInput!
  $condition: ModelIngredientConditionInput
) {
  createIngredient(input: $input, condition: $condition) {
    id
    recipeId
    tenant
    servingSize
    servingDescription
    foodId
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIngredientMutationVariables,
  APITypes.CreateIngredientMutation
>;
export const updateIngredient = /* GraphQL */ `mutation UpdateIngredient(
  $input: UpdateIngredientInput!
  $condition: ModelIngredientConditionInput
) {
  updateIngredient(input: $input, condition: $condition) {
    id
    recipeId
    tenant
    servingSize
    servingDescription
    foodId
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIngredientMutationVariables,
  APITypes.UpdateIngredientMutation
>;
export const deleteIngredient = /* GraphQL */ `mutation DeleteIngredient(
  $input: DeleteIngredientInput!
  $condition: ModelIngredientConditionInput
) {
  deleteIngredient(input: $input, condition: $condition) {
    id
    recipeId
    tenant
    servingSize
    servingDescription
    foodId
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIngredientMutationVariables,
  APITypes.DeleteIngredientMutation
>;
export const createFavorite = /* GraphQL */ `mutation CreateFavorite(
  $input: CreateFavoriteInput!
  $condition: ModelFavoriteConditionInput
) {
  createFavorite(input: $input, condition: $condition) {
    id
    trainerID
    tenant
    recipeId
    foodId
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFavoriteMutationVariables,
  APITypes.CreateFavoriteMutation
>;
export const updateFavorite = /* GraphQL */ `mutation UpdateFavorite(
  $input: UpdateFavoriteInput!
  $condition: ModelFavoriteConditionInput
) {
  updateFavorite(input: $input, condition: $condition) {
    id
    trainerID
    tenant
    recipeId
    foodId
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFavoriteMutationVariables,
  APITypes.UpdateFavoriteMutation
>;
export const deleteFavorite = /* GraphQL */ `mutation DeleteFavorite(
  $input: DeleteFavoriteInput!
  $condition: ModelFavoriteConditionInput
) {
  deleteFavorite(input: $input, condition: $condition) {
    id
    trainerID
    tenant
    recipeId
    foodId
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFavoriteMutationVariables,
  APITypes.DeleteFavoriteMutation
>;
export const createMealsCalendarDate = /* GraphQL */ `mutation CreateMealsCalendarDate(
  $input: CreateMealsCalendarDateInput!
  $condition: ModelMealsCalendarDateConditionInput
) {
  createMealsCalendarDate(input: $input, condition: $condition) {
    id
    userID
    tenant
    date
    mealLayoutID
    mealLayout {
      id
      layout
      tenant
      layoutName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMealsCalendarDateMutationVariables,
  APITypes.CreateMealsCalendarDateMutation
>;
export const updateMealsCalendarDate = /* GraphQL */ `mutation UpdateMealsCalendarDate(
  $input: UpdateMealsCalendarDateInput!
  $condition: ModelMealsCalendarDateConditionInput
) {
  updateMealsCalendarDate(input: $input, condition: $condition) {
    id
    userID
    tenant
    date
    mealLayoutID
    mealLayout {
      id
      layout
      tenant
      layoutName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMealsCalendarDateMutationVariables,
  APITypes.UpdateMealsCalendarDateMutation
>;
export const deleteMealsCalendarDate = /* GraphQL */ `mutation DeleteMealsCalendarDate(
  $input: DeleteMealsCalendarDateInput!
  $condition: ModelMealsCalendarDateConditionInput
) {
  deleteMealsCalendarDate(input: $input, condition: $condition) {
    id
    userID
    tenant
    date
    mealLayoutID
    mealLayout {
      id
      layout
      tenant
      layoutName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMealsCalendarDateMutationVariables,
  APITypes.DeleteMealsCalendarDateMutation
>;
export const createUserCalendarMeal = /* GraphQL */ `mutation CreateUserCalendarMeal(
  $input: CreateUserCalendarMealInput!
  $condition: ModelUserCalendarMealConditionInput
) {
  createUserCalendarMeal(input: $input, condition: $condition) {
    id
    idDate
    tenant
    date
    mealLayoutID
    mealLayout {
      id
      layout
      tenant
      layoutName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    UserCalendarMealItems {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserCalendarMealMutationVariables,
  APITypes.CreateUserCalendarMealMutation
>;
export const updateUserCalendarMeal = /* GraphQL */ `mutation UpdateUserCalendarMeal(
  $input: UpdateUserCalendarMealInput!
  $condition: ModelUserCalendarMealConditionInput
) {
  updateUserCalendarMeal(input: $input, condition: $condition) {
    id
    idDate
    tenant
    date
    mealLayoutID
    mealLayout {
      id
      layout
      tenant
      layoutName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    UserCalendarMealItems {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserCalendarMealMutationVariables,
  APITypes.UpdateUserCalendarMealMutation
>;
export const deleteUserCalendarMeal = /* GraphQL */ `mutation DeleteUserCalendarMeal(
  $input: DeleteUserCalendarMealInput!
  $condition: ModelUserCalendarMealConditionInput
) {
  deleteUserCalendarMeal(input: $input, condition: $condition) {
    id
    idDate
    tenant
    date
    mealLayoutID
    mealLayout {
      id
      layout
      tenant
      layoutName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    UserCalendarMealItems {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserCalendarMealMutationVariables,
  APITypes.DeleteUserCalendarMealMutation
>;
export const createUserCalendarMealItems = /* GraphQL */ `mutation CreateUserCalendarMealItems(
  $input: CreateUserCalendarMealItemsInput!
  $condition: ModelUserCalendarMealItemsConditionInput
) {
  createUserCalendarMealItems(input: $input, condition: $condition) {
    id
    calendarMealId
    tenant
    recipeId
    foodId
    userID
    meal
    servingSize
    servingDescription
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserCalendarMealItemsMutationVariables,
  APITypes.CreateUserCalendarMealItemsMutation
>;
export const updateUserCalendarMealItems = /* GraphQL */ `mutation UpdateUserCalendarMealItems(
  $input: UpdateUserCalendarMealItemsInput!
  $condition: ModelUserCalendarMealItemsConditionInput
) {
  updateUserCalendarMealItems(input: $input, condition: $condition) {
    id
    calendarMealId
    tenant
    recipeId
    foodId
    userID
    meal
    servingSize
    servingDescription
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserCalendarMealItemsMutationVariables,
  APITypes.UpdateUserCalendarMealItemsMutation
>;
export const deleteUserCalendarMealItems = /* GraphQL */ `mutation DeleteUserCalendarMealItems(
  $input: DeleteUserCalendarMealItemsInput!
  $condition: ModelUserCalendarMealItemsConditionInput
) {
  deleteUserCalendarMealItems(input: $input, condition: $condition) {
    id
    calendarMealId
    tenant
    recipeId
    foodId
    userID
    meal
    servingSize
    servingDescription
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserCalendarMealItemsMutationVariables,
  APITypes.DeleteUserCalendarMealItemsMutation
>;
export const createCalendar = /* GraphQL */ `mutation CreateCalendar(
  $input: CreateCalendarInput!
  $condition: ModelCalendarConditionInput
) {
  createCalendar(input: $input, condition: $condition) {
    id
    date
    days
    tenant
    userID
    recipeId
    foodId
    meal
    user {
      id
      email
      name
      tenant
      imageUrl
      gender
      activeStatus
      ageRange
      age
      height
      weightStart
      weightGoal
      motivation
      goal
      goalType
      achievement
      workoutCountPerWeek
      weightChangePerWeek
      workoutLocation
      workoutExperience
      workoutInfo
      mealCountPerDay
      phone
      location
      country
      timezone
      lastLogin
      emailValidated
      injuries
      dietPreference
      allergies
      excludeFood
      dailyKcal
      proteinInPercentage
      carbsInPercentage
      fatInPercentage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRecentActivityId
      __typename
    }
    servingSize
    servingDescription
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerId
    mealPlannerDayId
    mealPlannerDay {
      id
      dayNum
      tenant
      dayOff
      mealDayOrder
      mealPlannerId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerDetail
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCalendarMutationVariables,
  APITypes.CreateCalendarMutation
>;
export const updateCalendar = /* GraphQL */ `mutation UpdateCalendar(
  $input: UpdateCalendarInput!
  $condition: ModelCalendarConditionInput
) {
  updateCalendar(input: $input, condition: $condition) {
    id
    date
    days
    tenant
    userID
    recipeId
    foodId
    meal
    user {
      id
      email
      name
      tenant
      imageUrl
      gender
      activeStatus
      ageRange
      age
      height
      weightStart
      weightGoal
      motivation
      goal
      goalType
      achievement
      workoutCountPerWeek
      weightChangePerWeek
      workoutLocation
      workoutExperience
      workoutInfo
      mealCountPerDay
      phone
      location
      country
      timezone
      lastLogin
      emailValidated
      injuries
      dietPreference
      allergies
      excludeFood
      dailyKcal
      proteinInPercentage
      carbsInPercentage
      fatInPercentage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRecentActivityId
      __typename
    }
    servingSize
    servingDescription
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerId
    mealPlannerDayId
    mealPlannerDay {
      id
      dayNum
      tenant
      dayOff
      mealDayOrder
      mealPlannerId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerDetail
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCalendarMutationVariables,
  APITypes.UpdateCalendarMutation
>;
export const deleteCalendar = /* GraphQL */ `mutation DeleteCalendar(
  $input: DeleteCalendarInput!
  $condition: ModelCalendarConditionInput
) {
  deleteCalendar(input: $input, condition: $condition) {
    id
    date
    days
    tenant
    userID
    recipeId
    foodId
    meal
    user {
      id
      email
      name
      tenant
      imageUrl
      gender
      activeStatus
      ageRange
      age
      height
      weightStart
      weightGoal
      motivation
      goal
      goalType
      achievement
      workoutCountPerWeek
      weightChangePerWeek
      workoutLocation
      workoutExperience
      workoutInfo
      mealCountPerDay
      phone
      location
      country
      timezone
      lastLogin
      emailValidated
      injuries
      dietPreference
      allergies
      excludeFood
      dailyKcal
      proteinInPercentage
      carbsInPercentage
      fatInPercentage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRecentActivityId
      __typename
    }
    servingSize
    servingDescription
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerId
    mealPlannerDayId
    mealPlannerDay {
      id
      dayNum
      tenant
      dayOff
      mealDayOrder
      mealPlannerId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerDetail
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCalendarMutationVariables,
  APITypes.DeleteCalendarMutation
>;
export const createWorkoutsCalendarDate = /* GraphQL */ `mutation CreateWorkoutsCalendarDate(
  $input: CreateWorkoutsCalendarDateInput!
  $condition: ModelWorkoutsCalendarDateConditionInput
) {
  createWorkoutsCalendarDate(input: $input, condition: $condition) {
    id
    userID
    tenant
    date
    dayNotes
    sessionOrder
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWorkoutsCalendarDateMutationVariables,
  APITypes.CreateWorkoutsCalendarDateMutation
>;
export const updateWorkoutsCalendarDate = /* GraphQL */ `mutation UpdateWorkoutsCalendarDate(
  $input: UpdateWorkoutsCalendarDateInput!
  $condition: ModelWorkoutsCalendarDateConditionInput
) {
  updateWorkoutsCalendarDate(input: $input, condition: $condition) {
    id
    userID
    tenant
    date
    dayNotes
    sessionOrder
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorkoutsCalendarDateMutationVariables,
  APITypes.UpdateWorkoutsCalendarDateMutation
>;
export const deleteWorkoutsCalendarDate = /* GraphQL */ `mutation DeleteWorkoutsCalendarDate(
  $input: DeleteWorkoutsCalendarDateInput!
  $condition: ModelWorkoutsCalendarDateConditionInput
) {
  deleteWorkoutsCalendarDate(input: $input, condition: $condition) {
    id
    userID
    tenant
    date
    dayNotes
    sessionOrder
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWorkoutsCalendarDateMutationVariables,
  APITypes.DeleteWorkoutsCalendarDateMutation
>;
export const createUserCalendarWorkout = /* GraphQL */ `mutation CreateUserCalendarWorkout(
  $input: CreateUserCalendarWorkoutInput!
  $condition: ModelUserCalendarWorkoutConditionInput
) {
  createUserCalendarWorkout(input: $input, condition: $condition) {
    id
    idDate
    userID
    tenant
    dayNotes
    sessionOrder
    UserCalendarWorkoutItems {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserCalendarWorkoutMutationVariables,
  APITypes.CreateUserCalendarWorkoutMutation
>;
export const updateUserCalendarWorkout = /* GraphQL */ `mutation UpdateUserCalendarWorkout(
  $input: UpdateUserCalendarWorkoutInput!
  $condition: ModelUserCalendarWorkoutConditionInput
) {
  updateUserCalendarWorkout(input: $input, condition: $condition) {
    id
    idDate
    userID
    tenant
    dayNotes
    sessionOrder
    UserCalendarWorkoutItems {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserCalendarWorkoutMutationVariables,
  APITypes.UpdateUserCalendarWorkoutMutation
>;
export const deleteUserCalendarWorkout = /* GraphQL */ `mutation DeleteUserCalendarWorkout(
  $input: DeleteUserCalendarWorkoutInput!
  $condition: ModelUserCalendarWorkoutConditionInput
) {
  deleteUserCalendarWorkout(input: $input, condition: $condition) {
    id
    idDate
    userID
    tenant
    dayNotes
    sessionOrder
    UserCalendarWorkoutItems {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserCalendarWorkoutMutationVariables,
  APITypes.DeleteUserCalendarWorkoutMutation
>;
export const createUserCalendarWorkoutItems = /* GraphQL */ `mutation CreateUserCalendarWorkoutItems(
  $input: CreateUserCalendarWorkoutItemsInput!
  $condition: ModelUserCalendarWorkoutItemsConditionInput
) {
  createUserCalendarWorkoutItems(input: $input, condition: $condition) {
    id
    calendarWorkoutId
    userID
    tenant
    workoutPlanDetailId
    exerciseId
    exercise {
      id
      name
      additionalNotes
      weight
      tenant
      bodyPartId
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    session
    notes
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserCalendarWorkoutItemsMutationVariables,
  APITypes.CreateUserCalendarWorkoutItemsMutation
>;
export const updateUserCalendarWorkoutItems = /* GraphQL */ `mutation UpdateUserCalendarWorkoutItems(
  $input: UpdateUserCalendarWorkoutItemsInput!
  $condition: ModelUserCalendarWorkoutItemsConditionInput
) {
  updateUserCalendarWorkoutItems(input: $input, condition: $condition) {
    id
    calendarWorkoutId
    userID
    tenant
    workoutPlanDetailId
    exerciseId
    exercise {
      id
      name
      additionalNotes
      weight
      tenant
      bodyPartId
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    session
    notes
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserCalendarWorkoutItemsMutationVariables,
  APITypes.UpdateUserCalendarWorkoutItemsMutation
>;
export const deleteUserCalendarWorkoutItems = /* GraphQL */ `mutation DeleteUserCalendarWorkoutItems(
  $input: DeleteUserCalendarWorkoutItemsInput!
  $condition: ModelUserCalendarWorkoutItemsConditionInput
) {
  deleteUserCalendarWorkoutItems(input: $input, condition: $condition) {
    id
    calendarWorkoutId
    userID
    tenant
    workoutPlanDetailId
    exerciseId
    exercise {
      id
      name
      additionalNotes
      weight
      tenant
      bodyPartId
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    session
    notes
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserCalendarWorkoutItemsMutationVariables,
  APITypes.DeleteUserCalendarWorkoutItemsMutation
>;
export const createWorkoutCalendar = /* GraphQL */ `mutation CreateWorkoutCalendar(
  $input: CreateWorkoutCalendarInput!
  $condition: ModelWorkoutCalendarConditionInput
) {
  createWorkoutCalendar(input: $input, condition: $condition) {
    id
    date
    userID
    workoutPlanId
    tenant
    workoutPlanDayId
    workoutPlanDay {
      id
      dayNum
      workoutPlanId
      dayOff
      tenant
      dayNotes
      sessionNotes
      sessionOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    workoutPlanDetailId
    exerciseId
    exercise {
      id
      name
      additionalNotes
      weight
      tenant
      bodyPartId
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    session
    notes
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWorkoutCalendarMutationVariables,
  APITypes.CreateWorkoutCalendarMutation
>;
export const updateWorkoutCalendar = /* GraphQL */ `mutation UpdateWorkoutCalendar(
  $input: UpdateWorkoutCalendarInput!
  $condition: ModelWorkoutCalendarConditionInput
) {
  updateWorkoutCalendar(input: $input, condition: $condition) {
    id
    date
    userID
    workoutPlanId
    tenant
    workoutPlanDayId
    workoutPlanDay {
      id
      dayNum
      workoutPlanId
      dayOff
      tenant
      dayNotes
      sessionNotes
      sessionOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    workoutPlanDetailId
    exerciseId
    exercise {
      id
      name
      additionalNotes
      weight
      tenant
      bodyPartId
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    session
    notes
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorkoutCalendarMutationVariables,
  APITypes.UpdateWorkoutCalendarMutation
>;
export const deleteWorkoutCalendar = /* GraphQL */ `mutation DeleteWorkoutCalendar(
  $input: DeleteWorkoutCalendarInput!
  $condition: ModelWorkoutCalendarConditionInput
) {
  deleteWorkoutCalendar(input: $input, condition: $condition) {
    id
    date
    userID
    workoutPlanId
    tenant
    workoutPlanDayId
    workoutPlanDay {
      id
      dayNum
      workoutPlanId
      dayOff
      tenant
      dayNotes
      sessionNotes
      sessionOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    workoutPlanDetailId
    exerciseId
    exercise {
      id
      name
      additionalNotes
      weight
      tenant
      bodyPartId
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    session
    notes
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWorkoutCalendarMutationVariables,
  APITypes.DeleteWorkoutCalendarMutation
>;
export const createMealLayoutManager = /* GraphQL */ `mutation CreateMealLayoutManager(
  $input: CreateMealLayoutManagerInput!
  $condition: ModelMealLayoutManagerConditionInput
) {
  createMealLayoutManager(input: $input, condition: $condition) {
    id
    layout
    tenant
    layoutName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMealLayoutManagerMutationVariables,
  APITypes.CreateMealLayoutManagerMutation
>;
export const updateMealLayoutManager = /* GraphQL */ `mutation UpdateMealLayoutManager(
  $input: UpdateMealLayoutManagerInput!
  $condition: ModelMealLayoutManagerConditionInput
) {
  updateMealLayoutManager(input: $input, condition: $condition) {
    id
    layout
    tenant
    layoutName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMealLayoutManagerMutationVariables,
  APITypes.UpdateMealLayoutManagerMutation
>;
export const deleteMealLayoutManager = /* GraphQL */ `mutation DeleteMealLayoutManager(
  $input: DeleteMealLayoutManagerInput!
  $condition: ModelMealLayoutManagerConditionInput
) {
  deleteMealLayoutManager(input: $input, condition: $condition) {
    id
    layout
    tenant
    layoutName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMealLayoutManagerMutationVariables,
  APITypes.DeleteMealLayoutManagerMutation
>;
export const createMealPlanner = /* GraphQL */ `mutation CreateMealPlanner(
  $input: CreateMealPlannerInput!
  $condition: ModelMealPlannerConditionInput
) {
  createMealPlanner(input: $input, condition: $condition) {
    id
    planTitle
    mealLayoutID
    tenant
    mealLayout {
      id
      layout
      tenant
      layoutName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    numOfdays
    mealPlannerDay {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMealPlannerMutationVariables,
  APITypes.CreateMealPlannerMutation
>;
export const updateMealPlanner = /* GraphQL */ `mutation UpdateMealPlanner(
  $input: UpdateMealPlannerInput!
  $condition: ModelMealPlannerConditionInput
) {
  updateMealPlanner(input: $input, condition: $condition) {
    id
    planTitle
    mealLayoutID
    tenant
    mealLayout {
      id
      layout
      tenant
      layoutName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    numOfdays
    mealPlannerDay {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMealPlannerMutationVariables,
  APITypes.UpdateMealPlannerMutation
>;
export const deleteMealPlanner = /* GraphQL */ `mutation DeleteMealPlanner(
  $input: DeleteMealPlannerInput!
  $condition: ModelMealPlannerConditionInput
) {
  deleteMealPlanner(input: $input, condition: $condition) {
    id
    planTitle
    mealLayoutID
    tenant
    mealLayout {
      id
      layout
      tenant
      layoutName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    numOfdays
    mealPlannerDay {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMealPlannerMutationVariables,
  APITypes.DeleteMealPlannerMutation
>;
export const createMealPlannerDay = /* GraphQL */ `mutation CreateMealPlannerDay(
  $input: CreateMealPlannerDayInput!
  $condition: ModelMealPlannerDayConditionInput
) {
  createMealPlannerDay(input: $input, condition: $condition) {
    id
    dayNum
    tenant
    dayOff
    mealDayOrder
    mealPlannerId
    mealPlannerItemsMap {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMealPlannerDayMutationVariables,
  APITypes.CreateMealPlannerDayMutation
>;
export const updateMealPlannerDay = /* GraphQL */ `mutation UpdateMealPlannerDay(
  $input: UpdateMealPlannerDayInput!
  $condition: ModelMealPlannerDayConditionInput
) {
  updateMealPlannerDay(input: $input, condition: $condition) {
    id
    dayNum
    tenant
    dayOff
    mealDayOrder
    mealPlannerId
    mealPlannerItemsMap {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMealPlannerDayMutationVariables,
  APITypes.UpdateMealPlannerDayMutation
>;
export const deleteMealPlannerDay = /* GraphQL */ `mutation DeleteMealPlannerDay(
  $input: DeleteMealPlannerDayInput!
  $condition: ModelMealPlannerDayConditionInput
) {
  deleteMealPlannerDay(input: $input, condition: $condition) {
    id
    dayNum
    tenant
    dayOff
    mealDayOrder
    mealPlannerId
    mealPlannerItemsMap {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMealPlannerDayMutationVariables,
  APITypes.DeleteMealPlannerDayMutation
>;
export const createMealPlannerItemsMap = /* GraphQL */ `mutation CreateMealPlannerItemsMap(
  $input: CreateMealPlannerItemsMapInput!
  $condition: ModelMealPlannerItemsMapConditionInput
) {
  createMealPlannerItemsMap(input: $input, condition: $condition) {
    id
    mealPlannerDayId
    mealPlannerId
    day
    meal
    tenant
    recipeId
    foodId
    servingSize
    servingDescription
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMealPlannerItemsMapMutationVariables,
  APITypes.CreateMealPlannerItemsMapMutation
>;
export const updateMealPlannerItemsMap = /* GraphQL */ `mutation UpdateMealPlannerItemsMap(
  $input: UpdateMealPlannerItemsMapInput!
  $condition: ModelMealPlannerItemsMapConditionInput
) {
  updateMealPlannerItemsMap(input: $input, condition: $condition) {
    id
    mealPlannerDayId
    mealPlannerId
    day
    meal
    tenant
    recipeId
    foodId
    servingSize
    servingDescription
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMealPlannerItemsMapMutationVariables,
  APITypes.UpdateMealPlannerItemsMapMutation
>;
export const deleteMealPlannerItemsMap = /* GraphQL */ `mutation DeleteMealPlannerItemsMap(
  $input: DeleteMealPlannerItemsMapInput!
  $condition: ModelMealPlannerItemsMapConditionInput
) {
  deleteMealPlannerItemsMap(input: $input, condition: $condition) {
    id
    mealPlannerDayId
    mealPlannerId
    day
    meal
    tenant
    recipeId
    foodId
    servingSize
    servingDescription
    recipe {
      id
      title
      description
      tenant
      servingSize
      servingDescription
      imageUrl
      category
      isMainDish
      isBreakfast
      steps
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    food {
      id
      name
      description
      tenant
      calories
      carbs
      fats
      protien
      servingSize
      servingDescription
      gramsPerServe
      imageUrl
      foodGroup
      moreFields
      additionalUnits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMealPlannerItemsMapMutationVariables,
  APITypes.DeleteMealPlannerItemsMapMutation
>;
export const createMealType = /* GraphQL */ `mutation CreateMealType(
  $input: CreateMealTypeInput!
  $condition: ModelMealTypeConditionInput
) {
  createMealType(input: $input, condition: $condition) {
    name
    description
    tenant
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMealTypeMutationVariables,
  APITypes.CreateMealTypeMutation
>;
export const updateMealType = /* GraphQL */ `mutation UpdateMealType(
  $input: UpdateMealTypeInput!
  $condition: ModelMealTypeConditionInput
) {
  updateMealType(input: $input, condition: $condition) {
    name
    description
    tenant
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMealTypeMutationVariables,
  APITypes.UpdateMealTypeMutation
>;
export const deleteMealType = /* GraphQL */ `mutation DeleteMealType(
  $input: DeleteMealTypeInput!
  $condition: ModelMealTypeConditionInput
) {
  deleteMealType(input: $input, condition: $condition) {
    name
    description
    tenant
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMealTypeMutationVariables,
  APITypes.DeleteMealTypeMutation
>;
export const createMailStore = /* GraphQL */ `mutation CreateMailStore(
  $input: CreateMailStoreInput!
  $condition: ModelMailStoreConditionInput
) {
  createMailStore(input: $input, condition: $condition) {
    id
    from
    to
    tenant
    userID
    administratorID
    subject
    message
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMailStoreMutationVariables,
  APITypes.CreateMailStoreMutation
>;
export const updateMailStore = /* GraphQL */ `mutation UpdateMailStore(
  $input: UpdateMailStoreInput!
  $condition: ModelMailStoreConditionInput
) {
  updateMailStore(input: $input, condition: $condition) {
    id
    from
    to
    tenant
    userID
    administratorID
    subject
    message
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMailStoreMutationVariables,
  APITypes.UpdateMailStoreMutation
>;
export const deleteMailStore = /* GraphQL */ `mutation DeleteMailStore(
  $input: DeleteMailStoreInput!
  $condition: ModelMailStoreConditionInput
) {
  deleteMailStore(input: $input, condition: $condition) {
    id
    from
    to
    tenant
    userID
    administratorID
    subject
    message
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMailStoreMutationVariables,
  APITypes.DeleteMailStoreMutation
>;
export const createExercise = /* GraphQL */ `mutation CreateExercise(
  $input: CreateExerciseInput!
  $condition: ModelExerciseConditionInput
) {
  createExercise(input: $input, condition: $condition) {
    id
    name
    additionalNotes
    weight
    tenant
    bodyPartId
    bodyPart {
      id
      name
      tenant
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    videoUrl
    imageUrl
    selectedUnit
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExerciseMutationVariables,
  APITypes.CreateExerciseMutation
>;
export const updateExercise = /* GraphQL */ `mutation UpdateExercise(
  $input: UpdateExerciseInput!
  $condition: ModelExerciseConditionInput
) {
  updateExercise(input: $input, condition: $condition) {
    id
    name
    additionalNotes
    weight
    tenant
    bodyPartId
    bodyPart {
      id
      name
      tenant
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    videoUrl
    imageUrl
    selectedUnit
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExerciseMutationVariables,
  APITypes.UpdateExerciseMutation
>;
export const deleteExercise = /* GraphQL */ `mutation DeleteExercise(
  $input: DeleteExerciseInput!
  $condition: ModelExerciseConditionInput
) {
  deleteExercise(input: $input, condition: $condition) {
    id
    name
    additionalNotes
    weight
    tenant
    bodyPartId
    bodyPart {
      id
      name
      tenant
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    videoUrl
    imageUrl
    selectedUnit
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExerciseMutationVariables,
  APITypes.DeleteExerciseMutation
>;
export const createBodyPart = /* GraphQL */ `mutation CreateBodyPart(
  $input: CreateBodyPartInput!
  $condition: ModelBodyPartConditionInput
) {
  createBodyPart(input: $input, condition: $condition) {
    id
    name
    tenant
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBodyPartMutationVariables,
  APITypes.CreateBodyPartMutation
>;
export const updateBodyPart = /* GraphQL */ `mutation UpdateBodyPart(
  $input: UpdateBodyPartInput!
  $condition: ModelBodyPartConditionInput
) {
  updateBodyPart(input: $input, condition: $condition) {
    id
    name
    tenant
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBodyPartMutationVariables,
  APITypes.UpdateBodyPartMutation
>;
export const deleteBodyPart = /* GraphQL */ `mutation DeleteBodyPart(
  $input: DeleteBodyPartInput!
  $condition: ModelBodyPartConditionInput
) {
  deleteBodyPart(input: $input, condition: $condition) {
    id
    name
    tenant
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBodyPartMutationVariables,
  APITypes.DeleteBodyPartMutation
>;
export const createWorkoutPlan = /* GraphQL */ `mutation CreateWorkoutPlan(
  $input: CreateWorkoutPlanInput!
  $condition: ModelWorkoutPlanConditionInput
) {
  createWorkoutPlan(input: $input, condition: $condition) {
    id
    name
    numOfdays
    tenant
    workoutPlanDay {
      nextToken
      startedAt
      __typename
    }
    notes
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWorkoutPlanMutationVariables,
  APITypes.CreateWorkoutPlanMutation
>;
export const updateWorkoutPlan = /* GraphQL */ `mutation UpdateWorkoutPlan(
  $input: UpdateWorkoutPlanInput!
  $condition: ModelWorkoutPlanConditionInput
) {
  updateWorkoutPlan(input: $input, condition: $condition) {
    id
    name
    numOfdays
    tenant
    workoutPlanDay {
      nextToken
      startedAt
      __typename
    }
    notes
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorkoutPlanMutationVariables,
  APITypes.UpdateWorkoutPlanMutation
>;
export const deleteWorkoutPlan = /* GraphQL */ `mutation DeleteWorkoutPlan(
  $input: DeleteWorkoutPlanInput!
  $condition: ModelWorkoutPlanConditionInput
) {
  deleteWorkoutPlan(input: $input, condition: $condition) {
    id
    name
    numOfdays
    tenant
    workoutPlanDay {
      nextToken
      startedAt
      __typename
    }
    notes
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWorkoutPlanMutationVariables,
  APITypes.DeleteWorkoutPlanMutation
>;
export const createWorkoutPlanDay = /* GraphQL */ `mutation CreateWorkoutPlanDay(
  $input: CreateWorkoutPlanDayInput!
  $condition: ModelWorkoutPlanDayConditionInput
) {
  createWorkoutPlanDay(input: $input, condition: $condition) {
    id
    dayNum
    workoutPlanId
    dayOff
    tenant
    dayNotes
    sessionNotes
    sessionOrder
    workoutPlannerItemsMap {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWorkoutPlanDayMutationVariables,
  APITypes.CreateWorkoutPlanDayMutation
>;
export const updateWorkoutPlanDay = /* GraphQL */ `mutation UpdateWorkoutPlanDay(
  $input: UpdateWorkoutPlanDayInput!
  $condition: ModelWorkoutPlanDayConditionInput
) {
  updateWorkoutPlanDay(input: $input, condition: $condition) {
    id
    dayNum
    workoutPlanId
    dayOff
    tenant
    dayNotes
    sessionNotes
    sessionOrder
    workoutPlannerItemsMap {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorkoutPlanDayMutationVariables,
  APITypes.UpdateWorkoutPlanDayMutation
>;
export const deleteWorkoutPlanDay = /* GraphQL */ `mutation DeleteWorkoutPlanDay(
  $input: DeleteWorkoutPlanDayInput!
  $condition: ModelWorkoutPlanDayConditionInput
) {
  deleteWorkoutPlanDay(input: $input, condition: $condition) {
    id
    dayNum
    workoutPlanId
    dayOff
    tenant
    dayNotes
    sessionNotes
    sessionOrder
    workoutPlannerItemsMap {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWorkoutPlanDayMutationVariables,
  APITypes.DeleteWorkoutPlanDayMutation
>;
export const createWorkoutPlannerItemsMap = /* GraphQL */ `mutation CreateWorkoutPlannerItemsMap(
  $input: CreateWorkoutPlannerItemsMapInput!
  $condition: ModelWorkoutPlannerItemsMapConditionInput
) {
  createWorkoutPlannerItemsMap(input: $input, condition: $condition) {
    id
    workoutPlanId
    workoutPlanDayId
    exerciseId
    tenant
    exercise {
      id
      name
      additionalNotes
      weight
      tenant
      bodyPartId
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    session
    notes
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWorkoutPlannerItemsMapMutationVariables,
  APITypes.CreateWorkoutPlannerItemsMapMutation
>;
export const updateWorkoutPlannerItemsMap = /* GraphQL */ `mutation UpdateWorkoutPlannerItemsMap(
  $input: UpdateWorkoutPlannerItemsMapInput!
  $condition: ModelWorkoutPlannerItemsMapConditionInput
) {
  updateWorkoutPlannerItemsMap(input: $input, condition: $condition) {
    id
    workoutPlanId
    workoutPlanDayId
    exerciseId
    tenant
    exercise {
      id
      name
      additionalNotes
      weight
      tenant
      bodyPartId
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    session
    notes
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorkoutPlannerItemsMapMutationVariables,
  APITypes.UpdateWorkoutPlannerItemsMapMutation
>;
export const deleteWorkoutPlannerItemsMap = /* GraphQL */ `mutation DeleteWorkoutPlannerItemsMap(
  $input: DeleteWorkoutPlannerItemsMapInput!
  $condition: ModelWorkoutPlannerItemsMapConditionInput
) {
  deleteWorkoutPlannerItemsMap(input: $input, condition: $condition) {
    id
    workoutPlanId
    workoutPlanDayId
    exerciseId
    tenant
    exercise {
      id
      name
      additionalNotes
      weight
      tenant
      bodyPartId
      videoUrl
      imageUrl
      selectedUnit
      defaultValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    session
    notes
    defaultValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWorkoutPlannerItemsMapMutationVariables,
  APITypes.DeleteWorkoutPlannerItemsMapMutation
>;
