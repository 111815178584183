import React, { useEffect, useState } from "react";
import TopNav from "../TopNav/TopNav";
import "./Clients.scss";
import DeleteComponent from "../../component/Delete/Delete";
import { listUsers } from "../../graphql/customQueries";
import { API, graphqlOperation } from "aws-amplify";
import Pagination from "../../component/Pagination/pagination";
import Search from "../../component/Search/Search";
import { filterSearchData } from "../../Utils";

import { ReactComponent as Meals } from "../../images/Meals.svg";
import { ReactComponent as Workout } from "../../images/Workout.svg";
import { useNavigate } from "react-router-dom";

import { trackPromise } from "react-promise-tracker";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import { deleteUser } from "../../graphql/mutations";
import { ModelUserFilterInput } from "../../API";
let PageSize = 10;

const Badge = ({ text }) => {
  let background, color;
  if (text == "ACTIVE") {
    background = "#e6f7f4";
    color = "#06AB8D";
  } else if (text == "EXPIRED") {
    background = "#f5e2e2";
    color = "#D11818";
  } else if (text == "IN_ACTIVE") {
    background = "#ebeced";
    color = "#707683";
  } else if (text == "SUSPENDED") {
    background = "#f5e2e2";
    color = "#D11818";
  }
  return (
    <span
      className="badge"
      style={{
        background: background,
      }}
    >
      <span className="text_font" style={{ color: color }}>
        {text}
      </span>
    </span>
  );
};
const Clients = () => {
  //TODO: Define type
  const [users, setUsers] = useState<any>(null);
  const [filteredUser, setFilteredUser] = useState<any>(null);
  const [load, setLoad] = useState(false);

  // const listUsersFun = async () => {
  //   const data: any = await API.graphql(graphqlOperation(listUsers));
  //   let nextToken = data?.data?.listUsers?.nextToken;
  //   let nextUserItems = data?.data?.listUsers?.items;

  //   let uniqueUsers = new Map();
  //   nextUserItems.forEach((user) => {
  //     uniqueUsers.set(user.id, user);
  //   });

  //   while (nextToken) {
  //     let nextLeadData: any = await API.graphql(graphqlOperation(listUsers));
  //     let nextUsers = nextLeadData?.data?.listUsers?.items;

  //     nextUsers.forEach((user) => {
  //       uniqueUsers.set(user.id, user);
  //     });

  //     nextToken = nextLeadData?.data?.listUsers?.nextToken;
  //   }

  //   let uniqueUserItems = Array.from(uniqueUsers.values());

  //   setUsers(uniqueUserItems);
  // };
  const listUsersFun = async () => {
    let nextToken = null;
    let allUsers: ModelUserFilterInput[] = [];

    do {
      const userData: any = await API.graphql(
        graphqlOperation(listUsers, { nextToken })
      );
      const nextUserItems: ModelUserFilterInput[] =
        userData?.data?.listUsers?.items || [];
      allUsers.push(...nextUserItems);
      nextToken = userData?.data?.listUsers?.nextToken;
    } while (nextToken);

    setUsers(allUsers);
  };

  useEffect(() => {
    trackPromise(listUsersFun());
  }, [load]);
  useEffect(() => {
    let temp = users;
    console.log("users", users);
    if (users) {
      temp = temp.filter((item) => !item._deleted);
      temp.sort(compareBy("createdAt"));
      setFilteredUser(temp);
    }
  }, [users]);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredUser(filterSearchData(users, event.target.value, ["name"]));
  };

  return (
    <>
      <TopNav>
        <Search onChange={onChange} placeHolder="Search Clients by Name" />
      </TopNav>
      <div className="clients">
        <div className="clients__header">
          <h1>Clients</h1>
          <div className="clients__header__content">
            {/* <FilterByCategory
              onSubmit={(val) => {
                // TODO: Filter based on category
              }}
            /> */}
            {/* <Filter
              onSubmit={(val) => {
              }}
            />
            <FtButton
              className="createnewbtn"
              border="1px solid #109CF1"
              color="#109CF1"
              height="40px"
              onClick={() => console.log()}
              radius="5px"
              children="Create New"
              icon={<Add />}
            /> */}
          </div>
        </div>
        <div className="clients__container">
          {filteredUser && <Table data={filteredUser} setData={setLoad} />}
        </div>
      </div>
    </>
  );
};
//TODO: Refactor Table to functional component and make it reusable
// TODO: Define types for props and states
const Table = (props) => {
  const navigate = useNavigate();
  const sortTypes = {
    up: {
      class: "sort-up",
    },
    down: {
      class: "sort-down",
    },
    default: {
      class: "sort",
    },
  };
  const Columns = [
    { name: 0 },
    { meal: 0 },
    { email: 0 },
    { payment_type: 0 },
    { membership: 0 },
  ];

  const [tableState, setTableState] = useState<any>({
    currentSort: "default",
    activeColumnSort: "",
    columns: Columns,
    currentPage: 1,
    data: [],
  });
  const [paginatedData, setPaginatedData] = useState<any>([]);

  const compareBy = (key) => {
    return function (a, b) {
      if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
      if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
      return 0;
    };
  };

  const sortBy = (key) => {
    let nextSort;

    if (tableState.currentSort === "down") nextSort = "up";
    else if (tableState.currentSort === "up") nextSort = "default";
    else if (tableState.currentSort === "default") nextSort = "down";

    let arrayCopy1 = [...props.data];
    let arrayCopy2 = [...tableState.columns];

    let order = 1;
    arrayCopy1.sort(compareBy(key));
    arrayCopy1.sort(compareBy(key));
    if (tableState.columns[key] !== 2) {
      order = 2;
      arrayCopy1.reverse();
    }
    arrayCopy2[key] = order;
    setTableState({
      ...tableState,
      ...{
        data: arrayCopy1,

        currentPage: 1,
        currentSort: nextSort,
        activeColumnSort: key,
      },
    });
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;
    setPaginatedData(arrayCopy1?.slice(firstPage, lastPage));
  };

  const onPageChange = (page) => {
    setTableState({
      ...tableState,
      ...{ currentPage: page },
    });
  };

  useEffect(() => {
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;
    setTableState({
      ...tableState,
      ...{
        firstPageIndex: firstPage,
        lastPageIndex: lastPage,
        data: props.data,
      },
    });
    setPaginatedData(props.data?.slice(firstPage, lastPage));
  }, [props.data]);

  useEffect(() => {
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;

    setPaginatedData(tableState.data?.slice(firstPage, lastPage));
  }, [tableState.currentPage, tableState.data]);
  const [deletedId, setDeletedId] = useState<any>();
  const onDelete = async function dietForm(id: any, version: any) {
    await API.graphql(
      graphqlOperation(deleteUser, {
        input: {
          id: id,
          _version: version,
        },
      })
    );
    setDeletedId(id);
  };
  useEffect(() => {
    const datas = props.data.filter((item) => {
      if (item.id !== deletedId) {
        return item;
      }
    });
    props.setData(datas);
  }, [deletedId]);
  const gotoDetails = (id) => {
    navigate(`/clients/${id}/overview`);
  };
  const [isDeletes, setIsDeletes] = useState<any>(false);
  const Row = (
    {
      name,
      meal,
      workout,
      payment_type,
      activeStatus,
      id,
      recentActivity,
      _version,
    },
    index
  ) => (
    <tr key={index} className="tableRow" onClick={gotoDetails.bind(this, id)}>
      <td>{name}</td>
      <td>
        <Tooltip title={recentActivity?.mealPlannerData?.planTitle || ""}>
          <span>
            {" "}
            <Meals />
            {recentActivity && recentActivity?.mealsCalendar
              ? moment(recentActivity?.mealsCalendar).fromNow()
              : "NA"}
          </span>
        </Tooltip>
      </td>
      <td>
        {
          <Tooltip title={recentActivity?.workoutPlannerData?.name || ""}>
            <span>
              <Workout />
              {recentActivity && recentActivity?.workoutCalendar
                ? moment(recentActivity?.workoutCalendar).fromNow()
                : "NA"}
            </span>
          </Tooltip>
        }
      </td>
      <td>{payment_type ?? "NA"}</td>
      <td>{<Badge text={activeStatus} />}</td>
      <td
        className="actions"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {" "}
        <DeleteComponent
          deletes={isDeletes}
          setDeletes={setIsDeletes}
          onDeletes={(e) => {
            onDelete(id, _version);
          }}
        />
      </td>
    </tr>
  );

  const rows = paginatedData?.map((rowData) => <Row {...rowData} />);

  return (
    <div className="responsivetable">
      <table className="text-left">
        <thead>
          <tr>
            <th>
              Name
              <button onClick={() => sortBy("name")}>
                {tableState.activeColumnSort != "name" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort == "name" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>
            </th>
            <th>
              Meal
              <button onClick={() => sortBy("meal")}>
                {tableState.activeColumnSort != "meal" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort == "meal" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>
            </th>
            <th>
              Workout
              <button onClick={() => sortBy("workout")}>
                {tableState.activeColumnSort != "workout" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort == "workout" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>
            </th>
            <th>
              Payment Type
              <button onClick={() => sortBy("payment_type")}>
                {tableState.activeColumnSort != "payment_type" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort == "payment_type" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>{" "}
            </th>
            <th>
              Membership
              <button onClick={() => sortBy("membership")}>
                {tableState.activeColumnSort != "membership" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort == "membership" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        {rows?.length === 0 ? (
          <tbody>
            <tr>
              <td className="nodata" colSpan={5}>
                No data available
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>{rows}</tbody>
        )}
      </table>
      <Pagination
        className="pagination-bar"
        currentPage={tableState.currentPage}
        totalCount={tableState.data?.length}
        pageSize={PageSize}
        onPageChange={onPageChange}
        rowsPerPage={paginatedData?.length}
        label="Clients"
      />
    </div>
  );
};

export default Clients;
const compareBy = (key) => {
  return function (a, b) {
    if (a[key].toLowerCase() > b[key].toLowerCase()) return -1;
    if (a[key].toLowerCase() < b[key].toLowerCase()) return 1;
    return 0;
  };
};
