import { useEffect, useState } from "react";
import "./Chat.scss";
import Attachment from "../../images/attachment.svg";
import PackageAttachment from "./PackageAttachment";

import classnames from "classnames";
import moment from "moment";
import { onCreateMailStore } from "../../graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLSubscription } from "@aws-amplify/api";
import { listMailStores } from "../../graphql/queries";

import awsExports from "../../aws-exports";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { sendNotification } from "../../app/features/notifications";
import { ReactComponent as Check } from "../../images/check.svg";

import _ from "lodash";
import { trackPromise } from "react-promise-tracker";
import { useAppSelector } from "../../hooks";

const Chat = ({ leadDetail }) => {
  const listTenant = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;
  let initialState = {
    from: "",
    to: "",
    subject: "",
    userID: "",
    message: "",
    administratorID: "",
  };

  const [openAttachment, setAttachment] = useState(false);
  const [packageSeletion, setPackageSelection] = useState(false);
  const [packageDetail, setPackageDetail] = useState<any>(null);
  const [messageResponse, setMessageResponse] = useState<any>([]);
  const [messageItems, setMessageItems] = useState<any>([]);
  const [emailContent, setEmailContent] = useState(initialState);
  const today = new Date();
  const dispatch = useDispatch();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  const todayFormat = mm + "/" + dd + "/" + yyyy;
  const [check, setCheck] = useState(false);
  const [defaultValue, setDefaultValue] = useState("Buy your package here!");
  const [authToken, setAuthToken] = useState();
  const fcs = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    setEmailContent({
      ...emailContent,
      administratorID: currentUser?.attributes?.sub,
      from: currentUser?.attributes?.email,
      to: leadDetail.email,
      userID: leadDetail.id,
    });
    const filter = {
      and: [
        { userID: { eq: leadDetail.id } },
        { administratorID: { eq: currentUser?.attributes?.sub } },
      ],
    };
    const res = await API.graphql({
      query: listMailStores,
      variables: { filter: filter, limit: 1000 },
    });
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    setAuthToken(token);

    setMessageResponse(res);
  };
  useEffect(() => {
    let sortMessage = _.sortBy(
      messageResponse?.data?.listMailStores?.items,
      "createdAt"
    );

    setMessageItems(sortMessage);
  }, [messageResponse]);

  useEffect(() => {
    if (leadDetail && leadDetail?.email) {
      fcs();
    }
  }, [leadDetail]);

  useEffect(() => {
    API.graphql<GraphQLSubscription<typeof onCreateMailStore>>(
      graphqlOperation(onCreateMailStore)
    ).subscribe({
      next: async (value) => {
        if (leadDetail && leadDetail?.email) {
          fcs();
        }
      },
    });
  }, [leadDetail]);

  useEffect(() => {
    setPackageDetail(null);
  }, [openAttachment]);
  useEffect(() => {
    if (packageSeletion) {
      setDefaultValue("Buy your package here!");
    } else {
      setDefaultValue("");
    }
  }, [packageSeletion]);
  const handlePackageSubject = (e) => {
    setDefaultValue(e.target.value);
  };
  const sendMsg = async (e) => {
    e.preventDefault();

    if (packageDetail === null) {
      if (
        e.target.elements.message.value.length > 1 &&
        e.target.elements.subject.value.length > 1
      ) {
        const messageData = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...emailContent,
            message: e.target.elements.message.value,
            subject: e.target.elements.subject.value,
            tenant: tenant,
            token: authToken,
          }),
        };
        try {
          await trackPromise(
            fetch(
              awsExports.aws_cloud_logic_custom.filter(
                (element) => element.name === "stripeAPI"
              )[0].endpoint + "/sendEmail",
              messageData
            )
              .then((response) => response.json())
              .then((data) => {
                if (data?.success) {
                  dispatch(
                    sendNotification({
                      title: "Success",
                      description: "Chat conversation sent as mail!",
                      backgroundColor: "#5cb85c",
                      icon: Check,
                    })
                  );
                } else {
                  dispatch(
                    sendNotification({
                      title: "Failed",
                      description: "Failed to send the message!",
                      backgroundColor: "#AA4A44",
                    })
                  );
                }
              })
          );
        } catch (e) {
          dispatch(
            sendNotification({
              title: "Failed",
              description: "Failed to send the message!",
              backgroundColor: "#AA4A44",
              icon: Check,
            })
          );
          return e;
        }
      }
    } else {
      setCheck(true);
      const packageMessageData = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...emailContent,
          productId: packageDetail,
          customerId: leadDetail.id,
          name: leadDetail.name,
          tenant: tenant,
          mailSubject: e.target.elements.subject.value,
          mailMessage: e.target.elements.message.value,
          token: authToken,
        }),
      };
      try {
        await trackPromise(
          fetch(
            awsExports.aws_cloud_logic_custom.filter(
              (element) => element.name === "stripeAPI"
            )[0].endpoint + "/checkout",
            packageMessageData
          )
            .then((response) => response.json())
            .then((data) => {
              if (data?.success) {
                dispatch(
                  sendNotification({
                    title: "Success",
                    description: "Package details sent successfully!",
                    backgroundColor: "#5cb85c",
                    icon: Check,
                  })
                );
              } else {
                dispatch(
                  sendNotification({
                    title: "Failed",
                    description: "Failed to send package details!",
                    backgroundColor: "#AA4A44",
                    icon: Check,
                  })
                );
              }
            })
        );
      } catch (e) {
        dispatch(
          sendNotification({
            title: "Failed",
            description: "Failed to send send package details!",
            backgroundColor: "#AA4A44",
            icon: Check,
          })
        );
        return e;
      }
    }
    e.target.reset();
    setDefaultValue("");
    setAttachment(false);
  };

  return (
    <>
      {openAttachment ? (
        <div className="packageAttAnimation">
          <PackageAttachment
            setPackageDetail={setPackageDetail}
            setPackage={setPackageSelection}
            check={check}
            setAttachment={setAttachment}
            id={leadDetail.id}
          />
        </div>
      ) : (
        <>
          <div className="chat">
            <div className="chat__wrapper">
              {messageItems?.map((item, index) => {
                if (item["from"] === emailContent.from) {
                  return (
                    <div>
                      <div className="info__admin">
                        <div className="name">you</div>

                        <div className="date">
                          {moment(item["createdAt"]).format("L") === todayFormat
                            ? moment(item["createdAt"]).format("LT")
                            : moment(item["createdAt"]).format(
                                "DD/MM/YYYY, h:mm a"
                              )}
                        </div>
                      </div>
                      <div className="chat__message chat__message-own">
                        <div className="msg">{item["message"]}</div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      {" "}
                      <div className="info">
                        <div className="name">{leadDetail.name}</div>

                        <div className="date">
                          {moment(item["createdAt"]).format("L") === todayFormat
                            ? moment(item["createdAt"]).format("LT")
                            : moment(item["createdAt"]).format(
                                "DD/MM/YYYY, h:mm a "
                              )}
                        </div>
                      </div>
                      <div className="chat__message">
                        <div className="msg">{item["message"]}</div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
            {/* <div className="package"></div> */}
          </div>
        </>
      )}
      <div className="toAddress">
        <label>
          <strong>To: </strong>
          <span>{leadDetail.email}</span>
        </label>
      </div>

      <div className="chatInput">
        <>
          <div className="emailContainer">
            <form
              className="emailContainer-left"
              id="create-form"
              onSubmit={sendMsg}
            >
              <div className="emailContainer-left__textArea">
                <input
                  name="subject"
                  placeholder="Subject"
                  value={defaultValue}
                  onChange={handlePackageSubject}
                  type="input"
                  className={classnames("textarea1", "subject")}
                />
                <textarea
                  name="message"
                  className={classnames("textarea2", "body")}
                  placeholder="Message"
                />
              </div>
              <div className="emailContainer-left__button">
                <button type="submit" className="sendButton">
                  Send
                </button>
              </div>
            </form>
          </div>
        </>

        <button
          onClick={() => {
            setAttachment((prev) => !prev);
            setPackageSelection(false);
          }}
          style={{ border: "none", backgroundColor: "White" }}
        >
          <img
            style={{ marginLeft: "5px", marginRight: "5px" }}
            src={Attachment}
            alt="attach a package"
          />
        </button>
      </div>
    </>
  );
};
export default Chat;
