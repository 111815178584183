import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import Pagination from "../../../../component/Pagination/pagination";
import { useNavigate } from "react-router-dom";
import { filterSearchData } from "../../../../Utils";
import TopNav from "../../../TopNav/TopNav";
import Search from "../../../../component/Search/Search";
import FtButton from "../../../Ft-Button/Ft-Button";
import moment from "moment";
import { listWorkoutPlans } from "../../../../graphql/queries";
import DeleteComponent from "../../../../component/Delete/Delete";
import { deleteWorkoutPlan } from "../../../../graphql/mutations";
import { useAppSelector } from "../../../../hooks";
let PageSize = 10;
const WorkoutPlan = () => {
  const [workoutPlan, setWorkoutPlan] = useState<any>();
  const [filteredWorkoutPlan, setFilteredWorkoutPlan] = useState<any>(null);
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    async function getWorkoutPlan() {
      const WorkoutPlanData: any = await API.graphql(
        graphqlOperation(listWorkoutPlans, {})
      );

      setWorkoutPlan(WorkoutPlanData);
    }
    getWorkoutPlan();
  }, [load]);
  const compareBy = (key) => {
    return function (a, b) {
      if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) return -1;
      if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) return 1;
      return 0;
    };
  };
  useEffect(() => {
    //want to change
    let temp = workoutPlan?.data?.listWorkoutPlans?.items;
    if (temp) {
      temp = temp.filter((item) => !item._deleted);
      temp.sort(compareBy("name"));
      setFilteredWorkoutPlan(temp);
    }
  }, [workoutPlan]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredWorkoutPlan(
      filterSearchData(
        workoutPlan && workoutPlan?.data?.listWorkoutPlans?.items,
        event.target.value,
        ["name"]
      )
    );
  };
  const gotToWorkoutPlanDetail = () => {
    navigate(`/library/workoutplan/workoutplandetail`);
  };
  const backToLibrary = () => {
    navigate(`/library`);
  };

  return (
    <>
      <div className="topBar">
        <div className="topBar__back">
          <button onClick={backToLibrary} className="topBar__button">
            &#60; Back
          </button>
        </div>
        <div className="topBar__search">
          <TopNav>
            <Search
              onChange={onChange}
              placeHolder="Search Workout Plan  by name"
            />
          </TopNav>
        </div>
      </div>
      <div className="leads">
        <div className="leads__header">
          <div className="leads__header__text">
            <h1>Workout Plans</h1>
          </div>
          <div className="newbt">
            <FtButton
              border="1px solid #109CF1"
              color="#109CF1"
              height="40px"
              className="createnewbtn"
              radius="5px"
              onClick={gotToWorkoutPlanDetail}
            >
              Create New Workout Plan
            </FtButton>
          </div>
        </div>
        <div className="leads__container">
          {filteredWorkoutPlan && (
            <Table data={filteredWorkoutPlan} setData={setLoad} />
          )}
        </div>
      </div>
    </>
  );
};

const Table = (props) => {
  const sortTypes = {
    up: {
      class: "sort-up",
    },
    down: {
      class: "sort-down",
    },
    default: {
      class: "sort",
    },
  };

  const [tableState, setTableState] = useState<any>({
    currentSort: "default",
    activeColumnSort: "",

    currentPage: 1,
    data: [],
  });
  const [paginatedData, setPaginatedData] = useState<any>([]);

  const compareBy = (key) => {
    return function (a, b) {
      if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
      if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
      return 0;
    };
  };
  let order = 1;
  const sortBy = (key) => {
    let nextSort;
    if (tableState.currentSort === "down") nextSort = "up";
    else if (tableState.currentSort === "up") nextSort = "default";
    else if (tableState.currentSort === "default") nextSort = "down";

    let arrayCopy1 = [...props?.data];
    let arrayCopy2 = [...tableState.columns];

    arrayCopy1.sort(compareBy(key));
    if (tableState.currentSort === "down") {
      arrayCopy1.sort(function (a, b) {
        if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) return -1;
        if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) return 1;
        return 0;
      });
    } else if (tableState.currentSort === "up") {
      arrayCopy1.sort(function (a, b) {
        if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) return -1;
        if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) return 1;
        return 0;
      });
    }
    arrayCopy2[key] = order;
    setTableState({
      ...tableState,
      ...{
        data: arrayCopy1,
        currentPage: 1,
        currentSort: nextSort,
        activeColumnSort: key,
      },
    });
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;
    setPaginatedData(arrayCopy1?.slice(firstPage, lastPage));
  };

  const onPageChange = (page) => {
    setTableState({
      ...tableState,
      ...{ currentPage: page },
    });
  };

  useEffect(() => {
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;

    setTableState({
      ...tableState,
      ...{
        firstPageIndex: firstPage,
        lastPageIndex: lastPage,
        data: props.data,
      },
    });
    setPaginatedData(props.data?.slice(firstPage, lastPage));
  }, [props.data]);
  const [deletedId, setDeletedId] = useState<any>();
  const onDelete = async function dietForm(id: any, version: any) {
    await API.graphql(
      graphqlOperation(deleteWorkoutPlan, {
        input: {
          id: id,
          _version: version,
        },
      })
    );
    setDeletedId(id);
  };
  useEffect(() => {
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;
    setPaginatedData(tableState.data?.slice(firstPage, lastPage));
  }, [tableState.currentPage, tableState.data]);

  useEffect(() => {
    const datas = props.data.filter((item) => {
      if (item.id !== deletedId) {
        return item;
      }
    });
    props.setData(datas);
  }, [deletedId]);
  const listTenant = useAppSelector((state) => state.tenant);
  const tenants = listTenant?.tenant?.currentTenant;
  const [isDeletes, setIsDeletes] = useState<any>(false);
  const Row = ({ name, numOfdays, createdAt, id, _version, tenant }, index) => (
    <>
      <tr
        key={index}
        className="tableRow"
        onClick={(e) => gotToWorkoutPlanDetail(id, e)}
      >
        <td>{name ?? "NA"}</td>
        <td>{numOfdays ?? "NA"}</td>
        <td>{moment(createdAt).fromNow()}</td>

        <td
          className={
            tenants === "ADMIN" && tenant.includes("ADMIN") ? "actions" : ""
          }
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {tenant === null ||
          tenants === tenant[0] ||
          (tenants === "ADMIN" && tenant.includes("ADMIN")) ? (
            <DeleteComponent
              deletes={isDeletes}
              setDeletes={setIsDeletes}
              onDeletes={(e) => {
                onDelete(id, _version);
              }}
            />
          ) : null}
        </td>
      </tr>
    </>
  );

  const rows = paginatedData?.map((rowData) => {
    if (!rowData._deleted) {
      return <Row {...rowData} />;
    }
  });
  const navigate = useNavigate();
  const gotToWorkoutPlanDetail = (id, e) => {
    e.preventDefault();
    navigate(`/library/workoutplan/workoutplandetail/${id}`);
  };

  return (
    <div className="responsivetable">
      <table className="text-left">
        <thead>
          <tr>
            <th>
              Plan Name
              <button onClick={() => sortBy("name")}>
                {tableState.activeColumnSort !== "name" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort === "name" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>
            </th>

            <th>
              No Of Days
              <button>
                {tableState.activeColumnSort !== "numOfdays" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort === "numOfdays" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>{" "}
            </th>
            <th>
              Created At
              <button onClick={() => sortBy("createdAt")}>
                {tableState.activeColumnSort !== "createdAt" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort === "createdAt" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>
            </th>
            <th style={{ paddingLeft: 45 }}>Actions</th>
          </tr>
        </thead>
        {rows?.length === 0 ? (
          <tbody>
            <tr>
              <td className="nodata" colSpan={5}>
                No data available
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>{rows}</tbody>
        )}
      </table>
      <Pagination
        className="pagination-bar"
        currentPage={tableState.currentPage}
        totalCount={tableState.data?.length}
        pageSize={PageSize}
        onPageChange={onPageChange}
        rowsPerPage={paginatedData.length}
        label="plans"
      />
    </div>
  );
};

export default WorkoutPlan;
