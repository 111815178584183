import "./payment.scss";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import paymentimage from "../../images/paymentimage.png";
import Apple from "../../images/appleapp.png";
import Download from "../../images/downloadimage.png";
import Google from "../../images/googleplay.png";
import NeedHelp from "../../images/needhelp.png";
import moment from "moment";
const Payment = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  let email = searchParams.get("email");
  let name = searchParams.get("name");
  let date = searchParams.get("date");
  let amount = searchParams.get("amount");
  let productName = searchParams.get("productName");
  let paymentStatus = searchParams.get("paymentStatus");
  let paymentMethod = searchParams.get("paymentMethod");
  let invoiceNum = searchParams.get("invoiceNum");
  let currency = searchParams.get("currency");

  const base64Decode = (data) => {
    return decodeURIComponent(escape(atob(data)));
  };

  const stripeData = () => {
    let data = {
      emailData: base64Decode(email),
      nameData: base64Decode(name),
      dateData: base64Decode(date),
      amountData: base64Decode(amount),
      productNameData: base64Decode(productName),
      paymentStatusData: base64Decode(paymentStatus),
      paymentMethodData: base64Decode(paymentMethod),
      invoiceNumData: base64Decode(invoiceNum),
      currencyData: base64Decode(currency),
    };
    setData(data);
  };

  useEffect(() => {
    stripeData();
  }, []);

  //Payment
  return (
    <>
      <div className="paymentcontainer">
        <div className="paymentcontainer__left">
          <div className="paymentcontainer__left__header">
            <img className="payimage" src={paymentimage} alt="" />
          </div>
          <div className="paymentcontainer__left__footer">
            <div className="paymentcontainer__left__footer__toptext">
              Payment Success!
            </div>
            <div className="paymentcontainer__left__footer__middletext">
              <div className="paymentcontainer__left__footer__middletext__top">
                Thank you for choosing Fitness. You’re awesome and you’re our
                favorite Fitness user (just don’t tell the other ones).
              </div>
              <div className="paymentcontainer__left__footer__middletext__middle">
                You were charged ${data?.amountData} USD.{" "}
              </div>
              <div className="paymentcontainer__left__footer__middletext__bottomtext">
                Payment confirmation has been sent to your email
              </div>
              <div className="paymentcontainer__left__footer__middletext__bottomemail">
                {data?.emailData}
              </div>
              <div className="paymentcontainer__left__box">
                <div className="paymentcontainer__left__box__container">
                  <div className="paymentcontainer__left__box__container__left">
                    {" "}
                    <div className="paymentcontainer__left__box__container__left__top">
                      Start your fitness journey right away by downloading our
                      app
                    </div>
                    <div className="paymentcontainer__left__box__container__left__bottom">
                      <img src={Apple} alt="" />
                      <img src={Google} alt="" />
                    </div>
                  </div>
                  <div className="paymentcontainer__left__box__container__image">
                    <img src={Download} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="paymentcontainer__left__box"></div>
        </div>
        <div className="paymentcontainer__right">
          <div className="paymentcontainer__right__top">
            <div className="paymentcontainer__right__top__text">
              {" "}
              Package Details
            </div>
            <div className="paymentcontainer__right__top__amount">
              ${data?.amountData}
            </div>
            <div className="paymentcontainer__right__top__class">
              {data?.productNameData}
            </div>
          </div>
          <div className="paymentcontainer__right__bottom">
            <div className="paymentcontainer__right__bottom__heading">
              Invoice details
            </div>
            <div className="paymentcontainer__right__bottom__amount">
              Total Amount
              <strong>
                ${data?.amountData} {data?.currencyData?.toUpperCase()}
              </strong>
            </div>
            <div className="paymentcontainer__right__bottom__invoice">
              Invoice no.
              <strong>{data?.invoiceNumData}</strong>
            </div>
            <div className="paymentcontainer__right__bottom__payment">
              Payment Method
              <strong>{data?.paymentMethodData}</strong>
            </div>
            <div className="paymentcontainer__right__bottom__date">
              Date
              <strong>{moment(data?.dateData).format("LL")}</strong>
            </div>

            <div className="needhelpContainer">
              {" "}
              <div className="needhelp">
                <strong>Need help?</strong>
                <div className="needhelp__middle">
                  Contact Us
                  <div className="needhelp__middle__or">or</div>
                </div>
                <div>+1234556</div>
              </div>
              <div>
                <img src={NeedHelp} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Payment;
