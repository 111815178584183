import React from "react";
import downArrowDropdown from "../../images/downArrowDropdown.svg";

const DatePickerComponent = React.forwardRef((props, ref) => {
  // const CustomInput = React.forwardRef((props, ref) => {
  let color = props.color;
  return (
    <div style={{ backgroundColor: { color } }}>
      <img
        alt=""
        style={{ height: "6px", width: "12px", backgroundColor: "#F2F9FF" }}
        onClick={props.onClick}
        src={downArrowDropdown}
        ref={ref}
      />
    </div>
  );
});

export default DatePickerComponent;
