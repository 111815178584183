import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./../../../store";
import _ from "lodash";
import React from "react";

// Define a type for the slice state
interface NotificationState {
  toasts:
    | [
        {
          title: string;
          description: string;
          backgroundColor: string;
          icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
        }
      ]
    | []
    | any;
}

// Define the initial state using that type
const initialState: NotificationState = {
  toasts: []
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    sendNotification(
      state,
      action: {
        payload: {
          title: string;
          description: string;
          backgroundColor: string;
          icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
        };
      }
    ) {
      const id = Math.floor(Math.random() * 101 + 1);
      state.toasts.push({ ...action.payload, id });
    },
    removeToast(state, action) {
      const listItemIndex = state.toasts.findIndex(
        e => e.id === action.payload.id
      );
      const list = _.clone(state.toasts);
      list.splice(listItemIndex, 1);
      state.toasts = list;
    }
  }
});

// Other code such as selectors can use the imported `RootState` type
export const leads = (state: RootState) => state.toasts;
export const { sendNotification, removeToast } = notificationSlice.actions;

export default notificationSlice.reducer;
