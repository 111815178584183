import ModalComponent from "../../../component/ModalComponent/ModalComponent";
import { useEffect, useState } from "react";
import NutritentData from "../../../Utils/nutrition-data.json";
import { ReactComponent as Check } from "../../../images/check.svg";
import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import { sendNotification } from "../../../app/features/notifications";
import ButtonComponent from "../../../component/ButtonComponent/ButtonComponent";
import { createFavorite, deleteFavorite } from "../../../graphql/mutations";
import { useAppSelector } from "../../../hooks";

import ImageComponent from "../ImageComponent";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import "./Food.scss";
import { Grid } from "@material-ui/core";
import { ReactComponent as PlusCircle } from "../../../images/GreenStar.svg";
import { ReactComponent as MinusCircle } from "../../../images/MinusCircle.svg";
import _ from "lodash";
import TextField from "@mui/material/TextField";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import {
  createFoodsFn,
  updateFoodsFn,
} from "../../../app/features/Food/foodSlice";
import InputAdornment from "@mui/material/InputAdornment";
const additionalUnits = {
  data: [
    { no: 1, unitName: null, amount: null, grams: null },
    { no: 2, unitName: null, amount: null, grams: null },
    { no: 3, unitName: null, amount: null, grams: null },
    { no: 4, unitName: null, amount: null, grams: null },
  ],
  default: 0,
};
const initialStateFood = {
  calories: null,
  carbs: null,
  description: "",
  fats: null,
  foodGroup: null,
  gramsPerServe: null,
  imageUrl: null,
  name: "",
  protien: null,
  servingDescription: "",
  servingSize: null,
  additionalUnits: additionalUnits,
  moreFields: { data: NutritentData.data },
  tenant: null,
};
const Food = (props) => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState<any>();
  const [isFavorite, setIsFavorite] = useState<any>({
    status: false,
    item: [],
  });

  const listTenant = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;

  const [showInput, toggleShowInput] = useState(false);
  const [hiddenFields, setHiddenFields] = useState({
    Sugars: true,
    Fats: true,
  });
  const [formError, setFormError] = useState({
    name: "",
    description: "",
    calories: "",
    carbs: "",
    fats: "",
    protien: "",
    servingSize: "",
    servingDescription: "",
    gramsPerServe: "",
  });

  const { favorites, ...foodItemWithoutFavorite } =
    props.item || initialStateFood;
  const [foodItem, setFoodItem] = useState(foodItemWithoutFavorite);
  const [favoritesItem, setIsFavoritesItem] = useState<any>();

  useEffect(() => {
    if (favorites && favorites.items) {
      const data = favorites.items.filter((item) => !item._deleted);

      setIsFavoritesItem(data);
      if (data.length > 0) setIsFavorite({ status: true, item: data });
    }
  }, [favorites]);
  useEffect(() => {
    let moreFieldsInitData;
    let additionalUnitsData;
    if (
      foodItem.moreFields &&
      typeof foodItem.moreFields === "string" &&
      JSON.parse(foodItem.moreFields).data
    ) {
      moreFieldsInitData = JSON.parse(foodItem.moreFields).data;
    } else {
      moreFieldsInitData =
        foodItem.moreFields && foodItem.moreFields.data
          ? foodItem.moreFields.data
          : [...NutritentData.data];
    }

    if (
      foodItem.additionalUnits &&
      typeof foodItem.additionalUnits === "string" &&
      JSON.parse(foodItem.additionalUnits).data
    ) {
      additionalUnitsData = JSON.parse(foodItem.additionalUnits).data;
    } else {
      additionalUnitsData =
        foodItem.additionalUnits && foodItem.additionalUnits.data
          ? foodItem.additionalUnits.data
          : [...additionalUnits.data];
    }

    setFoodItem({
      ...foodItem,
      ...{
        additionalUnits: { data: additionalUnitsData },
        moreFields: { data: moreFieldsInitData },
      },
    });
    return () => {
      setFoodItem({ ...{ initialStateFood } });
    };
  }, []);
  const hideOnLoad = ["Sugars", "Fats"];
  const onClickShow = (item) => {
    const data = {};
    data[item] = !hiddenFields[item];
    setHiddenFields({ ...hiddenFields, ...data });
  };
  const renderAdditionalUnits = () => {
    let initData = foodItem.additionalUnits;
    if (
      foodItem.additionalUnits &&
      typeof foodItem.additionalUnits === "string"
    ) {
      initData = JSON.parse(foodItem.additionalUnits).data;
    } else {
      initData = foodItem.additionalUnits
        ? foodItem.additionalUnits.data
        : [...additionalUnits.data];
    }
    return initData?.map((item) => {
      return (
        <>
          <div className="form-group foodrows additionalUnits">
            <Grid container spacing={3}>
              <Grid
                item
                xs={2}
                justifyContent="center"
                alignItems="center"
                className="centered-text"
              >
                <label className="col-form-label " htmlFor="calories">
                  Unit {item.no + 1}
                </label>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id={"amount" + item.no}
                  value={item.amount}
                  onChange={onAdditionalUnitsChange.bind(this, item, "amount")}
                  name="amount"
                  label="Amount"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={
                    foodItem.tenant === null ||
                    tenant === foodItem.tenant[0] ||
                    (tenant === "ADMIN" && foodItem.tenant.includes("ADMIN"))
                      ? false
                      : true
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Unit Name"
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id={"unitName" + item.no}
                  value={item.unitName}
                  onChange={onAdditionalUnitsChange.bind(
                    this,
                    item,
                    "unitName"
                  )}
                  disabled={
                    foodItem.tenant === null ||
                    tenant === foodItem.tenant[0] ||
                    (tenant === "ADMIN" && foodItem.tenant.includes("ADMIN"))
                      ? false
                      : true
                  }
                />
              </Grid>
              <Grid item xs={1} className="centered-text">
                <div className="equal">=</div>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  className="kg"
                  label="Gram Weight"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">g</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id={"grams" + item.no}
                  value={item.grams}
                  onChange={onAdditionalUnitsChange.bind(this, item, "grams")}
                  disabled={
                    foodItem.tenant === null ||
                    tenant === foodItem.tenant[0] ||
                    (tenant === "ADMIN" && foodItem.tenant.includes("ADMIN"))
                      ? false
                      : true
                  }
                />
              </Grid>
            </Grid>
          </div>
        </>
      );
    });
  };
  const renderNutritionFacts = () => {
    // const initData =
    //   foodItem.moreFields && foodItem.moreFields.data
    //     ? [...foodItem.moreFields.data]
    //     : [...NutritentData.data];

    let initData: any;
    if (foodItem.moreFields && typeof foodItem.moreFields === "string") {
      initData = JSON.parse(foodItem.moreFields).data;
    } else {
      initData = foodItem.moreFields
        ? foodItem.moreFields.data
        : [...NutritentData.data];
    }

    const moreFieldsData = NutritentData.data.map((data) => {
      const matchingItems = initData.filter(
        (item) => item.nutrition === data.nutrition
      );
      const q = matchingItems.length > 0 ? matchingItems[0]["qty"] : null;
      return { ...data, qty: q };
    });

    const data = moreFieldsData?.reduce((acc, cur) => {
      if (cur?.category && acc[cur?.category]) {
        acc[cur.category].push(cur);
      } else {
        acc[cur.category] = [];
        acc[cur.category].push(cur);
      }
      return acc;
    }, {});
    return (
      data &&
      Object.keys(data).map((item, index) => {
        return (
          <>
            {hideOnLoad.indexOf(item) >= 0 ? (
              <div
                className={`show-more ${hiddenFields[item] ? "" : "show"}`}
                onClick={onClickShow.bind(this, item)}
              >
                {!showInput && hiddenFields[item] ? (
                  <PlusCircle onClick={() => toggleShowInput(!showInput)} />
                ) : (
                  <MinusCircle onClick={() => toggleShowInput(!showInput)} />
                )}
                {hiddenFields[item] ? "Show" : "Hide"} more {item}
              </div>
            ) : (
              <div className="meal_modal_heading">{item}</div>
            )}

            <div
              className={`nutritionFacts ${
                hiddenFields[item] ? "hide" : "show"
              }`}
            >
              {data[item].map((v, i) => {
                return (
                  <div className="form-group foodrows">
                    <label className="col-form-label">{v.nutrition}</label>
                    <div className="add-inputbox">
                      {
                        <div className="sm foodrows marginRight">
                          <input
                            id={v.nutrition + "_id"}
                            value={v.qty}
                            onChange={onFoodPropertyChange.bind(this, v)}
                            className="form-control"
                            name={v.nutrition}
                            type="number"
                            disabled={
                              foodItem.tenant === null ||
                              tenant === foodItem.tenant[0] ||
                              (tenant === "ADMIN" &&
                                foodItem.tenant.includes("ADMIN"))
                                ? false
                                : true
                            }
                          />
                          <span>{v.unit}</span>
                        </div>
                      }
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        );
      })
    );
  };
  //   useEffect(() => {
  //     (async () => {
  //       setImageUrl(await Storage.get(foodItem.imageUrl));
  //     })();
  //   }, [foodItem]);
  useEffect(() => {
    if (selectedItem) {
      // TODO: Need to refactor
      delete selectedItem.createdAt;
      delete selectedItem.updatedAt;
      delete selectedItem._deleted;
      delete selectedItem._lastChangedAt;
      delete selectedItem.type;
      let moreDetailsParsed;
      if (selectedItem.moreFields) {
        moreDetailsParsed = JSON.parse(selectedItem.moreFields);
      }

      setFoodItem({
        ...selectedItem,
        ...{ moreFields: moreDetailsParsed },
      });
    }
  }, [selectedItem]);
  const onFoodPropertyChange = (nutritionPayload, e) => {
    nutritionPayload.qty = e.target.value;

    setFoodItem({
      ...foodItem,
      ...{
        moreFields: {
          data: foodItem.moreFields.data.map((obj) =>
            nutritionPayload.nutrition === obj.nutrition
              ? nutritionPayload
              : obj
          ),
        },
      },
    });
  };
  const onAdditionalUnitsChange = (additionalUnit, name, e) => {
    additionalUnit[name] = e.target.value;

    setFoodItem({
      ...foodItem,
      ...{
        additionalUnits: {
          data: foodItem.additionalUnits.data.map((obj) =>
            additionalUnit[name] === obj["name"] ? additionalUnit : obj
          ),
        },
      },
    });
  };
  const onFoodPropertyInfoChange = (name, e) => {
    const data = {};
    data[name] = e.target.value;
    setFoodItem({
      ...foodItem,
      ...data,
    });
  };
  async function onChangeImage(e: any) {
    const file = e.target.files[0];
    try {
      await trackPromise(
        Storage.put(file.name, file, {
          contentType: "image/svg+xml/jpeg/png", // contentType is optional
        }).then(async (result) => {
          setFoodItem({
            ...foodItem,
            ...{
              imageUrl: result.key,
            },
          });
        })
      );
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }
  const updateFoodData = async () => {
    const { __typename, ...cleanedFoodItem } = foodItem;

    try {
      if (isFavorite.status && favoritesItem.length === 0) {
        const user = await Auth.currentAuthenticatedUser();

        const trainerID = user?.attributes?.sub;

        const favData = {
          trainerID: trainerID,
          foodId: foodItem.id,
          tenant: tenant,
        };

        await API.graphql(graphqlOperation(createFavorite, { input: favData }));
      } else if (favoritesItem.length > 0 && !isFavorite.status) {
        await API.graphql(
          graphqlOperation(deleteFavorite, {
            input: {
              id: isFavorite.item[0].id,
              _version: isFavorite.item[0]._version,
            },
          })
        );
      }

      dispatch(
        updateFoodsFn({
          input: {
            ...cleanedFoodItem,
            ...{
              moreFields: JSON.stringify(foodItem.moreFields),
              additionalUnits: JSON.stringify(foodItem.additionalUnits),
            },
            tenant:
              tenant === "ADMIN" ? ["ADMIN", "Trainers", "Clients"] : tenant,
          },
          favorite: isFavorite,
        })
      );
      return true;
    } catch (e) {
      return false;
    }
  };
  const validateField = (name, e) => {
    if (e.target.value.trim() === "") {
      let errorname = "";
      if (name === "description") {
        errorname = "Description";
      }
      if (name === "name") {
        errorname = "Name";
      }
      if (name === "calories") {
        errorname = "Calories";
      }
      if (name === "carbs") {
        errorname = "Carbs";
      }
      if (name === "fats") {
        errorname = "Fats";
      }
      if (name === "protien") {
        errorname = "Protein";
      }
      if (name === "servingSize") {
        errorname = "Serving size";
      }
      if (name === "servingDescription") {
        errorname = "Serving description";
      }
      if (name === "gramsPerServe") {
        errorname = "Grams per serve";
      }
      setFormError((prevState) => ({
        ...prevState,
        [name]: `${errorname}  is required`,
      }));
    } else {
      setFormError((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  const createFoodData = async () => {
    dispatch(
      createFoodsFn({
        input: {
          ...foodItem,
          ...{
            moreFields: JSON.stringify(foodItem.moreFields),
            additionalUnits: JSON.stringify(foodItem.additionalUnits),
          },
          tenant:
            tenant === "ADMIN" ? ["ADMIN", "Trainers", "Clients"] : [tenant],
        },
        favorite: isFavorite.status,
      })
    );
  };
  const submit = async (e) => {
    e.preventDefault();
    let valid = true;

    Object.keys(formError).forEach((key) => {
      if (
        foodItem[key] === null ||
        (typeof foodItem[key] === "string" && foodItem[key].trim() === "") ||
        (typeof foodItem[key] === "object" &&
          Object.keys(foodItem[key]).length === 0)
      ) {
        let errorname = "";
        if (key === "name") {
          errorname = "Name";
        }
        if (key === "description") {
          errorname = "Description";
        }
        if (key === "calories") {
          errorname = "Calories";
        }
        if (key === "carbs") {
          errorname = "Carbs";
        }
        if (key === "fats") {
          errorname = "Fats";
        }
        if (key === "protien") {
          errorname = "Protein";
        }
        if (key === "servingSize") {
          errorname = "Serving size";
        }
        if (key === "servingDescription") {
          errorname = "Serving description";
        }
        if (key === "gramsPerServe") {
          errorname = "Grams per serve";
        }

        setFormError((prevState) => ({
          ...prevState,
          [key]: `${errorname} is required.`,
        }));
        valid = false;
      }
    });
    if (valid) {
      e.preventDefault();
      if (foodItem.id) {
        delete foodItem.createdAt;
        delete foodItem.updatedAt;
        delete foodItem._deleted;
        delete foodItem._lastChangedAt;
        delete foodItem.type;

        const result = await trackPromise(updateFoodData());
        if (result) {
          dispatch(
            sendNotification({
              title: "Success",
              description: "Food updated successfully",
              backgroundColor: "#5cb85c",
              icon: Check,
            })
          );
        } else {
          dispatch(
            sendNotification({
              title: "Oops!",
              description: "we couldn’s save your changes!",
              backgroundColor: "#AA4A44",
            })
          );
        }
      } else {
        await trackPromise(createFoodData());
        dispatch(
          sendNotification({
            title: "Success",
            description: "New food created!",
            backgroundColor: "#5cb85c",
            icon: Check,
          })
        );
      }
      // props.setShowFood(false);
      // setFoodItem(initialStateFood);
      props.handleClose();
    }
  };
  return (
    <ModalComponent
      handleClose={props.handleClose}
      onClose={props.closeModal}
      title={foodItem.id ? "Edit Food" : "Create New Food"}
      open={props.showFood}
    >
      <>
        <div className="AddMealContainer">
          <div className="AddMeal image">
            <div style={{ height: "200px" }}>
              {ImageComponent(props.imagePath, foodItem.imageUrl)}
            </div>

            <input
              type="file"
              accept="image/svg+xml, image/jpeg, image/png"
              onChange={onChangeImage}
              name="Image"
              style={{ padding: "10px 0" }}
              disabled={
                foodItem.tenant === null ||
                tenant === foodItem.tenant[0] ||
                (tenant === "ADMIN" && foodItem.tenant.includes("ADMIN"))
                  ? false
                  : true
              }
            />
            <div
              className="mealName"
              style={{ display: "flex", flexFlow: "column" }}
            >
              {foodItem.name} <label>{foodItem.description}</label>
            </div>

            {favoritesItem ? (
              <div
                onClick={() => {
                  setIsFavorite({
                    status: !isFavorite.status,
                    item: favoritesItem,
                  });
                }}
              >
                {isFavorite.status ? (
                  <StarIcon style={{ color: "gold" }} />
                ) : (
                  <StarBorderIcon />
                )}
              </div>
            ) : null}
          </div>
          <div className="mealContainer">
            <label className="meal_modal_heading">
              Enter the details of food item
            </label>
            <div className="form-group row">
              <label className="col-form-label " htmlFor="name">
                Food name
              </label>
              <div className="add-inputbox">
                <div>
                  <input
                    id="name"
                    value={foodItem.name}
                    onChange={onFoodPropertyInfoChange.bind(this, "name")}
                    onBlur={validateField.bind(this, "name")}
                    className="form-control"
                    name="name"
                    type="text"
                    disabled={
                      foodItem.tenant === null ||
                      tenant === foodItem.tenant[0] ||
                      (tenant === "ADMIN" && foodItem.tenant.includes("ADMIN"))
                        ? false
                        : true
                    }
                  />
                </div>

                {formError.name && (
                  <div className="errorMessage">{formError.name}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label " htmlFor="name">
                Food Description
              </label>
              <div className="add-inputbox">
                <div>
                  <input
                    id="name"
                    value={foodItem.description}
                    onChange={onFoodPropertyInfoChange.bind(
                      this,
                      "description"
                    )}
                    onBlur={validateField.bind(this, "description")}
                    className="form-control"
                    name="description"
                    type="text"
                    disabled={
                      foodItem.tenant === null ||
                      tenant === foodItem.tenant[0] ||
                      (tenant === "ADMIN" && foodItem.tenant.includes("ADMIN"))
                        ? false
                        : true
                    }
                  />
                </div>

                {formError.description && (
                  <div className="errorMessage">{formError.description}</div>
                )}
              </div>
            </div>
            <label className="meal_modal_heading">Serving Size Info</label>
            <div className="form-group foodrows">
              <label className="col-form-label " htmlFor="calories">
                Calories
              </label>
              <div className="add-inputbox">
                <div>
                  <input
                    id="calories"
                    value={foodItem.calories}
                    onChange={onFoodPropertyInfoChange.bind(this, "calories")}
                    onBlur={validateField.bind(this, "calories")}
                    className="form-control"
                    name="calories"
                    type="text"
                    disabled={
                      foodItem.tenant === null ||
                      tenant === foodItem.tenant[0] ||
                      (tenant === "ADMIN" && foodItem.tenant.includes("ADMIN"))
                        ? false
                        : true
                    }
                  />
                  kcal
                </div>

                {formError.calories && (
                  <div className="errorMessage">{formError.calories}</div>
                )}
              </div>
            </div>
            <div className="form-group foodrows">
              <label className="col-form-label " htmlFor="carbs">
                {" "}
                Carbs
              </label>
              <div className="add-inputbox">
                <div>
                  <input
                    id="carbs"
                    value={foodItem.carbs}
                    onChange={onFoodPropertyInfoChange.bind(this, "carbs")}
                    onBlur={validateField.bind(this, "carbs")}
                    className="form-control"
                    name="carbs"
                    type="text"
                    disabled={
                      foodItem.tenant === null ||
                      tenant === foodItem.tenant[0] ||
                      (tenant === "ADMIN" && foodItem.tenant.includes("ADMIN"))
                        ? false
                        : true
                    }
                  />
                  g
                </div>
                {formError.carbs && (
                  <div className="errorMessage">{formError.carbs}</div>
                )}
              </div>
            </div>
            <div className="form-group foodrows">
              <label className="col-form-label " htmlFor="fats">
                {" "}
                Fats
              </label>
              <div className="add-inputbox">
                <div>
                  <input
                    id="fats"
                    value={foodItem.fats}
                    onChange={onFoodPropertyInfoChange.bind(this, "fats")}
                    onBlur={validateField.bind(this, "fats")}
                    className="form-control"
                    name="fats"
                    type="text"
                    disabled={
                      foodItem.tenant === null ||
                      tenant === foodItem.tenant[0] ||
                      (tenant === "ADMIN" && foodItem.tenant.includes("ADMIN"))
                        ? false
                        : true
                    }
                  />
                  g
                </div>
                {formError.fats && (
                  <div className="errorMessage">{formError.fats}</div>
                )}
              </div>
            </div>
            <div className="form-group foodrows">
              <label className="col-form-label " htmlFor="protien">
                {" "}
                Protein
              </label>
              <div className="add-inputbox">
                <div>
                  <input
                    id="protien"
                    value={foodItem.protien}
                    onChange={onFoodPropertyInfoChange.bind(this, "protien")}
                    onBlur={validateField.bind(this, "protien")}
                    className="form-control"
                    name="protien"
                    type="text"
                    disabled={
                      foodItem.tenant === null ||
                      tenant === foodItem.tenant[0] ||
                      (tenant === "ADMIN" && foodItem.tenant.includes("ADMIN"))
                        ? false
                        : true
                    }
                  />
                  g
                </div>
                {formError.protien && (
                  <div className="errorMessage">{formError.protien}</div>
                )}
              </div>
            </div>

            <div className="form-group foodrows">
              <label className="col-form-label " htmlFor="servingSize">
                Serving size
              </label>
              <div className="add-inputbox">
                <div>
                  <input
                    id="servingSize"
                    value={foodItem.servingSize}
                    onChange={onFoodPropertyInfoChange.bind(
                      this,
                      "servingSize"
                    )}
                    onBlur={validateField.bind(this, "servingSize")}
                    className="form-control"
                    name="servingSize"
                    type="text"
                    disabled={
                      foodItem.tenant === null ||
                      tenant === foodItem.tenant[0] ||
                      (tenant === "ADMIN" && foodItem.tenant.includes("ADMIN"))
                        ? false
                        : true
                    }
                  />
                </div>
                {formError.servingSize && (
                  <div className="errorMessage">{formError.servingSize}</div>
                )}
              </div>
            </div>
            <div className="form-group foodrows">
              <label className="col-form-label " htmlFor="servingDescription">
                Serving Description
              </label>
              <div className="add-inputbox">
                <div>
                  <input
                    id="serving_description"
                    value={foodItem.servingDescription}
                    onChange={onFoodPropertyInfoChange.bind(
                      this,
                      "servingDescription"
                    )}
                    onBlur={validateField.bind(this, "servingDescription")}
                    className="form-control"
                    name="servingDescription"
                    type="text"
                    disabled={
                      foodItem.tenant === null ||
                      tenant === foodItem.tenant[0] ||
                      (tenant === "ADMIN" && foodItem.tenant.includes("ADMIN"))
                        ? false
                        : true
                    }
                  />
                </div>
                {formError.servingDescription && (
                  <div className="errorMessage">
                    {formError.servingDescription}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group foodrows">
              <label className="col-form-label " htmlFor="gramsPerServe">
                Grams per serving
              </label>
              <div className="add-inputbox">
                <div>
                  <input
                    id="gramsPerServing"
                    value={foodItem.gramsPerServe}
                    onChange={onFoodPropertyInfoChange.bind(
                      this,
                      "gramsPerServe"
                    )}
                    onBlur={validateField.bind(this, "gramsPerServe")}
                    className="form-control"
                    name="gramsPerServe"
                    type="number"
                    disabled={
                      foodItem.tenant === null ||
                      tenant === foodItem.tenant[0] ||
                      (tenant === "ADMIN" && foodItem.tenant.includes("ADMIN"))
                        ? false
                        : true
                    }
                  />
                </div>
                {formError.gramsPerServe && (
                  <div className="errorMessage">{formError.gramsPerServe}</div>
                )}
              </div>
            </div>
            <label className="meal_modal_heading">Additional units</label>
            <div>{renderAdditionalUnits()}</div>
            <div className="nutritionFacts">
              <label className="meal_modal_heading">Nutrition facts</label>
              {renderNutritionFacts()}
            </div>
          </div>
        </div>
        <div className="modalFooter">
          <ButtonComponent
            style={{ background: "grey" }}
            onClick={() => {
              props.handleClose();
            }}
          >
            Cancel
          </ButtonComponent>
          {foodItem.tenant === null || tenant === foodItem.tenant[0] ? (
            <ButtonComponent
              type="submit"
              className="addMealsButton"
              onClick={submit}
            >
              Submit
            </ButtonComponent>
          ) : (
            ""
          )}
        </div>
      </>
    </ModalComponent>
  );
};
export default Food;
