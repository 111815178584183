import { useState } from "react";
import "./Notes.scss";
const Notes = (props) => {
  const getRndInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const defaultStyle = {
    transform: `rotate(${getRndInteger(-8, 8)}deg)`,
  };
  const [style, setStyle] = useState(defaultStyle);

  const handleMouseEnter = () => {
    setStyle({
      transform: `rotate(0deg) scale(1.3)`,
    });
  };
  const handleMouseDown = (e) => {
    setStyle(defaultStyle);
  };
  return (
    <div
      className="workoutNotes"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseDown}
      style={style}
    >
      <i className="pin"></i>

      <p contentEditable={props.readOnly ? false : true} onBlur={props.onLeave}>
        {props.text ? props.text : "Notes:"}
      </p>
    </div>
  );
};
export default Notes;
