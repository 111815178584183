import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  console.log("token", token);

  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleVerification = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`/verify-email?token=${token}`);
        console.log("response", response);
        if (response.ok) {
          setIsVerified(true);
        } else {
          setError("Error verifying email id");
        }
      } catch (error) {
        console.error("Verification error:", error);
        setError("An error occurred during verification. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    handleVerification();
  }, [token]);

  const handleSuccess = () => {
    navigate("/");
  };

  return (
    <div className="verify-email">
      {isLoading ? (
        <p>Verifying email...</p>
      ) : isVerified ? (
        <div>
          <p>Email verification successful!</p>
          <button onClick={handleSuccess}>Continue to Trainer Portal</button>
        </div>
      ) : error ? (
        <div>
          <p>Error verifying email: {error}</p>
          <button onClick={() => navigate("/support")}>Contact Support</button>
        </div>
      ) : null}
    </div>
  );
};

export default VerifyEmail;
