import { listCustomFavorites } from "../../../graphql/customQueries";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { API, graphqlOperation } from "aws-amplify";

import _ from "lodash";

// Define the state shape for your list of food items
interface listFavoriteState {
  value: number;
  favorites: { items: any[]; nextToken: string | null; status: string | null };
  status: string | null;
}

// Create the initial state
const initialState: listFavoriteState = {
  value: 0,
  favorites: {
    items: [],
    nextToken: null,
    status: null,
  },
  status: null, // Initial status can be "idle" or any other initial status you prefer
};

export const fetchFavorites = createAsyncThunk(
  "food/listFavorites",
  async (nextToken: string | null) => {
    const response = await API.graphql(
      graphqlOperation(listCustomFavorites, { nextToken })
    );

    return response;
  }
);

export const favoriteSlice = createSlice({
  name: "Favorites",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
    logout: (state) => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFavorites.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchFavorites.fulfilled, (state, action) => {
        const sortedItems = action.payload["data"].listFavorites.items.filter(
          (item) => !item?._deleted
        );

        state.favorites = {
          items: state.favorites?.items.concat(sortedItems),
          nextToken: action.payload["data"].listFavorites.nextToken,
          status: "succeeded",
        };
      })
      .addCase(fetchFavorites.rejected, (state) => {
        state.favorites.status = "failed";
      });
  },
});
export const { increment, decrement, incrementByAmount, logout } =
  favoriteSlice.actions;
export default favoriteSlice.reducer;
