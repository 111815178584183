import "./PackageAttachment.scss";
import { useEffect, useState } from "react";
import LensShape from "../../images/LensShape.svg";
import Xcircle from "../../images/x-circle.svg";
import { API, graphqlOperation } from "aws-amplify";
import { listProducts } from "../../graphql/queries";
import { filterSearchData } from "../../Utils";
import Nodata from "../../images/noData.png";

const PackageAttachment = ({
  setPackage,
  setPackageDetail,
  check,
  id,
  setAttachment,
}) => {
  const [tab, setTab] = useState("Packages");
  const [packageData, setPackageData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [checkedOptions, setCheckedOptions] = useState([]);
  const tabs = ["Packages"];

  useEffect(() => {
    async function getPackages() {
      const client = await API.graphql(
        graphqlOperation(listProducts, {
          name: "",
          durationInMonths: "",
          price: "",
        })
      );
      const data = client.data.listProducts.items
        .filter((item) => !item._deleted && item.stripePriceId) // Filter out items without stripePriceId
        .map((item) => {
          if (item.description !== "") {
            const contentStateObject =
              item.description !== "" ? JSON.parse(item.description) : "";

            // Extract the text from blocks
            const blocks = contentStateObject.blocks;
            const plainText = blocks.map((block) => block.text).join(" ");

            return {
              ...item,
              description: plainText,
            };
          }
          return item; // Ensure the item is returned even if description is empty
        });
      setPackageData(data);
    }
    getPackages();
  }, []);

  useEffect(() => {
    if (checkedOptions.length === 0) {
      setPackage(false);
    } else {
      setPackage(true);
    }
  }, [checkedOptions]);

  const searchForData = (e) => {
    setInputValue(e.target.value);
    setSearchedData(filterSearchData(packageData, e.target.value, ["name"]));
  };

  const handleChange = (e) => {
    const name = e.id;
    const index = checkedOptions.indexOf(name);
    if (index === -1) {
      setCheckedOptions([...checkedOptions, name]);
      setPackageDetail([...checkedOptions, name]);
    } else {
      const newCheckedOptions = [...checkedOptions];
      newCheckedOptions.splice(index, 1);
      setCheckedOptions(newCheckedOptions);
      setPackageDetail(newCheckedOptions);
    }
  };

  const packageBlock = (data) => {
    return data
      .filter((item, i) => {
        return item?.status === false;
      })
      .map((item, i) => {
        return (
          <button
            className={`packageItem ${
              checkedOptions.includes(item.id) ? "active" : ""
            }`}
            onClick={() => {
              handleChange(item);
            }}
          >
            <div className="packageItemTop">
              <div>
                <span style={{ marginLeft: "-1px" }} className="itemPrice">
                  {"$" + item.price}
                </span>
              </div>

              <div className="round">
                <input
                  type="checkbox"
                  name={item.id}
                  checked={checkedOptions.includes(item.id)}
                  disabled={true}
                  id={`checkbox-${item.id}`} // Assign a unique ID to each checkbox
                />
                <label htmlFor={`checkbox-${item.id}`}></label>
              </div>
            </div>

            <span style={{ marginLeft: "-1px" }} className="itemNumber">
              {item.name}{" "}
            </span>
            <span style={{ marginLeft: "-1px" }} className="itemDuration">
              {"Duration " + item.durationInMonths + " months"}{" "}
            </span>
            <p className="itemDescription">{item.description}</p>
          </button>
        );
      });
  };

  return (
    <>
      <div className="packageBlock">
        <div className="upperPart">
          <div>
            <input
              onChange={(e) => searchForData(e)}
              placeholder="Browse Packages"
              className="inputPackageBlock"
            />
            <img className="lensImage" src={LensShape} alt=""></img>
            <img
              className="closePackage"
              onClick={() => {
                setAttachment(false);
              }}
              alt="close"
              src={Xcircle}
            />
          </div>

          <div className="tabs package">
            {tabs.map((tabItem) => (
              <div className="tabItem">
                <button
                  value={tabItem}
                  name="items"
                  className={
                    tab == tabItem ? "tabItemButtonDefault" : "tabItemButton"
                  }
                  onClick={() => setTab(tabItem)}
                >
                  {tabItem}
                </button>
              </div>
            ))}
          </div>
        </div>

        {tab == "Packages" ? (
          <div className="blocks">
            <div className="package">
              {packageData.length ? (
                inputValue.length ? (
                  searchedData.length ? (
                    packageBlock(searchedData)
                  ) : (
                    <div className="Food__body">
                      <img src={Nodata} alt="No data found" />
                    </div>
                  )
                ) : (
                  packageBlock(packageData)
                )
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default PackageAttachment;
