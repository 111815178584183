import "./MealItem.scss";
import { useState } from "react";
import Servings from "../../Library/Workout/Serving/Serving";
import { getAdditionUnits } from "../../../Utils";
import ScaleIcon from "@mui/icons-material/Scale";
import Image from "mui-image";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import DeleteComponent from "../../../component/Delete/Delete";
import CopyMealsModal from "../../../component/CopyMealsModal/CopyMealsModal";
import { CopyAll } from "@mui/icons-material";
import { Tooltip, IconButton } from "@material-ui/core";
import { useAppSelector } from "../../../hooks";
interface IProps {
  name: string;
  imageData: string;
  type: string;
  debouncedChangeHandler: (
    amount: any,
    unit: any,
    index: any,
    _version: any,
    type: any
  ) => void;
  data?: any;
  mealPlannerItemsMapId?: any;
  onDelete: (id: any, version: any) => void;
  onCopyRefresh?: (status: any) => void;
  servingSize: number;
  servingDescription: string;
  id: string;
  _version: number;
  grams?: number;
  userId?: string;
  date?: string;
  key?: string;
  endDate?: number;
  mealLayout?: any;
}

const MealItem = (props: IProps) => {
  const listTenant = useAppSelector((state) => state.tenant);
  const tenants = listTenant?.tenant?.currentTenant;

  const {
    name,
    debouncedChangeHandler,
    data,
    imageData,
    _version,
    servingDescription,
    servingSize,
    onDelete,
    id,
    grams,
    mealPlannerItemsMapId,
    userId,
    type,
    key,
    endDate,
  } = {
    ...props,
  };

  const tenant = props.data.tenant;

  const [isDeletes, setIsDeletes] = useState<any>(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const onCopyFoodClose = () => {
    setShowCopyModal(false);
  };
  return (
    <Paper
      key={key}
      sx={{
        p: 2,
        margin: "15px 0",
        maxWidth: 500,
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
      className="mealItem"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm container>
          <Grid item spacing={1}>
            <ButtonBase
              sx={{
                width: 100,
                height: 100,
              }}
            >
              <Image
                alt={name}
                fit="contain"
                style={{
                  width: "100%",
                  borderRadius: "5px",
                }}
                src={imageData}
              />
            </ButtonBase>
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction="column"
            style={{ padding: "0 5px" }}
          >
            <Typography
              gutterBottom
              variant="subtitle1"
              component="div"
              noWrap
              style={{
                textOverflow: "ellipsis",
                width: "100%",
              }}
            >
              {name}
            </Typography>

            <Grid>
              <Servings
                key={"serving" + key}
                allUnits={getAdditionUnits(data)}
                onServingChange={debouncedChangeHandler}
                selectedServingSize={servingSize}
                selectedServingDescription={servingDescription}
                id={id}
                mealPlannerItemId={mealPlannerItemsMapId}
                _version={_version}
                disabled={
                  type === "copyFood"
                    ? true
                    : tenant === null ||
                      tenants === tenant[0] ||
                      (tenants === "ADMIN" && tenant?.includes("ADMIN"))
                }
                // disabled={

                // }
              />
            </Grid>
            {grams ? (
              <Grid>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  noWrap
                  style={{
                    textOverflow: "ellipsis",
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "13.6052px",
                    lineHeight: "20px",

                    color: "#34465e",
                  }}
                >
                  <ScaleIcon sx={{ fontSize: "16px !important" }} />
                  <span style={{ paddingLeft: "5px" }}>
                    {Math.round(grams)} grams
                  </span>
                </Typography>
              </Grid>
            ) : null}
          </Grid>

          <Grid
            item
            xs={2}
            direction={"column"}
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid
              item
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"row-reverse"}
              display={"flex"}
            >
              {type === "copyFood" ? (
                <DeleteComponent
                  deletes={isDeletes}
                  setDeletes={setIsDeletes}
                  onDeletes={onDelete}
                />
              ) : tenant === null ||
                tenants === tenant[0] ||
                (tenants === "ADMIN" && tenant?.includes("ADMIN")) ? (
                <DeleteComponent
                  deletes={isDeletes}
                  setDeletes={setIsDeletes}
                  onDeletes={onDelete}
                />
              ) : null}

              {tenant === null ||
              tenants === tenant[0] ||
              (tenants === "ADMIN" && tenant?.includes("ADMIN")) ? (
                <Tooltip title="Click here to copy this food to other days!">
                  <IconButton
                    onClick={() => {
                      setShowCopyModal(true);
                    }}
                  >
                    <CopyAll fontSize="medium" />
                  </IconButton>
                </Tooltip>
              ) : null}

              {showCopyModal && (
                <CopyMealsModal
                  show={showCopyModal}
                  onClose={onCopyFoodClose}
                  onCopyRefresh={props.onCopyRefresh}
                  mealLayout={props.mealLayout}
                  data={{
                    calendar: [props.data],
                    userId: userId,
                    selectedDate: props.date,
                  }}
                  date={props.date}
                  type={
                    props.type === "dietPlanFood" ? "dietPlanFood" : "copyFood"
                  }
                  endDate={endDate}
                  title={"Copy food"}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default MealItem;
