import { useEffect, useState } from "react";
import TopNav from "../TopNav/TopNav";
import "./LeadDetail.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Lead } from "../../images/lead_avatar.svg";
import { ReactComponent as Health } from "../../images/health.svg";
import { ReactComponent as Mail } from "../../images/mail.svg";
import { ReactComponent as Clipboard } from "../../images/clipboard.svg";
import { ReactComponent as Phone } from "../../images/phone.svg";
import { ReactComponent as Client } from "../../images/client.svg";

import { ReactComponent as Save } from "../../images/save.svg";
import { ReactComponent as Plus } from "../../images/save1.svg";
import { ReactComponent as Check } from "../../images/check.svg";
import X from "../../images/X.svg";
import FtButton from "../Ft-Button/Ft-Button";
import { useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import More from "../More/More";
import { getLead } from "../../graphql/queries";
import { convertClient, updateLead } from "../../graphql/mutations";
import { useDispatch } from "react-redux";

import { sendNotification } from "../../app/features/notifications";
import Chat from "../../component/Chat/Chat";
import { listEnumValues } from "../../graphql/customQueries";
import { CometChat } from "@cometchat-pro/chat";

const LeadDetail = () => {
  const navigate = useNavigate();
  function goToLeads() {
    navigate(`/leads`);
  }
  const { id } = useParams();
  // TODO: refactor type any
  const [leadDetail, setLeadDetail] = useState<any>();
  const [message, setMessage] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState<any>();
  const [notes, setNotes] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState<any>();
  const [currentStatus, setCurrentStatus] = useState<any>();
  // const[load,setLoad]=
  const dispatch = useDispatch();
  useEffect(() => {
    async function getLeadDetail() {
      const lead = await API.graphql(graphqlOperation(getLead, { id }));
      const status = await API.graphql(
        graphqlOperation(listEnumValues, { enum: "LeadStatuses" })
      );
      // setNotesData(lead["data"]["getLead"]["notes"]);

      setCurrentStatus(lead["data"]["getLead"]["activeStatus"]);
      setLeadDetail(lead);
      setStatus(status);
    }
    getLeadDetail();
  }, [updatedStatus]);

  async function updateStatus() {
    // TODO: updatedAt should be at server end.
    //status payload need to be discussed
    // status: leadDetail?.data?.getLead?.status
    // ? [
    //     ...leadDetail?.data?.getLead?.status,
    //     { leadStatus: selectedStatus, updatedAt: new Date() },
    //   ]
    // : [{ leadStatus: selectedStatus, updatedAt: new Date() }],
    const res = await API.graphql(
      graphqlOperation(updateLead, {
        input: {
          id: id,
          activeStatus: selectedStatus,
          _version: leadDetail?.data.getLead?._version,
          status: { leadStatus: selectedStatus, updatedAt: new Date() },
        },
      })
    );
    dispatch(
      sendNotification({
        title: "Success",
        description: "Status updated successfully!",
        backgroundColor: "#5cb85c",
        icon: Check,
      })
    );
    setUpdatedStatus(res);
  }
  async function getLeadAndConvert() {
    // TODO: updatedAt should be at server end.
    const res: any = await API.graphql(
      graphqlOperation(convertClient, {
        id: id,
      })
    );
    if (
      res?.data?.convertClient === "{status=success, message=User data added}"
    ) {
      dispatch(
        sendNotification({
          title: "Success",
          description: "New client added! Redirecting to client",
          backgroundColor: "#5cb85c",
          icon: Check,
        })
      );
      // const { name, email } = leadDetail?.data?.getLead;
      // let authKey: string = "4a32d52918d469edd39450436b0e2d9293cb7308",
      //   UID = id;

      // var user = new CometChat.User(UID);

      // user.setName(name);
      // user.setRole("3");

      // CometChat.createUser(user, authKey).then(
      //   (user: CometChat.User) => {
      //     console.log("user created", user);
      //   },
      //   (error: CometChat.CometChatException) => {
      //     console.log("error", error);
      //   }
      // );

      setTimeout(() => {
        navigate(`/clients/${id}/overview`);
      }, 3000);
    }
  }
  function obSaveChanges() {
    const updatedResponse = updateStatus();
  }
  function executeConvertClient() {
    getLeadAndConvert();
  }
  const addNote = async () => {
    if (message.length > 1) {
      const res = await API.graphql(
        graphqlOperation(updateLead, {
          input: {
            id: id,
            notes: message,
          },
        })
      );
      setMessage("");
    }
  };
  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const showNotes = async () => {
    const lead = await API.graphql(graphqlOperation(getLead, { id }));
    setNotesData(lead["data"]["getLead"]["notes"]);
    setNotes(true);
  };

  return (
    <>
      <TopNav>
        <button className="backtoleads" onClick={goToLeads}>
          {" "}
          <p> &#60; Back to Leads</p>{" "}
        </button>
      </TopNav>
      <div className="LeadDetails">
        <div className="leaddetails__header">
          <div className="leaddetails__header__text">Lead Detail</div>
          <More
            id={id}
            version={leadDetail?.data.getLead?._version}
            updatedStatus={updatedStatus}
            setUpdatedStatus={setUpdatedStatus}
            lead={leadDetail}
          />
          {currentStatus !== "WON" ? (
            <FtButton
              className="filterbtn"
              margin="0 0 0 10px"
              border="1px solid #109CF1"
              color="white"
              height="40px"
              onClick={executeConvertClient}
              radius="5px"
              width="189px"
              children="Convert as Client"
              icon={<Client />}
            />
          ) : (
            ""
          )}

          <FtButton
            className="createnewbtn"
            margin="0 0 0 10px"
            border="1px solid #109CF1"
            color="#109CF1"
            height="40px"
            onClick={obSaveChanges}
            radius="5px"
            disabled={selectedStatus ? false : true}
            width="169px"
            children="Save changes"
            icon={<Save />}
          />
        </div>
        <div className="leaddetails__container">
          <div className="leaddetails__container__content">
            <div className="leaddetails__container__content__header">
              <div className="leaddetails__container__content__header__profile">
                <div className="leaddetails__container__content__header__profile__name">
                  <div className="leaddetails__container__content__header__profile__name__image">
                    <Lead />
                  </div>
                  <div className="leaddetails__container__content__header__profile__name__username">
                    {leadDetail?.data.getLead?.name}
                    <span className="leaddetails__container__content__header__profile__name__age">
                      {leadDetail?.data.getLead?.ageRange}{" "}
                    </span>
                  </div>
                </div>
                <div className="leaddetails__container__content__header__profile__name__health">
                  <div className="leaddetails__container__content__header__profile__name__health__goal">
                    <span>Goal</span>
                    <span className="leaddetails__container__content__header__profile__name__health__goal__color">
                      {leadDetail?.data.getLead?.goal}
                    </span>
                  </div>

                  <div className="leaddetails__container__content__header__profile__name__health__image">
                    <Health />
                  </div>
                </div>
              </div>
            </div>
            <div className="leaddetails__container__content__contact">
              <div>
                <div className="leaddetails__container__content__contact__email">
                  <div className="leaddetails__container__content__contact__email__filter">
                    <Mail />
                  </div>
                  <div className="leaddetails__container__content__contact__email__filter__text">
                    EMAIL ADDRESS
                  </div>
                </div>
                <div className="leaddetails__container__content__contact__email__filter__text__email">
                  {leadDetail?.data.getLead?.email}
                </div>
              </div>

              <div>
                <div className="leaddetails__container__content__contact__phoneno">
                  <div className="leaddetails__container__content__contact__phoneno__filter">
                    <Phone />
                  </div>
                  <div className="leaddetails__container__content__contact__phoneno__filter__text">
                    PHONE NUMBER
                  </div>
                </div>
                <div className="leaddetails__container__content__contact__phoneno__filter__text__phoneno">
                  {leadDetail?.data.getLead?.phone}
                </div>
              </div>
            </div>

            <div className="leaddetails__container__content__chat">
              <Chat
                leadDetail={
                  leadDetail && leadDetail.data ? leadDetail.data.getLead : {}
                }
              />
            </div>
          </div>
          <div className="leaddetails__container__sidebar">
            <div className="leaddetails__container__sidebar__list">
              <div className="leaddetails__container__sidebar__list__scroll">
                <h2>I would like to achieve</h2>

                <p>{leadDetail?.data.getLead?.achievement}</p>

                <h2>Motivation to start</h2>

                <p>{leadDetail?.data.getLead?.motivation}</p>
              </div>
            </div>
            <div className="leaddetails__container__sidebar__status">
              <h2>Status</h2>
              <select
                className="statusbtn"
                onChange={(event) => setSelectedStatus(event.target.value)}
              >
                {status?.data?.enum?.enumValues.map(({ name }, item) => {
                  return (
                    <option
                      key={item}
                      value={name}
                      selected={
                        leadDetail?.data.getLead?.activeStatus === name
                          ? true
                          : false
                      }
                    >
                      {name.replace("_", " ")}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="leaddetails__container__sidebar__notes">
              <h2>
                <i>
                  <Clipboard />
                </i>
                <span style={{ paddingLeft: "5px" }}>Notes</span>
              </h2>

              <div className="leaddetails__container__sidebar__notes__container">
                {notes ? (
                  <div style={{ height: "200px" }}>
                    <div className="leaddetails__container__sidebar__notes__container__notes">
                      <div className="leaddetails__container__sidebar__notes__container__notes__sub">
                        <div className="leaddetails__container__sidebar__notes__container__notes__sub__header">
                          Saved Notes
                          <div className="leaddetails__container__sidebar__notes__container__notes__sub__header__closed">
                            <img
                              style={{ width: "20px" }}
                              onClick={() => {
                                setNotes(false);
                              }}
                              src={X}
                              alt=""
                            />
                          </div>
                        </div>
                        {/* <div className="leaddetails__container__sidebar__notes__container__notes__sub__closed">
                          <img
                            style={{ width: "20px" }}
                            onClick={() => {
                              setNotes(false);
                            }}
                            src={X}
                            alt=""
                          />
                        </div> */}
                      </div>
                      {notesData?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="leaddetails__container__sidebar__notes__container__notes__item"
                          >
                            {item}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div style={{ height: "200px" }}>
                    <textarea
                      className="leaddetails__container__sidebar__notes__container__textArea"
                      placeholder="Type you notes here for internal reference"
                      onChange={handleChange}
                      value={message}
                    ></textarea>
                  </div>
                )}

                <div className="notestypebuttons">
                  <button
                    className="text_button"
                    onClick={showNotes}
                    // onBlur={() => {
                    //   setNotes(false);
                    // }}
                  >
                    View Saved
                  </button>
                  <button
                    className="text_button__plus"
                    type="button"
                    onClick={addNote}
                  >
                    <Plus />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LeadDetail;
