import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Auth } from "aws-amplify";
import _ from "lodash";

interface TenantState {
  tenant: { currentTenant: string | null; status: string | null };
}

// Define the initial state using that type
export const initialState: TenantState = {
  tenant: { currentTenant: null, status: null },
};

export const fetchTenant = createAsyncThunk(
  "tenant/currentTenant",
  async (nextToken: string | null) => {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    const tenant = groups?.filter((item) => item !== "Trainers");

    return tenant[0];
  }
);

export const tenantSlice = createSlice({
  name: "tenant",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    logout: (state) => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTenant.pending, (state, action) => {
        state.tenant.status = "loading";
      })
      .addCase(fetchTenant.fulfilled, (state, action) => {
        state.tenant = { currentTenant: action.payload, status: "succeeded" };
      });
  },
});

// Other code such as selectors can use the imported `RootState` type
// export const tenant = (state: RootState) => state.tenant;
export const { logout } = tenantSlice.actions;
export default tenantSlice.reducer;
