import { useRef, useState } from "react";

import "./More.scss";
import { ReactComponent as Setting } from "../../images/more.svg";

import { ReactComponent as Spam } from "../../images/Spam.svg";
import { API, graphqlOperation } from "aws-amplify";
import { updateLead } from "../../graphql/mutations";
interface Props {
  id: any;
  version: number;
  updatedStatus: any;
  setUpdatedStatus: any;
  lead: any;
}
const More = (props: Props) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const onClick = () => setIsActive(!isActive);

  const options = [
    {
      name: "Spam",
      icon: <Spam />,
      onClick: () => handleSpamClick(),
    },
  ];
  function handleSpamClick() {
    if (props?.lead?.data?.getLead?.state === "SPAM") {
      return false;
    } else {
      updateStatusLost();
    }
  }
  async function updateStatusLost() {
    const res = await API.graphql(
      graphqlOperation(updateLead, {
        input: {
          id: props.id,
          activeStatus: "LOST",
          _version: props.version,
          state: "SPAM",
          status: { leadStatus: "LOST", updatedAt: new Date() },
        },
      })
    );

    props.setUpdatedStatus(res);
  }

  return (
    <div className="MoreContainer">
      <button onClick={onClick} className="moretrigger">
        <div className="icon">
          <Setting />
        </div>
      </button>
      <nav
        ref={dropdownRef}
        className={`more ${isActive ? "active" : "inactive"}`}
      >
        <form>
          <div className="MoreContainer__content">
            <>
              {options.map((option, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className={`Options ${
                        props?.lead?.data?.getLead?.state === "SPAM"
                          ? "disabled"
                          : ""
                      }`}
                      onClick={option.onClick}
                    >
                      <div>{option.icon}</div>
                      <div
                        className={`Options__text ${
                          option.name === "Spam" ? "redcol" : ""
                        }`}
                      >
                        {option.name}
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          </div>
        </form>
      </nav>
    </div>
  );
};
export default More;
