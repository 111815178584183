import { useEffect, useState, useRef } from "react";
import { listMealTypes } from "../../../../graphql/queries";
import { getMealLayoutManager } from "../../../../graphql/queries";
import { useParams, useNavigate } from "react-router-dom";
import DeleteComponent from "../../../../component/Delete/Delete";
import "./CreateLayoutList.scss";
import { createMealLayoutManager } from "../../../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import xcircle from "../../../../images/x-circle.svg";

import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { ReactComponent as Check } from "../../../../images/check.svg";
import { sendNotification } from "../../../../app/features/notifications";
import { useAppSelector } from "../../../../hooks";

const LayoutList = () => {
  const { id } = useParams();
  const [layout, setLayout] = useState<any>([]);
  const [isDeletes, setIsDeletes] = useState<any>(false);
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const goToLayouPlan = () => {
    navigate(`/library/layoutmanager`);
  };
  const [layoutname, setLayoutname] = useState<any>("");
  const [data, setData] = useState<any>([]);

  const [layoutNames, setLayoutNames] = useState<any>([]);
  const [selectedLayoutName, setSelectedLayoutName] = useState<any>("");
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [inputValue, setInputValue] = useState("");
  const listTenant: any = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;
  const Submit = (e) => {
    e.preventDefault();
  };
  const dispatch = useDispatch();
  const CreateMealLayoutManager = async () => {
    await API.graphql(
      graphqlOperation(createMealLayoutManager, {
        input: {
          layout: selectedItems,
          layoutName: layoutname,
          tenant:
            tenant === "ADMIN" ? ["ADMIN", "Trainers", "Clients"] : tenant,
        },
      })
    );
    dispatch(
      sendNotification({
        title: "Success",
        description: "Layout created successfully!",
        backgroundColor: "#5cb85c",
        icon: Check,
      })
    );

    setLoad(!load);
  };
  useEffect(() => {
    async function getAllMealTypes() {
      const mealTypes = await API.graphql(graphqlOperation(listMealTypes));

      setData(mealTypes);
    }
    getAllMealTypes();
  }, []);

  useEffect(() => {
    setLayoutNames(
      data?.data?.listMealTypes?.items
        ?.filter((meal) => !meal._deleted)
        ?.map((item) => item.name)
    );
  }, [data]);

  const onInputChangeLayoutName = (e: any, value: any) => {
    e?.stopPropagation();
    setInputValue(value);
  };

  const onhandleSave = (value: any) => {
    setSelectedItems((prevSelectedItems) => {
      const newItem = selectedLayoutName;
      const alreadySelected = prevSelectedItems.includes(newItem);
      if (alreadySelected) {
        return prevSelectedItems;
      } else {
        return [...prevSelectedItems, newItem];
      }
    });
  };

  useEffect(() => {
    setSelectedLayoutName(inputValue);
  }, [inputValue]);

  const onDeleteSelectedItem = (index) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((item, i) => i !== index)
    );
  };

  useEffect(() => {
    async function getLayout() {
      const LayoutData: any = await API.graphql(
        graphqlOperation(getMealLayoutManager, {
          id,
        })
      );
      setLayout(LayoutData?.data?.getMealLayoutManager);
    }
    getLayout();
  }, [load]);

  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  const handleSubmit = async (data) => {
    await API.graphql(
      graphqlOperation(createMealLayoutManager, {
        input: {
          layout: [...layout.layout, data],
          _version: layout._version,
        },
      })
    );
  };
  const AddMeal = ({ handleClose, show, handleSubmit }) => {
    const showHideClassName = show
      ? "Editmodal display-block"
      : "Editmodal display-none";

    const handleInputKeyDown = (event: any) => {
      event.stopPropagation();
    };

    return (
      <>
        <div className={showHideClassName}>
          <section className="Editmodal-layoutlist">
            <div className="heading">
              Add Meals to Weekly planner
              <img
                className="heading__image"
                onClick={handleClose}
                src={xcircle}
              />
            </div>
            <div className="LayoutLibrary__body">
              <div className="LayoutLibrary__body__text">Which Meal Type:</div>
              <div className="LayoutLibrary__body__text__input">
                {layoutNames && (
                  <Autocomplete
                    id="combo-box-demo"
                    onInputChange={(e, newInputValue) =>
                      onInputChangeLayoutName(e, newInputValue)
                    }
                    value={inputValue}
                    options={
                      layoutNames && layoutNames?.length > 0 ? layoutNames : []
                    }
                    getOptionLabel={(option: any) => option}
                    style={{ width: 300 }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          onKeyDown={handleInputKeyDown}
                          label="Search"
                          variant="outlined"
                          value={inputValue}
                        />
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div className="layoutsavebtn__container">
              <button className="layoutsavebtn" onClick={onhandleSave}>
                save
              </button>
            </div>
          </section>
        </div>
      </>
    );
  };

  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();

  const dragStart = (e: any, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e: any, position) => {
    e.preventDefault();
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...selectedItems];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setSelectedItems(copyListItems);
  };

  return (
    <>
      <button className="backtodietplan" onClick={goToLayouPlan}>
        <p> &#60; Back to Layout Plan</p>
      </button>

      <form onSubmit={Submit}>
        <div className="layouts-container">
          <div className="list">
            <span className="inputlayout">Meal Layout Name: </span>
            <input
              style={{ height: 30 }}
              className="inputLayoutText"
              type="text"
              onChange={(e) => setLayoutname(e.target.value)}
            />
          </div>
          <div className="checkbox-container">
            <div style={{ paddingLeft: 0 }}>
              <span className="inputlayout">Meal Types: </span>
            </div>
          </div>

          <div className="leads">
            <div className="leads__header">
              <AddMeal
                show={show}
                handleClose={hideModal}
                handleSubmit={handleSubmit}
              />

              <div className="leads__header__buttons"></div>
            </div>
            <div className="layout__list">
              {selectedItems &&
                selectedItems.map((item, index) => (
                  <div
                    className="layout__list__names"
                    onDragStart={(e) => dragStart(e, index)}
                    onDragEnter={(e) => dragEnter(e, index)}
                    onDragEnd={drop}
                    key={index}
                    draggable
                  >
                    <div className="layout__list__names__delete">
                      <DeleteComponent
                        deletes={isDeletes}
                        setDeletes={setIsDeletes}
                        onDeletes={() => onDeleteSelectedItem(index)}
                      />
                    </div>
                    <div className="layout__list__names__title"> {item}</div>
                  </div>
                ))}
              <button className="AddMeals__button" onClick={showModal}>
                Add Meals{" "}
              </button>
            </div>
            <div className="saveCreateLayout">
              <button
                type="submit"
                className="editButton"
                onClick={() => {
                  CreateMealLayoutManager();
                }}
                disabled={layoutname === "" || selectedItems?.length === 0}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default LayoutList;
