import lens from "../../images/lens.svg";

interface Props {
  onChange: (event) => void;
  placeHolder: string;
}
const Search = (props: Props) => {
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      <img
        src={lens}
        alt="Search"
        style={{
          height: "15px",
          width: "48px",
          color: "lightgrey",
          paddingLeft: 20,
        }}
      />
      <input
        onChange={props.onChange}
        className="leadSearchBar"
        placeholder={props.placeHolder}
      />
    </span>
  );
};
export default Search;
