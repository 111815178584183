import { useEffect, useRef, useState } from "react";
import "./FilterStatus.scss";
import { ReactComponent as Shape } from "../../images/Shape.svg";
interface Props {
  onSubmit: (val: []) => void;
}
const Filter = (props: Props) => {
  const dropdownRef = useRef(null);
  useOutsideAlerter(dropdownRef);
  const [isActive, setIsActive] = useState(false);
  const onClick = () => setIsActive(!isActive);
  const [checkedOptions, setCheckedOptions] = useState<any>([]);
  const [applied, setAppllied] = useState<any>([]);

  const res = {
    "option-1": "NEW_LEAD",
    "option-2": "WON",
    "option-3": "ON_HOLD",
    "option-4": "OFFER_SENT",
    "option-5": "LOST",
    "option-6": "CONTACTED",
    "option-7": "IN_DIALOGUE",
    "option-8": "FIRST_MEETING",
  };
  const options = [
    {
      name: "option-1",
      label: "New Lead",
    },
    {
      name: "option-2",
      label: "Won",
    },
    {
      name: "option-3",
      label: "On Hold",
    },
    {
      name: "option-4",
      label: "Offer sent",
    },
    {
      name: "option-5",
      label: "Lost",
    },
    {
      name: "option-6",
      label: "Contacted",
    },
    {
      name: "option-7",
      label: "In dialogue",
    },
    {
      name: "option-8",
      label: "First meeting",
    },
  ];

  const handleChange = (e) => {
    const { name } = e.target;
    if (name === "show-all") {
      if (checkedOptions.length === options.length) {
        setCheckedOptions([]);
      } else {
        setCheckedOptions(options.map((option) => option.name));
      }
      return;
    }

    const index = checkedOptions.indexOf(name);
    if (index === -1) {
      setCheckedOptions([...checkedOptions, name]);
    } else {
      const newCheckedOptions = [...checkedOptions];
      newCheckedOptions.splice(index, 1);
      setCheckedOptions(newCheckedOptions);
    }
  };

  const applyFilter = () => {
    props.onSubmit(checkedOptions.map((val) => res[val]));
    setAppllied(checkedOptions);
    setIsActive(false);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsActive(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);
  }
  return (
    <div className="MenuContainer">
      <button
        onClick={onClick}
        className={
          isActive || applied.length !== 0
            ? "FilterMenuTriggerActive"
            : "FilterMenutrigger"
        }
      >
        <div className="icon">
          <Shape />
        </div>
        <span className="MenuContainer__span">
          Filter by Status
          {applied.length ? "(" + applied.length + ")" : ""}
        </span>
      </button>

      <nav
        ref={dropdownRef}
        className={`menu ${isActive ? "active" : "inactive"}`}
      >
        <form>
          <h5 className="heading">Filter by Status</h5>
          <div className="MenuContainer__content">
            <>
              <input
                type="checkbox"
                name="show-all"
                checked={checkedOptions.length === options.length}
                onChange={handleChange}
              />
              <label>(Show All)</label>
              {options.map((option) => {
                return (
                  <>
                    <div>
                      <input
                        type="checkbox"
                        name={option.name}
                        checked={checkedOptions.includes(option.name)}
                        onChange={handleChange}
                      />
                      <label>{option.label}</label>
                    </div>
                  </>
                );
              })}
            </>
          </div>

          <button className="applyfilters" type="button" onClick={applyFilter}>
            Apply Filters
          </button>
        </form>
      </nav>
    </div>
  );
};
export default Filter;
