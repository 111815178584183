import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopNav from "../TopNav/TopNav";

import { ReactComponent as Muscle } from "../../images/muscle.svg";
import { ReactComponent as Duration } from "../../images/Duration.svg";
import { ReactComponent as Star } from "../../images/star.svg";
import { ReactComponent as Payment } from "../../images/payment.svg";
import ClientProgress from "../Clients/ClientProgress/ClientProgress";
import MealPlanner from "../Clients/MealPlanner/MealPlanner";
import { API, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import "./ClientDetail.scss";
import Meal from "./ClientDetailMeal/ClientDetailMeal";

import "./ClientDetail.scss";
import { generateAvatar, GOAL } from "../../Utils";
import moment from "moment";
import NotesAndRemainder from "./NotesAndReminder/NotesAndReminder";
import MealsScheduler from "../Clients/MealSchedule/MealsSchedule";
import { getUser } from "../../graphql/queries";
import ClientWorkoutDetail from "./ClientWorkoutDetail/ClientWorkoutDetail";

type HeaderNavItem = {
  display: string;
  to: string;
}[];
const headerNavItems: HeaderNavItem = [
  {
    display: "Overview",
    to: "/overview",
  },
  {
    display: "Meals",

    to: "/meals",
  },
  {
    display: "Workout",

    to: "/workout",
  },
];

const ClientDetail = () => {
  const { id } = useParams();
  const { tab1 } = useParams();
  const navigate = useNavigate();

  const [clientDetail, setClientDetail] = useState<any>();

  const [load, setLoad] = useState(false);
  const [tab, setTab] = useState(tab1 ? tab1 : "overview");
  const [calendarData, setCalendarData] = useState<any>();

  const startTime = clientDetail?.data.getUser?.membership?.items?.filter(
    (item) => item.status === "ACTIVE"
  )[0]?.startTime;
  const endTime = clientDetail?.data.getUser?.membership?.items?.filter(
    (item) => item.status === "ACTIVE"
  )[0]?.endTime;

  useEffect(() => {
    async function getClientDetail() {
      const client = await API.graphql(graphqlOperation(getUser, { id }));

      setClientDetail(client);
    }
    getClientDetail();
  }, [load]);

  function goToClients() {
    navigate(`/clients`);
  }

  const onLoad = () => {
    setLoad(!load);
  };
  function Overview() {
    return (
      <div className={"clientdetails__container__profile "}>
        <div className="clientdetails__container__profile__left">
          <div className="clientdetails__container__profile__left__content">
            <div className="clientdetails__container__profile__left__content__profile">
              <div className="clientdetails__container__profile__left__content__profile__name">
                <div className="clientdetails__container__profile__left__content__profile__name__image">
                  {clientDetail?.data.getUser && (
                    <img
                      src={
                        clientDetail?.data.getUser.imageUrl
                          ? clientDetail?.data.getUser.imageUrl
                          : generateAvatar(
                              clientDetail?.data.getUser.name[0],
                              "white",
                              "rgb(16, 156, 241)"
                            )
                      }
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                      }}
                      alt="avatar"
                    />
                  )}
                </div>
                <div className="clientdetails__container__profile__left__content__profile__name__username">
                  {clientDetail?.data.getUser?.name}
                  <span className="text">
                    {" "}
                    {clientDetail?.data.getUser?.gender}{" "}
                    {clientDetail?.data.getUser?.ageRange}{" "}
                  </span>
                </div>
              </div>
              {clientDetail?.data.getUser?.goalType && (
                <div className="clientdetails__container__profile__left__content__profile__name__health">
                  <div className="clientdetails__container__profile__left__content__profile__name__health__goal text">
                    <span> Goal</span>
                    <span className="clientdetails__container__profile__left__content__profile__name__health__goal__color">
                      {GOAL[clientDetail?.data.getUser?.goalType]}
                    </span>
                  </div>

                  <div className="clientdetails__container__profile__left__content__profile__name__health__image">
                    <Muscle />
                  </div>
                </div>
              )}
            </div>
          </div>

          {clientDetail?.data.getUser?.activeStatus ? (
            <div className="clientdetails__container__profile__left__center">
              <div>
                <div className="clientdetails__container__profile__left__center__period">
                  <div className="clientdetails__container__profile__left__center__period__filter">
                    <Duration />
                  </div>
                  <div className="clientdetails__container__profile__left__center__period__filter text">
                    Period
                  </div>
                </div>
                <div className="clientdetails__container__profile__left__center__period__date">
                  {startTime &&
                    endTime &&
                    moment(startTime).format("MMMM Do,YYYY") +
                      " - " +
                      moment(endTime).format("MMMM Do,YYYY")}
                </div>
              </div>

              <div>
                <div className="clientdetails__container__profile__left__center__member">
                  <div className="clientdetails__container__profile__left__center__member__filter">
                    <Star />
                  </div>
                  <div className="clientdetails__container__profile__left__center__member__filter text">
                    Membership
                  </div>
                </div>
                <div className="clientdetails__container__profile__left__center__member__filter__status">
                  {clientDetail?.data.getUser.activeStatus}
                </div>
              </div>

              <div>
                <div className="clientdetails__container__profile__left__center__payment">
                  <div className="clientdetails__container__profile__left__center__payment__filter">
                    <Payment />
                  </div>
                  <div className="clientdetails__container__profile__left__center__payment__filter text">
                    Payment
                  </div>
                </div>
                <div className="clientdetails__container__profile__left__center__payment__filter__text__paid">
                  Paid
                </div>
              </div>
            </div>
          ) : (
            <div className="noMembership placeholder">
              {" "}
              No membership info to show
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <>
      <TopNav>
        <button className="backtoclients" onClick={goToClients}>
          {" "}
          <p> &#60; Back to Clients</p>{" "}
        </button>
      </TopNav>
      <div className="Clientdetails">
        <div className="clientdetails__header">
          <div className="clientdetails__header__text">Client Detail</div>
        </div>
        <div className="clientdetails__container">
          <div className="clientdetails__container__header">
            <div className="clientdetails__container__header__left">
              {" "}
              {headerNavItems?.map((item, index) => (
                <div
                  key={index}
                  className={`clientdetails__container__header__item `}
                >
                  <div
                    className={
                      "clientdetails__container__header__item__text " +
                      (tab === item.to.split("/")[1] ? "activeunderline" : "")
                    }
                    onClick={() => {
                      navigate(`/clients/${id}/${item.to.split("/")[1]}`);
                      setTab(item.to.split("/")[1]);
                    }}
                  >
                    {item.display}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {tab === "overview" && (
            <div className={tab === "overview" ? "activetab" : "inactivetab"}>
              <Overview />
              <div style={{ padding: "30px 50px" }}>
                {clientDetail?.data.getUser.dailyProgress && (
                  <ClientProgress id={id} />
                )}
              </div>
              <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <NotesAndRemainder />
              </div>
            </div>
          )}
          {tab === "meals" && (
            <div className={tab === "meals" ? "activetab" : "inactivetab"}>
              <Meal data={clientDetail} fetchdata={onLoad} />
              <div className="mealsContainerlayout">
                <MealPlanner
                  data={clientDetail}
                  nutrientsData={calendarData}
                  fetchData={onLoad}
                />

                <MealsScheduler userId={id} calendarData={setCalendarData} />
              </div>
            </div>
          )}
          {tab === "workout" && (
            <div className={tab === "workout" ? "activetab" : "inactivetab"}>
              <ClientWorkoutDetail
                id={id}
                data={clientDetail}
                fetchData={onLoad}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ClientDetail;
