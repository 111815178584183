import { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import "./ClientProgress.scss";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  Tooltip,
} from "recharts";
import carbonProgress from "../../../images/carbonProgress.svg";

import { ReactComponent as Bicep } from "../../../images/bicep.svg";
import { getUser } from "../../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";

import moment from "moment";

import leftArrow from "../../../images/chevron-left.svg";
import rightArrow from "../../../images/chevron-right.svg";

const ClientProgress = (props) => {
  const [data, setData] = useState([]);
  const [dataInitialLoad, setDataInitialLoad] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [otherData, setOtherData] = useState({});
  const [currentDayDate, setCurrentDayDate] = useState("");
  const [currentWeek, setCurrentWeek] = useState("");
  const [weightPerDate, setWeightPerDate] = useState(0);
  let [dateCounter, setDateCounter] = useState(0);
  let [datePerDay, setDatePerDay] = useState(0);
  const [nextDisabled, setNextButtonDisabled] = useState(true);
  const [prevDisabled, setPrevButtonDisabled] = useState(false);
  const [imgData, setImgData] = useState([]);

  const [imagePath, setImagePath] = useState();
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    (async () => {
      const url = await Storage.get("");

      return setImagePath(url.split("?")[0]);
    })();
  }, []);
  useEffect(() => {
    console.log("data", imageData);
    const getImgData =
      imageData && imageData.length > 0
        ? imageData.map((item) => {
            let obj = {};
            if (item && item.imageUrls != null) {
              obj = {
                src: item && item.imageUrls ? imagePath + item.imageUrls : null,
                style: {
                  width: "190px",
                  height: "200px",
                  marginLeft: "25px",
                  borderRadius: "17px",
                },
                date: item && item.date ? item.date : null,
              };
            }
            return obj;
          })
        : [];
    let newArray = getImgData.filter(
      (value) => Object.keys(value).length !== 0
    );
    setImgData(newArray.slice().reverse());
  }, [imageData]);
  const fetchData = async () => {
    const response = await API.graphql(
      graphqlOperation(getUser, { id: props.id })
    );

    if (
      response.data.getUser &&
      response.data.getUser.dailyProgress &&
      response.data.getUser.dailyProgress.length > 0
    ) {
      let dataInitial = response.data.getUser.dailyProgress?.slice().reverse();

      setDataInitialLoad(dataInitial);

      setData(response.data.getUser.dailyProgress);
      setImageData(response.data.getUser.imageProgress);

      setWeightPerDate(dataInitial[dateCounter]?.weight);
      setDatePerDay(dataInitial[dateCounter]?.date);
      setOtherData(response.data.getUser);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getSystemDate();
  }, [datePerDay]);

  const getSystemDate = () => {
    const obj = {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thrusday",
      5: "Friday",
      6: "Saturday",
    };
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const today = new Date(datePerDay);
    let day =
      obj[today.getDay()] +
      " " +
      today.getDate() +
      " " +
      month[today.getMonth()];

    setCurrentDayDate(day);
    let oneJan = new Date(today.getFullYear(), 0, 1);
    let numberOfDays = Math.floor((today - oneJan) / (24 * 60 * 60 * 1000));
    let result = Math.ceil((today.getDay() + 1 + numberOfDays) / 7);

    setCurrentWeek(result);
  };
  const dataForMonth = () => {
    //const data = [{ date: '2022-03-24', weight: 94 }, { date: '2022-03-24', weight: 94 }, { date: '2022-03-24', weight: 94 }],
    let result = Object.values(
      data.reduce((r, date) => {
        const [year, month, day] = date.date.substring(0, 10).split("-");
        const weight = date.weight;
        const key = `${year}_${month}`;
        r[key] = r[key] || { month, year, dates: [], weight: [] };
        r[key].dates.push(day);
        r[key].weight.push(weight);
        return r;
      }, {})
    );
    let arr = [];
    let sum = 0;
    let counter = 0;
    result.map((item) => {
      for (let x of item.weight) {
        sum = sum + x;
        counter++;
      }
      arr.push({
        weight: sum / counter,
        date: moment(item.month, "M").format("MMM YY"),
      });
    });

    // setDataFilter(arr);

    setDataFilter(arr);
    console.log("arr", arr);
  };

  const dataForWeek = () => {
    let finalArr = data.map((item) => {
      const today = new Date(item.date);
      let oneJan = new Date(today.getFullYear(), 0, 1);
      let numberOfDays = Math.floor((today - oneJan) / (24 * 60 * 60 * 1000));
      let result = Math.ceil((today.getDay() + 1 + numberOfDays) / 7);
      return { ...item, date: result }; //+moment(item.date).format("-YYYY") };
    });
    setDataFilter(finalArr);
  };

  function getPreviousData() {
    if (dateCounter > dataInitialLoad.length - 1) {
      setPrevButtonDisabled(true);
    } else {
      dateCounter++;
      if (dataInitialLoad && dataInitialLoad.length > 0) {
        setWeightPerDate(dataInitialLoad[dateCounter].weight);
        setDatePerDay(dataInitialLoad[dateCounter].date);
      }

      setDateCounter(dateCounter++);
      setNextButtonDisabled(false);
    }
  }

  function getNextData() {
    if (dateCounter > 0) {
      dateCounter--;
      if (dataInitialLoad && dataInitialLoad.length > 0) {
        setWeightPerDate(dataInitialLoad[dateCounter].weight);
        setDatePerDay(dataInitialLoad[dateCounter].date);
      }
      if (dateCounter == 0) {
        setNextButtonDisabled(true);
      }

      setDateCounter(dateCounter--);
    } else {
      setNextButtonDisabled(true);
    }
  }

  return (
    <>
      <div className="clientProgress">
        <div className="topBarProgress">
          <div>
            <img src={carbonProgress ?? 0} alt="" />
            <span className="name">Client Progress</span>
          </div>

          <div style={{ paddingRight: "50px" }}>
            <button className="week" onClick={dataForWeek}>
              Week
            </button>
            <button className="month" onClick={dataForMonth}>
              {" "}
              Month
            </button>
          </div>
        </div>
        {data ? (
          <>
            <div className="bar-container">
              <ResponsiveContainer width="95%" height={400}>
                <AreaChart
                  width="100%"
                  height={320}
                  style={{ marginLeft: "-30px", width: "95%", height: "100%" }}
                  data={dataFilter && dataFilter.length > 0 ? dataFilter : data}
                  margin={{ top: 72, right: 10.5 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor="darkorange"
                        stopOpacity={0.8}
                      />
                      <stop
                        offset="95%"
                        stopColor="darkorange"
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="date" />
                  <YAxis tickLine={false} width={80} domain={[195, "auto"]} />

                  <CartesianGrid horizontal={false} />
                  <Tooltip />
                  <Area
                    dot={{
                      stroke: "darkorange",
                      strokeWidth: 2,
                      r: 7,
                      fill: "white",
                    }}
                    dataKey="weight"
                    stroke="darkorange"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <div className="weeklyCalendar">
              <div>
                <div className="progressCalender">
                  <div style={{ width: "16%" }}>
                    {currentDayDate ?? 0}
                    <div className="progressWeek">Week {currentWeek} Of 52</div>
                  </div>

                  <div>
                    {prevDisabled ? (
                      <img className="leftArrow" src={leftArrow} alt="" xw />
                    ) : (
                      <img
                        className="leftArrow"
                        onClick={getPreviousData}
                        src={leftArrow}
                        alt=""
                      />
                    )}

                    {nextDisabled ? (
                      <img
                        style={{
                          position: "relative",
                          left: "10px",
                          top: "13px",
                          width: "30px",
                          height: "30px",
                          background: "#F2F9FF",
                          border: "transparent",
                          marginLeft: "-5px",
                          cursor: "pointer",
                        }}
                        alt=""
                        src={rightArrow}
                      />
                    ) : (
                      <img
                        onClick={getNextData}
                        style={{
                          position: "relative",
                          left: "10px",
                          top: "13px",
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                          background: "#F2F9FF",
                          border: "transparent",
                          marginLeft: "-5px",
                        }}
                        alt=""
                        src={rightArrow}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "92%",
                  height: "300px",
                  paddingTop: "46px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  marginTop: "80px",
                }}
              >
                <div
                  style={{
                    width: "243px",
                    height: "200px",
                    border: "1px dashed #C2CFE0",
                    boxSizing: "border-box",
                    borderRadius: "10px",
                    float: "left",
                  }}
                >
                  <div className="boxGoal">
                    <div>
                      <Bicep />
                    </div>
                    <div>
                      {" "}
                      <span>Actual Goal</span>
                      <div className="weightGoal">
                        {otherData.weightGoal ?? 0 + " " + "lbs"}
                        <span style={{ marginLeft: "75px" }}></span>
                      </div>
                    </div>
                  </div>
                  <div className="boxStart"></div>
                  <div className="start">
                    Start
                    <div className="weightStart">
                      {otherData.weightStart ?? 0 + " " + "lbs"}
                    </div>
                  </div>
                  <div
                    style={{
                      border: "0.25px dashed #C2CFE0",
                      transform: "rotate(-90deg)",
                      marginLeft: "90px",
                      marginTop: "30px",
                      width: "60px",
                    }}
                  ></div>
                  <div className="boxCurrent">
                    Current
                    <div className="weightStart">
                      {weightPerDate ?? 0 + " " + "lbs"}
                    </div>
                  </div>
                  <div
                    style={{
                      border: "0.25px dashed #C2CFE0",
                      width: "239px",
                      height: "0px",
                      background: "#FFFFFF",
                      marginTop: "-9px",
                    }}
                  ></div>
                  <div style={{ marginLeft: "10px", marginTop: "7px" }}>
                    <div className="weightChange">
                      Weight Change
                      <span className="weightChangeData">
                        {(weightPerDate ?? 0) -
                          (otherData.weightStart ?? 0) +
                          " " +
                          "lbs"}
                      </span>
                    </div>

                    <div className="dailyCaloriesChange">
                      Daily Calories
                      <span className="weightChangeData">
                        {otherData.dailyKcal ?? 0}Kcal
                      </span>
                    </div>
                  </div>
                </div>

                <div className="Content">
                  {data && data.length > 0
                    ? imgData.map((item) => (
                        <div>
                          <img
                            style={{
                              width: "190px",
                              height: "200px",
                              marginLeft: "25px",
                              borderRadius: "17px",
                            }}
                            alt=""
                            src={item.src}
                          />
                          <div className="imgTag">
                            {data && data.length > 0
                              ? moment(item.date).format("MMMM Do,YYYY")
                              : ""}
                          </div>
                        </div>
                      ))
                    : []}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="placeholder clientProgressPlaceholder">
            <span>No progress to show </span>
          </div>
        )}
      </div>
    </>
  );
};

export default ClientProgress;
