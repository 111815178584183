import { API, graphqlOperation } from "aws-amplify";
import { listEnumValues } from "../graphql/customQueries";
interface IFood {
  additionalUnits: string;
  calories: number;
  carbs: number;
  createdAt: string;
  description: string;
  fats: number;
  foodGroup?: string;
  gramsPerServe: number;
  id: string;
  imageUrl: string;
  moreFields: string;
  name: string;
  protien: number;
  servingDescription: string;
  servingSize: number;
  updatedAt: string;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  _version: number;
}
const filterSearchData = (
  data: any[],
  searchText: string,
  fields: (string | ((item: any) => string))[]
) => {
  return data?.filter((item) => {
    return fields?.some((field) => {
      if (typeof field === "string") {
        return (
          item[field] &&
          item[field]?.toLowerCase()?.includes(searchText?.toLowerCase())
        );
      } else if (typeof field === "function") {
        const valueToSearch = field(item);
        return valueToSearch
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase());
      }
      return false;
    });
  });
};

const filterStatusData = (
  data: any[],
  query: string[],
  includedColumn: string[]
) => {
  return data?.filter((row) => {
    for (let item in row) {
      if (includedColumn.indexOf(item) >= 0) {
        for (let val of query)
          if (row[item]?.toLowerCase().includes(val.toLowerCase())) {
            return true;
          }
      }
    }
  });
};

const getSelectedUnit = (food: IFood, unitName: string) => {
  const selectedUnit = food?.additionalUnits
    ? [
        {
          no: 0,
          amount: food?.servingSize ?? 1,
          unitName: food?.servingDescription ?? "grams",
          grams: food?.gramsPerServe ?? 1,
        },
        ...JSON.parse(food.additionalUnits)?.data,
      ]
    : [];

  selectedUnit?.push({
    no: selectedUnit.length,
    amount: food?.servingSize ?? 1,
    unitName: "grams",
    grams: 1,
  });
  if (!unitName || unitName == "") {
    return selectedUnit[1];
  }
  return selectedUnit?.filter(
    (item) => item.unitName == unitName && item.unitName != ""
  )[0];
};

const getAdditionUnits = (food) => {
  let allUnits;

  if (food.additionalUnits) {
    const additionalUnits =
      food?.additionalUnits && JSON.parse(food?.additionalUnits);
    const additionalUnitsWithNotNull =
      additionalUnits && additionalUnits?.data
        ? additionalUnits?.data?.filter((item) => item.unitName !== null)
        : [];
    allUnits = [
      {
        no: "0",
        amount: food?.servingSize,
        unitName: food?.servingDescription,
        grams: food?.gramsPerServe,
      },
      ...additionalUnitsWithNotNull,
    ];
    allUnits.push({
      no: allUnits.length,
      amount: food?.servingSize,
      unitName: "grams",
      grams: 1,
    });
  } else if (food.recipe?.ingredients) {
    allUnits = [
      {
        no: "0",
        amount: food?.recipe?.servingSize,
        unitName: food?.recipe?.servingDescription,
        grams: food?.recipe?.calculatedGrams,
      },
    ];
    allUnits.push({
      no: allUnits.length,
      amount: food?.recipe?.servingSize,
      unitName: "grams",
      grams: food?.recipe?.calculatedGrams,
    });
  } else {
    const additionalUnits =
      food?.food?.additionalUnits && JSON.parse(food?.food?.additionalUnits);
    const additionalUnitsWithNotNull =
      additionalUnits && additionalUnits?.data
        ? additionalUnits?.data?.filter((item) => item.unitName !== null)
        : [];
    allUnits = [
      {
        no: "0",
        amount: food?.food?.servingSize,
        unitName: food?.food?.servingDescription,
        grams: food?.food?.gramsPerServe,
      },
      ...additionalUnitsWithNotNull,
    ];
    allUnits.push({
      no: allUnits.length,
      amount: food?.food?.servingSize,
      unitName: "grams",
      grams: 1,
    });
  }

  return allUnits;
};
const permaterConfiguredServing = (
  gramsPerServe,
  requiredGrams,
  parameterValue
) => {
  return (requiredGrams / gramsPerServe) * parameterValue;
};
const getCalculateFoodServings = (food, amount, unit) => {
  const selectedUnit = getSelectedUnit(food, unit);

  const calories = Math.round(
    permaterConfiguredServing(
      food?.gramsPerServe || 0,
      selectedUnit?.grams * amount,
      food.calories
    )
  );
  const protien = Math.round(
    permaterConfiguredServing(
      food?.gramsPerServe || 0,
      selectedUnit?.grams * amount,
      food.protien
    )
  );

  const carbs = Math.round(
    permaterConfiguredServing(
      food?.gramsPerServe || 0,
      selectedUnit?.grams * amount,
      food.carbs
    )
  );

  const fats = Math.round(
    permaterConfiguredServing(
      food?.gramsPerServe || 0,
      selectedUnit?.grams * amount,
      food.fats
    )
  );

  const calculatedGrams =
    (food.gramsPerServe * (selectedUnit?.grams * amount)) / food.gramsPerServe;
  // 100*((150*1)/100)

  const moreFields = getMoreFieldsFoodSum(
    food.moreFields,
    selectedUnit,
    food.gramsPerServe,
    amount
  );

  const servingSize = amount;
  const servingDescription = selectedUnit?.unitName; // Store the unit name in servingDescription
  //TODO: Calculate the same for carbs, fat, protiens

  return {
    calories,
    servingSize,
    servingDescription,
    calculatedGrams,
    protien,
    carbs,
    fats,
    moreFields,
  };
};

const getMoreFieldsFoodSum = (food, selelectedUnit, gramsPerServe, amount) => {
  const moreFields = JSON.parse(food);
  const r = moreFields?.data?.reduce((acc, item: any) => {
    const res: any = permaterConfiguredServing(
      gramsPerServe || 0,
      selelectedUnit?.grams * amount,
      Number(item.qty)
    );

    acc[item["nutrition"]] = { qty: res || 0, unit: item.unit };
    return acc;
  }, {});

  return r;
};

const getMoreFieldsRecipeSum = (
  ingredient,
  gramsPerServing,
  requiredGramsPerServe
) => {
  const r = ingredient?.items.reduce((acc, items, index) => {
    for (let key in items.moreFields) {
      const configureServing = permaterConfiguredServing(
        gramsPerServing || 0,
        requiredGramsPerServe,
        items.moreFields[key]["qty"]
      );
      if (!acc[key]) {
        acc[key] = {
          qty: configureServing,
          unit: items.moreFields[key]["unit"],
        };
      } else {
        acc[key] = {
          qty: acc[key].qty + configureServing,
          unit: items.moreFields[key]["unit"],
        };
      }
    }
    return acc;
  }, {});

  return r;
};
const getTotalSumOFMoreFields = (data) => {
  const r = data.reduce((acc, items, index) => {
    for (let key in items.moreFields) {
      if (!acc[key]) {
        acc[key] = {
          qty: items.moreFields[key]["qty"],
          unit: items.moreFields[key]["unit"],
        };
      } else {
        acc[key] = {
          qty: acc[key].qty + items.moreFields[key]["qty"],
          unit: items.moreFields[key]["unit"],
        };
      }
    }

    return acc;
  }, {});
  return r;
};
const getCalculateRecipeServings = (recipe) => {
  const updatedWithIngredients = recipe?.ingredients?.items
    ?.filter((item) => !item?._deleted)
    .map((ingredient) => {
      let calculated;
      if (ingredient?.food && !ingredient?._deleted) {
        calculated = getCalculateFoodServings(
          ingredient.food,
          ingredient.servingSize || 1,
          ingredient.servingDescription
        );
      }
      return { ...ingredient, ...calculated };
    });

  return updatedWithIngredients;
};
const sumOfUnits = (items: any[]) => {
  const sum = {
    calculatedGrams: 0,
    calories: 0,
    protien: 0,
    carbs: 0,
    fats: 0,
  };

  const roundedSum = items?.reduce((acc, item) => {
    acc.calculatedGrams += Number(item.calculatedGrams) || 0;
    acc.calories += Number(item.calories) || 0;
    acc.protien += Number(item.protien) || 0;
    acc.carbs += Number(item.carbs) || 0;
    acc.fats += Number(item.fats) || 0;
    // TODO: for protien, carbs, fat
    return acc;
  }, sum);

  // Round the values to the nearest integer
  for (const [key, value] of Object.entries(roundedSum)) {
    roundedSum[key] = Math.round(Number(value));
  }

  return roundedSum;
};

const getGramsPerServings = (
  recipe,
  servingDescription,
  requiredServingSize
) => {
  if (servingDescription.toLowerCase() === "grams") {
    return requiredServingSize
      ? requiredServingSize
      : recipe?.calculatedGrams || 0;
  } else if (recipe.servingDescription === servingDescription) {
    return (
      recipe.calculatedGrams * (requiredServingSize / recipe.servingSize) || 0
    );
  }
};

function generateAvatar(
  text,
  foregroundColor = "white",
  backgroundColor = "black"
) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = 100;
  canvas.height = 100;
  // Draw background
  if (context) {
    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, canvas.width, canvas.height);
    // Draw text
    context.font = "bold 5em Poppins";
    context.fillStyle = foregroundColor;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, canvas.width / 2, canvas.height / 2 + 5);
  }

  return canvas.toDataURL("image/png");
}

const listEnum = async (enumVariable: string) => {
  return await API.graphql(
    graphqlOperation(listEnumValues, { enum: enumVariable })
  );
};
enum GOAL {
  LOSE_WEIGHT = "LOSE WEIGHT",
  BUILD_MUSCLE = "BUILD MUSCLE",
  KEEP_WEIGHT = "KEEP WEIGHT",
}

function bmr(gender, weight, height, age) {
  const val =
    gender === "Female"
      ? 655 + 9.6 * weight * 0.45359237 + 1.8 * height * 30.48 - 4.7 * age
      : 66 + 13.7 * weight * 0.45359237 + 5 * height * 30.48 - 6.8 * age;

  return val;
}

const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
const validateServing = (val) => {
  // Define the regular expressions for the patterns
  var pattern1 = /^\d+\s\d+\/\d+$/; // Matches "1 1/4", "10 1/2" pattern
  var pattern2 = /^\d+$/; // Matches "1","10" pattern
  var pattern3 = /^\d+\/\d+$/; // Matches "1/4" pattern

  // Test the user input against the patterns
  var isValid = pattern1.test(val) || pattern2.test(val) || pattern3.test(val);

  // Output the result
  if (isValid) {
    return true;
  } else {
    return false;
  }
};
const convertFractionToDecimal = (fraction) => {
  var parts = fraction.toString()?.split(" ");

  if (parts.length === 1) {
    // Fraction is in the format "1/4"
    var fractionParts = parts[0].split("/");
    var numerator = parseInt(fractionParts[0]);
    if (fractionParts[1]) {
      var denominator = parseInt(fractionParts[1]);
      return numerator / denominator;
    }
    return numerator;
  } else if (parts.length === 2) {
    // Fraction is in the format "1 1/2"
    var wholeNumber = parseInt(parts[0]);
    var fractionParts = parts[1].split("/");
    var numerator = parseInt(fractionParts[0]);
    var denominator = parseInt(fractionParts[1]);

    var decimal = wholeNumber + numerator / denominator;
    return decimal;
  }

  // Invalid fraction format
  return null;
};

const convertDecimalToFraction = (decimal) => {
  var wholeNumber = Math.floor(decimal);
  var decimalPart = decimal - wholeNumber;

  if (decimalPart === 0) {
    return wholeNumber.toString();
  }

  var numerator = Math.round(decimalPart * 1000000);
  var denominator = 1000000;
  var gcd = greatestCommonDivisor(numerator, denominator);

  numerator /= gcd;
  denominator /= gcd;

  if (wholeNumber === 0) {
    return numerator.toString() + "/" + denominator.toString();
  } else {
    return (
      wholeNumber.toString() +
      " " +
      numerator.toString() +
      "/" +
      denominator.toString()
    );
  }
};

const compareBy = (key) => {
  return function (a, b) {
    if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) return -1;
    if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) return 1;
    return 0;
  };
};
function sortByDay(data) {
  data.sort(compareBy("createdAt"));
  return data;
}
function greatestCommonDivisor(a, b) {
  if (b === 0) {
    return a;
  }

  return greatestCommonDivisor(b, a % b);
}

// // Example usage:
export {
  filterSearchData,
  filterStatusData,
  generateAvatar,
  GOAL,
  listEnum,
  bmr,
  isValidUrl,
  getSelectedUnit,
  getAdditionUnits,
  getCalculateFoodServings,
  getCalculateRecipeServings,
  sumOfUnits,
  getGramsPerServings,
  getMoreFieldsRecipeSum,
  getMoreFieldsFoodSum,
  getTotalSumOFMoreFields,
  permaterConfiguredServing,
  validateServing,
  convertFractionToDecimal,
  convertDecimalToFraction,
  sortByDay,
};
