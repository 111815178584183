import TopNav from "../TopNav/TopNav";
import Search from "../../component/Search/Search";
import React, { useEffect, useState } from "react";
import { filterSearchData, filterStatusData } from "../../Utils";
import FtButton from "../Ft-Button/Ft-Button";
import moment from "moment";
import Pagination from "../../component/Pagination/pagination";
import { API, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import Filter from "../FilterStatus/FilterStatus";
import { ReactComponent as Add } from "../../images/add.svg";
import { listTrainers } from "../../graphql/queries";
import { trackPromise } from "react-promise-tracker";
import { ReactComponent as Spam } from "../../images/Spam.svg";
import DeleteComponent from "../../component/Delete/Delete";
// import { deleteLead } from "../../graphql/mutations";
import { deleteTrainer } from "../../graphql/mutations";

let PageSize = 10;
const Badge = ({ text }) => {
  let background, color;
  if (text === "NEW_LEAD") {
    text = "NEW LEAD";
    background = "#fcf7e4";
    color = " #E0AF00";
  } else if (text === "WON") {
    background = "#e6f7f4";
    color = "#06AB8D";
  } else if (text === "ON_HOLD") {
    text = "ON HOLD";
    background = "#ebeced";
    color = "#707683";
  } else if (text === "OFFER_SENT") {
    text = "OFFER SENT";
    background = "#f7ecf9";
    color = " #AC3EC8";
  } else if (text === "LOST") {
    background = "#f5e2e2";
    color = "#D11818";
  } else if (text === "CONTACTED") {
    background = "#fdf1e6";
    color = " #EB7305";
  } else if (text === "IN_DIALOGUE") {
    text = "IN DIALOGUE";
    background = "#fdf1e6";
    color = " #EB7305";
  } else if (text === "IN_PROGRESS") {
    text = "IN PROGRESS";
    background = "#fdf1e6";
    color = " #EB7305";
  } else if (text === "FIRST_MEETING") {
    text = "FIRST MEETING";
    background = "#fdf1e6";
    color = " #EB7305";
  }
  return (
    <span
      className="badge"
      style={{
        background: background,
      }}
    >
      <span className="text_font" style={{ color: color, width: "12px" }}>
        {text}
      </span>
    </span>
  );
};
const Trainers = () => {
  const [trainerList, setTrainerList] = useState<any>(null);
  const [filteredTrainer, setFilteredTrainer] = useState<any>(null);
  const [load, setLoad] = useState(false);

  const listTrainersFun = async () => {
    let nextToken = null;
    let nextLeadItems = [];

    do {
      const queryVariables = { nextToken };
      const result: any = await API.graphql(
        graphqlOperation(listTrainers, queryVariables)
      );

      const leadData = result?.data?.listTrainers;
      console.log("Trrainer Data", leadData);
      nextToken = leadData?.nextToken;
      const items = leadData?.items;

      if (items) {
        nextLeadItems = nextLeadItems.concat(items);
      }
    } while (nextToken);

    setTrainerList(nextLeadItems);
  };

  const navigate = useNavigate();
  function handleClick() {
    navigate(`/questionnaire`);
  }

  useEffect(() => {
    trackPromise(listTrainersFun());
  }, [load]);

  useEffect(() => {
    let temp = trainerList;

    if (temp) {
      temp = temp.filter((item) => !item._deleted);
      temp.sort(compareBy("updatedAt"));
      setFilteredTrainer(temp);
    }
  }, [trainerList]);

  const compareBy = (key) => {
    return function (a, b) {
      if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) return -1;
      if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) return 1;
      return 0;
    };
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredTrainer(
      filterSearchData(trainerList, event.target.value, [
        "name",
        "email",
        "phone",
      ])
    );
  };
  return (
    <>
      <TopNav>
        <Search
          onChange={onChange}
          placeHolder="Search Trainer by Name, Email or Phone Number"
        />
      </TopNav>
      <div className="leads">
        <div className="leads__header">
          <div className="leads__header__text">
            <h1>Trainers</h1>
          </div>

          {/* <div className="leads__header__buttons">
            <Filter
              onSubmit={(val) => {
                if (val.length !== 0) {
                  setFilteredTrainer(
                    filterStatusData(trainerList, val, ["activeStatus"])
                  );
                } else {
                  setFilteredTrainer(trainerList);
                }
              }}
            />
            {process.env.REACT_APP_BUILD_ENV !== "Prod" ? (
              <FtButton
                className="createnewbtn"
                border="1px solid #109CF1"
                color="#109CF1"
                height="40px"
                onClick={handleClick}
                radius="5px"
                margin="5px"
                width=""
                children="Create New"
                icon={<Add />}
              />
            ) : null}
          </div> */}
        </div>
        <div className="leads__container">
          {filteredTrainer && (
            <Table data={filteredTrainer} setData={setLoad} />
          )}
        </div>
      </div>
    </>
  );
};
const Table = (props) => {
  const navigate = useNavigate();
  const sortTypes = {
    up: {
      class: "sort-up",
    },
    down: {
      class: "sort-down",
    },
    default: {
      class: "sort",
    },
  };
  const Columns = [
    { name: 0 },
    { phone: 0 },
    { email: 0 },
    { activeStatus: 0 },
    { updatedAt: 0 },
  ];

  const [tableState, setTableState] = useState<any>({
    currentSort: "default",
    activeColumnSort: "",
    columns: Columns,
    currentPage: 1,
    data: [],
  });

  const [paginatedData, setPaginatedData] = useState<any>([]);

  let order = 1;
  const sortBy = (key) => {
    let nextSort;
    if (tableState.currentSort === "down") nextSort = "up";
    else if (tableState.currentSort === "up") nextSort = "default";
    else if (tableState.currentSort === "default") nextSort = "down";

    let arrayCopy1 = [...props.data];
    let arrayCopy2 = [...tableState.columns];

    const compareBy = (key) => {
      return function (a, b) {
        if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
        if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
        return 0;
      };
    };
    arrayCopy1.sort(compareBy(key));

    if (tableState.currentSort === "default") {
      arrayCopy1.sort(function (a, b) {
        if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) return -1;
        if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) return 1;
        return 0;
      });
    } else if (tableState.currentSort === "up") {
      arrayCopy1.sort(function (a, b) {
        if (a["updatedAt"]?.toLowerCase() > b["updatedAt"]?.toLowerCase())
          return -1;
        if (a["updatedAt"]?.toLowerCase() < b["updatedAt"]?.toLowerCase())
          return 1;
        return 0;
      });
    }
    arrayCopy2[key] = order;
    setTableState({
      ...tableState,
      ...{
        data: arrayCopy1,
        currentPage: 1,
        currentSort: nextSort,
        activeColumnSort: key,
      },
    });
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;
    setPaginatedData(arrayCopy1?.slice(firstPage, lastPage));
  };

  const onPageChange = (page) => {
    setTableState({
      ...tableState,
      ...{ currentPage: page },
    });
  };

  useEffect(() => {
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;
    setTableState({
      ...tableState,
      ...{
        firstPageIndex: firstPage,
        lastPageIndex: lastPage,
        data: props.data,
      },
    });
    setPaginatedData(props.data?.slice(firstPage, lastPage));
  }, [props.data]);

  useEffect(() => {
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;

    setPaginatedData(tableState.data?.slice(firstPage, lastPage));
  }, [tableState.currentPage, tableState.data]);

  const gotoDetails = (id) => {
    navigate(`/trainers/${id}`);
  };
  const [deletedId, setDeletedId] = useState<any>();

  const onDelete = async function dietForm(id: any, version: any) {
    await API.graphql(
      graphqlOperation(deleteTrainer, {
        input: {
          id: id,
          _version: version,
        },
      })
    );
    setDeletedId(id);
  };

  useEffect(() => {
    const datas = props.data.filter((item) => {
      if (item.id !== deletedId) {
        return item;
      }
    });
    props.setData(datas);
  }, [deletedId]);
  const [isDeletes, setIsDeletes] = useState<any>(false);
  const Row = (
    {
      name,
      phone,
      email,
      activeStatus,
      createdAt,
      updatedAt,
      id,
      state,
      _version,
    },
    index
  ) => (
    <tr
      key={index}
      className="tableRow"
      // onClick={gotoDetails.bind(this, id)}
    >
      {/* <td>
        <input type="checkbox" />{" "}
      </td> */}
      <td>
        <span style={{ display: "flex" }}>
          <>
            <span style={{ display: "flex", alignItems: "center" }}>
              {name}
            </span>
            {state === "SPAM" && (
              <span style={{ display: "flex", alignItems: "center" }}>
                <Spam />
              </span>
            )}
          </>
        </span>
      </td>
      <td>
        <span> {phone ?? "NA"}</span>
      </td>
      <td>{<span> {email ?? "NA"}</span>}</td>
      <td> {<Badge text={activeStatus} />}</td>
      <td>{moment(updatedAt).fromNow()}</td>

      <td
        className="actions"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {" "}
        <DeleteComponent
          deletes={isDeletes}
          setDeletes={setIsDeletes}
          onDeletes={(e) => {
            onDelete(id, _version);
          }}
        />
      </td>
    </tr>
  );

  const rows = paginatedData?.map((rowData) => {
    if (!rowData._deleted) {
      return <Row {...rowData} />;
    }
  });

  return (
    <div className="responsivetable">
      <table className="text-left">
        <thead>
          <tr>
            {/* <th>
              <input type="checkbox" />{" "}
            </th> */}
            <th>
              Name
              <button onClick={() => sortBy("name")}>
                {tableState.activeColumnSort !== "name" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort === "name" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>
            </th>
            <th>
              Phone
              <button onClick={() => sortBy("phone")}>
                {tableState.activeColumnSort !== "phone" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort === "phone" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>
            </th>
            <th>
              Email
              <button onClick={() => sortBy("email")}>
                {tableState.activeColumnSort !== "email" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort === "email" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>
            </th>
            <th>
              Status
              <button onClick={() => sortBy("activeStatus")}>
                {tableState.activeColumnSort !== "activeStatus" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort === "activeStatus" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>{" "}
            </th>
            <th>
              Date
              <button onClick={() => sortBy("updatedAt")}>
                {tableState.activeColumnSort !== "updatedAt" && (
                  <i className="fa fa-sort"></i>
                )}
                {tableState.activeColumnSort === "updatedAt" && (
                  <i
                    className={`fa fa-${
                      sortTypes[tableState.currentSort].class
                    }`}
                  ></i>
                )}
              </button>
            </th>

            <th style={{ paddingLeft: 45 }}>Actions</th>
          </tr>
        </thead>
        {rows?.length === 0 ? (
          <tbody>
            <tr>
              <td className="nodata" colSpan={5}>
                No data available
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>{rows}</tbody>
        )}
      </table>
      <Pagination
        className="pagination-bar"
        currentPage={tableState.currentPage}
        totalCount={tableState.data?.length}
        pageSize={PageSize}
        onPageChange={onPageChange}
        rowsPerPage={paginatedData?.length}
        label="Trainers"
      />
    </div>
  );
};

export default Trainers;
