import "./MealInfo.scss";
import { useEffect, useState } from "react";
import { ReactComponent as Graph } from "../../../images/GraphIcon.svg";
import { ReactComponent as More } from "../../../images/MoreButton.svg";
import { ReactComponent as PlusCircle } from "../../../images/PlusCircle.svg";
import { sumOfUnits } from "../../../Utils";
import QuickInfo from "../../../component/QuickInfo/QuickInfo";
const MealInfo = (props) => {
  const [sum, setSum] = useState<any>();
  useEffect(() => {
    if (props.data && props.data[props.label]) {
      const sums = sumOfUnits(props.data[props.label]);
      setSum(sums);
    }
  }, [props.data, props.label]);

  return (
    <div className="mealInfo">
      <div className="mealInfoHeading">
        <div className="mealTypeWithNutients">
          <Graph />
          <div className="mealType">
            <h2>{props.label}</h2>
            <QuickInfo
              name={""}
              calories={sum?.calories}
              carbs={sum?.carbs}
              protein={sum?.protien}
              fats={sum?.fats}
              description={""}
            >
              <label className="dietinfohoverl">{sum?.calories} kcal</label>
            </QuickInfo>
          </div>
          <More />
          <button
            className="addSymbol"
            onClick={() => {
              props.setOpenFoodRecipe(true);
            }}
          >
            <PlusCircle />
          </button>
        </div>
      </div>
    </div>
  );
};
export default MealInfo;
