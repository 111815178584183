import { listCustomRecipes } from "../../../graphql/customQueries";
import {
  createRecipe,
  deleteRecipe,
  updateRecipe,
} from "../../../graphql/mutations";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { API, graphqlOperation } from "aws-amplify";

import _ from "lodash";
import {
  getCalculateRecipeServings,
  getGramsPerServings,
  permaterConfiguredServing,
  sumOfUnits,
} from "../../../Utils";

// Define the state shape for your list of recipe items
interface ListRecipeState {
  value: number;
  recipes: {
    items: any[];
    id: any;
    nextToken: string | null;
    status: string | null;
  };
  status: string | null;
}

// Create the initial state
export const initialState: ListRecipeState = {
  value: 0,
  recipes: {
    items: [],
    id: null,
    nextToken: null,
    status: null,
  },
  status: null, // Initial status can be "idle" or any other initial status you prefer
};

export const fetchRecipes = createAsyncThunk(
  "recipe/listRecipes",
  async (nextToken: string | null) => {
    const response = await API.graphql(
      graphqlOperation(listCustomRecipes, { nextToken })
    );

    return response;
  }
);
export const deleteRecipeFn = createAsyncThunk(
  "recipe/deleteRecipe",
  async (item: any) => {
    const response = await API.graphql(
      graphqlOperation(deleteRecipe, {
        input: {
          id: item.id,
          _version: item._version,
        },
      })
    );
    return response;
  }
);

export const updateRecipeFn = createAsyncThunk(
  "recipe/update",
  async (input: any) => {
    const response = await API.graphql(
      graphqlOperation(updateRecipe, { input: input })
    );
    return response;
  }
);
export const createRecipeFn = createAsyncThunk(
  "recipe/create",
  async (input: any) => {
    const response = await API.graphql(
      graphqlOperation(createRecipe, { input: input })
    );
    return response;
  }
);
export const recipeSlice = createSlice({
  name: "recipe",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
    logout: (state) => {
      return initialState;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchRecipes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchRecipes.fulfilled, (state, action) => {
        const sortedItems = action.payload["data"].listRecipes.items
          .filter((item) => !item?._deleted)
          .sort((a, b) => a.title.localeCompare(b.title));

        const filteredItems = sortedItems.map((item, index) => {
          const result = getCalculateRecipeServings(item); // Calcualte sum of ingredients

          const sumOfUnitsObj = sumOfUnits(result); //item = { ...item, ingredients?.items :result };

          const newIngredients = { ...item["ingredients"], items: result };

          item["ingredients"] = newIngredients;

          item = { ...item, ...sumOfUnitsObj }; // calculate the sum of grams and calories and store in recipe
          const requiredGramsPerServe = getGramsPerServings(
            item,
            item?.servingDescription,
            item?.servingSize
          );

          const requiredCalories = permaterConfiguredServing(
            item?.calculatedGrams || 0,
            requiredGramsPerServe,
            item?.calories
          );
          const requiredProtien = permaterConfiguredServing(
            item?.calculatedGrams || 0,
            requiredGramsPerServe,
            item?.protien
          );
          const requiredCarbs = permaterConfiguredServing(
            item?.calculatedGrams || 0,
            requiredGramsPerServe,
            item?.carbs
          );
          const requiredFats = permaterConfiguredServing(
            item?.calculatedGrams || 0,
            requiredGramsPerServe,
            item?.fats
          );

          const param = {
            calculatedGrams: requiredGramsPerServe,
            calories: requiredCalories,
            protien: requiredProtien,
            carbs: requiredCarbs,
            fats: requiredFats,
          };
          item = { ...item, ...param };

          // Store the unit name in servingDescription
          //TODO: Calculate the same for carbs, fat, protiens
          return item;
        });

        state.recipes = {
          items: state.recipes?.items.concat(filteredItems),
          id: null,
          nextToken: action.payload["data"].listRecipes.nextToken,
          status: "succeeded",
        };
      })
      .addCase(fetchRecipes.rejected, (state) => {
        state.recipes.status = "failed";
      })
      .addCase(deleteRecipeFn.fulfilled, (state, action) => {
        // Add any fetched posts to the array
        //state.bodyParts = action.payload["data"].listBodyParts.items;

        const deletedItem: any = action.payload;
        state.recipes = {
          ...state.recipes,
          ...{
            items: state.recipes?.items.map((data) => {
              if (data.id === deletedItem.data?.deleteRecipe.id) {
                return deletedItem?.data?.deleteRecipe;
              }
              return data;
            }),
            status: "succeeded",
          },
        };
      })
      .addCase(updateRecipeFn.fulfilled, (state, action) => {
        const updatedRecipes = action.payload["data"].updateRecipe;

        const recipeIndex = state.recipes.items.findIndex(
          (exercise) => exercise.id === updatedRecipes.id
        );

        if (recipeIndex !== -1) {
          state.recipes.items[recipeIndex] = updatedRecipes;
        }

        state.recipes.status = "succeeded";
      })
      .addCase(createRecipeFn.fulfilled, (state, action) => {
        const createdExercise = action.payload["data"].createRecipe; // Adjust this to match your API response structure

        // Add the created exercise to the items array
        state.recipes.items.push(createdExercise);
        state.recipes.id = action.payload["data"].createRecipe.id;
        // Set the status to "succeeded"
        state.recipes.status = "succeeded";
      });
  },
});
export const { increment, decrement, incrementByAmount, logout } =
  recipeSlice.actions;

export default recipeSlice.reducer;
