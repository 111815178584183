import "./failure.scss";

import paymentImage from "../../images/paymentimage.png";

const Failure = () => {
  //Payment
  return (
    <>
      <div className="failureContainer">
        <div className="failureContainer__left">
          <div className="failureContainer__left__header">
            <img className="payImage" src={paymentImage} alt="" />
          </div>
          <div className="failureContainer__left__footer">
            <div className="failureContainer__left__footer__topText">
              Payment Failure!
            </div>
            <div className="failureContainer__left__footer__middleText">
              <div className="failureContainer__left__footer__middleText__top">
                Thank you for choosing Fitness.Please try again
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Failure;
