import "./switch.scss";
import cls from "classnames";

const Switch = ({ rounded = false, isToggle, onToggle }) => {
  const sliderCls = cls("slider-check", {
    rounded: rounded,
  });
  return (
    <label className="switch">
      <input type="checkbox" checked={isToggle} onChange={onToggle} />
      <span className={sliderCls}></span>
    </label>
  );
};

export default Switch;
