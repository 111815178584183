import { useEffect, useRef, useState } from "react";
import "./AddPackage.scss";
import { Storage } from "aws-amplify";
import { useDispatch } from "react-redux";
import { ReactComponent as Check } from "../../../images/check.svg";
import { ReactComponent as Upload } from "../../../images/upload_file.svg";
import { sendNotification } from "../../../app/features/notifications";
import xcircle from "../../../images/x-circle.svg";
import EditIcon from "@mui/icons-material/Edit";
import { v4 as uuid } from "uuid";

import VideoPlayerTag from "../../../component/VideoPlayer/VideoPlayer";

import Grid from "@mui/material/Grid";
import MUIRichTextEditor, { TMUIRichTextEditorRef } from "mui-rte";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Tooltip } from "@material-ui/core";
import LoaderButton from "../../../component/LoaderButton";

const AddPackage = ({
  handleClose,
  show,
  handleSubmit,
  packageItem,
  handleEdit,
}) => {
  const [bucketPath, setBucketPath]: any = useState();
  const [uploadPercentage, setUploadPercentage]: any = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [emptyDescription, isEmptyDescription] = useState<boolean>(false);
  const [focusedOnDescription, isFocusedOnDEscription] =
    useState<boolean>(false);

  const ref = useRef<TMUIRichTextEditorRef>(null);
  const handleClick = () => {
    isFocusedOnDEscription(true);

    ref.current?.save();
  };

  useEffect(() => {
    (async () => {
      const url = await Storage.get("");
      return setBucketPath(url.split("?")[0]);
    })();
  }, []);

  const dispatch = useDispatch();
  const showHideClassName = show
    ? "Addmodal display-block"
    : "Addmodal display-none";
  const paymentTypes = ["Subscription", "One-time"];
  const features = ["Meal plans", "Workouts"];
  const choices = [
    {
      keys: ["face"],
      value: "[face_1]",
      content: "face_1",
    },
    {
      keys: ["prix"],
      value: "[prix]",
      content: "prix",
    },
    {
      keys: ["prix", "tva"],
      value: "[tva]",
      content: "tva",
    },
  ];
  const functions = [
    {
      keys: ["math"],
      value: "eval(  )",
      content: "eval(  )",
    },
  ];
  const [videoFile, setVideoFile] = useState<string>();
  const [thumbnail, setThumbnail] = useState<any>();
  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
  };

  const [formState, setFormState] = useState<{
    name: string;
    price: string;
    duration: string;
    description: string;
    productType: string;
    features: string[];
    thumbnail: Record<string, any>;
    video: Record<string, any>; // Type definition for video as an object
  }>({
    name: "",
    price: "",
    duration: "",
    description: "",
    thumbnail: {
      any: undefined,
    },
    video: {}, // Provide an empty object as the initial value
    features: ["Meal plans", "Workouts"],
    productType: "Subscription",
  });

  const [formError, setFormError] = useState({
    priceError: "",
    nameError: "",
    durationError: "",
    video: "",
    videoFile: "",
    thumbnail: {},
    features: "",
    productType: "",
    description: "",
  });
  useEffect(() => {
    packageItem?.video && setVideoFile(bucketPath + packageItem?.video);
  }, [bucketPath, packageItem]);
  useEffect(() => {
    if (packageItem != null) {
      setFormState({
        name: `${packageItem.name}`,
        price: `${packageItem.price}`,
        duration: `${packageItem.duration}`,
        description: packageItem.description,
        // description: content,
        video: packageItem.video,
        thumbnail: packageItem.thumbnail,
        features: packageItem.features,
        productType: packageItem.productType,
      });
    } else {
      setFormState(formState);
    }
  }, [packageItem]);

  const myTheme = createTheme({
    // Set up your custom MUI theme here
  });

  const successResponse = (description) => {
    setSuccess(true);
    setLoading(false);
    clearState();
    handleClose();
    dispatch(
      sendNotification({
        title: "Success",
        description: description,
        backgroundColor: "#5cb85c",
        icon: Check,
      })
    );
  };
  const Submit = (e) => {
    e.preventDefault();
    let valid = true;

    if (formState.name.trim() === "") {
      setFormError((prevState) => ({
        ...prevState,
        nameError: "Name is required",
      }));
      valid = false;
    }

    if (formState.price.trim() === "") {
      setFormError((prevState) => ({
        ...prevState,
        priceError: "Price is required",
      }));
      valid = false;
    } else if (!/^\d+$/.test(formState.price)) {
      setFormError((prevState) => ({
        ...prevState,
        priceError: "Invalid price. Only numbers are allowed",
      }));

      valid = false;
    } else {
      setFormError((prevState) => ({
        ...prevState,
        priceError: "",
      }));
    }

    if (formState.duration.trim() === "") {
      setFormError((prevState) => ({
        ...prevState,
        durationError: "Duration is required",
      }));
      valid = false;
    } else if (!/^\d+$/.test(formState.duration)) {
      setFormError((prevState) => ({
        ...prevState,
        durationError: "Invalid Duration. Only numbers are allowed",
      }));

      valid = false;
    } else {
      setFormError((prevState) => ({
        ...prevState,
        durationError: "",
      }));
    }

    if (formState.productType === "") {
      setFormError((prevState) => ({
        ...prevState,
        productType: "Product type is required",
      }));
      valid = false;
    } else {
      setFormError((prevState) => ({
        ...prevState,
        productType: "",
      }));
    }
    if (formState.features && formState.features.length === 0) {
      setFormError((prevState) => ({
        ...prevState,
        features: "Feature is required",
      }));
      valid = false;
    } else {
      setFormError((prevState) => ({
        ...prevState,
        features: "",
      }));
    }
    if (valid) {
      e.preventDefault();

      if (packageItem === null) {
        try {
          if (formState?.video?.name) {
            setLoading(true);

            const upload = Storage.put(
              formState.video.name,
              formState.video.file,
              {
                contentType: "video/*", // contentType is optional
                completeCallback: (event) => {
                  console.log(`Successfully uploaded ${event.key}`);
                },
                progressCallback: (progress) => {
                  console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                  const completionPercentage =
                    (progress.loaded / progress.total) * 100;
                  setUploadPercentage(completionPercentage);
                  if (completionPercentage >= 100) {
                    successResponse("Packages updated successfully!");
                  }
                },
                errorCallback: (err) => {
                  console.error("Unexpected error while uploading", err);
                },
              }
            );
            Storage.put(formState.thumbnail.name, formState.thumbnail.file, {
              contentType: "image/svg+xml/jpeg/png", // contentType is optional
              completeCallback: (event) => {
                console.log(`Successfully uploaded ${event.key}`);
              },
              progressCallback: (progress) => {
                console.log(
                  `Uploaded Image: ${progress.loaded}/${progress.total}`
                );
              },
              errorCallback: (err) => {
                console.error("Unexpected error while uploading", err);
              },
            });
          } else {
            successResponse("Packages created successfully!");
          }
        } catch (e) {
          console.log("S3 error", e);
        }
        handleSubmit({
          name: e.target.name.value,
          price: e.target.price.value,
          duration: e.target.duration.value,
          description: formState.description,
          productType: formState.productType,
          features: formState.features,
          thumbnail: formState.thumbnail.name,
          video: formState.video.name,
        });
      } else {
        let formData = { ...formState };
        if (formState?.video?.file) {
          setLoading(true);

          try {
            Storage.remove(packageItem.video);
            Storage.remove(packageItem.thumbnail);
            const upload = Storage.put(
              formState.video.name,
              formState.video.file,
              {
                contentType: "video/*", // contentType is optional
                completeCallback: (event) => {
                  console.log(`Successfully uploaded ${event.key}`);
                },
                progressCallback: (progress) => {
                  console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                  const completionPercentage =
                    (progress.loaded / progress.total) * 100;
                  setUploadPercentage(completionPercentage);
                  setLoading(true);
                  if (completionPercentage >= 100) {
                    successResponse("Packages updated successfully!");
                  }
                },
                errorCallback: (err) => {
                  console.error("Unexpected error while uploading", err);
                },
              }
            );
            Storage.put(formState.thumbnail.name, formState.thumbnail.file, {
              contentType: "image/svg+xml/jpeg/png",
              completeCallback: (event) => {
                console.log(`Successfully uploaded ${event.key}`);
              },
              progressCallback: (progress) => {
                console.log(
                  `Uploaded Image: ${progress.loaded}/${progress.total}`
                );
              },
              errorCallback: (err) => {
                console.error("Unexpected error while uploading", err);
              },
            });
            formData = {
              ...formState,
              video: formState.video.name,
              thumbnail: formState.thumbnail.name,
            };
          } catch (e) {
            console.log("S3 error", e);
          }
        } else {
          successResponse("Packages updated successfully!");
        }

        console.log("packageItem edit", packageItem);
        console.log("formState during edit", formState);
        handleEdit({
          ...formData,
          _version: packageItem._version,
          id: packageItem.id,
        });
      }
    }
  };

  const handleChangeName = (event) => {
    setFormState((prevState) => ({
      ...prevState,
      name: event.target.value,
    }));
    setFormError((prevState) => ({
      ...prevState,
      nameError: "",
    }));
  };

  const handleChangePrice = (event) => {
    setFormState((prevState) => ({
      ...prevState,
      price: event.target.value,
    }));
    setFormError((prevState) => ({
      ...prevState,
      priceError: "",
    }));
  };

  const handleChangeDuration = (event) => {
    setFormState((prevState) => ({
      ...prevState,
      duration: event.target.value,
    }));
    setFormError((prevState) => ({
      ...prevState,
      durationError: "",
    }));
  };
  const handleChangeProductType = (event) => {
    setFormState((prevState) => ({
      ...prevState,
      productType: event.target.value,
    }));
  };
  const handleChangeFeatures = (event) => {
    const { value, checked } = event.target;

    setFormState((prevState) => {
      let updatedPaymentType;
      if (checked) {
        updatedPaymentType = [...prevState.features, value];
      } else {
        updatedPaymentType = prevState.features.filter(
          (type) => type !== value
        );
      }

      return {
        ...prevState,
        features: updatedPaymentType,
      };
    });
  };
  useEffect(() => {
    if (emptyDescription) {
      setFormError((prevState) => ({
        ...prevState,
        description: "Description is required",
      }));
    } else {
      setFormError((prevState) => ({
        ...prevState,
        description: "",
      }));
    }
  }, [emptyDescription]);
  const handleDescription = (data) => {
    setFormState((prevState) => ({
      ...prevState,
      description: data,
    }));
  };
  const handleEmptyDescription = (editorState) => {
    if (focusedOnDescription && !editorState.getCurrentContent().hasText()) {
      //console.log("empty");
      isEmptyDescription(true);
    } else {
      isEmptyDescription(false);
    }
  };
  const handleBlur = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "name":
        if (!value) {
          setFormError((prevState) => ({
            ...prevState,
            nameError: "Name is required",
          }));
        } else {
          setFormError((prevState) => ({
            ...prevState,
            nameError: "",
          }));
        }
        break;
      case "price":
        if (!value) {
          setFormError((prevState) => ({
            ...prevState,
            priceError: "Price is required",
          }));
        } else if (!/^\d+$/.test(value)) {
          setFormError((prevState) => ({
            ...prevState,
            priceError: "Invalid price. Only numbers are allowed",
          }));
        } else {
          setFormError((prevState) => ({
            ...prevState,
            priceError: "",
          }));
        }
        break;
      case "duration":
        if (!value) {
          setFormError((prevState) => ({
            ...prevState,
            durationError: "Duration is required",
          }));
        } else if (!/^\d+$/.test(value)) {
          setFormError((prevState) => ({
            ...prevState,
            durationError: "Duration price. Only numbers are allowed",
          }));
        } else {
          setFormError((prevState) => ({
            ...prevState,
            durationError: "",
          }));
        }
        break;

      default:
        break;
    }
  };
  const clearState = () => {
    setFormState((prevState) => ({
      ...prevState,
      name: "",
    }));
    setFormState((prevState) => ({
      ...prevState,
      price: "",
    }));
    setFormState((prevState) => ({
      ...prevState,
      duration: "",
    }));
    setFormState((prevState) => ({
      ...prevState,
      description: "",
    }));
    setFormError((prevState) => ({
      ...prevState,
      nameError: "",
    }));
    setFormError((prevState) => ({
      ...prevState,
      priceError: "",
    }));
    setFormError((prevState) => ({
      ...prevState,
      durationError: "",
    }));
    setFormError((prevState) => ({
      ...prevState,
      featuresError: "",
    }));
    setFormError((prevState) => ({
      ...prevState,
      paymentTypeError: "",
    }));
  };

  async function handleChange(e: any) {
    const file = e.target.files[0];
    try {
      e.persist();
      const packageVideoName = `package_${uuid()}`;
      if (!e.target.files[0]) return;
      const video = {
        file: file,
        name: packageVideoName,
      };
      setFormState((currentState) => ({
        ...currentState,
        //file: URL.createObjectURL(e.target.files[0]),
        video,
      }));
      setVideoFile(URL.createObjectURL(e.target.files[0]));
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }
  const handleGetThumbnail = (imgBlob) => {
    const imgName = `Img_${formState.video.name}.jpg`;
    const file = new File([imgBlob], imgName);
    const thumbnail = {
      file: file,
      name: imgName,
    };

    setFormState((currentState) => ({
      ...currentState,
      thumbnail,
    }));
  };

  return (
    <>
      <div className={showHideClassName}>
        <section className="AddPackage-main">
          <div className="heading">
            {packageItem === null ? "Add Package" : "Edit Package"}
            <img
              className="heading__image"
              onClick={() => {
                handleClose();
                clearState();
              }}
              alt="close"
              src={xcircle}
            />
          </div>
          <form className="formstyle" onSubmit={Submit}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <div>
                  <div className="videoPackage">
                    <div>
                      <input
                        type="file"
                        hidden
                        id="package-upload"
                        accept="video/*"
                        onChange={handleChange}
                        name="Package video"
                      />
                    </div>
                    {formState?.video?.name || packageItem?.video ? (
                      <div className="videoPreview">
                        <div className="videoChange">
                          <Tooltip title="Change video">
                            <label
                              htmlFor="package-upload"
                              className="package-upload"
                            >
                              <EditIcon />
                            </label>
                          </Tooltip>
                        </div>
                        <VideoPlayerTag
                          src={videoFile}
                          getThumbnail={handleGetThumbnail}
                        />
                      </div>
                    ) : (
                      <label className="noVideo" htmlFor="package-upload">
                        <Upload />
                        <span> Click here to upload the package video</span>
                      </label>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item sm={6} container>
                <div className="packagecontent">
                  <div className="packagename">
                    <span className="inputType">Name</span>
                    <div>
                      <input
                        type="text"
                        className="contentinput"
                        name="name"
                        value={formState.name}
                        onChange={handleChangeName}
                        onBlur={handleBlur}
                        disabled={packageItem?.name ? true : false}
                      />
                      {formError.nameError && (
                        <div className="errorMessage">
                          {formError.nameError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="price">
                    <span className="inputType"> Price (in $)</span>
                    <div>
                      <input
                        type="text"
                        className="contentinput"
                        name="price"
                        value={formState.price}
                        onChange={handleChangePrice}
                        onBlur={handleBlur}
                        disabled={packageItem?.price ? true : false}
                      />
                      {formError.priceError && (
                        <div className="errorMessage">
                          {formError.priceError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="duration">
                    <div className="inputType">Duration (in months)</div>
                    <div>
                      <input
                        type="text"
                        className="contentinput"
                        name="duration"
                        value={formState.duration}
                        onChange={handleChangeDuration}
                        onBlur={handleBlur}
                        disabled={packageItem?.duration ? true : false}
                      />
                      {formError.durationError && (
                        <div className="errorMessage">
                          {formError.durationError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="description">
                    <span className="inputType">Description</span>
                    <div>
                      <div className="customdescriptionBox">
                        <ThemeProvider theme={myTheme}>
                          <MUIRichTextEditor
                            label="Type something here..."
                            onChange={handleEmptyDescription}
                            onBlur={handleClick}
                            onSave={handleDescription}
                            //onFocus={handleDescriptionFocus}
                            ref={ref}
                            defaultValue={formState.description}
                            inlineToolbar={true}
                            controls={[
                              "bold",
                              "italic",
                              "underline",
                              "bulletList",
                              "numberList",
                              "undo",
                              "redo",
                              "color",
                              "highlight",
                            ]}
                            toolbarButtonSize="small"
                            autocomplete={{
                              strategies: [
                                {
                                  items: choices,
                                  triggerChar: "[",
                                  insertSpaceAfter: false,
                                },
                                {
                                  items: functions,
                                  triggerChar: "$",
                                  insertSpaceAfter: false,
                                },
                              ],
                            }}
                          />
                        </ThemeProvider>
                      </div>
                      <div>
                        {formError.description && (
                          <div className="errorMessage">
                            {formError.description}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="producttype">
                    <span className="inputType">Product Type</span>
                    <div>
                      <div className="payment-type">
                        {paymentTypes.map((item) => (
                          <label key={item}>
                            <input
                              type="radio"
                              name="productType"
                              value={item}
                              checked={formState.productType === item}
                              onChange={handleChangeProductType}
                              disabled={packageItem?.name ? true : false}
                            />
                            {item}
                          </label>
                        ))}
                      </div>
                      <div>
                        {formError.productType && (
                          <div className="errorMessage">
                            {formError.productType}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="producttype">
                    <span className="inputType">Features</span>
                    <div>
                      <div className="feature-type">
                        {features.map((item) => (
                          <label key={item}>
                            <input
                              type="checkbox"
                              name="features"
                              value={item}
                              checked={formState?.features?.includes(item)}
                              onChange={handleChangeFeatures}
                            />
                            {item}
                          </label>
                        ))}
                      </div>
                      <div>
                        {formError.features && (
                          <div className="errorMessage">
                            {formError.features}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div>
              <LoaderButton
                className="savepackage"
                progress={uploadPercentage}
                loading={loading}
                success={success}
              />
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default AddPackage;
