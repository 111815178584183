import React, { useEffect, useState } from "react";
import "./Profile.scss";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { getTrainer } from "../../graphql/queries";
import { updateTrainer } from "../../graphql/mutations";

const Profile = () => {
  const [editMode, setEditMode] = useState(false);
  const [trainerId, setTrainerId] = useState("");
  const [userId, setUserId] = useState("");
  const [profileData, setProfileData] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    stripeEndpointSecret: "",
    stripeSecretKey: "",
    _version: "",
  });

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const user = await Auth.currentUserInfo();
      const { attributes } = user;
      setTrainerId(attributes["custom:trainerId"]);
      setUserId(attributes.sub);
      if (attributes["custom:trainerId"]) {
        const trainerData = await getTrainerData(
          attributes["custom:trainerId"]
        );

        setProfileData((prevData) => ({
          ...prevData,
          id: trainerData?.id,
          name: trainerData?.name,
          email: trainerData?.email,
          phone: trainerData?.phone,
          stripeEndpointSecret: trainerData?.stripeEndpointSecret,
          stripeSecretKey: trainerData?.stripeSecretKey,
          _version: trainerData?._version,
        }));
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };

  const getTrainerData = async (trainerID) => {
    try {
      const graphqlData: any = await API.graphql(
        graphqlOperation(getTrainer, { id: trainerID })
      );
      if (graphqlData?.data) {
        return graphqlData.data.getTrainer;
      } else {
        const { data } = await graphqlData.toPromise();
        return data.getTrainer;
      }
    } catch (error) {
      console.error("Error fetching trainer data: ", error);
      return {};
    }
  };

  const handleInputChange = (event) => {
    setProfileData({ ...profileData, [event.target.name]: event.target.value });
  };

  const handleEditClick = () => {
    setEditMode(!editMode);
  };
  const handleSaveChanges = async () => {
    try {
      if (trainerId) {
        const updatedTrainer = {
          id: profileData.id,
          name: profileData.name,
          email: profileData.email,
          phone: profileData.phone,
          stripeEndpointSecret: profileData.stripeEndpointSecret,
          stripeSecretKey: profileData.stripeSecretKey,
          _version: profileData._version,
        };

        const updateInput = {
          input: updatedTrainer,
        };
        const graphqlData = await API.graphql(
          graphqlOperation(updateTrainer, updateInput)
        );

        await fetchUserData();
        setEditMode(false);
      } else {
        console.error("Trainer ID not found");
      }
    } catch (error) {
      console.error("Error updating trainer data:", error);
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <h1>Profile</h1>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<EditIcon />}
          onClick={handleEditClick}
          sx={{ color: "white" }}
        >
          Edit
        </Button>
      </div>
      <div className="form-container">
        <div className="card">
          <div className="profile-form">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={profileData.name}
              onChange={handleInputChange}
              disabled={!editMode}
            />
          </div>
          <div className="profile-form">
            <label>User Id:</label>
            <input type="text" name="userId" value={userId} disabled />
          </div>
          <div className="profile-form">
            <label>Email:</label>
            <input
              type="text"
              name="email"
              value={profileData.email}
              disabled
            />
          </div>
          <div className="profile-form">
            <label>Phone:</label>
            <input
              type="text"
              name="phone"
              value={profileData.phone}
              disabled
            />
          </div>
          {/* <div className="profile-form">
            <label>Stripe Endpoint Secret:</label>
            <input
              type="text"
              name="stripeEndpointSecret"
              value={profileData.stripeEndpointSecret}
              onChange={handleInputChange}
              disabled={!editMode}
            />
          </div> */}
          <div className="profile-form">
            <label>Stripe Secret Key:</label>
            <input
              type="text"
              name="stripeSecretKey"
              value={profileData.stripeSecretKey}
              onChange={handleInputChange}
              disabled={!editMode}
            />
          </div>
          {editMode && (
            <div className="profile-form">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveChanges}
                sx={{ color: "white" }}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
