import { green } from "@mui/material/colors";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box
      sx={{
        color: green[500],
        height: 28,
      }}
    >
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 12,
          // left: 0,
          // bottom: 0,
          right: 15,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          fontSize={"8px"}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
export default function LoaderButton(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
      <Box sx={{ m: 1, position: "relative" }}>
        <LoadingButton
          loadingPosition="end"
          disabled={props.loading}
          type="submit"
          endIcon={
            props.loading ? (
              <CircularProgressWithLabel value={props.progress} size={26} />
            ) : (
              <SaveIcon />
            )
          }
          variant="contained"
        >
          Save
        </LoadingButton>
      </Box>
    </Box>
  );
}
