import { useEffect, useState } from "react";
import "./Nutrients.scss";
import xcircle from "../../../images/x-circle.svg";
import { PieChart, Pie, Cell, Legend } from "recharts";
import _ from "lodash";
import { getTotalSumOFMoreFields, sumOfUnits } from "../../../Utils";

const Nutrients = ({ show, handleClose, data }) => {
  const showHideClassName = show
    ? "Nutrients display-block"
    : "Nutrients display-none";
  const [moreFields, setMoreFields] = useState<any>();
  const [pieData, setPieData] = useState<any>([]);
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

  const Bullet = ({ backgroundColor, size }) => {
    return (
      <div
        style={{
          backgroundColor,
          width: size,
          height: size,
        }}
      ></div>
    );
  };
  const CustomizedLegend = (props) => {
    const { payload } = props;
    return (
      <ul className="LegendList">
        {payload.map((entry, index) => (
          <li key={`item-${index}`}>
            <div className="BulletLabel">
              <Bullet backgroundColor={entry.payload.fill} size="10px" />
              <div className="BulletLabelText">{entry.value}</div>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    if (data) {
      const unitResult = sumOfUnits(data);
      setPieData([
        { name: "Protein", value: unitResult.protien },
        { name: "Carbs", value: unitResult.carbs },
        { name: "Fats", value: unitResult.fats },
      ]);

      const res = getTotalSumOFMoreFields(data);

      setMoreFields({
        Calories: { qty: unitResult.calories, unit: "kcal" },
        ...res,
      });
    }
  }, [data]);

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  interface Nutrient {
    qty: number;
    unit: string;
  }

  interface Props {
    moreFields: Record<string, Nutrient>;
  }

  const NutrientTable: React.FC<Props> = ({ moreFields }) => {
    if (!moreFields) {
      return null;
    }

    const rows = Object.entries(moreFields).reduce(
      (accumulator: JSX.Element[], [key, value]) => {
        const formattedQty = value.qty.toFixed(2);
        let nutrientLeftText: JSX.Element, nutrientRightText: JSX.Element;
        if (key === "Calories") {
          nutrientLeftText = (
            <td style={{ fontFamily: "Poppins-Medium" }}>{key}</td>
          );
          nutrientRightText = (
            <td style={{ fontFamily: "Poppins-Medium" }}>
              {formattedQty}
              {value.unit}
            </td>
          );
        } else {
          nutrientLeftText = <td>{key}</td>;
          nutrientRightText = (
            <td>
              {formattedQty}
              {value.unit}
            </td>
          );
        }

        return [
          ...accumulator,
          <tr style={{ border: "1px solid black" }} key={key}>
            {nutrientLeftText}
            {nutrientRightText}
          </tr>,
        ];
      },
      []
    );

    return (
      <table style={{ border: "1px solid black", width: "100%" }}>
        <thead>
          <tr></tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  return (
    <>
      <div className={showHideClassName}>
        <section className="Nutrients-main">
          <div className="heading">
            More Detailed information
            <img
              className="heading__image"
              onClick={handleClose}
              src={xcircle}
              alt=""
            />
            {pieData && (
              <div className="Nutrients-main__body">
                <PieChart width={400} height={180}>
                  <Pie
                    data={pieData}
                    cx={110}
                    cy={85}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={90}
                    dataKey="value"
                  >
                    {pieData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Legend content={<CustomizedLegend />} />
                </PieChart>

                <div></div>
              </div>
            )}
          </div>

          <div
            className={
              pieData.length !== 0 ? "Nutrientsbody" : "Nutrientswithoutdata"
            }
          >
            <>
              <div className="column">
                <NutrientTable moreFields={moreFields} />
              </div>
            </>
          </div>
        </section>
      </div>
    </>
  );
};

export default Nutrients;
