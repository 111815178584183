import { Auth } from "aws-amplify";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { ReactComponent as Lock } from "../../images/Lock.svg";
import { ReactComponent as Message } from "../../images/Message.svg";
import { ReactComponent as HidePassword } from "../../images/Hide-Password.svg";
import { Snackbar, SnackbarContent, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const initialFormState = {
  username: "",
  password: "",
  resetPassword: "",
  confirmResetPassword: "",
  forgotPasswordPasswordEmail: "",
  resetForgotPassword: "",
  confirmResetForgotPassword: "",
  authCode: "",
  formType: "signIn",
};
const initialErrorState = {
  username: "",
  password: "",
  resetPassword: "",
  confirmResetPassword: "",
  forgotPasswordPasswordEmail: "",
  resetForgotPassword: "",
  confirmResetForgotPassword: "",
  authCode: "",
  errors: "",
};
const Login = () => {
  const [formState, setFormState] = useState(initialFormState);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [formErrors, setFormErrors] = useState(initialErrorState);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [resetForgotPassword, setResetForgotPassword] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const navigate = useNavigate();
  const { formType } = formState;
  const onChange = (e) => {
    setFormState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const signInUser = async () => {
    const { username, password, confirmResetPassword } = formState;

    try {
      if (!isResetPassword) {
        const user = await Auth.signIn(username, password);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setIsResetPassword(true);
        } else {
          if (!user.attributes.email_verified) {
            await Auth.verifyCurrentUserAttribute("email");
            setSnackbarMessage("Verification code sent to your email.");
            setSnackbarOpen(true);
            setVerifyEmail(true);
            setFormErrors({
              ...formErrors,
              errors: "Email not verified. Verification email sent.",
            });
          } else {
            if (
              user.signInUserSession &&
              user.signInUserSession.accessToken &&
              user.signInUserSession.accessToken.payload &&
              user.signInUserSession.accessToken.payload["cognito:groups"]
            ) {
              const userGroups =
                user.signInUserSession.accessToken.payload["cognito:groups"];
              if (
                !userGroups.includes("ADMIN") &&
                !userGroups.includes("Trainers")
              ) {
                setFormErrors({
                  ...formErrors,
                  errors: "Access denied. Only ADMIN and Trainers can log in.",
                });
                return;
              }
              navigate("/");
              setSnackbarMessage("Login successful.");
              setSnackbarOpen(true);
            } else {
              setFormErrors({
                ...formErrors,
                errors: "User session or access token payload is missing",
              });
            }
          }
        }
      } else {
        const user = await Auth.signIn(username, password);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          await Auth.completeNewPassword(user, confirmResetPassword, {})
            .then(async (data) => {
              // After setting the new password, check the user groups again
              const userWithNewPassword = await Auth.signIn(
                username,
                confirmResetPassword
              );
              const userGroups =
                userWithNewPassword.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ];
              console.log("User Groups:", userGroups);

              if (
                !userGroups.includes("ADMIN") &&
                !userGroups.includes("Trainers")
              ) {
                setFormErrors({
                  ...formErrors,
                  errors: "Access denied. Only ADMIN and Trainers can log in.",
                });
                return;
              }

              navigate("/");
              setSnackbarMessage("Login successful.");
              setSnackbarOpen(true);
            })
            .catch((err) => {
              console.error("Error during password reset", err);
              setFormErrors({
                ...formErrors,
                errors: "Error resetting password. Please try again.",
              });
            });
        }
      }
    } catch (error) {
      console.error("Error during sign-in process", error);
      setFormErrors({
        ...formErrors,
        errors: "Username or Password is incorrect",
      });
    } finally {
      setIsSigningIn(false);
      console.log("Sign-in process complete");
    }
  };

  const signIn = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formState));
    setIsSubmit(true);
    setIsSigningIn(true);
    signInUser();
  };
  const verifyUserEmail = async (e) => {
    e.preventDefault();
    const { authCode } = formState;
    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", authCode);
      setVerifyEmail(false);
      navigate("/");
      setSnackbarMessage("Email verified successfully.");
      setSnackbarOpen(true);
    } catch (error) {
      setFormErrors({
        ...formErrors,
        errors: "Failed to verify email. Please try again.",
      });
    }
  };
  const forgotPassword = async (e) => {
    e.preventDefault();
    const { forgotPasswordPasswordEmail } = formState;

    try {
      await Auth.forgotPassword(forgotPasswordPasswordEmail);
      setResetForgotPassword(true);
      setSnackbarMessage("Verification code sent to your email.");
      setSnackbarOpen(true);
    } catch (error) {
      setFormErrors({
        ...formErrors,
        errors: "Failed to initiate password reset. Please try again.",
      });
    }
  };

  const handleResetForgotPassword = async (e) => {
    e.preventDefault();
    const {
      forgotPasswordPasswordEmail,
      authCode,
      confirmResetForgotPassword,
    } = formState;

    try {
      const resetData = await Auth.forgotPasswordSubmit(
        forgotPasswordPasswordEmail,
        authCode,
        confirmResetForgotPassword
      );
      navigate("/");
      setSnackbarMessage(
        "Password changed successfully. Please login with new credentials."
      );
      setSnackbarOpen(true);
    } catch (error) {
      setFormErrors({
        ...formErrors,
        errors: "Failed to reset password. Please try again.",
      });
    }
  };

  const validate = (values) => {
    const errors = { ...initialErrorState };
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.username || !regex.test(values.username)) {
      errors.username = "Please enter a valid email address";
    }
    if (!values.password || values.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }
    if (isResetPassword) {
      if (!values.resetPassword || values.resetPassword.length < 8) {
        errors.resetPassword =
          "New Password must be at least 8 characters long";
      }
      if (
        !values.confirmResetPassword ||
        values.confirmResetPassword !== values.resetPassword
      ) {
        errors.confirmResetPassword = "Passwords do not match";
      }
    }
    return errors;
  };

  const showPassword = () => {
    setIsRevealPwd(!isRevealPwd);
  };

  // const goToSignUp = () => {
  //   navigate("/SignUp");
  // };

  return (
    <div>
      {formType === "signIn" && (
        <div className="figma">
          <div className="row">
            <div className="fitness img">
              <div className="fitness logo">
                <Logo />
              </div>
            </div>

            <div className="fitness form">
              {isForgotPassword ? (
                <>
                  <div className="welcome_text">Reset Password</div>
                  <div className="login_text">
                    Please enter your email to reset your password
                  </div>
                  <div className="errors__in-signin">{formErrors.errors}</div>

                  {resetForgotPassword ? (
                    <>
                      <form onSubmit={handleResetForgotPassword}>
                        <div className="input-container">
                          <label>Verification Code:</label>
                          <div className="inputFront">
                            <Lock />
                          </div>

                          <input
                            name="authCode"
                            type="text"
                            onChange={onChange}
                            required
                          />
                          <HidePassword onClick={showPassword} />
                          <div className="errors">{formErrors.authCode}</div>
                        </div>
                        <div className="input-container">
                          <label>New Password:</label>
                          <div className="inputFront">
                            <Lock />
                          </div>

                          <input
                            name="resetForgotPassword"
                            type={isRevealPwd ? "text" : "password"}
                            onChange={onChange}
                            required
                          />
                          <HidePassword onClick={showPassword} />
                          <div className="errors">
                            {formErrors.resetForgotPassword}
                          </div>
                        </div>
                        <div className="input-container">
                          <label>Confirm New Password:</label>
                          <div className="inputFront">
                            <Lock />
                          </div>

                          <input
                            name="confirmResetForgotPassword"
                            type={isRevealPwd ? "text" : "password"}
                            onChange={onChange}
                            required
                          />
                          <HidePassword onClick={showPassword} />
                          <div className="errors">
                            {formErrors.confirmResetForgotPassword}
                          </div>
                        </div>
                        <div className="button-container">
                          <button type="submit" className="loginbtn">
                            Reset Password
                          </button>
                        </div>
                      </form>
                    </>
                  ) : (
                    <form onSubmit={forgotPassword}>
                      <div className="input-container">
                        <label>Email: </label>
                        <div className="inputFront">
                          <Message />
                        </div>
                        <input
                          type="text"
                          name="forgotPasswordPasswordEmail"
                          onChange={onChange}
                          required
                        />
                        <div className="errors">
                          {formErrors.forgotPasswordPasswordEmail}
                        </div>
                      </div>
                      <div className="button-container">
                        <button type="submit" className="loginbtn">
                          Reset Password
                        </button>
                      </div>
                    </form>
                  )}
                </>
              ) : verifyEmail ? (
                <>
                  <div className="welcome_text">Welcome back</div>
                  <div className="login_text">Login to your account</div>
                  <div className="errors__in-signin">{formErrors.errors}</div>
                  <form onSubmit={verifyUserEmail}>
                    <div className="input-container">
                      <label>Email: </label>
                      <div className="inputFront">
                        <Message />
                      </div>
                      <input
                        type="text"
                        name="username"
                        onChange={onChange}
                        required
                      />
                      <div className="errors">{formErrors.username}</div>
                    </div>
                    <div className="input-container">
                      <label>Verification Code:</label>
                      <div className="inputFront">
                        <Lock />
                      </div>

                      <input
                        name="authCode"
                        type="text"
                        onChange={onChange}
                        required
                      />
                      {/* <HidePassword onClick={showPassword} /> */}
                      <div className="errors">{formErrors.authCode}</div>
                    </div>
                    <div className="button-container">
                      <button type="submit" className="loginbtn">
                        Confirm Email
                      </button>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <div className="welcome_text">Welcome back</div>
                  <div className="login_text">Login to your account</div>
                  <div className="errors__in-signin">{formErrors.errors}</div>
                  <form onSubmit={signIn}>
                    {isResetPassword ? (
                      <>
                        <div className="input-container">
                          <label>Email: </label>
                          <div className="inputFront">
                            <Message />
                          </div>
                          <input
                            type="text"
                            name="forgotPasswordPasswordEmail"
                            onChange={onChange}
                            required
                          />
                          <div className="errors">
                            {formErrors.forgotPasswordPasswordEmail}
                          </div>
                        </div>
                        <div className="input-container">
                          <label>New Password:</label>
                          <div className="inputFront">
                            <Lock />
                          </div>

                          <input
                            name="resetPassword"
                            type={isRevealPwd ? "text" : "password"}
                            onChange={onChange}
                            required
                          />
                          <HidePassword onClick={showPassword} />
                          <div className="errors">
                            {formErrors.resetPassword}
                          </div>
                        </div>
                        <div className="input-container">
                          <label>Confirm New Password:</label>
                          <div className="inputFront">
                            <Lock />
                          </div>

                          <input
                            name="confirmResetPassword"
                            type={isRevealPwd ? "text" : "password"}
                            onChange={onChange}
                            required
                          />
                          <HidePassword onClick={showPassword} />
                          <div className="errors">
                            {formErrors.confirmResetPassword}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="input-container">
                          <label>Email: </label>
                          <div className="inputFront">
                            <Message />
                          </div>
                          <input
                            type="text"
                            name="username"
                            onChange={onChange}
                            required
                          />
                          <div className="errors">{formErrors.username}</div>
                        </div>
                        <div className="input-container">
                          <label>Password:</label>
                          <div className="inputFront">
                            <Lock />
                          </div>

                          <input
                            name="password"
                            type={isRevealPwd ? "text" : "password"}
                            onChange={onChange}
                            required
                          />
                          <HidePassword onClick={showPassword} />
                          <div className="errors">{formErrors.password}</div>
                        </div>
                      </>
                    )}

                    <div className="message"></div>
                    <div className="forgot-container">
                      <button
                        type="button"
                        onClick={() => setIsForgotPassword(true)}
                      >
                        Forgot Password?
                      </button>
                    </div>
                    <div className="button-container">
                      <button
                        type="submit"
                        className="loginbtn"
                        onClick={signIn}
                        disabled={isSigningIn}
                      >
                        {isSigningIn ? "Logging in ..." : "Login"}
                      </button>
                    </div>
                    {/* <div className="bottom">
                  <p>
                    Dont have an account?{" "}
                    <a href="" className="signin__button" onClick={goToSignUp}>
                      Sign Up
                    </a>
                  </p>
                </div> */}
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{ backgroundColor: "green" }}
          message={snackbarMessage}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </Snackbar>
    </div>
  );
};

export default Login;
