import Login from "./component/Ft-Login/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAppSelector } from "./hooks";
import PackageVideo from "./views/packageVideo/packageVideo";
import AppLayout from "./layout/AppLayout";
import Leads from "./views/Leads/Leads";
import Clients from "./views/Clients/Clients";
import Library from "./views/Library/LibraryPage";
import LeadDetail from "./views/LeadDetail/LeadDetail";
import Package from "./views/Package/PackageList";
import ClientDetail from "./views/ClientDetail/ClientDetail";
import Dashboard from "./views/Dashboard/Dashboard";
import Questionnaire from "./views/Questionnaire/Questionnaire";
import Payment from "./views/Payment/payment";
import Failure from "./views/Payment/failure";
import Exercise from "./views/Library/Workout/Exercise/Exercise";
import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import FoodPlan from "./views/Library/Workout/FoodPlan/FoodPlan";
import DietPlan from "./views/Library/Modal/DietPlan";
import DietPlanDetail from "./views/Library/Modal/DietPlanDetail";
import awsExports from "./aws-exports";
import Toast from "./component/Toast/Toast";
import { Spinner } from "./component/Common/Spinner";
import SignUp from "./component/Ft-SignUp/SignUp";
import { RequireAuth } from "./RequiredAuth";
import LayoutManager from "./views/Library/Workout/LayoutManager/LayoutManager";
import LayoutList from "./views/Library/Workout/LayoutList/LayoutList";
import CreateLayoutList from "./views/Library/Workout/CreateLayoutList/CreateLayoutList";
import MealType from "./views/MealType";
import BodyParts from "./views/BodyParts";
import Settings from "./views/Settings";
import Recipe from "./views/Clients/Recipe/Recipe";
import WorkoutPlan from "./views/Library/Workout/WorkoutPlan/WorkoutPlan";
import WorkoutPlanDetail from "./views/Library/Workout/WorkoutPlanDetail/WorkoutPlanDetail";
import Messaging from "./component/Messaging/Messaging";
import Trainers from "./views/Trainers/Trainers";
import { useEffect, useState } from "react";
import VerifyEmail from "./views/VerifyEmail/VerifyEmail";
import Profile from "./views/Profile/Profile";
Amplify.configure(awsExports);

function MyRoutes() {
  const toasts = useAppSelector((state) => state.toasts);

  const [adminId, setAdminId] = useState(null);
  const fcs = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    console.log("currentUser", currentUser);
    setAdminId(currentUser?.attributes?.sub);
  };
  const env = process.env.REACT_APP_BUILD_ENV;
  useEffect(() => {
    fcs();
  }, []);
  return (
    <>
      <Spinner />
      <Toast
        toastList={toasts.toasts}
        position={"top-right"}
        autoDelete={true}
        dismissTime={3000}
      />
      <div className="App">
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            {/* <Route path="/SignUp" element={<SignUp />} /> */}
            <Route path="/package/:id" element={<PackageVideo />} />;
            <Route
              path="/"
              element={
                <RequireAuth>
                  <AppLayout />
                </RequireAuth>
              }
            >
              {env !== "Prod" ? (
                <Route path="/questionnaire" element={<Questionnaire />} />
              ) : null}
              <Route path="/" element={<Dashboard />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/trainers" element={<Trainers />} />
              {/* <Route path="/trainers/:id" element={<TrainerDetail />} /> */}
              <Route path="/leads" element={<Leads />} />
              <Route path="/chat" element={<Messaging id={adminId} />} />
              <Route path="/packages" element={<Package />} />
              <Route path="/library" element={<Library />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/settings/meal-type" element={<MealType />} />
              <Route path="/settings/body-parts" element={<BodyParts />} />
              <Route path="/settings/profile" element={<Profile />} />
              <Route path="/leads/:id" element={<LeadDetail />} />
              <Route path="/clients/:id/:tab1" element={<ClientDetail />} />
              <Route path="/library/foodplan" element={<FoodPlan />} />
              <Route
                path="/library/Foodplan/recipedetails"
                element={<Recipe />}
              />
              <Route
                path="/library/Foodplan/recipedetails/:id"
                element={<Recipe />}
              />
              <Route path="/library/dietplan" element={<DietPlan />} />
              <Route path="/library/workoutplan" element={<WorkoutPlan />} />
              <Route
                path="/library/layoutmanager"
                element={<LayoutManager />}
              />

              <Route
                path="library/layoutmanager/layoutdetails/:id"
                element={<LayoutList />}
              />
              <Route
                path="library/layoutmanager/createlayoutdetails"
                element={<CreateLayoutList />}
              />
              <Route
                path="/library/dietplan/dietplandetail/:id"
                element={<DietPlanDetail />}
              />
              <Route
                path="/library/workoutplan/workoutplandetail"
                element={<WorkoutPlanDetail />}
              />
              <Route
                path="/library/workoutplan/workoutplandetail/:id"
                element={<WorkoutPlanDetail />}
              />
              <Route path="/library/exercise" element={<Exercise />} />
            </Route>
            <Route path="/successpayment" element={<Payment />} />
            <Route path="/failurepayment" element={<Failure />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}
export default App;
