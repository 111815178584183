import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

export default function AutocompleteComp({ loadNames, handleChange }) {
  return (
    <div>
      <Autocomplete
        id="combo-box-demo"
        onInputChange={(e, newInputValue) => handleChange(newInputValue)}
        //value={value}
        options={loadNames}
        getOptionLabel={(option) => option}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Search" variant="outlined" />
        )}
      />
    </div>
  );
}
