import "./ButtonComponent.scss";
const ButtonComponent = props => {
  return (
    <button
      className="customButton"
      style={{ ...props?.style }}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};
export default ButtonComponent;
