import Image from "mui-image";
import { isValidUrl } from "../../Utils";
const ImageComponent = (imagePath, url) => {
  return (
    <Image
      src={isValidUrl(url) ? url : imagePath + url}
      fit="contain"
      showLoading
      errorIcon
    />
  );
};
export default ImageComponent;
