import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./sidebar.scss";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { ReactComponent as Dashboard } from "../../images/dashboard-active.svg";
import { ReactComponent as Clients } from "../../images/clients.svg";
import { ReactComponent as Leads } from "../../images/lead.svg";
import { ReactComponent as Chat } from "../../images/chat.svg";
import { ReactComponent as Package } from "../../images/package.svg";
import { ReactComponent as Library } from "../../images/deals.svg";
import { ReactComponent as Settings } from "../../images/settings.svg";
import { ReactComponent as ProfileEdit } from "../../images/profileedit.svg";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { generateAvatar } from "../../Utils";
import { isValidUrl } from "../../Utils";
import { updateAdministrator } from "../../graphql/mutations";
import { getAdministrator } from "../../graphql/queries";
import { useDispatch } from "react-redux";
import { ReactComponent as Check } from "../../images/check.svg";
import { sendNotification } from "../../app/features/notifications";
import { useAppSelector } from "../../hooks";
import { CometChat } from "@cometchat-pro/chat";
import { logout } from "../../app/features/Food/foodSlice";
import { fetchTenant } from "../../app/features/Tenant/tenantSlice";

type SidebarNavItem = {
  display: string;
  icon: ReactElement;
  to: string;
  section: string;
}[];
const sidebarNavItems: SidebarNavItem = [
  {
    display: "Dashboard",
    icon: (
      <i>
        <Dashboard />
      </i>
    ),
    to: "/",
    section: "dashboard",
  },
  {
    display: "Leads",
    icon: (
      <i>
        <Leads />
      </i>
    ),
    to: "/leads",
    section: "leads",
  },
  {
    display: "Trainers",
    icon: (
      <i>
        <Leads />
      </i>
    ),
    to: "/trainers",
    section: "trainers",
  },
  {
    display: "Clients",
    icon: (
      <i>
        <Clients />
      </i>
    ),
    to: "/clients",
    section: "clients",
  },

  {
    display: "Chat",
    icon: (
      <i>
        <Chat />
      </i>
    ),
    to: "/chat",
    section: "chat",
  },
  {
    display: "Packages",
    icon: (
      <i>
        <Package />
      </i>
    ),
    to: "/packages",
    section: "packages",
  },
  {
    display: "Library",
    icon: (
      <i>
        <Library />
      </i>
    ),
    to: "/library",
    section: "library",
  },
  {
    display: "Settings",
    icon: (
      <i>
        <Settings />
      </i>
    ),
    to: "/settings",
    section: "settings",
  },
];

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [stepHeight, setStepHeight] = useState<number>(0);
  const [currentNabBarItems, setCurrentNavBarItems] = useState<any>();
  const [user, setUser] = useState<any>();
  const [imageUrl, setImageUrl] = useState<any>("");
  const [imagePath, setImagePath] = useState<any>();
  const sidebarRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const indicatorRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const location = useLocation();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [load, setLoad] = useState<any>(false);
  const [administrator, setAdministrator] = useState<any>();
  const listTenant: any = useAppSelector((state) => state.tenant);
  const tenant = listTenant?.tenant?.currentTenant;
  const isTenantAdmin = tenant === "ADMIN";
  const dispatch = useDispatch();

  useEffect(() => {
    if (listTenant?.tenant?.status !== "succeeded") {
      dispatch(fetchTenant(null));
    }
    setTimeout(() => {
      const sidebarItem = sidebarRef.current.querySelector(
        ".sidebar__menu__item"
      );
      indicatorRef.current.style.height = `${sidebarItem?.clientHeight}px`;
      setStepHeight(sidebarItem?.clientHeight || 0);
    }, 50);
    Auth.currentAuthenticatedUser().then((user) => {
      setUser(user);
    });
  }, []);

  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const currentItems = isTenantAdmin
      ? sidebarNavItems
      : sidebarNavItems.filter((item) => item.section !== "trainers");
    setCurrentNavBarItems(currentItems);
    const activeItem = currentItems.findIndex(
      (item) => item.section === curPath
    );
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [listTenant, location]);
  // useEffect(() => {
  //   const curPath = window.location.pathname.split("/")[1];
  //   const currentItems = sidebarNavItems.filter(item => isTenantAdmin || item.section !== "tenants");

  //   const activeItem = sidebarNavItems.findIndex(
  //     (item) => item.section === curPath
  //   );
  //   setActiveIndex(activeItem !== -1 ? activeItem : 0);
  // }, [location]);

  const onSignOut = () => {
    Auth?.signOut();
    dispatch({ type: "LOGOUT" });
    CometChat.logout();
    localStorage.clear();
    dispatch(logout());

    // dispatch(reset());
    setTimeout(() => {
      navigate(`/login`);
    }, 500);
  };

  useEffect(() => {
    const getAdministratorData = async () => {
      const data: any = await API.graphql(
        graphqlOperation(getAdministrator, { id: user?.attributes?.sub })
      );
      setAdministrator(data);
      setImageUrl(data?.data?.getAdministrator?.imageUrl);
    };
    if (user && user?.attributes?.sub) {
      getAdministratorData();
    }
  }, [user, load]);

  const updateAdministratorData = async (imageUrl) => {
    await API.graphql(
      graphqlOperation(updateAdministrator, {
        input: {
          name: user?.attributes?.name,
          imageUrl: imageUrl,
          email: user?.attributes?.email,
          id: user?.attributes?.sub,
          _version: administrator?.data?.getAdministrator?._version,
        },
      })
    );
    setLoad(!load);
  };

  async function handleImageChange(e: any) {
    const file = e.target.files[0];
    try {
      await trackPromise(
        Storage.put(file.name, file, {
          contentType: "image/svg+xml/jpeg/png", // contentType is optional
        }).then(async (result) => {
          setImageUrl(result.key);
          updateAdministratorData(result.key);
        })
      );
      dispatch(
        sendNotification({
          title: "Success",
          description: "Profile Image updated successfully",
          backgroundColor: "#5cb85c",
          icon: Check,
        })
      );
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  useEffect(() => {
    (async () => {
      const url = await Storage.get("");
      return setImagePath(url.split("?")[0]);
    })();
  }, []);

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <i>
          <Logo />
        </i>
      </div>
      <div className="sidebar__profile">
        <div className="avatar">
          {user?.attributes?.name && (
            <>
              <div
                className="imageBox"
                onClick={() => {
                  handleAvatarClick();
                }}
              >
                <div>
                  <img
                    alt="imgae"
                    src={
                      imageUrl
                        ? isValidUrl(imageUrl)
                          ? imageUrl
                          : imagePath + imageUrl
                        : generateAvatar(
                            user?.attributes?.name[0],
                            "white",
                            "rgb(16, 156, 241)"
                          )
                    }
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      position: "relative",
                    }}
                  />
                </div>
                <div className="hoverImg">
                  <ProfileEdit />
                </div>
              </div>
              <input
                type="file"
                accept="image/svg+xml/jpeg/png"
                onChange={handleImageChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </>
          )}
        </div>

        <div className="profile">
          <div className="username">{user?.attributes?.name}</div>
          <div className="email">
            {user?.attributes?.email}
            <span className="tooltiptext">{user?.attributes?.email}</span>
          </div>
          <div className="signOut" onClick={onSignOut}>
            Logout
          </div>
        </div>
      </div>
      <div ref={sidebarRef} className="sidebar__menu">
        <div
          ref={indicatorRef}
          className="sidebar__menu__indicator"
          style={{
            transform: `translateX(-50%) translateY(${
              activeIndex * stepHeight
            }px)`,
          }}
        ></div>
        {currentNabBarItems?.map(
          (item, index) =>
            (isTenantAdmin || item.section !== "tenants") && (
              <Link to={item.to} key={index}>
                <div
                  className={`sidebar__menu__item ${
                    activeIndex === index ? "active" : ""
                  }`}
                >
                  <div className="sidebar__menu__item__icon icon">
                    {item.icon}
                  </div>
                  <div className="sidebar__menu__item__text">
                    {item.display}
                  </div>
                </div>
              </Link>
            )
        )}
      </div>
    </div>
  );
};
export default Sidebar;
