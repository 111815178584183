import { useEffect, useState } from "react";
import "./ReusableTable.scss";
import "../../Clients/Clients.scss";

let PageSize = 10;

const ReusableTable = (props) => {
  const Columns = [{ Name: 0 }, { Message: 0 }];

  const columnMap = [];
  columnMap["name"] = 0;
  columnMap["message"] = 1;

  const [tableState, setTableState] = useState({
    columns: Columns,
    currentPage: 1,
    data: [],
  });

  useEffect(() => {
    const firstPage = (tableState.currentPage - 1) * PageSize;
    const lastPage = firstPage + PageSize;
    setTableState({
      ...tableState,
      ...{
        firstPageIndex: firstPage,
        lastPageIndex: lastPage,
        data: props.data,
      },
    });
  }, [props.data]);

  const Row = ({ user, message, id }, index) => (
    <tr key={index} className="tableRow">
      <td>
        <span className="tableColName">{user?.name?.slice(0, 5)}</span>
      </td>
      <td>
        <span className="tableDataFormat"> {message ?? "NA"}</span>
      </td>
    </tr>
  );

  const rows = props.data?.map((rowData) => <Row {...rowData} />);

  return (
    <div className="responsivetable">
      <table>
        <thead>
          <tr>
            <th className="tablehead">Name</th>
            <th>Message</th>
          </tr>
        </thead>
        {rows?.length === 0 ? (
          <tbody className="tablebody">
            <tr>
              <td className="nodata" colSpan={5}>
                No data available
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>{rows}</tbody>
        )}
      </table>
    </div>
  );
};

export default ReusableTable;
