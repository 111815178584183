import "./Settings.scss";

import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();

  const goToFoodPlan = () => {
    navigate(`/settings/meal-type`);
  };
  const goToBodyPartSettings = () => {
    navigate(`/settings/body-parts`);
  };

  const goToProfileSettings = () => {
    navigate(`/settings/profile`);
  };

  return (
    <>
      <div className="clients">
        <div className="clients__header">
          <h1>Settings</h1>
        </div>
      </div>
      <div className="Library">
        <div className="Library__body">
          <button className="Library__body__text" onClick={goToFoodPlan}>
            Meal type settings
          </button>
        </div>
        <div className="Library__body">
          <button
            className="Library__body__text"
            onClick={goToBodyPartSettings}
          >
            Body part settings
          </button>
        </div>
        <div className="Library__body">
          <button className="Library__body__text" onClick={goToProfileSettings}>
            Profile settings
          </button>
        </div>
      </div>
    </>
  );
};

export default Settings;
