import { Select, MenuItem } from "@material-ui/core";
import "./SelectComponent.scss";

const SelectComponent = (props) => {
  return (
    <div
      style={{ width: props.width, ...props.style }}
      className="selectComponent"
    >
      <form style={{ border: "1px solid #c2cfe0", borderRadius: "5px" }}>
        <Select
          labelId={`${props.label}-label`}
          id={props.label}
          value={props.value}
          onChange={props.handleChange}
          fullWidth
          name={props?.name}
          style={{ width: "100%" }}
          disabled={props.disabled}
        >
          {props.data.map((item) => {
            return <MenuItem value={item}>{item}</MenuItem>;
          })}
        </Select>
      </form>
    </div>
  );
};
export default SelectComponent;
