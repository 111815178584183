import { useEffect, useRef, useState } from "react";

const VideoPlayerTag = (props) => {
  const videoElem = useRef<any>();
  const [videoSrc, setVideoSrc] = useState();
  const drawThumbnail = async () => {
    const canvas = document.createElement("canvas");
    canvas.width = videoElem?.current?.videoWidth;
    canvas.height = videoElem?.current?.videoHeight;

    canvas
      ?.getContext("2d")
      ?.drawImage(
        videoElem?.current,
        0,
        0,
        videoElem?.current.videoWidth,
        videoElem.current.videoHeight
      );

    canvas.toBlob((blob) => {
      // const file = new File(
      //   [blob],
      //   name.substring(0, name.length - 4) + ".png"
      // );
      blob && props.getThumbnail(blob);
    });
  };

  useEffect(() => {
    setVideoSrc(props.src);
  }, [props.src]);
  useEffect(() => {
    props.getThumbnail &&
      setTimeout(() => {
        drawThumbnail();
      }, 1000);
  }, [videoSrc]);
  return (
    <div>
      <video
        className="uploadedVideos"
        // width="100%"
        // height="70%"
        style={{
          width: "100%",
          height: 225,
        }}
        ref={videoElem}
        controls
        src={props.src}
      />
    </div>
  );
};
export default VideoPlayerTag;
