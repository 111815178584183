import "../MealInfo/MealInfo.scss";
import { ReactComponent as Graph } from "../../../images/GraphIcon.svg";
import { ReactComponent as More } from "../../../images/MoreButton.svg";

import { useCallback, useEffect, useState } from "react";
import "./dietInfo.scss";
import QuickInfo from "../../../component/QuickInfo/QuickInfo";
const DietInfo = (props) => {
  const [data, setData] = useState<any>(0);
  const sumOfCaloriesForTheMeal = useCallback(
    (data) => {
      const sum = {
        calculatedGrams: 0,
        calories: 0,
        protien: 0,
        carbs: 0,
        fats: 0,
      };
      data?.reduce((acc, item) => {
        acc.calculatedGrams += item?.calculatedGrams || 0;
        acc.calories += item?.calories || 0;
        acc.protien += item?.protien || 0;
        acc.carbs += item?.carbs || 0;
        acc.fats += item?.fats || 0;
        return acc;
      }, sum);
      const formattedSum = Object?.entries(sum)?.reduce((acc, [key, value]) => {
        acc[key] = Math.round(value);
        return acc;
      }, {});

      return formattedSum;
    },
    [props.data]
  );

  useEffect(() => {
    const sum = sumOfCaloriesForTheMeal(props.data);
    setData(sum);
  }, [props.data]);
  return (
    <div className="mealInfo">
      <div className="mealInfoHeading">
        <div className="mealTypeWithNutients">
          <Graph />
          <div className="mealType">
            <QuickInfo
              name={props.label}
              calories={data.calories}
              carbs={data.carbs}
              protein={data.protien}
              fats={data.fats}
              description={""}
            >
              <div className="dietinfohover">
                <h2>{props.label}</h2>

                <label className="dietinfohover">{data.calories} kcal</label>
              </div>
            </QuickInfo>
          </div>
          <More />
        </div>
      </div>
    </div>
  );
};
export default DietInfo;
