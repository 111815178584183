import Tooltip from "@material-ui/core/Tooltip";
import "./InputComponent.scss";
const InputComponent = (props) => {
  return (
    <Tooltip title="" open={props.error}>
      <input
        type="input"
        disabled={props.disabled ? props.disabled : false}
        style={props.style}
        className={`customInput ${props.className}`}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
      />
    </Tooltip>
  );
};
export default InputComponent;
