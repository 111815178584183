import React, { useState, useCallback, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import WorkoutItem from "../../Library/Workout/WorkoutPlanDetail/WorkoutItem";
import { API, graphqlOperation } from "aws-amplify";
import { updateUserCalendarWorkout } from "../../../graphql/mutations";
import { trackPromise } from "react-promise-tracker";

type WorkoutListProp = {
  workoutsInASessions: Array<any>;
  imagePath: string;
  onDelete: (id: string, version: number) => void;
  workoutUnitChange: (info: any) => void;
  session: string;
  sessionOrder: string;
  workoutPlanDates: { idDate: string; _version: number };
  getAllWorkouts: () => void;
};

const WorkoutList: React.FC<WorkoutListProp> = ({
  workoutsInASessions,
  imagePath,
  onDelete,
  workoutUnitChange,
  session,
  sessionOrder,
  workoutPlanDates,
  getAllWorkouts,
}) => {
  const filteredWorkouts = workoutsInASessions.filter(
    (workout) => !workout?._deleted
  );

  const [workouts, setWorkouts] = useState(filteredWorkouts);
  const [newSessionOrder, setNewSessionOrder] = useState<{}>(sessionOrder);

  const updateSessionData = (data, keyToUpdate, newValue) => {
    if (data.hasOwnProperty(keyToUpdate)) {
      data[keyToUpdate] = newValue;
    } else {
      console.log(`Key "${keyToUpdate}" not found in the data object.`);
    }
    return data;
  };

  const updateWorkoutOrder = async (newOrder) => {
    try {
      await API.graphql(
        graphqlOperation(updateUserCalendarWorkout, {
          input: {
            idDate: workoutPlanDates.idDate,
            _version: workoutPlanDates._version,
            sessionOrder: JSON.stringify(newOrder),
          },
        })
      );
      trackPromise(
        new Promise<void>((resolve, reject) => {
          try {
            getAllWorkouts();
            resolve();
          } catch (error) {
            reject(error);
          }
        })
      );
    } catch (error) {
      console.error("Error updating workout order:", error);
    }
  };

  const moveWorkoutItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = workouts[dragIndex];
      const hoverItem = workouts[hoverIndex];

      // Swap places of dragItem and hoverItem in the workouts array
      setWorkouts((workouts) => {
        const updatedWorkouts = [...workouts];
        updatedWorkouts[dragIndex] = hoverItem;
        updatedWorkouts[hoverIndex] = dragItem;

        const newCurrentSessionOrder = updatedWorkouts.map(
          (item) => item.workoutPlanDetailId
        );
        const updatedData = updateSessionData(
          newSessionOrder,
          session,
          newCurrentSessionOrder
        );
        setNewSessionOrder(updatedData);
        updateWorkoutOrder(updatedData);

        return updatedWorkouts;
      });
    },
    [workouts, newSessionOrder, session]
  );

  useEffect(() => {
    if (filteredWorkouts) {
      setWorkouts(filteredWorkouts);
      const newCurrentSessionOrder = filteredWorkouts.map(
        (item) => item.workoutPlanDetailId
      );
      const updatedData = updateSessionData(
        sessionOrder,
        session,
        newCurrentSessionOrder
      );
      setNewSessionOrder(updatedData);
    }
  }, [sessionOrder, session]);

  return (
    <>
      {workouts?.map((workout, index) => (
        <WorkoutItem
          index={index}
          key={workout.id}
          name={workout?.exercise?.name || ""}
          imageData={imagePath + workout?.exercise?.imageUrl}
          data={workout}
          onDelete={() => onDelete(workout.id, workout._version)}
          onChange={workoutUnitChange}
          type={"calendar"}
          moveWorkoutItem={moveWorkoutItem}
        />
      ))}
    </>
  );
};

export default WorkoutList;
