import "../Clients/MealInfo/MealInfo.scss";

import { ReactComponent as Graph } from "../../images/GraphIcon.svg";
import { ReactComponent as More } from "../../images/MoreButton.svg";
import { ReactComponent as PlusCircle } from "../../images/PlusCircle.svg";
const WorkoutInfo = (props) => {
  return (
    <div className="mealInfo">
      <div className="mealInfoHeading">
        <div className="mealTypeWithNutients">
          <Graph />
          <div className="mealType">
            <h2>{props.label}</h2>
          </div>
          <More />
        </div>
      </div>

      <div className="mealInfoAddButton">
        <button
          className="addSymbol"
          onClick={() => {
            props.setOpenNewWorkouts(true);
          }}
        >
          <PlusCircle />
        </button>
      </div>
      {/* <Notes text={props.dayNotes} onLeave={async (e) => {}} /> */}
    </div>
  );
};
export default WorkoutInfo;
