/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateNote = /* GraphQL */ `subscription OnCreateNote($filter: ModelSubscriptionNoteFilterInput) {
  onCreateNote(filter: $filter) {
    id
    userID
    tenant
    note
    created_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNoteSubscriptionVariables,
  APITypes.OnCreateNoteSubscription
>;
export const onUpdateNote = /* GraphQL */ `subscription OnUpdateNote($filter: ModelSubscriptionNoteFilterInput) {
  onUpdateNote(filter: $filter) {
    id
    userID
    tenant
    note
    created_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNoteSubscriptionVariables,
  APITypes.OnUpdateNoteSubscription
>;
export const onDeleteNote = /* GraphQL */ `subscription OnDeleteNote($filter: ModelSubscriptionNoteFilterInput) {
  onDeleteNote(filter: $filter) {
    id
    userID
    tenant
    note
    created_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNoteSubscriptionVariables,
  APITypes.OnDeleteNoteSubscription
>;
export const onCreateReminder = /* GraphQL */ `subscription OnCreateReminder($filter: ModelSubscriptionReminderFilterInput) {
  onCreateReminder(filter: $filter) {
    id
    userID
    tenant
    message
    finishBy
    isDone
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReminderSubscriptionVariables,
  APITypes.OnCreateReminderSubscription
>;
export const onUpdateReminder = /* GraphQL */ `subscription OnUpdateReminder($filter: ModelSubscriptionReminderFilterInput) {
  onUpdateReminder(filter: $filter) {
    id
    userID
    tenant
    message
    finishBy
    isDone
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReminderSubscriptionVariables,
  APITypes.OnUpdateReminderSubscription
>;
export const onDeleteReminder = /* GraphQL */ `subscription OnDeleteReminder($filter: ModelSubscriptionReminderFilterInput) {
  onDeleteReminder(filter: $filter) {
    id
    userID
    tenant
    message
    finishBy
    isDone
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReminderSubscriptionVariables,
  APITypes.OnDeleteReminderSubscription
>;
export const onCreateMembership = /* GraphQL */ `subscription OnCreateMembership(
  $filter: ModelSubscriptionMembershipFilterInput
) {
  onCreateMembership(filter: $filter) {
    id
    type
    status
    startTime
    endTime
    tenant
    deliveryAddress
    userID
    order {
      id
      userID
      tenant
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderProductId
      orderPaymentId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    membershipOrderId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMembershipSubscriptionVariables,
  APITypes.OnCreateMembershipSubscription
>;
export const onUpdateMembership = /* GraphQL */ `subscription OnUpdateMembership(
  $filter: ModelSubscriptionMembershipFilterInput
) {
  onUpdateMembership(filter: $filter) {
    id
    type
    status
    startTime
    endTime
    tenant
    deliveryAddress
    userID
    order {
      id
      userID
      tenant
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderProductId
      orderPaymentId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    membershipOrderId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMembershipSubscriptionVariables,
  APITypes.OnUpdateMembershipSubscription
>;
export const onDeleteMembership = /* GraphQL */ `subscription OnDeleteMembership(
  $filter: ModelSubscriptionMembershipFilterInput
) {
  onDeleteMembership(filter: $filter) {
    id
    type
    status
    startTime
    endTime
    tenant
    deliveryAddress
    userID
    order {
      id
      userID
      tenant
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderProductId
      orderPaymentId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    membershipOrderId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMembershipSubscriptionVariables,
  APITypes.OnDeleteMembershipSubscription
>;
export const onCreatePayment = /* GraphQL */ `subscription OnCreatePayment($filter: ModelSubscriptionPaymentFilterInput) {
  onCreatePayment(filter: $filter) {
    id
    paymentMode
    tenant
    totalAmount
    discountAmount
    shippingAmount
    taxAmount
    transactionId
    status
    paymentType
    stripeCurrency
    stripePaymentIntent
    stripePaymentStatus
    stripeStatus
    userId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaymentSubscriptionVariables,
  APITypes.OnCreatePaymentSubscription
>;
export const onUpdatePayment = /* GraphQL */ `subscription OnUpdatePayment($filter: ModelSubscriptionPaymentFilterInput) {
  onUpdatePayment(filter: $filter) {
    id
    paymentMode
    tenant
    totalAmount
    discountAmount
    shippingAmount
    taxAmount
    transactionId
    status
    paymentType
    stripeCurrency
    stripePaymentIntent
    stripePaymentStatus
    stripeStatus
    userId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaymentSubscriptionVariables,
  APITypes.OnUpdatePaymentSubscription
>;
export const onDeletePayment = /* GraphQL */ `subscription OnDeletePayment($filter: ModelSubscriptionPaymentFilterInput) {
  onDeletePayment(filter: $filter) {
    id
    paymentMode
    tenant
    totalAmount
    discountAmount
    shippingAmount
    taxAmount
    transactionId
    status
    paymentType
    stripeCurrency
    stripePaymentIntent
    stripePaymentStatus
    stripeStatus
    userId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaymentSubscriptionVariables,
  APITypes.OnDeletePaymentSubscription
>;
export const onCreateProduct = /* GraphQL */ `subscription OnCreateProduct($filter: ModelSubscriptionProductFilterInput) {
  onCreateProduct(filter: $filter) {
    id
    name
    imageUrl
    tenant
    description
    type
    price
    status
    stripePriceId
    durationInMonths
    includes
    video
    thumbnailUrl
    features
    productType
    trainerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductSubscriptionVariables,
  APITypes.OnCreateProductSubscription
>;
export const onUpdateProduct = /* GraphQL */ `subscription OnUpdateProduct($filter: ModelSubscriptionProductFilterInput) {
  onUpdateProduct(filter: $filter) {
    id
    name
    imageUrl
    tenant
    description
    type
    price
    status
    stripePriceId
    durationInMonths
    includes
    video
    thumbnailUrl
    features
    productType
    trainerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductSubscriptionVariables,
  APITypes.OnUpdateProductSubscription
>;
export const onDeleteProduct = /* GraphQL */ `subscription OnDeleteProduct($filter: ModelSubscriptionProductFilterInput) {
  onDeleteProduct(filter: $filter) {
    id
    name
    imageUrl
    tenant
    description
    type
    price
    status
    stripePriceId
    durationInMonths
    includes
    video
    thumbnailUrl
    features
    productType
    trainerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductSubscriptionVariables,
  APITypes.OnDeleteProductSubscription
>;
export const onCreateOrder = /* GraphQL */ `subscription OnCreateOrder($filter: ModelSubscriptionOrderFilterInput) {
  onCreateOrder(filter: $filter) {
    id
    userID
    tenant
    product {
      id
      name
      imageUrl
      tenant
      description
      type
      price
      status
      stripePriceId
      durationInMonths
      includes
      video
      thumbnailUrl
      features
      productType
      trainerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    payment {
      id
      paymentMode
      tenant
      totalAmount
      discountAmount
      shippingAmount
      taxAmount
      transactionId
      status
      paymentType
      stripeCurrency
      stripePaymentIntent
      stripePaymentStatus
      stripeStatus
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderProductId
    orderPaymentId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderSubscriptionVariables,
  APITypes.OnCreateOrderSubscription
>;
export const onUpdateOrder = /* GraphQL */ `subscription OnUpdateOrder($filter: ModelSubscriptionOrderFilterInput) {
  onUpdateOrder(filter: $filter) {
    id
    userID
    tenant
    product {
      id
      name
      imageUrl
      tenant
      description
      type
      price
      status
      stripePriceId
      durationInMonths
      includes
      video
      thumbnailUrl
      features
      productType
      trainerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    payment {
      id
      paymentMode
      tenant
      totalAmount
      discountAmount
      shippingAmount
      taxAmount
      transactionId
      status
      paymentType
      stripeCurrency
      stripePaymentIntent
      stripePaymentStatus
      stripeStatus
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderProductId
    orderPaymentId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderSubscriptionVariables,
  APITypes.OnUpdateOrderSubscription
>;
export const onDeleteOrder = /* GraphQL */ `subscription OnDeleteOrder($filter: ModelSubscriptionOrderFilterInput) {
  onDeleteOrder(filter: $filter) {
    id
    userID
    tenant
    product {
      id
      name
      imageUrl
      tenant
      description
      type
      price
      status
      stripePriceId
      durationInMonths
      includes
      video
      thumbnailUrl
      features
      productType
      trainerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    payment {
      id
      paymentMode
      tenant
      totalAmount
      discountAmount
      shippingAmount
      taxAmount
      transactionId
      status
      paymentType
      stripeCurrency
      stripePaymentIntent
      stripePaymentStatus
      stripeStatus
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderProductId
    orderPaymentId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderSubscriptionVariables,
  APITypes.OnDeleteOrderSubscription
>;
export const onCreateUser = /* GraphQL */ `subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
  onCreateUser(filter: $filter) {
    id
    email
    name
    tenant
    imageUrl
    gender
    status {
      userStatus
      updatedAt
      __typename
    }
    activeStatus
    ageRange
    age
    height
    weightStart
    weightGoal
    motivation
    goal
    goalType
    achievement
    workoutCountPerWeek
    weightChangePerWeek
    workoutLocation
    workoutExperience
    workoutInfo
    mealCountPerDay
    phone
    location
    country
    timezone
    notes {
      nextToken
      startedAt
      __typename
    }
    Orders {
      nextToken
      startedAt
      __typename
    }
    membership {
      nextToken
      startedAt
      __typename
    }
    reminders {
      nextToken
      startedAt
      __typename
    }
    lastLogin
    emailValidated
    dailyProgress {
      date
      weight
      imageUrls
      __typename
    }
    imageProgress {
      date
      imageUrls
      __typename
    }
    injuries
    dietPreference
    allergies
    excludeFood
    dailyKcal
    proteinInPercentage
    carbsInPercentage
    fatInPercentage
    recentActivity {
      id
      mealsCalendar
      tenant
      workoutCalendar
      mealPlannerID
      workoutPlannerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userRecentActivityId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
  onUpdateUser(filter: $filter) {
    id
    email
    name
    tenant
    imageUrl
    gender
    status {
      userStatus
      updatedAt
      __typename
    }
    activeStatus
    ageRange
    age
    height
    weightStart
    weightGoal
    motivation
    goal
    goalType
    achievement
    workoutCountPerWeek
    weightChangePerWeek
    workoutLocation
    workoutExperience
    workoutInfo
    mealCountPerDay
    phone
    location
    country
    timezone
    notes {
      nextToken
      startedAt
      __typename
    }
    Orders {
      nextToken
      startedAt
      __typename
    }
    membership {
      nextToken
      startedAt
      __typename
    }
    reminders {
      nextToken
      startedAt
      __typename
    }
    lastLogin
    emailValidated
    dailyProgress {
      date
      weight
      imageUrls
      __typename
    }
    imageProgress {
      date
      imageUrls
      __typename
    }
    injuries
    dietPreference
    allergies
    excludeFood
    dailyKcal
    proteinInPercentage
    carbsInPercentage
    fatInPercentage
    recentActivity {
      id
      mealsCalendar
      tenant
      workoutCalendar
      mealPlannerID
      workoutPlannerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userRecentActivityId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
  onDeleteUser(filter: $filter) {
    id
    email
    name
    tenant
    imageUrl
    gender
    status {
      userStatus
      updatedAt
      __typename
    }
    activeStatus
    ageRange
    age
    height
    weightStart
    weightGoal
    motivation
    goal
    goalType
    achievement
    workoutCountPerWeek
    weightChangePerWeek
    workoutLocation
    workoutExperience
    workoutInfo
    mealCountPerDay
    phone
    location
    country
    timezone
    notes {
      nextToken
      startedAt
      __typename
    }
    Orders {
      nextToken
      startedAt
      __typename
    }
    membership {
      nextToken
      startedAt
      __typename
    }
    reminders {
      nextToken
      startedAt
      __typename
    }
    lastLogin
    emailValidated
    dailyProgress {
      date
      weight
      imageUrls
      __typename
    }
    imageProgress {
      date
      imageUrls
      __typename
    }
    injuries
    dietPreference
    allergies
    excludeFood
    dailyKcal
    proteinInPercentage
    carbsInPercentage
    fatInPercentage
    recentActivity {
      id
      mealsCalendar
      tenant
      workoutCalendar
      mealPlannerID
      workoutPlannerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userRecentActivityId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateRecentActivity = /* GraphQL */ `subscription OnCreateRecentActivity(
  $filter: ModelSubscriptionRecentActivityFilterInput
) {
  onCreateRecentActivity(filter: $filter) {
    id
    mealsCalendar
    tenant
    workoutCalendar
    mealPlannerID
    workoutPlannerID
    workoutPlannerData {
      id
      name
      numOfdays
      tenant
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerData {
      id
      planTitle
      mealLayoutID
      tenant
      numOfdays
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRecentActivitySubscriptionVariables,
  APITypes.OnCreateRecentActivitySubscription
>;
export const onUpdateRecentActivity = /* GraphQL */ `subscription OnUpdateRecentActivity(
  $filter: ModelSubscriptionRecentActivityFilterInput
) {
  onUpdateRecentActivity(filter: $filter) {
    id
    mealsCalendar
    tenant
    workoutCalendar
    mealPlannerID
    workoutPlannerID
    workoutPlannerData {
      id
      name
      numOfdays
      tenant
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerData {
      id
      planTitle
      mealLayoutID
      tenant
      numOfdays
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRecentActivitySubscriptionVariables,
  APITypes.OnUpdateRecentActivitySubscription
>;
export const onDeleteRecentActivity = /* GraphQL */ `subscription OnDeleteRecentActivity(
  $filter: ModelSubscriptionRecentActivityFilterInput
) {
  onDeleteRecentActivity(filter: $filter) {
    id
    mealsCalendar
    tenant
    workoutCalendar
    mealPlannerID
    workoutPlannerID
    workoutPlannerData {
      id
      name
      numOfdays
      tenant
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mealPlannerData {
      id
      planTitle
      mealLayoutID
      tenant
      numOfdays
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRecentActivitySubscriptionVariables,
  APITypes.OnDeleteRecentActivitySubscription
>;
export const onCreateTrainer = /* GraphQL */ `subscription OnCreateTrainer($filter: ModelSubscriptionTrainerFilterInput) {
  onCreateTrainer(filter: $filter) {
    id
    transactionId
    totalAmount
    currency
    customerId
    name
    tenant
    email
    status
    phone
    stripeEndpointSecret
    stripeSecretKey
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTrainerSubscriptionVariables,
  APITypes.OnCreateTrainerSubscription
>;
export const onUpdateTrainer = /* GraphQL */ `subscription OnUpdateTrainer($filter: ModelSubscriptionTrainerFilterInput) {
  onUpdateTrainer(filter: $filter) {
    id
    transactionId
    totalAmount
    currency
    customerId
    name
    tenant
    email
    status
    phone
    stripeEndpointSecret
    stripeSecretKey
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTrainerSubscriptionVariables,
  APITypes.OnUpdateTrainerSubscription
>;
export const onDeleteTrainer = /* GraphQL */ `subscription OnDeleteTrainer($filter: ModelSubscriptionTrainerFilterInput) {
  onDeleteTrainer(filter: $filter) {
    id
    transactionId
    totalAmount
    currency
    customerId
    name
    tenant
    email
    status
    phone
    stripeEndpointSecret
    stripeSecretKey
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTrainerSubscriptionVariables,
  APITypes.OnDeleteTrainerSubscription
>;
export const onCreateTrainerTransaction = /* GraphQL */ `subscription OnCreateTrainerTransaction(
  $filter: ModelSubscriptionTrainerTransactionFilterInput
) {
  onCreateTrainerTransaction(filter: $filter) {
    id
    transactionId
    totalAmount
    currency
    name
    tenant
    email
    status
    phone
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTrainerTransactionSubscriptionVariables,
  APITypes.OnCreateTrainerTransactionSubscription
>;
export const onUpdateTrainerTransaction = /* GraphQL */ `subscription OnUpdateTrainerTransaction(
  $filter: ModelSubscriptionTrainerTransactionFilterInput
) {
  onUpdateTrainerTransaction(filter: $filter) {
    id
    transactionId
    totalAmount
    currency
    name
    tenant
    email
    status
    phone
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTrainerTransactionSubscriptionVariables,
  APITypes.OnUpdateTrainerTransactionSubscription
>;
export const onDeleteTrainerTransaction = /* GraphQL */ `subscription OnDeleteTrainerTransaction(
  $filter: ModelSubscriptionTrainerTransactionFilterInput
) {
  onDeleteTrainerTransaction(filter: $filter) {
    id
    transactionId
    totalAmount
    currency
    name
    tenant
    email
    status
    phone
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTrainerTransactionSubscriptionVariables,
  APITypes.OnDeleteTrainerTransactionSubscription
>;
export const onCreateLead = /* GraphQL */ `subscription OnCreateLead($filter: ModelSubscriptionLeadFilterInput) {
  onCreateLead(filter: $filter) {
    id
    name
    tenant
    email
    phone
    ageRange
    country
    location
    status {
      leadStatus
      updatedAt
      __typename
    }
    state
    gender
    goal
    achievement
    motivation
    notes
    activeStatus
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateLeadSubscriptionVariables,
  APITypes.OnCreateLeadSubscription
>;
export const onUpdateLead = /* GraphQL */ `subscription OnUpdateLead($filter: ModelSubscriptionLeadFilterInput) {
  onUpdateLead(filter: $filter) {
    id
    name
    tenant
    email
    phone
    ageRange
    country
    location
    status {
      leadStatus
      updatedAt
      __typename
    }
    state
    gender
    goal
    achievement
    motivation
    notes
    activeStatus
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateLeadSubscriptionVariables,
  APITypes.OnUpdateLeadSubscription
>;
export const onDeleteLead = /* GraphQL */ `subscription OnDeleteLead($filter: ModelSubscriptionLeadFilterInput) {
  onDeleteLead(filter: $filter) {
    id
    name
    tenant
    email
    phone
    ageRange
    country
    location
    status {
      leadStatus
      updatedAt
      __typename
    }
    state
    gender
    goal
    achievement
    motivation
    notes
    activeStatus
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteLeadSubscriptionVariables,
  APITypes.OnDeleteLeadSubscription
>;
export const onCreateAdministrator = /* GraphQL */ `subscription OnCreateAdministrator(
  $filter: ModelSubscriptionAdministratorFilterInput
) {
  onCreateAdministrator(filter: $filter) {
    id
    email
    tenant
    name
    emailValidated
    imageUrl
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAdministratorSubscriptionVariables,
  APITypes.OnCreateAdministratorSubscription
>;
export const onUpdateAdministrator = /* GraphQL */ `subscription OnUpdateAdministrator(
  $filter: ModelSubscriptionAdministratorFilterInput
) {
  onUpdateAdministrator(filter: $filter) {
    id
    email
    tenant
    name
    emailValidated
    imageUrl
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAdministratorSubscriptionVariables,
  APITypes.OnUpdateAdministratorSubscription
>;
export const onDeleteAdministrator = /* GraphQL */ `subscription OnDeleteAdministrator(
  $filter: ModelSubscriptionAdministratorFilterInput
) {
  onDeleteAdministrator(filter: $filter) {
    id
    email
    tenant
    name
    emailValidated
    imageUrl
    favorites {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAdministratorSubscriptionVariables,
  APITypes.OnDeleteAdministratorSubscription
>;
export const onCreateMealType = /* GraphQL */ `subscription OnCreateMealType($filter: ModelSubscriptionMealTypeFilterInput) {
  onCreateMealType(filter: $filter) {
    name
    description
    tenant
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMealTypeSubscriptionVariables,
  APITypes.OnCreateMealTypeSubscription
>;
export const onUpdateMealType = /* GraphQL */ `subscription OnUpdateMealType($filter: ModelSubscriptionMealTypeFilterInput) {
  onUpdateMealType(filter: $filter) {
    name
    description
    tenant
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMealTypeSubscriptionVariables,
  APITypes.OnUpdateMealTypeSubscription
>;
export const onDeleteMealType = /* GraphQL */ `subscription OnDeleteMealType($filter: ModelSubscriptionMealTypeFilterInput) {
  onDeleteMealType(filter: $filter) {
    name
    description
    tenant
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMealTypeSubscriptionVariables,
  APITypes.OnDeleteMealTypeSubscription
>;
export const onCreateMailStore = /* GraphQL */ `subscription OnCreateMailStore($filter: ModelSubscriptionMailStoreFilterInput) {
  onCreateMailStore(filter: $filter) {
    id
    from
    to
    tenant
    userID
    administratorID
    subject
    message
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMailStoreSubscriptionVariables,
  APITypes.OnCreateMailStoreSubscription
>;
export const onUpdateMailStore = /* GraphQL */ `subscription OnUpdateMailStore($filter: ModelSubscriptionMailStoreFilterInput) {
  onUpdateMailStore(filter: $filter) {
    id
    from
    to
    tenant
    userID
    administratorID
    subject
    message
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMailStoreSubscriptionVariables,
  APITypes.OnUpdateMailStoreSubscription
>;
export const onDeleteMailStore = /* GraphQL */ `subscription OnDeleteMailStore($filter: ModelSubscriptionMailStoreFilterInput) {
  onDeleteMailStore(filter: $filter) {
    id
    from
    to
    tenant
    userID
    administratorID
    subject
    message
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMailStoreSubscriptionVariables,
  APITypes.OnDeleteMailStoreSubscription
>;
export const onCreateBodyPart = /* GraphQL */ `subscription OnCreateBodyPart($filter: ModelSubscriptionBodyPartFilterInput) {
  onCreateBodyPart(filter: $filter) {
    id
    name
    tenant
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBodyPartSubscriptionVariables,
  APITypes.OnCreateBodyPartSubscription
>;
export const onUpdateBodyPart = /* GraphQL */ `subscription OnUpdateBodyPart($filter: ModelSubscriptionBodyPartFilterInput) {
  onUpdateBodyPart(filter: $filter) {
    id
    name
    tenant
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBodyPartSubscriptionVariables,
  APITypes.OnUpdateBodyPartSubscription
>;
export const onDeleteBodyPart = /* GraphQL */ `subscription OnDeleteBodyPart($filter: ModelSubscriptionBodyPartFilterInput) {
  onDeleteBodyPart(filter: $filter) {
    id
    name
    tenant
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBodyPartSubscriptionVariables,
  APITypes.OnDeleteBodyPartSubscription
>;
