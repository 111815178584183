import "./ClientWorkoutDetail.scss";
import { useState } from "react";
import hash from "../../../images/hashTag.svg";
import { API, graphqlOperation } from "aws-amplify";
import { updateUser } from "../../../graphql/mutations";
import workoutHeaderIcon from "../../../images/workoutHeaderIcon.svg";
import { ReactComponent as PlusCircle } from "../../../images/GreenStar.svg";
import { ReactComponent as MinusCircle } from "../../../images/MinusCircle.svg";
import { ReactComponent as X } from "../../../images/X20.svg";
import WorkoutSession from "../WorkoutCalendar/WorkoutSession";
import { ReactComponent as Health } from "../../../images/health.svg";
const ClientWorkoutDetail = (props) => {
  const [showInput, toggleShowInput] = useState(false);

  const [inputInjuries, setInputInjuries] = useState("");
  async function updateInjuries(items) {
    await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: props?.data?.data?.getUser?.id,
          _version: props?.data?.data?.getUser?._version,
          injuries: items,
        },
      })
    );

    props.fetchData();
  }

  async function deleteUpdateInjuries(list) {
    await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: props?.data?.data?.getUser?.id,
          _version: props?.data?.data?.getUser?._version,
          injuries: list,
        },
      })
    );

    props.fetchData();
  }
  const deleteInjuries = (i) => {
    const list = props?.data?.data?.getUser?.injuries;
    list.splice(i, 1);
    deleteUpdateInjuries(list);
  };

  const saveInjuries = () => {
    const items = props?.data?.data?.getUser?.injuries;

    if (inputInjuries !== "" && items !== null) {
      items.push(inputInjuries);
      updateInjuries(items);
    } else if (inputInjuries !== "") {
      updateInjuries(inputInjuries);
    }
  };
  return (
    <>
      <div className="Workout">
        <div className="WorkoutClientContainer">
          <div className="workoutHeader">
            <img style={{ marginLeft: "6px" }} src={workoutHeaderIcon} alt="" />
            <span className="workoutInfoHeaderText">Workout Information</span>
          </div>
          <hr className="lineBelowWorkoutHeader" />
          <div className="workoutBoxItems">
            <div className="workoutBoxItems__left">
              <div className="workoutBoxItem">
                <img className="boxIcon" src={hash} alt="" />
                <div className="boxDetail">
                  <td>
                    <span className="boxDetailHeader">workout Per Week</span>
                    <span className="boxDetailHeaderInfo">
                      {props?.data?.data?.getUser?.workoutCountPerWeek
                        ? props?.data?.data?.getUser?.workoutCountPerWeek +
                          "Times"
                        : "0 Times"}
                    </span>
                  </td>
                </div>
              </div>

              <div className="workoutBoxItem">
                <img className="boxIcon" src={Health} alt="" />
                <div className="boxDetail">
                  <td>
                    <span className="boxDetailHeader">Want to achieve</span>
                    <span className="boxDetailHeaderInfo">
                      {props?.data?.data?.getUser?.achievement}
                    </span>
                  </td>
                </div>
              </div>
            </div>
            <div className="WorkoutCalendarContainer__content__right">
              <div className="WorkoutCalendarContainer__content__right__header">
                <div>Injuries</div>
                <div>
                  {!showInput ? (
                    <PlusCircle onClick={() => toggleShowInput(!showInput)} />
                  ) : (
                    <MinusCircle onClick={() => toggleShowInput(!showInput)} />
                  )}
                </div>
              </div>
              <div className="WorkoutCalendarContainer__content__right__body">
                {props?.data?.data?.getUser?.injuries &&
                props?.data?.data?.getUser?.injuries.length > 0
                  ? props?.data?.data?.getUser?.injuries.map((item, i) => (
                      <div key={i}>
                        <div
                          style={{ backgroundColor: "white" }}
                          className={
                            "WorkoutCalendarContainer__content__right__item"
                          }
                        >
                          {item}
                        </div>
                        <div className="WorkoutCalendarContainer__content__right__item__hide">
                          <X onClick={() => deleteInjuries(i)} />
                        </div>
                      </div>
                    ))
                  : null}

                {showInput && (
                  <input
                    type="text"
                    name="inputText"
                    placeholder="Edit"
                    onChange={(e) => setInputInjuries(e.target.value)}
                    onBlur={saveInjuries}
                    className="WorkoutCalendarContainer__content__right__item"
                    style={{
                      minWidth: "30px",
                      height: "25px",
                      padding: "5px",
                      margin: "15px",
                      display: "flex",
                      outline: "none",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <WorkoutSession id={props.id} />
      </div>
    </>
  );
};
export default ClientWorkoutDetail;
