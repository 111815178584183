import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { Provider } from "react-redux";
import store from "./store";
import "./styles/app.scss";
import App from "./App";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { createRoot } from "react-dom/client";
import { CometChat } from "@cometchat-pro/chat";
import { ApolloProvider } from "@apollo/client";
import client from "./apollo-client";
Amplify.configure(awsExports);

const container = document.getElementById("root");

const root = createRoot(container!); // createRoot(container!) if you use TypeScript
const appID = process.env.REACT_APP_CHAT_APP_ID;
const region = process.env.REACT_APP_CHAT_REGION;
const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(region)
  .build();
CometChat.init(appID, appSetting).then(
  () => {
    console.log("Initialization completed successfully");
    // You can now call login function.
  },
  (error) => {
    console.log("Initialization failed with error:", error);
    // Check the reason for error and take appropriate action.
  }
);

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </ApolloProvider>
);
